import { NoSsr, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { SignInErrorTypes } from 'app/auth/signin/shared';
import retry from 'async-retry';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Spinner from 'components/shared/Spinner';
import { castActiveState } from 'state/atoms';
import { InferTRPCOutputTypes, trpc } from 'helpers/trpc';
import { signInPageURL } from 'auth/constants';

type AiSCOUTRole = NonNullable<
  InferTRPCOutputTypes['shared']['currentUser']
>['user']['aiSCOUTRole'];

const checkRoleIsAllowed = (
  aiSCOUTRole: AiSCOUTRole,
  allowedRoles: (AiSCOUTRole | 'All')[]
): boolean => {
  // Check if all are allowed or no roles are provided
  if (allowedRoles.length === 0 || allowedRoles.includes('All')) {
    return true;
  }

  // Find out if our current user role is in the list.
  return allowedRoles.includes(aiSCOUTRole);
};

const checkClubIsAllowed = (
  aiSCOUTClub: number,
  allowedClubs: (number | 'All')[]
): boolean => {
  // Check if all clubs are allowed or no clubs are provided
  if (allowedClubs.length === 0 || allowedClubs.includes('All')) {
    return true;
  }

  // Find out if our current user club is in the list.
  return allowedClubs.includes(aiSCOUTClub);
};

// Props for this component.
interface Props {
  /**
   * Should we allow unauthenticated users to access this page?
   */
  allowUnauthenticated?: boolean;

  /**
   * What is the array of allowed roles who can view this page?
   *
   * Pass in 'All' or leave off to allow all roles.
   *
   * @default ['All']
   */
  allowedRoles?: (AiSCOUTRole | 'All')[];

  /**
   * What is the array of allowed clubs who can view this page?
   *
   * Pass in 'All' or leave off to allow all clubs.
   *
   * @default ['All']
   */
  allowedClubs?: (number | 'All')[];

  /**
   * The children to render if the user is allowed to view this page.
   */
  children?: React.ReactNode;
}

const UserSessionProvider = ({
  allowUnauthenticated = false,
  allowedRoles = [],
  allowedClubs = [],
  children,
}: Props) => {
  const currentUserQuery = trpc.shared.currentUser.useQuery();

  const isRoleAllowed = Boolean(
    currentUserQuery.data &&
      checkRoleIsAllowed(currentUserQuery.data.user.aiSCOUTRole, allowedRoles)
  );

  const isClubAllowed = Boolean(
    currentUserQuery.data &&
      checkClubIsAllowed(currentUserQuery.data.club.activeClubId, allowedClubs)
  );

  if (allowUnauthenticated || (isRoleAllowed && isClubAllowed)) {
    return <NoSsr>{children}</NoSsr>;
  }

  return (
    <CheckLogin
      allowUnauthenticated={allowUnauthenticated}
      allowedRoles={allowedRoles}
      allowedClubs={allowedClubs}
    >
      {children}
    </CheckLogin>
  );
};

/**
 * This component is used to provide the current user to the rest of the app and
 * also check if the user is allowed to be on the requested page.
 * @param Props: Props for this component.
 * @returns
 */
export const CheckLogin = ({
  allowUnauthenticated = false,
  allowedRoles = [],
  allowedClubs = [],
  children,
}: Props) => {
  const utils = trpc.useContext();
  const queryClient = useQueryClient();
  const router = useRouter();
  const [checkingSession, setCheckingSession] = useState(true);
  const [loadingText, setLoadingText] = useState('Loading...');
  const castActive = useRecoilValue(castActiveState);

  useEffect(() => {
    if (router.isReady) {
      // Async function so we can check there is a valid session.
      const checkUserSessionValid = async () => {
        // Call to get the User Details - this will throw if the session is wrong
        // or the dev endpoint is broken. This will also store the return in the
        // cache so others can use it.
        const currentUser = await utils.shared.currentUser.fetch(undefined, {
          retry: 1, // fail fast, retry will be handled by the retryer below if in cast mode.
          staleTime: 5000, // Make sure initial loads don't refresh this .. There is no point.
        });

        if (!currentUser) {
          throw new Error('No Current User');
        } else {
          return currentUser;
        }
      };

      setLoadingText('Checking your login ...');

      // Call our checking function above so it can do Async stuff.
      if (allowUnauthenticated) {
        // let the rest of the UI Load.
        setCheckingSession(false);
      } else {
        // if anything throws, we retry
        retry(
          async (_bail, attempts) => {
            // Update the UI if we are retrying
            if (attempts > 1) {
              setLoadingText(`Checking your login ... (Attempt ${attempts})`);
            }

            // Check!
            return checkUserSessionValid();
          },
          {
            forever: castActive,
            maxTimeout: 10000,
            ...(!castActive && { retries: 0 }),
          }
        )
          .then((currentUserReturn) => {
            // Check if the user is allowed to be on this page.
            const isRoleAllowed = checkRoleIsAllowed(
              currentUserReturn.user.aiSCOUTRole,
              allowedRoles
            );

            const isClubAllowed = checkClubIsAllowed(
              currentUserReturn.club.activeClubId,
              allowedClubs
            );

            if (isRoleAllowed && isClubAllowed) {
              // We're good to go!
              setLoadingText('Welcome back!');

              // let the rest of the UI Load.
              setCheckingSession(false);
            } else {
              // We're not allowed here.

              const errorType: SignInErrorTypes = 'unauthorised';
              setLoadingText('You are not allowed on this page! 😟');
              setTimeout(() => {
                setLoadingText('');
                router.push({
                  pathname: signInPageURL,
                  query: {
                    redirectURL: window.location.href,
                    error: errorType,
                  },
                });
              }, 2000);
            }
          })
          .catch((_err) => {
            // Check if any old user can see this page.
            if (allowUnauthenticated) {
              // let the rest of the UI Load.
              setCheckingSession(false);
            } else {
              // Check if we are production or Dev
              // TODO: Figure out our approach to local Dev - Do we want to call
              // against stage and actually log in? For testing enabling login.
              if (true) {
                // We had an error checking the session so the user is not logged in. Hence push them to
                // the login page with a redirect variable to bring them back afterwards.
                setLoadingText(`Sending you to login...`);

                router.push({
                  pathname: signInPageURL,
                  query: { redirectURL: window.location.href },
                });
              } else {
              }
            }
          });
      }
    }
  }, [
    allowUnauthenticated,
    allowedRoles,
    allowedClubs,
    castActive,
    queryClient,
    router,
    utils,
  ]);

  if (checkingSession) {
    return (
      <Spinner>
        <Typography fontSize={16} color="white">
          {loadingText}
        </Typography>
      </Spinner>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default UserSessionProvider;

/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * AiSCOUT API [STAGING]
 * OpenAPI spec version: 2.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { customInstance } from './customAxiosInstance';
import type { ErrorType } from './customAxiosInstance';
export type GetApiV2UsersUserIdNotificationsParams = {
  notificationId?: number;
  filterTime?: string;
  limit?: number;
};

export type PutApiV2UsersUserIdNotificationsSeenParams = {
  lastSeen: string;
};

export type GetApiV2TrialsTurkentriesCountParams = {
  query?: V2TrialsQueriesTurkEntriesCountQuery;
};

export type GetApiV2TrialsTurkentriesNextParams = {
  offset?: number;
  includeAnalysisLogs?: boolean;
};

export type GetApiV2TrialsTrialIdParams = {
  includeEntryMeasurementTypes?: boolean;
  includeEntryRatingTypes?: boolean;
  includeEquipments?: boolean;
  includeInstructions?: boolean;
  includeUnlockRequirements?: boolean;
  includeMetadata?: boolean;
};

export type GetApiV2TrialsTrialIdEntriesSummaryCountParams = {
  ageGroupId?: number | null;
  gender?: PlayersEnumsGender;
  preferredFootType?: PlayersEnumsPreferredFootType;
  minHeight?: number | null;
  maxHeight?: number | null;
  minWeight?: number | null;
  maxWeight?: number | null;
  homeCountyIds?: string | null;
  homeCountryIds?: string | null;
  dateOfBirthMin?: string | null;
  dateOfBirthMax?: string | null;
  positionIds?: string | null;
};

export type GetApiV2TrialsTrialIdEntriesSummaryParams = {
  ageGroupId?: number | null;
  gender?: PlayersEnumsGender;
  preferredFootType?: PlayersEnumsPreferredFootType;
  minHeight?: number | null;
  maxHeight?: number | null;
  minWeight?: number | null;
  maxWeight?: number | null;
  homeCountyIds?: string | null;
  homeCountryIds?: string | null;
  dateOfBirthMin?: string | null;
  dateOfBirthMax?: string | null;
  sortType?: string | null;
  includeSignedProClub?: boolean;
  positionIds?: string | null;
  offset?: number;
  limit?: number;
};

export type GetApiV2TrialsTrialIdTrialinstructionsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2TrialsTrialIdSummaryParams = {
  includeTrialEquipments?: boolean;
  includeTrialInstructions?: boolean;
  includeProClub?: boolean;
  includeUnlockRequirements?: boolean;
};

export type GetApiV2TrialidentitiesParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2TrialgroupsTrialGroupIdRulesParams = {
  includeDescriptionDebugOnly?: boolean;
};

export type GetApiV2TrialgroupsSummaryParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams = {
  includeProClub?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2TrialgroupsQuestionsParams = {
  trialGroupIds: string;
  includePopUpQuestions?: boolean;
  includeProfileBasedQuestions?: boolean;
  onlyUnansweredQuestions?: boolean;
};

export type GetApiV2TrialgroupsPopupquestionsParams = {
  trialGroupIds: string;
  onlyUnansweredQuestions?: boolean;
};

export type GetApiV2TrialgroupsTrialGroupIdParams = {
  includeTrials?: boolean;
  includeInfoItems?: boolean;
  includePopUpQuestions?: boolean;
  includePlayerQuestions?: boolean;
};

export type GetApiV2TrialgroupsTrialGroupIdSummaryParams = {
  includeTrials?: boolean;
  includeReview?: boolean;
  includePlayerEntryState?: boolean;
  includeUnansweredQuestions?: boolean;
  includeProClub?: boolean;
  includeInfoItems?: boolean;
};

export type GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams = {
  includeTrials?: boolean;
  includeProClub?: boolean;
  limit?: number;
};

export type GetApiV2TrialcategoriesParams = {
  includeTrialGroupsCount?: boolean;
};

export type GetApiV2TrialcategoriesTrialCategoryIdProclubsParams = {
  includeTrialGroupsCount?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams =
  {
    trainingAvailabilityTypes?: string | null;
    orderBy?: string | null;
    offset?: number;
    limit?: number;
  };

export type GetApiV2TrainingsessionsBatchParams = {
  trainingSessionIds: string;
  includeCountries?: boolean;
  includeCounties?: boolean;
  includePositions?: boolean;
  includeTrials?: boolean;
  includeEntryStates?: boolean;
  includePlayerStates?: boolean;
};

export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams =
  {
    trainingAvailabilityTypes?: string | null;
  };

export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams = {
  sortType?: V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType;
};

export type GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams =
  {
    isStarted?: boolean;
    offset?: number;
    limit?: number;
  };

export type GetApiV2TestParams = {
  query?: V3TestsQueriesIndexQuery;
};

export type GetApiV2TeamsSearchCountParams = {
  searchTerm?: string;
  onlyTeamsAvailableForPlayer?: boolean;
};

export type GetApiV2TeamsSearchParams = {
  searchTerm?: string;
  onlyTeamsAvailableForPlayer?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2SummaryreportsCountParams = {
  proClubId?: number;
  scoutId?: number;
};

export type GetApiV2SummaryreportsParams = {
  proClubId?: number;
  scoutId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2SuggestedopportunityproclubsParams = {
  opportunityNodeId?: number;
  limit?: number;
};

export type GetApiV2SignuptiersFindParams = {
  countryId: number;
  dateOfBirth: string;
};

export type GetApiV2ScoutsScoutIdScoutamateurfixturesParams = {
  includePlayers?: boolean;
  futureFixturesOnly?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdReportsStatsParams = {
  includePlayerReportStats?: boolean;
  includeGameReportStats?: boolean;
  includeSummaryReportStats?: boolean;
};

export type GetApiV2ScoutsScoutIdGamereportdraftsParams = {
  status: GameReportDraftsEnumsGameReportDraftStatus;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdGamereportdraftsCountParams = {
  status: GameReportDraftsEnumsGameReportDraftStatus;
};

export type GetApiV2ScoutsScoutIdGamereportsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdSummaryreportdraftsParams = {
  status: SummaryReportDraftsEnumsSummaryReportDraftStatus;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams = {
  status: SummaryReportDraftsEnumsSummaryReportDraftStatus;
};

export type GetApiV2ScoutsScoutIdSummaryreportsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdPlayerreportsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdPlayerreportdraftsParams = {
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams = {
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
};

export type GetApiV2ScoutsScoutIdScoutingnotesCountParams = {
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
};

export type GetApiV2ScoutsScoutIdScoutingnotesParams = {
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
  includePlayers?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdScoutingreportsParams = {
  offset?: number;
  limit?: number;
};

export type PutApiV2ScoutsScoutIdNotificationsSeenParams = {
  lastSeen: string;
};

export type GetApiV2ScoutsScoutIdNotificationsParams = {
  lastNotificationId?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdFollowingPlayersCountParams = {
  searchTerm?: string;
  genderId?: PlayersEnumsGender;
  watchPositionIds?: string;
  dateOfBirthMin?: string;
  dateOfBirthMax?: string;
};

export type GetApiV2ScoutsScoutIdFollowingPlayersParams = {
  searchTerm?: string;
  genderId?: PlayersEnumsGender;
  watchPositionIds?: string;
  dateOfBirthMin?: string;
  dateOfBirthMax?: string;
  includeSignedProClub?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutsScoutIdParentinvitationsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutingreportsScoutingReportIdSummaryParams = {
  scoutingReportId: number;
};

export type GetApiV2ScoutingnotesScoutingNoteIdSummaryParams = {
  includeScoutingPlayers?: boolean;
};

export type GetApiV2ScoutingnotesCountParams = {
  proClubId?: number;
  scoutId?: number;
};

export type GetApiV2ScoutingnotesParams = {
  proClubId?: number;
  scoutId?: number;
  includePlayers?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams = {
  includePlayer?: boolean;
};

export type GetApiV2SchoolsSearchParams = {
  searchTerm?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProleaguesProLeagueIdProclubsParams = {
  playerId: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProleaguesParams = {
  includeProClubsCount?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams =
  {
    offset?: number;
    limit?: number;
  };

export type GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams =
  {
    offset?: number;
    limit?: number;
  };

export type GetApiV2ProclubsProClubIdGamereportquestionsParams = {
  isTeamBasedQuestion?: boolean;
};

export type GetApiV2ProclubsProClubIdPlayerreportquestionsParams = {
  isPlayerBasedQuestion?: boolean;
};

export type GetApiV2ProclubsProClubIdScoutingquestionsParams = {
  isPlayerBasedQuestion?: boolean;
};

export type GetApiV2ProclubsProClubIdScoutsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsProClubIdTrialsParams = {
  trialTypes?: string | null;
  trialIdentityIds?: string | null;
  isEnabled?: boolean | null;
  isLocked?: boolean | null;
  isStandalone?: boolean | null;
  includeTrialIdentity?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsSummaryBatchParams = {
  ids?: string;
};

export type GetApiV2ProclubsProClubIdPlayersCountParams = {
  searchTerm?: string | null;
  playerProClubSignedTypes?: string | null;
};

export type GetApiV2ProclubsProClubIdPlayersParams = {
  searchTerm?: string | null;
  playerProClubSignedTypes?: string | null;
  includeBiometrics?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsProClubIdPostsParams = {
  lastPostId?: number | null;
  limit?: number;
};

export type GetApiV2ProclubsProClubIdSummaryParams = {
  includeFollowersCount?: boolean;
};

export type GetApiV2ProclubsProClubIdTrialgroupsCountParams = {
  trialCategoryId?: number | null;
  trialTypes: string;
};

export type GetApiV2ProclubsProClubIdTrialgroupsParams = {
  trialCategoryId?: number | null;
  trialTypes?: string | null;
  includeDisabled?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsSearchParams = {
  searchTerm?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2ProclubsSuggestedParams = {
  playerId: number;
  includeFollowing?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PostsPostIdLikesParams = {
  filterTime?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2PostsPostIdParams = {
  includeCreator?: boolean;
};

export type GetApiV2PopupquestionsParams = {
  searchTerm?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdTrialsEntriesParams = {
  lastEntryId?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams = {
  includeRatings?: boolean;
  excludePendingAndFailedEntries?: boolean;
};

export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams = {
  includeEntryReview?: boolean;
};

export type GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams =
  {
    includeBenchmarkScores?: boolean;
    offset?: number;
    limit?: number;
  };

export type GetApiV2PlayersPlayerIdTrainingsessionsStatsParams = {
  academyTeamId?: number | null;
  isStarted?: boolean | null;
  availabilityTypes?: string | null;
};

export type GetApiV2PlayersPlayerIdTrainingsessionsParams = {
  availabilityTypes?: string | null;
  academyTeamId?: number | null;
  isStarted?: boolean | null;
  standaloneTrainingSessions?: boolean | null;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams =
  {
    availabilityTypes: string;
    isStarted?: boolean | null;
    includeTrialIdentities?: boolean;
    offset?: number;
    limit?: number;
  };

export type GetApiV2PlayersPlayerIdTrainingsessiongroupsParams = {
  academyTeamId?: number | null;
  isStarted?: boolean | null;
  availabilityTypes?: string | null;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdPostsParams = {
  lastPostId?: number | null;
  includePlayer?: boolean;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdPerformancelabreportsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdNotificationsParams = {
  lastNotificationId?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdFollowersPlayersParams = {
  filterTime?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdFollowingPlayersParams = {
  filterTime?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdFollowingProclubsParams = {
  includeProLeagues?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdSignupagreementParams = {
  includeTermsAgreement?: boolean;
};

export type GetApiV2PlayersPlayerIdGuardiansSummaryParams = {
  includeContactDetails?: boolean;
};

export type GetApiV2PlayersPlayerIdAcademysubscriptionsParams = {
  includeProClub?: boolean;
  academyType: AcademiesEnumsAcademyType;
};

export type GetApiV2PlayersPlayerIdTrialgroupsParams = {
  trialTypes: string;
  includeProClub?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersSummaryBatchParams = {
  ids: string;
  includeLocationDetails?: boolean;
  includeProfileImage?: boolean;
  includePrimaryPosition?: boolean;
  includeFollowingScoutsCountByProClubId?: number | null;
  includePlayerReportsCountByProClubId?: number | null;
  includeCreatedPlayerReportDraftsCountByProClubId?: number | null;
};

export type GetApiV2PlayersPlayerIdSummaryParams = {
  includeSignedClub?: boolean;
  includeAcademyMemberProClub?: boolean;
  includeLocationDetails?: boolean;
  includeCanPlayForCountries?: boolean;
  includeWatchPositions?: boolean;
  includeWatchPositionsCount?: boolean;
  includePlayerTeams?: boolean;
  includePlayerTeamsCount?: boolean;
  includeProfileImage?: boolean;
  includePersonalStatement?: boolean;
  includePositions?: boolean;
  includeGuardianDetails?: boolean;
  includePassportAuthorities?: boolean;
  includeNationalities?: boolean;
  includeContactDetails?: boolean;
  includeEthnicity?: boolean;
  includeMeasurements?: boolean;
  includeAmateurClubsCount?: boolean;
};

export type GetApiV2PlayersSearchCountParams = {
  searchTerm?: string;
};

export type GetApiV2PlayersSearchParams = {
  searchTerm?: string;
  includeWatchPositionsCount?: boolean;
  includeHomeCounty?: boolean;
  includeDateOfBirth?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersPlayerIdAmateurfixturesParams = {
  futureFixturesOnly?: boolean;
  includeTeams?: boolean;
  includeScoutAmateurFixtures?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams = {
  request?: V2PlayersQueriesAdvancedSearchFiltersDateOfBirthQuery;
};

export type PutApiV2PlayersPlayerIdNotificationsSeenParams = {
  lastSeen: string;
};

export type GetApiV2PlayerreportsCountParams = {
  proClubId?: number;
  scoutId?: number;
};

export type GetApiV2PlayerreportsParams = {
  proClubId?: number;
  scoutId?: number;
  includeParticipants?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams = {
  includeParticipants?: boolean;
};

export type GetApiV2PassportauthoritiesParams = {
  query?: V2PassportAuthoritiesQueriesListQuery;
};

export type GetApiV2OpportunitynodesNodeIdProclubsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2OpportunitynodesParams = {
  parentNodeId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2LeaguesLeagueIdDivisionsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2LeaguesSearchParams = {
  searchTerm?: string;
  countryId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2GuardiansGuardianIdSummaryParams = {
  includeContactDetails?: boolean;
};

export type GetApiV2GamereportdraftsGameReportDraftIdSummaryParams = {
  includeParticipants?: boolean;
};

export type GetApiV2FilesUploadurlBatchParams = {
  count: number;
  fileEntityType: FilesEnumsFileEntityType;
  fileMediaType: SpotMeDomainEnumsFileMediaType;
  mimeType: string;
  proClubId?: number | null;
  scoutingNoteId?: number | null;
  gameReportDraftId?: number | null;
  playerReportDraftId?: number | null;
  playerId?: number | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
};

export type GetApiV2FilesUploadurlParams = {
  fileEntityType: FilesEnumsFileEntityType;
  fileMediaType: SpotMeDomainEnumsFileMediaType;
  mimeType: string;
  proClubId?: number | null;
  scoutingNoteId?: number | null;
  gameReportDraftId?: number | null;
  playerReportDraftId?: number | null;
  playerId?: number | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
};

export type GetApiV2FeedsPlayersHomeParams = {
  lastPostId?: number | null;
  filterTime?: string;
  limit?: number;
};

export type GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams = {
  searchTerm?: string | null;
  libraryCategoryIds?: string | null;
  offset?: number;
  limit?: number;
};

export type GetApiV2EquipmentsParams = {
  trialIdentityId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2EntryratingtypesParams = {
  trialIdentityId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2EntrymeasurementtypesParams = {
  trialIdentityId?: number;
  offset?: number;
  limit?: number;
};

export type GetApiV2DiscoverytrialsParams = {
  trialType: SpotMeDomainEnumsTrialType;
  offset?: number;
  limit?: number;
};

export type GetApiV2DiscoverysponsorsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2DiscoverypostsBatchParams = {
  ids?: string;
};

export type GetApiV2DiscoverycarouselitemsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2CountiesParams = {
  countryIds?: string | null;
  includeCountry?: boolean;
  sortType: SpotMeDomainQueriesCountiesRequestsEnumsCountySortType;
  offset?: number;
  limit?: number;
};

export type PutApiV2CoachesCoachIdNotificationsSeenParams = {
  lastSeen: string;
};

export type GetApiV2CoachesCoachIdNotificationsParams = {
  lastNotificationId?: number;
  limit?: number;
};

export type GetApiV2CoachesCoachIdSummaryParams = {
  includeProClub?: boolean;
};

export type GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams = {
  includeMeasurements?: boolean;
  includeRatings?: boolean;
};

export type GetApiV2AnalysisinterpretersParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2AmateurclubsSearchCountParams = {
  searchTerm?: string;
};

export type GetApiV2AmateurclubsSearchParams = {
  searchTerm?: string;
  offset?: number;
  limit?: number;
};

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams = {
  isStarted?: boolean;
};

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams = {
  isStarted?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams = {
  isStarted?: boolean;
  standaloneTrainingSessions?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams =
  {
    isStarted?: boolean | null;
    isCompleted?: boolean | null;
    sortType: SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType;
    offset?: number;
    limit?: number;
  };

export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams =
  {
    isCompleted?: boolean;
    sortType?: SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType;
    offset?: number;
    limit?: number;
  };

export type GetApiV2AcademyteamsAcademyTeamIdPlayersParams = {
  sortType?: V2AcademyTeamsQueriesPlayersListEnumsSortType;
};

export type GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams =
  {
    includePlayerDiaryEntryNotes?: boolean;
  };

export type GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams = {
  timeZoneId: string;
};

export type GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams = {
  timeZoneId: string;
  includePlayerStatuses?: boolean;
};

export type GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams =
  {
    includeEquipments?: boolean;
    includeInfoItems?: boolean;
    includeExtraInfo?: boolean;
  };

export type GetApiV2AcademiesAcademyIdProclubPlayersParams = {
  playerProClubSignedTypes?: string | null;
  includePrimaryPlayerPosition?: boolean;
  includePlayerFollowingStatus?: boolean;
  includeAcademyTeams?: boolean;
  limit?: number;
};

export type GetApiV2AcademiesAcademyIdTrialgroupsParams = {
  offset?: number;
  limit?: number;
};

export type GetApiV2AcademiesAcademyIdCoachingcontentsParams = {
  minimumAge?: number | null;
  maximumAge?: number | null;
  contentCreatorIds?: string | null;
  tpsCategoryIds?: string | null;
  difficultyLevelId?: number | null;
  includeTpsCategories?: boolean;
  includeContentCreators?: boolean;
  includeIsBookmarked?: boolean;
  searchTerm?: string | null;
  bookmarkedContentsOnly?: boolean;
  offset?: number;
  limit?: number;
};

export type GetApiV2AcademiesAcademyIdOnboardingpanelsParams = {
  limit?: number;
};

export type GetApiV2AcademiesAcademyIdSummaryParams = {
  includeConfirmationDetails?: boolean;
  includeLockScreenDetails?: boolean;
  includeHomePageContent?: boolean;
};

export interface V3UsersCommandsUserRefreshTokensCreateResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
}

export interface V3UsersCommandsUserRefreshTokensCreateCommand {
  /**
   * @minLength 1
   * @maxLength 2000
   */
  accessToken: string;
  /**
   * @minLength 3
   * @maxLength 200
   * @nullable
   */
  fcmToken?: string | null;
  /**
   * @maxLength 450
   * @nullable
   */
  refreshToken?: string | null;
}

export interface V3UsersCommandsSwitchesScoutsResponse {
  /** @nullable */
  accessToken?: string | null;
  designationType?: ScoutsEnumsScoutDesignationType;
  proClubId?: number;
  /** @nullable */
  refreshToken?: string | null;
  scoutId?: number;
  userId?: string;
}

export interface V3UsersCommandsSwitchesScoutsCommand {
  /** @nullable */
  fcmToken?: string | null;
}

export interface V3UsersCommandsSwitchesGuardiansResponse {
  /** @nullable */
  accessToken?: string | null;
  guardianId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V3UsersCommandsSwitchesGuardiansCommand {
  /** @minLength 1 */
  fcmToken: string;
}

export interface V3UsersCommandsSwitchesCoachesResponse {
  /** @nullable */
  accessToken?: string | null;
  coachId?: number;
  coachProClubId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V3UsersCommandsSwitchesCoachesCommand {
  /** @minLength 1 */
  fcmToken: string;
}

export interface V3UsersCommandsSwitchesAdminsResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V3UsersCommandsSwitchesAdminsCommand {
  [key: string]: unknown;
}

export interface V3UsersCommandsResetPasswordSendEmailCommand {
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
}

export interface V3UsersCommandsResetPasswordResetCommand {
  /**
   * @minLength 1
   * @maxLength 6
   */
  code: string;
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface V3UsersCommandsResetPasswordCodeValidateCommand {
  /**
   * @minLength 1
   * @maxLength 6
   */
  code: string;
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
}

export interface V3UsersCommandsLogoutCommand {
  /** @minLength 1 */
  fcmToken: string;
}

export interface V3UsersCommandsLoginResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
  /** @nullable */
  roleTypes?: RolesEnumsRoleType[] | null;
  userId?: string;
}

export interface V3UsersCommandsLoginCommand {
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  password: string;
}

export interface V3UsersCommandsChangePasswordCommand {
  /**
   * @minLength 8
   * @maxLength 64
   */
  newPassword: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  oldPassword: string;
}

export interface V3TestsQueriesIndexResponse {
  /** @nullable */
  message?: string | null;
}

export interface V3TestsQueriesIndexQuery {
  [key: string]: unknown;
}

export interface V3TestsQueriesAuthenticatedResponse {
  /** @nullable */
  message?: string | null;
}

export interface V3TeamsQueriesMetadataTeamTypesListModelsTeamTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3TeamsQueriesMetadataTeamTypesListResponse {
  /** @nullable */
  items?: V3TeamsQueriesMetadataTeamTypesListModelsTeamTypeModel[] | null;
}

export interface V3PostsCommandsUpdateMediaReadyStatusCommand {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaPath: string;
}

export interface V3PostsCommandsDeleteCommand {
  postId: number;
}

export interface V3PlayersQueriesPlayerTeamsListModelsPlayerTeamModel {
  /** @nullable */
  coachEmail?: string | null;
  /** @nullable */
  coachMobile?: string | null;
  /** @nullable */
  coachName?: string | null;
  /** @nullable */
  endDate?: string | null;
  id?: number;
  isMainTeam?: boolean;
  /** @nullable */
  startDate?: string | null;
  team?: V3PlayersQueriesPlayerTeamsListModelsTeamModel;
}

export interface V3PlayersQueriesPlayerTeamsListResponse {
  /** @nullable */
  items?: V3PlayersQueriesPlayerTeamsListModelsPlayerTeamModel[] | null;
}

export interface V3PlayersQueriesPlayerTeamsListModelsLeagueModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3PlayersQueriesPlayerTeamsListModelsDivisionModel {
  id?: number;
  league?: V3PlayersQueriesPlayerTeamsListModelsLeagueModel;
  /** @nullable */
  name?: string | null;
}

export interface V3PlayersQueriesPlayerTeamsListModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3PlayersQueriesPlayerTeamsListModelsTeamModel {
  ageGroup?: V3PlayersQueriesPlayerTeamsListModelsAgeGroupModel;
  division?: V3PlayersQueriesPlayerTeamsListModelsDivisionModel;
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V3PlayersQueriesPlayerAffiliationsListModelsAffiliationModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3PlayersQueriesPlayerAffiliationsListResponse {
  /** @nullable */
  items?: V3PlayersQueriesPlayerAffiliationsListModelsAffiliationModel[] | null;
}

export interface V3PlayersQueriesNotificationsStatsResponse {
  hasUnseen?: boolean;
}

export interface V3PlayersQueriesGuardianConsentResponse {
  /** @nullable */
  hasGuardianConsent?: boolean | null;
}

export interface V3PlayersCommandsPlayerTeamsUpdateCommand {
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  coachEmail?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  coachMobile?: string | null;
  /**
   * @maxLength 50
   * @nullable
   */
  coachName?: string | null;
  /** @nullable */
  endDate?: string | null;
  isMainTeam?: boolean;
  /** @minLength 1 */
  startDate: string;
}

export interface V3PlayersCommandsPlayerTeamsCreateCommand {
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  coachEmail?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  coachMobile?: string | null;
  /**
   * @maxLength 50
   * @nullable
   */
  coachName?: string | null;
  /** @nullable */
  endDate?: string | null;
  isMainTeam?: boolean;
  /** @minLength 1 */
  startDate: string;
  teamId: number;
}

export interface V3PlayersCommandsPlayerAffiliationsCreateCommand {
  /**
   * @minLength 1
   * @maxLength 15
   */
  uniqueEntryCode: string;
}

export interface V3PlayersCommandsNotificationsMarkAllAsSeenCommand {
  /** @minLength 1 */
  lastSeen: string;
  playerId?: number;
}

export interface V3PlayersCommandsLoginResponse {
  /** @nullable */
  accessToken?: string | null;
  playerId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V3PlayersCommandsLoginCommand {
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /**
   * @minLength 3
   * @maxLength 200
   */
  fcmToken: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  password: string;
}

export interface V3PlayersCommandsGuardianConsentUpdateCommand {
  /**
   * @minLength 1
   * @maxLength 6
   */
  code: string;
}

export interface V3PlayersCommandsGuardianConsentSendCommand {
  [key: string]: unknown;
}

export interface V3PlayersCommandsDeleteCommand {
  /**
   * @minLength 8
   * @maxLength 64
   * @nullable
   */
  password?: string | null;
  userId: string;
}

export interface V3NotificationsCommandsMarkAsClickedCommand {
  notificationId: number;
}

export interface V3NotificationsCommandsDeleteCommand {
  notificationId: number;
}

export interface V3FilesQueriesUploadUrlSingleUrlResponse {
  /** @nullable */
  preSignedUrl?: string | null;
  /** @nullable */
  s3ObjectKey?: string | null;
}

export interface V3FilesQueriesUploadUrlSingleUrlQuery {
  fileEntityType: FilesEnumsFileEntityType;
  fileMediaType: SpotMeDomainEnumsFileMediaType;
  /** @nullable */
  gameReportDraftId?: number | null;
  /** @nullable */
  imageHeight?: number | null;
  /** @nullable */
  imageWidth?: number | null;
  /** @minLength 1 */
  mimeType: string;
  /** @nullable */
  playerId?: number | null;
  /** @nullable */
  playerReportDraftId?: number | null;
  /** @nullable */
  proClubId?: number | null;
  /** @nullable */
  scoutingNoteId?: number | null;
}

export interface V3FilesQueriesUploadUrlBatchUrlResponse {
  /** @nullable */
  items?: V3FilesQueriesUploadUrlBatchUrlModelsSignedUrlModel[] | null;
}

export interface V3FilesQueriesUploadUrlBatchUrlQuery {
  /**
   * @minimum 1
   * @maximum 1000
   */
  count: number;
  fileEntityType: FilesEnumsFileEntityType;
  fileMediaType: SpotMeDomainEnumsFileMediaType;
  /** @nullable */
  gameReportDraftId?: number | null;
  /** @nullable */
  imageHeight?: number | null;
  /** @nullable */
  imageWidth?: number | null;
  /** @minLength 1 */
  mimeType: string;
  /** @nullable */
  playerId?: number | null;
  /** @nullable */
  playerReportDraftId?: number | null;
  /** @nullable */
  proClubId?: number | null;
  /** @nullable */
  scoutingNoteId?: number | null;
}

export interface V3FilesQueriesUploadUrlBatchUrlModelsSignedUrlModel {
  /** @nullable */
  preSignedUrl?: string | null;
  /** @nullable */
  s3ObjectKey?: string | null;
}

export interface V3CountiesQueriesListResponse {
  /** @nullable */
  items?: V3CountiesQueriesListModelsCountyModel[] | null;
  meta?: V3CountiesQueriesListModelsMetadataModel;
}

export interface V3CountiesQueriesListQuery {
  /** @nullable */
  countryIds?: string | null;
  includeCountry?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  sortType: SpotMeDomainQueriesCountiesRequestsEnumsCountySortType;
}

export interface V3CountiesQueriesListModelsMetadataModel {
  /** @nullable */
  countries?: V3CountiesQueriesListModelsCountryModel[] | null;
  limit?: number;
  offset?: number;
}

export interface V3CountiesQueriesListModelsCountyModel {
  countryId?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3CountiesQueriesListModelsCountryModel {
  flag?: CommonResponsesMediaMediaItemResponse;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3AgeGroupsQueriesListModelsAgeGroupModel {
  age?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V3AgeGroupsQueriesListResponse {
  /** @nullable */
  items?: V3AgeGroupsQueriesListModelsAgeGroupModel[] | null;
}

export interface V2UsersQueriesUserSettingsModelsSettingModel {
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  key?: string | null;
  /** @nullable */
  name?: string | null;
}

export interface V2UsersQueriesUserSettingsModelsUserSettingModel {
  setting?: V2UsersQueriesUserSettingsModelsSettingModel;
  status?: boolean;
}

export interface V2UsersQueriesUserSettingsResponse {
  /** @nullable */
  items?: V2UsersQueriesUserSettingsModelsUserSettingModel[] | null;
}

export interface V2UsersQueriesRolesModelsRoleModel {
  id?: string;
  /** @nullable */
  name?: string | null;
}

export interface V2UsersQueriesRolesResponse {
  /** @nullable */
  items?: V2UsersQueriesRolesModelsRoleModel[] | null;
}

export interface V2UsersQueriesNotificationsStatsResponse {
  hasUnseen?: boolean;
}

export interface V2UsersQueriesNotificationsStatsQuery {
  /** @minLength 1 */
  userId: string;
}

export interface V2UsersQueriesNotificationsListResponse {
  /** @nullable */
  items?: HandlerServicesV2NotificationHandlersModelsNotificationModel[] | null;
  limit?: number;
}

export interface V2UsersCommandsUserSettingsUpsertCommand {
  settingId: number;
  status: boolean;
}

export interface V2UsersCommandsSwitchesPlayersResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  hasGuardianConsent?: boolean | null;
  isOnboardingComplete?: boolean;
  playerId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V2UsersCommandsSwitchesPlayersCommand {
  /** @minLength 1 */
  fcmToken: string;
}

export interface V2UsersCommandsNotificationsMarkAsClickedCommand {
  notificationId: number;
  /** @minLength 1 */
  userId: string;
}

export interface V2UsersCommandsNotificationsMarkAllAsSeenCommand {
  /** @minLength 1 */
  lastSeen: string;
  userId?: string;
}

export interface V2TrialsQueriesTurkEntriesNextEntryQuery {
  includeAnalysisLogs?: boolean;
  /** @minimum 0 */
  offset?: number;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsPlayerModel {
  dateOfBirth?: string;
  /** @nullable */
  firstName?: string | null;
  gender?: PlayersEnumsGender;
  id?: number;
  /** @nullable */
  lastName?: string | null;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsMetadataModel {
  hasEntry?: boolean;
  offset?: number;
}

export interface V2TrialsQueriesTurkEntriesNextEntryResponse {
  item?: V2TrialsQueriesTurkEntriesNextEntryModelsEntryModel;
  metadata?: V2TrialsQueriesTurkEntriesNextEntryModelsMetadataModel;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryRatingTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryMeasurementTypeModel {
  /** @nullable */
  displayUnit?: string | null;
  id?: number;
  /** @nullable */
  measurementUnit?: string | null;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogRatingModel {
  entryRatingTypeId?: number;
  /** @nullable */
  name?: string | null;
  rating?: number;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogMeasurementModel {
  /** @nullable */
  displayUnit?: string | null;
  entryMeasurementTypeId?: number;
  /** @nullable */
  measurementUnit?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogModel {
  /** @nullable */
  analysedVideoPath?: string | null;
  /** @nullable */
  customErrorCode?: number | null;
  /** @nullable */
  customErrorMessage?: string | null;
  /** @nullable */
  customErrorTitle?: string | null;
  /** @nullable */
  measurements?:
    | V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogMeasurementModel[]
    | null;
  /** @nullable */
  nationalRatingSystemScore?: number | null;
  originType?: EntryAnalysisLogsEnumsOriginType;
  /** @nullable */
  ratedAt?: string | null;
  /** @nullable */
  ratedByScoutId?: number | null;
  /** @nullable */
  ratings?:
    | V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogRatingModel[]
    | null;
  /** @nullable */
  reason?: string | null;
  score?: number;
  trialEntryReviewType?: DrillEntriesEnumsDrillEntryFeedbackType;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsDrillModel {
  id?: number;
  /** @nullable */
  measurementTypes?:
    | V2TrialsQueriesTurkEntriesNextEntryModelsEntryMeasurementTypeModel[]
    | null;
  /** @nullable */
  ratingTypes?:
    | V2TrialsQueriesTurkEntriesNextEntryModelsEntryRatingTypeModel[]
    | null;
  /** @nullable */
  title?: string | null;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2TrialsQueriesTurkEntriesNextEntryModelsEntryModel {
  /** @nullable */
  analysedVideoUrl?: string | null;
  /** @nullable */
  ballSize?: number | null;
  /** @nullable */
  created?: string | null;
  /** @nullable */
  readonly currentStatus?: string | null;
  currentStatusCode?: SpotMeDomainEnumsDrillAnalyzedNotification;
  /** @nullable */
  entryAnalysisLogs?:
    | V2TrialsQueriesTurkEntriesNextEntryModelsEntryAnalysisLogModel[]
    | null;
  /** @nullable */
  id?: number | null;
  player?: V2TrialsQueriesTurkEntriesNextEntryModelsPlayerModel;
  submittedVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  suggestedAnalysedVideoPath?: string | null;
  trial?: V2TrialsQueriesTurkEntriesNextEntryModelsDrillModel;
}

export interface V2TrialsQueriesTurkEntriesCountResponse {
  count?: number;
}

export interface V2TrialsQueriesTurkEntriesCountQuery {
  [key: string]: unknown;
}

export interface V2TrialsQueriesTrialInstructionsListModelsDrillInstructionModel {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  descriptionHtml?: string | null;
  /** @nullable */
  heading?: string | null;
  /** @nullable */
  introduction?: string | null;
  /** @nullable */
  introductionHtml?: string | null;
  media?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  title?: string | null;
  readonly trialInstructionType?: number;
}

export interface V2TrialsQueriesTrialInstructionsListResponse {
  /** @nullable */
  items?:
    | V2TrialsQueriesTrialInstructionsListModelsDrillInstructionModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TrialsQueriesSummaryModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialsQueriesSummaryModelsEquipmentModel {
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialsQueriesSummaryModelsDrillUnlockRequirementModel {
  /** @nullable */
  description?: string | null;
}

export interface V2TrialsQueriesSummaryModelsDrillInstructionModel {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  descriptionHtml?: string | null;
  /** @nullable */
  heading?: string | null;
  /** @nullable */
  introduction?: string | null;
  /** @nullable */
  introductionHtml?: string | null;
  isInherited?: boolean;
  media?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  title?: string | null;
  readonly trialInstructionType?: number;
}

export interface V2TrialsQueriesSummaryResponse {
  /** @nullable */
  allowedEntryLimit?: number | null;
  badge?: CommonResponsesMediaImageResponse;
  coverImage?: CommonResponsesMediaImageResponse;
  demoVideo?: CommonResponsesMediaVideoResponse;
  id?: number;
  isLocked?: boolean;
  measurementFactType?: DrillsEnumsDrillMeasurementFactType;
  previewImage?: CommonResponsesMediaImageResponse;
  proClub?: V2TrialsQueriesSummaryModelsProClubModel;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  trialEquipments?: V2TrialsQueriesSummaryModelsEquipmentModel[] | null;
  /** @nullable */
  trialEstimatedTime?: string | null;
  /** @nullable */
  trialInstructions?:
    | V2TrialsQueriesSummaryModelsDrillInstructionModel[]
    | null;
  type?: SpotMeDomainEnumsTrialType;
  /** @nullable */
  unlockRequirements?:
    | V2TrialsQueriesSummaryModelsDrillUnlockRequirementModel[]
    | null;
}

export interface V2TrialsQueriesEntriesSummaryListResponse {
  /** @nullable */
  items?: V2TrialsQueriesEntriesSummaryListModelsDrillEntryModel[] | null;
  meta?: V2TrialsQueriesEntriesSummaryListModelsMetadataModel;
}

export interface V2TrialsQueriesEntriesSummaryListQuery {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  dateOfBirthMax?: string | null;
  /** @nullable */
  dateOfBirthMin?: string | null;
  drillId?: number;
  gender?: PlayersEnumsGender;
  /** @nullable */
  homeCountryIds?: string | null;
  /** @nullable */
  homeCountyIds?: string | null;
  includeSignedProClub?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @exclusiveMinimum
   * @nullable
   */
  maxHeight?: number | null;
  /**
   * @minimum 0
   * @exclusiveMinimum
   * @nullable
   */
  maxWeight?: number | null;
  /** @nullable */
  minHeight?: number | null;
  /** @nullable */
  minWeight?: number | null;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  /** @nullable */
  positionIds?: string | null;
  preferredFootType?: PlayersEnumsPreferredFootType;
  /** @nullable */
  sortType?: string | null;
}

export interface V2TrialsQueriesEntriesSummaryListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2TrialsQueriesEntriesSummaryListModelsPlayerModel {
  dateOfBirth?: string;
  /** @nullable */
  height?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  preferredFoot?: PlayersEnumsPreferredFootType;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  signedProClubId?: number | null;
  /** @nullable */
  weight?: number | null;
}

export interface V2TrialsQueriesEntriesSummaryListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  proClubs?: V2TrialsQueriesEntriesSummaryListModelsProClubModel[] | null;
}

export interface V2TrialsQueriesEntriesSummaryListModelsDrillEntryModel {
  id?: number;
  player?: V2TrialsQueriesEntriesSummaryListModelsPlayerModel;
  score?: number;
}

export interface V2TrialsQueriesEntriesSummaryCountResponse {
  count?: number;
}

export interface V2TrialsQueriesEntriesSummaryCountQuery {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  dateOfBirthMax?: string | null;
  /** @nullable */
  dateOfBirthMin?: string | null;
  drillId?: number;
  gender?: PlayersEnumsGender;
  /** @nullable */
  homeCountryIds?: string | null;
  /** @nullable */
  homeCountyIds?: string | null;
  /**
   * @minimum 0
   * @exclusiveMinimum
   * @nullable
   */
  maxHeight?: number | null;
  /**
   * @minimum 0
   * @exclusiveMinimum
   * @nullable
   */
  maxWeight?: number | null;
  /** @nullable */
  minHeight?: number | null;
  /** @nullable */
  minWeight?: number | null;
  /** @nullable */
  positionIds?: string | null;
  preferredFootType?: PlayersEnumsPreferredFootType;
}

export interface V2TrialsQueriesEntriesDetailsModelsPendingDrillEntryModel {
  entryVideo?: CommonResponsesMediaVideoResponse;
}

export interface V2TrialsQueriesEntriesDetailsModelsErrorDrillEntryModel {
  /** @nullable */
  statusDescription?: string | null;
  /** @nullable */
  statusTitle?: string | null;
}

export interface V2TrialsQueriesEntriesDetailsModelsDrillEntryRatingModel {
  /** @nullable */
  maximumRating?: number | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  rating?: number | null;
  ratingPercentage?: number;
  /** @nullable */
  type?: string | null;
}

export interface V2TrialsQueriesEntriesDetailsModelsAnalysedDrillEntryModel {
  analysedVideo?: CommonResponsesMediaVideoResponse;
  entryVideo?: CommonResponsesMediaVideoResponse;
  maximumScore?: number;
  ratedAt?: string;
  /** @nullable */
  ratings?: V2TrialsQueriesEntriesDetailsModelsDrillEntryRatingModel[] | null;
  /** @nullable */
  score?: number | null;
}

export interface V2TrialsQueriesEntriesDetailsResponse {
  analysedTrialEntry?: V2TrialsQueriesEntriesDetailsModelsAnalysedDrillEntryModel;
  created?: string;
  entryId?: number;
  errorTrialEntry?: V2TrialsQueriesEntriesDetailsModelsErrorDrillEntryModel;
  pendingTrialEntry?: V2TrialsQueriesEntriesDetailsModelsPendingDrillEntryModel;
  trialEntryStatus?: SpotMeDomainEnumsDrillEntryStatus;
}

export interface V2TrialsQueriesDetailsModelsProClubModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialsQueriesDetailsModelsMetadataModel {
  isEntryMeasurementTypesInherited?: boolean;
  isEntryRatingTypesInherited?: boolean;
  isEquipmentsInherited?: boolean;
}

export interface V2TrialsQueriesDetailsModelsEquipmentModel {
  id?: number;
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialsQueriesDetailsModelsEntryRatingTypeModel {
  id?: number;
  isAvailableToCoach?: boolean;
  isAvailableToPlayer?: boolean;
  isAvailableToScout?: boolean;
  isAvailableToTurk?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  turkUiText?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2TrialsQueriesDetailsModelsEntryMeasurementTypeModel {
  /** @nullable */
  displayUnit?: string | null;
  /** @nullable */
  highIsBest?: boolean | null;
  id?: number;
  isAvailableToPlayer?: boolean;
  isAvailableToTurk?: boolean;
  /** @nullable */
  measurementUnit?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  turkUiText?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2TrialsQueriesDetailsModelsDrillUnlockRequirementModel {
  /** @nullable */
  description?: string | null;
  id?: number;
  isEnabled?: boolean;
}

export interface V2TrialsQueriesDetailsModelsDrillInstructionModel {
  /** @nullable */
  descriptionHtml?: string | null;
  /** @nullable */
  heading?: string | null;
  id?: number;
  /** @nullable */
  introductionHtml?: string | null;
  isInherited?: boolean;
  media?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialsQueriesDetailsResponse {
  /** @nullable */
  analysisInterpreterId?: string | null;
  backgroundImage?: CommonResponsesMediaMediaItemResponse;
  badge?: CommonResponsesMediaImageResponse;
  coverImage?: CommonResponsesMediaImageResponse;
  demoVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  entryLimit?: number | null;
  /** @nullable */
  entryMeasurementTypes?:
    | V2TrialsQueriesDetailsModelsEntryMeasurementTypeModel[]
    | null;
  /** @nullable */
  entryRatingTypes?: V2TrialsQueriesDetailsModelsEntryRatingTypeModel[] | null;
  /** @nullable */
  equipments?: V2TrialsQueriesDetailsModelsEquipmentModel[] | null;
  id?: number;
  /** @nullable */
  instructions?: V2TrialsQueriesDetailsModelsDrillInstructionModel[] | null;
  isEnabled?: boolean;
  isHiddenScore?: boolean;
  isLocked?: boolean;
  meta?: V2TrialsQueriesDetailsModelsMetadataModel;
  /** @nullable */
  minimumRequiredScore?: number | null;
  /** @nullable */
  nationalRatingSystemMultiplier?: number | null;
  previewImage?: CommonResponsesMediaImageResponse;
  proClub?: V2TrialsQueriesDetailsModelsProClubModel;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  trialEstimatedTime?: string | null;
  /** @nullable */
  trialIdentityId?: number | null;
  trialMeasurementFactType?: DrillsEnumsDrillMeasurementFactType;
  type?: SpotMeDomainEnumsTrialType;
  /** @nullable */
  unlockRequirements?:
    | V2TrialsQueriesDetailsModelsDrillUnlockRequirementModel[]
    | null;
}

export interface V2TrialsCommandsUpdateModelsDrillUnlockRequirementModel {
  /**
   * @minLength 1
   * @maxLength 200
   */
  description: string;
  isEnabled?: boolean;
}

export interface V2TrialsCommandsUpdateModelsDrillInstructionModel {
  /**
   * @minLength 1
   * @maxLength 24000
   */
  descriptionHtml: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  heading: string;
  /**
   * @minLength 1
   * @maxLength 24000
   */
  introductionHtml: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaRelativePath: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2TrialsCommandsUpdateCommand {
  /** @nullable */
  analysisInterpreterId?: string | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  backgroundImagePath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  badgesPath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  coverImagePath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  demoVideoPath: string;
  /** @nullable */
  entryLimit?: number | null;
  /** @nullable */
  entryMeasurementTypeIds?: number[] | null;
  /** @nullable */
  entryRatingTypeIds?: number[] | null;
  /** @nullable */
  equipmentIds?: number[] | null;
  /** @nullable */
  instructions?: V2TrialsCommandsUpdateModelsDrillInstructionModel[] | null;
  isHiddenScore?: boolean;
  isLocked?: boolean;
  /** @nullable */
  minimumRequiredScore?: number | null;
  /** @nullable */
  nationalRatingSystemMultiplier?: number | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  previewImagePath: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  shortDescription: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  subtitle: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  /** @nullable */
  trialEstimatedTime?: string | null;
  /** @nullable */
  trialIdentityId?: number | null;
  trialMeasurementFactType?: DrillsEnumsDrillMeasurementFactType;
  /** @nullable */
  unlockRequirements?:
    | V2TrialsCommandsUpdateModelsDrillUnlockRequirementModel[]
    | null;
}

export interface V2TrialsCommandsTurkEntriesRatingsCreateModelsEntryMeasurementModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialsCommandsTurkEntriesRatingsCreateModelsDrillEntryRatingModel {
  id?: number;
  /** @nullable */
  rating?: number | null;
}

export interface V2TrialsCommandsTurkEntriesRatingsCreateCommand {
  /** @nullable */
  customErrorCode?: number | null;
  /** @nullable */
  customErrorMessage?: string | null;
  /** @nullable */
  customErrorTitle?: string | null;
  /** @nullable */
  errorCode?: number | null;
  ignoreNullRatingsAndMeasurements?: boolean;
  /** @nullable */
  measurements?:
    | V2TrialsCommandsTurkEntriesRatingsCreateModelsEntryMeasurementModel[]
    | null;
  preventPushNotifications?: boolean;
  /** @nullable */
  ratings?:
    | V2TrialsCommandsTurkEntriesRatingsCreateModelsDrillEntryRatingModel[]
    | null;
  replaceAnalysedVideoWithOriginal?: boolean;
}

export interface V2TrialsCommandsDeleteCommand {
  readonly drillId?: number;
  trialId: number;
}

export interface V2TrialsCommandsCreateModelsDrillUnlockRequirementModel {
  /**
   * @minLength 1
   * @maxLength 200
   */
  description: string;
}

export interface V2TrialsCommandsCreateModelsDrillInstructionModel {
  /**
   * @minLength 1
   * @maxLength 24000
   */
  descriptionHtml: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  heading: string;
  /**
   * @minLength 1
   * @maxLength 24000
   */
  introductionHtml: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaRelativePath: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2TrialsCommandsCreateCommand {
  /** @nullable */
  analysisInterpreterId?: string | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  backgroundImagePath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  badgesPath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  coverImagePath: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  demoVideoPath: string;
  /** @nullable */
  entryLimit?: number | null;
  entryMeasurementTypeIds: number[];
  entryRatingTypeIds: number[];
  equipmentIds: number[];
  instructions: V2TrialsCommandsCreateModelsDrillInstructionModel[];
  isEnabled?: boolean;
  isHiddenScore?: boolean;
  isLocked?: boolean;
  /** @nullable */
  minimumRequiredScore?: number | null;
  /** @nullable */
  nationalRatingSystemMultiplier?: number | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  previewImagePath: string;
  proClubId: number;
  /**
   * @minLength 1
   * @maxLength 200
   */
  shortDescription: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  subtitle: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  /** @nullable */
  trialEstimatedTime?: string | null;
  /** @nullable */
  trialIdentityId?: number | null;
  trialMeasurementFactType?: DrillsEnumsDrillMeasurementFactType;
  trialType: SpotMeDomainEnumsTrialType;
  unlockRequirements: V2TrialsCommandsCreateModelsDrillUnlockRequirementModel[];
}

export interface V2TrialsCommandsCloneCommand {
  includeEntryReviewTemplates?: boolean;
  includeEquipments?: boolean;
  includeInstructions?: boolean;
  includeMeasurementTypes?: boolean;
  includeRatingTypes?: boolean;
}

export interface V2TrialsCommandsCacheDeleteCommand {
  [key: string]: unknown;
}

export interface V2TrialIdentitiesQueriesListModelsDrillIdentityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialIdentitiesQueriesListResponse {
  /** @nullable */
  items?: V2TrialIdentitiesQueriesListModelsDrillIdentityModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TrialIdentitiesQueriesDetailsModelsAnalysisInterpreterModel {
  /** @nullable */
  description?: string | null;
  id?: string;
}

export interface V2TrialIdentitiesQueriesDetailsResponse {
  analysisInterpreter?: V2TrialIdentitiesQueriesDetailsModelsAnalysisInterpreterModel;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialIdentitiesCommandsUpdateCommand {
  /** @nullable */
  analysisInterpreterId?: string | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
}

export interface V2TrialIdentitiesCommandsCreateCommand {
  /** @nullable */
  analysisInterpreterId?: string | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
}

export interface V2TrialGroupsQueriesTrialsSummaryModelsProClubModel {
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2TrialGroupsQueriesTrialsSummaryModelsDrillModel {
  completedPlayerCount?: number;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesTrialsSummaryResponse {
  /** @nullable */
  items?: V2TrialGroupsQueriesTrialsSummaryModelsDrillModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
  proClub?: V2TrialGroupsQueriesTrialsSummaryModelsProClubModel;
}

export interface V2TrialGroupsQueriesSummaryModelsTrialInfoItemModel {
  hasEndSeparator?: boolean;
  /** @nullable */
  htmlDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesSummaryModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2TrialGroupsQueriesSummaryResponse {
  bannerImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  bannerSubtitle?: string | null;
  /** @nullable */
  bannerTitle?: string | null;
  completionType?: TrialsEnumsTrialCompletionType;
  id?: number;
  /** @nullable */
  infoItems?: V2TrialGroupsQueriesSummaryModelsTrialInfoItemModel[] | null;
  /** @nullable */
  infoScreenTitle?: string | null;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  playerQuestionTypes?: TrialsEnumsPlayerQuestionType[] | null;
  /** @nullable */
  popUpQuestions?: V2TrialGroupsQueriesSummaryModelsPopUpQuestionModel[] | null;
  proClub?: V2TrialGroupsQueriesSummaryModelsProClubModel;
  proClubId?: number;
  review?: TrialsLegacyTrialFeedbackServicesModelsTrialFeedbackDto;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  trials?: V2TrialGroupsQueriesSummaryModelsDrillModel[] | null;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2TrialGroupsQueriesSummaryModelsPopUpQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialGroupsQueriesSummaryModelsPopUpQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  /** @nullable */
  description?: string | null;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  isSkippable?: boolean;
  /** @nullable */
  options?: V2TrialGroupsQueriesSummaryModelsPopUpQuestionOptionModel[] | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2TrialGroupsQueriesSummaryModelsEntryStateModel {
  /** @nullable */
  entryScore?: number | null;
  hasEntered?: boolean;
  hasPassed?: boolean;
  readonly maximumScore?: number;
  /** @nullable */
  minimumRequiredScore?: number | null;
}

export interface V2TrialGroupsQueriesSummaryModelsDrillModel {
  entryState?: V2TrialGroupsQueriesSummaryModelsEntryStateModel;
  /** @nullable */
  estimatedTime?: string | null;
  hasEntered?: boolean;
  id?: number;
  isLocked?: boolean;
  previewImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesSummaryListModelsTrialModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  completedPlayersCount?: number;
  id?: number;
  /** @nullable */
  infoScreenTitle?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  nameForScoutApp?: string | null;
  proClubId?: number;
}

export interface V2TrialGroupsQueriesSummaryListResponse {
  /** @nullable */
  items?: V2TrialGroupsQueriesSummaryListModelsTrialModel[] | null;
  meta?: V2TrialGroupsQueriesSummaryListModelsMetadataModel;
}

export interface V2TrialGroupsQueriesSummaryListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2TrialGroupsQueriesSummaryListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  proClubs?: V2TrialGroupsQueriesSummaryListModelsProClubModel[] | null;
}

export interface V2TrialGroupsQueriesRulesResponse {
  hasInvalidAncestryRules?: boolean;
  hasInvalidRules?: boolean;
  isTrialGroupChangeInProgress?: boolean;
  unlockRequirements?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto;
  viewRequirements?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto;
}

export interface V2TrialGroupsQueriesQuestionsQuery {
  includePopUpQuestions?: boolean;
  includeProfileBasedQuestions?: boolean;
  onlyUnansweredQuestions?: boolean;
  /** @minLength 1 */
  trialGroupIds: string;
  /** @nullable */
  readonly trialIds?: string | null;
}

export interface V2TrialGroupsQueriesQuestionsModelsTrialPopUpQuestionModel {
  id?: number;
  isSkippable?: boolean;
  popUpQuestionId?: number;
}

export interface V2TrialGroupsQueriesQuestionsModelsTrialModel {
  id?: number;
  /** @nullable */
  playerQuestionTypes?: TrialsEnumsPlayerQuestionType[] | null;
  /** @nullable */
  popUpQuestions?:
    | V2TrialGroupsQueriesQuestionsModelsTrialPopUpQuestionModel[]
    | null;
}

export interface V2TrialGroupsQueriesQuestionsModelsPopUpQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialGroupsQueriesQuestionsModelsPopUpQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  /** @nullable */
  description?: string | null;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  /** @nullable */
  options?:
    | V2TrialGroupsQueriesQuestionsModelsPopUpQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2TrialGroupsQueriesQuestionsResponse {
  /** @nullable */
  popUpQuestions?:
    | V2TrialGroupsQueriesQuestionsModelsPopUpQuestionModel[]
    | null;
  /** @nullable */
  trialGroups?: V2TrialGroupsQueriesQuestionsModelsTrialModel[] | null;
}

export interface V2TrialGroupsQueriesPopUpQuestionsQuery {
  onlyUnansweredQuestions?: boolean;
  /** @minLength 1 */
  trialGroupIds: string;
  /** @nullable */
  readonly trialIds?: string | null;
}

export interface V2TrialGroupsQueriesPopUpQuestionsModelsTrialPopUpQuestionModel {
  id?: number;
  isSkippable?: boolean;
  popUpQuestionId?: number;
}

export interface V2TrialGroupsQueriesPopUpQuestionsModelsTrialModel {
  id?: number;
  /** @nullable */
  popUpQuestionIds?: number[] | null;
  /** @nullable */
  popUpQuestions?:
    | V2TrialGroupsQueriesPopUpQuestionsModelsTrialPopUpQuestionModel[]
    | null;
}

export interface V2TrialGroupsQueriesPopUpQuestionsModelsPopUpQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialGroupsQueriesPopUpQuestionsModelsPopUpQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  /** @nullable */
  description?: string | null;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  /** @nullable */
  options?:
    | V2TrialGroupsQueriesPopUpQuestionsModelsPopUpQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2TrialGroupsQueriesPopUpQuestionsResponse {
  /** @nullable */
  popUpQuestions?:
    | V2TrialGroupsQueriesPopUpQuestionsModelsPopUpQuestionModel[]
    | null;
  /** @nullable */
  trialGroups?: V2TrialGroupsQueriesPopUpQuestionsModelsTrialModel[] | null;
}

export interface V2TrialGroupsQueriesInfoItemsListModelsTrialInfoItemModel {
  hasEndSeparator?: boolean;
  /** @nullable */
  htmlDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesInfoItemsListResponse {
  /** @nullable */
  items?: V2TrialGroupsQueriesInfoItemsListModelsTrialInfoItemModel[] | null;
}

export interface V2TrialGroupsQueriesDetailsModelsTrialInfoItemModel {
  hasEndSeparator?: boolean;
  /** @nullable */
  htmlDescription?: string | null;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesDetailsModelsPopUpQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2TrialGroupsQueriesDetailsModelsPopUpQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  /** @nullable */
  description?: string | null;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  isSkippable?: boolean;
  /** @nullable */
  options?: V2TrialGroupsQueriesDetailsModelsPopUpQuestionOptionModel[] | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2TrialGroupsQueriesDetailsModelsDrillModel {
  /** @nullable */
  estimatedTime?: string | null;
  id?: number;
  isLocked?: boolean;
  previewImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialGroupsQueriesDetailsResponse {
  /** @nullable */
  academyId?: number | null;
  bannerImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  bannerSubtitle?: string | null;
  /** @nullable */
  bannerTitle?: string | null;
  completionType?: TrialsEnumsTrialCompletionType;
  id?: number;
  /** @nullable */
  infoItems?: V2TrialGroupsQueriesDetailsModelsTrialInfoItemModel[] | null;
  /** @nullable */
  infoScreenTitle?: string | null;
  isEnabled?: boolean;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  nameForScoutApp?: string | null;
  /** @nullable */
  playerQuestionTypes?: TrialsEnumsPlayerQuestionType[] | null;
  /** @nullable */
  popUpQuestions?: V2TrialGroupsQueriesDetailsModelsPopUpQuestionModel[] | null;
  proClubId?: number;
  /** @nullable */
  shortDescription?: string | null;
  trialCategoryId?: number;
  /** @nullable */
  trials?: V2TrialGroupsQueriesDetailsModelsDrillModel[] | null;
}

export interface V2TrialGroupsCommandsUpdateModelsInfoItemModel {
  hasEndSeparator?: boolean;
  /**
   * @minLength 1
   * @maxLength 4000
   */
  htmlDescription: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  title: string;
}

export interface V2TrialGroupsCommandsUpdateCommand {
  /** @nullable */
  academyId?: number | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  bannerImagePath: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  bannerSubtitle: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  bannerTitle: string;
  completionType: TrialsEnumsTrialCompletionType;
  /** @nullable */
  infoItems?: V2TrialGroupsCommandsUpdateModelsInfoItemModel[] | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  infoScreenTitle: string;
  isEnabled?: boolean;
  isUnlocked?: boolean;
  /**
   * @minLength 1
   * @maxLength 30
   */
  name: string;
  /**
   * @maxLength 30
   * @nullable
   */
  nameForScoutApp?: string | null;
  /** @nullable */
  playerQuestionTypes: TrialsEnumsPlayerQuestionType[] | null;
  /** @nullable */
  popUpQuestionIds?: number[] | null;
  /**
   * @minLength 1
   * @maxLength 500
   */
  shortDescription: string;
  /** @nullable */
  trialIds?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesPositionRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesPlayerEmailRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: string[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesPlayerCountyRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesHomeCountryRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesGenderRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: PlayersEnumsGender[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesCanPlayForCountryRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesAgeRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRulesAffiliationRule {
  inverseOperator?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsRuleModel {
  affiliationRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesAffiliationRule;
  ageRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesAgeRule;
  canPlayForCountryRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesCanPlayForCountryRule;
  genderRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesGenderRule;
  homeCountryRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesHomeCountryRule;
  playerCountyRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesPlayerCountyRule;
  playerEmailRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesPlayerEmailRule;
  positionRule?: V2TrialGroupsCommandsRulesUpdateModelsRulesPositionRule;
}

export interface V2TrialGroupsCommandsRulesUpdateModelsNodeModel {
  /** @nullable */
  must?: V2TrialGroupsCommandsRulesUpdateModelsNodeModel[] | null;
  rule?: V2TrialGroupsCommandsRulesUpdateModelsRuleModel;
  /** @nullable */
  should?: V2TrialGroupsCommandsRulesUpdateModelsNodeModel[] | null;
}

/**
 * @nullable
 */
export type V2TrialGroupsCommandsRulesUpdateInvalidRulesResponseErrors = {
  [key: string]: string[] | null;
} | null;

export interface V2TrialGroupsCommandsRulesUpdateInvalidRulesResponse {
  /** @nullable */
  codes?: string[] | null;
  /** @nullable */
  readonly errors?: V2TrialGroupsCommandsRulesUpdateInvalidRulesResponseErrors;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  messages?: string[] | null;
  unlockRequirements?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto;
  viewRequirements?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto;
}

export interface V2TrialGroupsCommandsRulesUpdateCommand {
  unlockRequirements?: V2TrialGroupsCommandsRulesUpdateModelsNodeModel;
  viewRequirements?: V2TrialGroupsCommandsRulesUpdateModelsNodeModel;
}

export interface V2TrialGroupsCommandsDeleteCommand {
  trialGroupId: number;
  readonly trialId?: number;
}

export interface V2TrialGroupsCommandsCreateModelsInfoItemModel {
  hasEndSeparator?: boolean;
  /**
   * @minLength 1
   * @maxLength 4000
   */
  htmlDescription: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  title: string;
}

export interface V2TrialGroupsCommandsCreateCommand {
  /** @nullable */
  academyId?: number | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  bannerImagePath: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  bannerSubtitle: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  bannerTitle: string;
  completionType: TrialsEnumsTrialCompletionType;
  infoItems: V2TrialGroupsCommandsCreateModelsInfoItemModel[];
  /**
   * @minLength 1
   * @maxLength 100
   */
  infoScreenTitle: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  name: string;
  /**
   * @maxLength 30
   * @nullable
   */
  nameForScoutApp?: string | null;
  playerQuestionTypes: TrialsEnumsPlayerQuestionType[];
  popUpQuestionIds: number[];
  proClubId: number;
  /**
   * @minLength 1
   * @maxLength 500
   */
  shortDescription: string;
  trialCategoryId: number;
  /** @nullable */
  trialIds?: number[] | null;
}

export interface V2TrialGroupsCommandsCacheDeleteCommand {
  trialGroupId: number;
  readonly trialId?: number;
}

export interface V2TrialCategoriesQueriesTrialGroupsListResponse {
  /** @nullable */
  items?: V2TrialCategoriesQueriesTrialGroupsListModelsTrialModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2TrialCategoriesQueriesTrialGroupsListQuery {
  readonly includeDrills?: boolean;
  includeProClub?: boolean;
  includeTrials?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  trialCategoryId?: number;
}

export interface V2TrialCategoriesQueriesTrialGroupsListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2TrialCategoriesQueriesTrialGroupsListModelsDrillModel {
  /** @nullable */
  estimatedTime?: string | null;
  hasEntered?: boolean;
  id?: number;
  isLocked?: boolean;
  previewImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrialCategoriesQueriesTrialGroupsListModelsTrialModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  /** @nullable */
  infoScreenTitle?: string | null;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  proClub?: V2TrialCategoriesQueriesTrialGroupsListModelsProClubModel;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  trials?: V2TrialCategoriesQueriesTrialGroupsListModelsDrillModel[] | null;
}

export interface V2TrialCategoriesQueriesProClubsListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  trialGroupsCount?: number | null;
}

export interface V2TrialCategoriesQueriesProClubsListResponse {
  /** @nullable */
  items?: V2TrialCategoriesQueriesProClubsListModelsProClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TrialCategoriesQueriesListModelsTrialCategoryModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  trialGroupsCount?: number | null;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2TrialCategoriesQueriesListResponse {
  /** @nullable */
  items?: V2TrialCategoriesQueriesListModelsTrialCategoryModel[] | null;
}

export interface V2TrainingSessionsQueriesTrialsPlayersListQuery {
  drillId?: number;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  /** @nullable */
  orderBy?: string | null;
  /** @nullable */
  trainingAvailabilityTypes?: string | null;
  trainingSessionId?: number;
}

export interface V2TrainingSessionsQueriesTrialsPlayersListModelsPlayerModel {
  availabilityType?: TrainingSessionsEnumsTrainingAvailabilityType;
  dateOfBirth?: string;
  hasSessionSubmission?: boolean;
  /** @nullable */
  height?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  weight?: number | null;
}

export interface V2TrainingSessionsQueriesTrialsPlayersListResponse {
  /** @nullable */
  items?: V2TrainingSessionsQueriesTrialsPlayersListModelsPlayerModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TrainingSessionsQueriesTrialsListModelsDrillModel {
  id?: number;
  isCompleted?: boolean;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2TrainingSessionsQueriesTrialsListResponse {
  /** @nullable */
  items?: V2TrainingSessionsQueriesTrialsListModelsDrillModel[] | null;
}

export interface V2TrainingSessionsQueriesTrainingPlayersStatsResponse {
  hasPlayers?: boolean;
}

export interface V2TrainingSessionsQueriesTrainingPlayersListModelsPlayerModel {
  dateOfBirth?: string;
  /** @nullable */
  height?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  weight?: number | null;
}

export interface V2TrainingSessionsQueriesTrainingPlayersListModelsTrainingPlayerModel {
  availabilityType?: TrainingSessionsEnumsTrainingAvailabilityType;
  player?: V2TrainingSessionsQueriesTrainingPlayersListModelsPlayerModel;
}

export interface V2TrainingSessionsQueriesTrainingPlayersListResponse {
  /** @nullable */
  items?:
    | V2TrainingSessionsQueriesTrainingPlayersListModelsTrainingPlayerModel[]
    | null;
}

export type V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType =
  typeof V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType[keyof typeof V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export interface V2TrainingSessionsQueriesTrainingPlayersListQuery {
  sortType?: V2TrainingSessionsQueriesTrainingPlayersListEnumsSortType;
  trainingSessionId?: number;
}

export interface V2TrainingSessionsQueriesTrainingPlayersCountResponse {
  count?: number;
}

export interface V2TrainingSessionsQueriesTrainingPlayersCountQuery {
  /** @nullable */
  trainingAvailabilityTypes?: string | null;
  trainingSessionId?: number;
}

export interface V2TrainingSessionsQueriesSummaryResponse {
  canReopen?: boolean;
  id?: number;
  isCompleted?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  subtitle?: string | null;
}

export interface V2TrainingSessionsQueriesBatchResponse {
  /** @nullable */
  items?: V2TrainingSessionsQueriesBatchModelsTrainingSessionModel[] | null;
  meta?: V2TrainingSessionsQueriesBatchModelsMetadataModel;
}

export interface V2TrainingSessionsQueriesBatchQuery {
  includeCounties?: boolean;
  includeCountries?: boolean;
  readonly includeDrills?: boolean;
  includeEntryStates?: boolean;
  includePlayerStates?: boolean;
  includePositions?: boolean;
  includeTrials?: boolean;
  /** @minLength 1 */
  trainingSessionIds: string;
}

export interface V2TrainingSessionsQueriesBatchModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrainingSessionsQueriesBatchModelsPlayerStateModel {
  availabilityType?: TrainingSessionsEnumsTrainingAvailabilityType;
  playerId?: number;
}

export interface V2TrainingSessionsQueriesBatchModelsPlayerModel {
  /** @nullable */
  countyId?: number | null;
  /** @nullable */
  customCounty?: string | null;
  dateOfBirth?: string;
  gender?: PlayersEnumsGender;
  /** @nullable */
  homeCountryId?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryPositionId?: number | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2TrainingSessionsQueriesBatchModelsEntryStateModel {
  hasFailedEntry?: boolean;
  hasPendingEntry?: boolean;
  hasRatedEntry?: boolean;
  /** @nullable */
  highestScore?: number | null;
  playerId?: number;
}

export interface V2TrainingSessionsQueriesBatchModelsDrillModel {
  /** @nullable */
  benchmarkScore?: number | null;
  /** @nullable */
  entryStates?: V2TrainingSessionsQueriesBatchModelsEntryStateModel[] | null;
  id?: number;
  readonly maxPossibleScore?: number;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  trialIdentityId?: number | null;
}

export interface V2TrainingSessionsQueriesBatchModelsTrainingSessionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  playerStates?: V2TrainingSessionsQueriesBatchModelsPlayerStateModel[] | null;
  startTime?: string;
  /** @nullable */
  trials?: V2TrainingSessionsQueriesBatchModelsDrillModel[] | null;
}

export interface V2TrainingSessionsQueriesBatchModelsDrillIdentityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrainingSessionsQueriesBatchModelsCountyModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrainingSessionsQueriesBatchModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrainingSessionsQueriesBatchModelsMetadataModel {
  /** @nullable */
  counties?: V2TrainingSessionsQueriesBatchModelsCountyModel[] | null;
  /** @nullable */
  countries?: V2TrainingSessionsQueriesBatchModelsCountryModel[] | null;
  /** @nullable */
  players?: V2TrainingSessionsQueriesBatchModelsPlayerModel[] | null;
  /** @nullable */
  positions?: V2TrainingSessionsQueriesBatchModelsPositionModel[] | null;
  /** @nullable */
  trialIdentities?:
    | V2TrainingSessionsQueriesBatchModelsDrillIdentityModel[]
    | null;
}

/**
 * @nullable
 */
export type V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponseErrors =
  { [key: string]: string[] | null } | null;

export interface V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponse {
  /** @nullable */
  codes?: string[] | null;
  /** @nullable */
  readonly errors?: V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponseErrors;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  messages?: string[] | null;
  /** @nullable */
  playerIds?: number[] | null;
}

export interface V2TrainingSessionsCommandsTrainingPlayersUpsertModelsPlayerModel {
  availabilityType: TrainingSessionsEnumsTrainingAvailabilityType;
  playerId: number;
}

export interface V2TrainingSessionsCommandsTrainingPlayersUpsertCommand {
  players: V2TrainingSessionsCommandsTrainingPlayersUpsertModelsPlayerModel[];
  preventMarkingMissingPlayersAsAway?: boolean;
}

export interface V2TrainingSessionGroupsQueriesTrainingSessionsListModelsTrainingSessionModel {
  /** @nullable */
  completedTime?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TrainingSessionGroupsQueriesTrainingSessionsListResponse {
  /** @nullable */
  items?:
    | V2TrainingSessionGroupsQueriesTrainingSessionsListModelsTrainingSessionModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand {
  isCompleted?: boolean;
  playerId: number;
  workoutId: number;
}

export interface V2TeamsQueriesSearchListResponse {
  /** @nullable */
  items?: V2TeamsQueriesSearchListModelsTeamModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2TeamsQueriesSearchListModelsLeagueModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TeamsQueriesSearchListModelsDivisionModel {
  id?: number;
  league?: V2TeamsQueriesSearchListModelsLeagueModel;
  /** @nullable */
  name?: string | null;
}

export interface V2TeamsQueriesSearchListModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2TeamsQueriesSearchListModelsTeamModel {
  ageGroup?: V2TeamsQueriesSearchListModelsAgeGroupModel;
  division?: V2TeamsQueriesSearchListModelsDivisionModel;
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2TeamsQueriesSearchCountResponse {
  count?: number;
}

export interface V2SummaryReportsQueriesSummaryResponse {
  id?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2SummaryReportsQueriesListModelsSummaryReportModel {
  created?: string;
  id?: number;
  scoutId?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2SummaryReportsQueriesListResponse {
  /** @nullable */
  items?: V2SummaryReportsQueriesListModelsSummaryReportModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2SummaryReportsQueriesCountResponse {
  count?: number;
}

export interface V2SummaryReportsCommandsDeleteCommand {
  summaryReportId: number;
}

export interface V2SummaryReportsCommandsCreateCommand {
  /** @minLength 1 */
  sourceUrl: string;
  summaryReportDraftId: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2SummaryReportDraftsQueriesDetailsModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /** @nullable */
  value?: string | null;
}

export interface V2SummaryReportDraftsQueriesDetailsModelsSummaryReportDraftResponseModel {
  /** @nullable */
  answers?: V2SummaryReportDraftsQueriesDetailsModelsAnswerModel[] | null;
  questionId?: number;
}

export interface V2SummaryReportDraftsQueriesDetailsResponse {
  created?: string;
  /** @nullable */
  responses?:
    | V2SummaryReportDraftsQueriesDetailsModelsSummaryReportDraftResponseModel[]
    | null;
  /** @nullable */
  title?: string | null;
}

export interface V2SummaryReportDraftsCommandsResponsesUpdateCommand {
  /** @nullable */
  responses?:
    | HandlerServicesQuestionValidationsSummaryReportsModelsSummaryReportDraftResponseModel[]
    | null;
  summaryReportDraftId?: number;
}

export interface V2SummaryReportDraftsCommandsDeleteCommand {
  summaryReportDraftId: number;
}

export interface V2SummaryReportDraftsCommandsCreateCommand {
  responses: HandlerServicesQuestionValidationsSummaryReportsModelsSummaryReportDraftResponseModel[];
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2SuggestedOpportunityProClubsQueriesListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2SuggestedOpportunityProClubsQueriesListResponse {
  /** @nullable */
  items?: V2SuggestedOpportunityProClubsQueriesListModelsProClubModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2SignUpTiersQueriesFindQuery {
  countryId: number;
  /** @minLength 1 */
  dateOfBirth: string;
}

export interface V2SignUpTiersQueriesFindModelsTermsAgreementModel {
  /** @nullable */
  acceptButtonText?: string | null;
  /** @nullable */
  blurb?: string | null;
  /** @nullable */
  buttonText?: string | null;
  id?: number;
  /** @nullable */
  resourceUrl?: string | null;
}

export interface V2SignUpTiersQueriesFindResponse {
  id?: number;
  /** @nullable */
  popUpDescription?: string | null;
  /** @nullable */
  popUpProceedButtonText?: string | null;
  /** @nullable */
  popUpTitle?: string | null;
  signUpTierType?: SignUpTiersEnumsSignUpTierType;
  termsAgreement?: V2SignUpTiersQueriesFindModelsTermsAgreementModel;
}

export interface V2ScoutsQueriesSummaryReportsListModelsSummaryReportModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesSummaryReportsListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesSummaryReportsListModelsSummaryReportModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesSummaryReportsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesSummaryReportDraftsListQuery {
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  scoutId?: number;
  status: SummaryReportDraftsEnumsSummaryReportDraftStatus;
}

export interface V2ScoutsQueriesSummaryReportDraftsListModelsSummaryReportDraftModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesSummaryReportDraftsListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesSummaryReportDraftsListModelsSummaryReportDraftModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesSummaryReportDraftsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesSummaryReportDraftsCountQuery {
  scoutId?: number;
  status: SummaryReportDraftsEnumsSummaryReportDraftStatus;
}

export interface V2ScoutsQueriesScoutingReportsStatsModelsScoutingReportStatModel {
  /** @nullable */
  lastUpdatedScoutingReportDate?: string | null;
  scoutingReportCount?: number;
}

export interface V2ScoutsQueriesScoutingReportsStatsResponse {
  scoutingReportStatModel?: V2ScoutsQueriesScoutingReportsStatsModelsScoutingReportStatModel;
}

export interface V2ScoutsQueriesScoutingReportsListModelsScoutingReportModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesScoutingReportsListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesScoutingReportsListModelsScoutingReportModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesScoutingReportsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesScoutingNotesListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesScoutingNotesListModelsPlayerReportDraftModel[] | null;
  meta?: V2ScoutsQueriesScoutingNotesListModelsMetadataModel;
}

export interface V2ScoutsQueriesScoutingNotesListQuery {
  includePlayers?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  scoutId?: number;
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
}

export interface V2ScoutsQueriesScoutingNotesListModelsScoutingPlayerModel {
  /** @nullable */
  age?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  player?: V2ScoutsQueriesScoutingNotesListModelsPlayerModel;
}

export interface V2ScoutsQueriesScoutingNotesListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesScoutingNotesListModelsPlayerReportDraftModel {
  created?: string;
  id?: number;
  /** @nullable */
  scoutingPlayerIds?: number[] | null;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesScoutingNotesListModelsPlayerModel {
  id?: number;
  primaryPosition?: V2ScoutsQueriesScoutingNotesListModelsPositionModel;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2ScoutsQueriesScoutingNotesListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  scoutingPlayers?:
    | V2ScoutsQueriesScoutingNotesListModelsScoutingPlayerModel[]
    | null;
}

export interface V2ScoutsQueriesScoutingNotesCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesScoutingNotesCountQuery {
  scoutId?: number;
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
}

export interface V2ScoutsQueriesScoutAmateurFixturesUpcomingPendingCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesScoutAmateurFixturesListModelsScoutAmateurFixtureModel[]
    | null;
  meta?: V2ScoutsQueriesScoutAmateurFixturesListModelsMetadataModel;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsTeamModel {
  ageGroupId?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsScoutAmateurFixtureModel {
  acceptedVersion?: V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureVersionModel;
  amateurFixture?: V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureModel;
  attendanceStatus?: ScoutAmateurFixturesEnumsAttendanceStatus;
  id?: number;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  isWatching?: boolean;
  /** @nullable */
  name?: string | null;
  playerId?: number;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsMetadataModel {
  /** @nullable */
  ageGroups?:
    | V2ScoutsQueriesScoutAmateurFixturesListModelsAgeGroupModel[]
    | null;
  /** @nullable */
  amateurFixtureTypes?:
    | V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureTypeModel[]
    | null;
  limit?: number;
  offset?: number;
  /** @nullable */
  players?: V2ScoutsQueriesScoutAmateurFixturesListModelsPlayerModel[] | null;
  /** @nullable */
  teams?: V2ScoutsQueriesScoutAmateurFixturesListModelsTeamModel[] | null;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureVersionModel {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  awayTeamAgeGroupId?: number | null;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureTypeId?: number;
  /** @nullable */
  homeTeamAgeGroupId?: number | null;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  /** @nullable */
  localTimeZoneId?: string | null;
  /** @nullable */
  postCode?: string | null;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
  versionNumber?: number;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureTypeModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesScoutAmateurFixturesListModelsAmateurFixtureModel {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  awayTeamAgeGroupId?: number | null;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureTypeId?: number;
  /** @nullable */
  homeTeamAgeGroupId?: number | null;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  latestVersionId?: number;
  /** @nullable */
  localTimeZoneId?: string | null;
  playerId?: number;
  /** @nullable */
  postCode?: string | null;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
}

export interface V2ScoutsQueriesReportsStatsModelsReportStatsModel {
  /** @nullable */
  lastUpdatedReportDate?: string | null;
  reportsCount?: number;
}

export interface V2ScoutsQueriesReportsStatsResponse {
  gameReportStats?: V2ScoutsQueriesReportsStatsModelsReportStatsModel;
  playerReportStats?: V2ScoutsQueriesReportsStatsModelsReportStatsModel;
  summaryReportStats?: V2ScoutsQueriesReportsStatsModelsReportStatsModel;
}

export interface V2ScoutsQueriesPlayerReportsListModelsPlayerReportModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesPlayerReportsListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesPlayerReportsListModelsPlayerReportModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesPlayerReportsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesPlayerReportDraftsListQuery {
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  scoutId?: number;
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
}

export interface V2ScoutsQueriesPlayerReportDraftsListModelsPlayerReportDraftModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesPlayerReportDraftsListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesPlayerReportDraftsListModelsPlayerReportDraftModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesPlayerReportDraftsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesPlayerReportDraftsCountQuery {
  scoutId?: number;
  status: PlayerReportDraftsEnumsPlayerReportDraftStatus;
}

export interface V2ScoutsQueriesParentInvitationsListModelsParentInvitationModel {
  id?: number;
  lastRequestedTime?: string;
  /** @nullable */
  parentName?: string | null;
}

export interface V2ScoutsQueriesParentInvitationsListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesParentInvitationsListModelsParentInvitationModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesNotificationsStatsResponse {
  hasUnseen?: boolean;
}

export interface V2ScoutsQueriesNotificationsListResponse {
  /** @nullable */
  items?: HandlerServicesV2NotificationHandlersModelsNotificationModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2ScoutsQueriesGameReportsListModelsGameReportModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesGameReportsListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesGameReportsListModelsGameReportModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesGameReportsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesGameReportDraftsListQuery {
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  scoutId?: number;
  status: GameReportDraftsEnumsGameReportDraftStatus;
}

export interface V2ScoutsQueriesGameReportDraftsListModelsGameReportDraftModel {
  created?: string;
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutsQueriesGameReportDraftsListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesGameReportDraftsListModelsGameReportDraftModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ScoutsQueriesGameReportDraftsCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesGameReportDraftsCountQuery {
  scoutId?: number;
  status: GameReportDraftsEnumsGameReportDraftStatus;
}

export interface V2ScoutsQueriesFollowingPlayersPositionsListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesFollowingPlayersPositionsListResponse {
  /** @nullable */
  items?:
    | V2ScoutsQueriesFollowingPlayersPositionsListModelsPositionModel[]
    | null;
}

export interface V2ScoutsQueriesFollowingPlayersListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2ScoutsQueriesFollowingPlayersListModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  signedProClubId?: number | null;
  /** @nullable */
  watchPositionsCount?: number | null;
}

export interface V2ScoutsQueriesFollowingPlayersListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  proClubs?: V2ScoutsQueriesFollowingPlayersListModelsProClubModel[] | null;
}

export interface V2ScoutsQueriesFollowingPlayersListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesFollowingPlayersListModelsPlayerModel[] | null;
  meta?: V2ScoutsQueriesFollowingPlayersListModelsMetadataModel;
}

export interface V2ScoutsQueriesFollowingPlayersCountResponse {
  count?: number;
}

export interface V2ScoutsQueriesDetailsModelsScoutPositionModel {
  id?: number;
  /** @nullable */
  position?: string | null;
}

export interface V2ScoutsQueriesDetailsModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesDetailsResponse {
  /** @nullable */
  clubContactName?: string | null;
  /** @nullable */
  clubContactPhoneNumber?: string | null;
  dbsExpiry?: string;
  id?: number;
  /** @nullable */
  name?: string | null;
  proClub?: V2ScoutsQueriesDetailsModelsProClubModel;
  profileImage?: CommonResponsesMediaResizedImageResponse;
  scoutPosition?: V2ScoutsQueriesDetailsModelsScoutPositionModel;
  verifiedStatus?: boolean;
}

export interface V2ScoutsQueriesBusinessCardsModelsScoutBusinessCardModel {
  /** @nullable */
  email?: string | null;
  id?: number;
  /** @nullable */
  phoneNumber?: string | null;
  /** @nullable */
  standardNote?: string | null;
}

export interface V2ScoutsQueriesBusinessCardsResponse {
  /** @nullable */
  items?: V2ScoutsQueriesBusinessCardsModelsScoutBusinessCardModel[] | null;
}

export interface V2ScoutsQueriesAvailableProClubsModelsProClubModel {
  id?: number;
  /** @nullable */
  isPrimaryClub?: boolean | null;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesAvailableProClubsResponse {
  /** @nullable */
  items?: V2ScoutsQueriesAvailableProClubsModelsProClubModel[] | null;
}

export interface V2ScoutsQueriesAchievementsListModelsScoutAchievementModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutsQueriesAchievementsListResponse {
  /** @nullable */
  items?: V2ScoutsQueriesAchievementsListModelsScoutAchievementModel[] | null;
}

export interface V2ScoutsCommandsScoutDesignationUpdateCommand {
  designationType: ScoutsEnumsScoutDesignationType;
}

export interface V2ScoutsCommandsRefreshTokensUpdatedPermissionsResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
  scoutDesignationType?: ScoutsEnumsScoutDesignationType;
}

export interface V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand {
  /** @minLength 1 */
  accessToken: string;
}

export interface V2ScoutsCommandsProfileImageCommand {
  /** @nullable */
  profileImagePath?: string | null;
}

export interface V2ScoutsCommandsProClubsSwitchResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
}

export interface V2ScoutsCommandsNotificationsMarkAllAsSeenCommand {
  /** @minLength 1 */
  lastSeen: string;
  scoutId?: number;
}

export interface V2ScoutsCommandsLoginResponse {
  /** @nullable */
  accessToken?: string | null;
  proClubId?: number;
  /** @nullable */
  refreshToken?: string | null;
  scoutId?: number;
  userId?: string;
}

export interface V2ScoutsCommandsLoginCommand {
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /**
   * @minLength 3
   * @maxLength 200
   */
  fcmToken: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  password: string;
}

export interface V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand {
  /** @minItems 1 */
  positionIds: number[];
}

export interface V2ScoutsCommandsCreateModelsUserModel {
  /**
   * @minLength 1
   * @maxLength 100
   */
  email: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface V2ScoutsCommandsCreateCommand {
  /**
   * @maxLength 35
   * @nullable
   */
  clubContactName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  clubContactNumber?: string | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  proClubId: number;
  user: V2ScoutsCommandsCreateModelsUserModel;
}

export interface V2ScoutsCommandsAppointedProClubsCreateCommand {
  proClubId?: number;
}

export interface V2ScoutingReportsQueriesSummaryResponse {
  id?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutingReportsCommandsDeleteCommand {
  scoutingReportId: number;
}

export interface V2ScoutingReportsCommandsCreateCommand {
  scoutingNoteId?: number;
  scoutingPlayerId?: number;
  /**
   * @minLength 1
   * @maxLength 2048
   */
  sourceUrl: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2ScoutingNotesQueriesSummaryModelsScoutingPlayerModel {
  age?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutingNotesQueriesSummaryResponse {
  /** @nullable */
  awayTeamName?: string | null;
  created?: string;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  modified?: string;
  /** @nullable */
  scoutingPlayers?:
    | V2ScoutingNotesQueriesSummaryModelsScoutingPlayerModel[]
    | null;
  status?: PlayerReportDraftsEnumsPlayerReportDraftStatus;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutingNotesQueriesListModelsScoutingNoteModel {
  created?: string;
  id?: number;
  scoutId?: number;
  /** @nullable */
  scoutingPlayerIds?: number[] | null;
  /** @nullable */
  title?: string | null;
}

export interface V2ScoutingNotesQueriesListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutingNotesQueriesListModelsPlayerModel {
  age?: number;
  id?: number;
  primaryPosition?: V2ScoutingNotesQueriesListModelsPositionModel;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2ScoutingNotesQueriesListModelsScoutingPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  player?: V2ScoutingNotesQueriesListModelsPlayerModel;
}

export interface V2ScoutingNotesQueriesListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  scoutingPlayers?:
    | V2ScoutingNotesQueriesListModelsScoutingPlayerModel[]
    | null;
}

export interface V2ScoutingNotesQueriesListResponse {
  /** @nullable */
  items?: V2ScoutingNotesQueriesListModelsScoutingNoteModel[] | null;
  meta?: V2ScoutingNotesQueriesListModelsMetadataModel;
}

export interface V2ScoutingNotesQueriesCountResponse {
  count?: number;
}

export interface V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchModelsScoutingVoiceNoteModel {
  /** @nullable */
  scoutingPlayerIds?: number[] | null;
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
  /** @nullable */
  transcriptionText?: string | null;
}

export interface V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand {
  /** @minItems 1 */
  notes: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchModelsScoutingVoiceNoteModel[];
}

export interface V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand {
  /** @nullable */
  scoutingPlayerIds?: number[] | null;
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
  /** @nullable */
  transcriptionText?: string | null;
}

export interface V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchModelsScoutingVoiceNoteModel {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  audioFilePath: string;
  /**
   * @minimum 0
   * @maximum 7200000
   * @exclusiveMinimum
   */
  durationInMilliseconds: number;
  /** @nullable */
  scoutingPlayerClientIds?: string[] | null;
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
}

export interface V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand {
  /** @minItems 1 */
  voiceNotes: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchModelsScoutingVoiceNoteModel[];
}

export interface V2ScoutingNotesCommandsCreateModelsTeamModel {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutingNotesCommandsCreateModelsScoutingPlayerModel {
  /**
   * @minimum 0
   * @maximum 100
   */
  age: number;
  /** @minLength 1 */
  clientSidePlayerId: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @nullable */
  playerId?: number | null;
}

export interface V2ScoutingNotesCommandsCreateModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /**
   * @minLength 1
   * @maxLength 3000
   */
  value: string;
}

export interface V2ScoutingNotesCommandsCreateModelsResponseModel {
  /** @minItems 1 */
  answers: V2ScoutingNotesCommandsCreateModelsAnswerModel[];
  dataType: QuestionsEnumsQuestionDataType;
  questionId: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
  /** @nullable */
  scoutingPlayerClientId?: string | null;
}

export interface V2ScoutingNotesCommandsCreateCommand {
  awayTeam: V2ScoutingNotesCommandsCreateModelsTeamModel;
  homeTeam: V2ScoutingNotesCommandsCreateModelsTeamModel;
  /** @minLength 1 */
  localTimeZoneId: string;
  responses: V2ScoutingNotesCommandsCreateModelsResponseModel[];
  /** @nullable */
  scoutingPlayers?:
    | V2ScoutingNotesCommandsCreateModelsScoutingPlayerModel[]
    | null;
  /** @minLength 1 */
  startTimeUtc: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2ScoutAmateurFixturesQueriesDetailsResponse {
  acceptedVersion?: V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureVersionModel;
  amateurFixture?: V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureModel;
  attendanceStatus?: ScoutAmateurFixturesEnumsAttendanceStatus;
  id?: number;
}

export interface V2ScoutAmateurFixturesQueriesDetailsModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  isWatching?: boolean;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureTypeModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutAmateurFixturesQueriesDetailsModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureVersionModel {
  /** @nullable */
  address?: string | null;
  awayTeamAgeGroup?: V2ScoutAmateurFixturesQueriesDetailsModelsAgeGroupModel;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureType?: V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureTypeModel;
  homeTeamAgeGroup?: V2ScoutAmateurFixturesQueriesDetailsModelsAgeGroupModel;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  /** @nullable */
  localTimeZoneId?: string | null;
  /** @nullable */
  postCode?: string | null;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
  versionNumber?: number;
}

export interface V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureModel {
  /** @nullable */
  address?: string | null;
  awayTeamAgeGroup?: V2ScoutAmateurFixturesQueriesDetailsModelsAgeGroupModel;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureType?: V2ScoutAmateurFixturesQueriesDetailsModelsAmateurFixtureTypeModel;
  homeTeamAgeGroup?: V2ScoutAmateurFixturesQueriesDetailsModelsAgeGroupModel;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  latestVersionId?: number;
  /** @nullable */
  localTimeZoneId?: string | null;
  player?: V2ScoutAmateurFixturesQueriesDetailsModelsPlayerModel;
  /** @nullable */
  postCode?: string | null;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
}

export interface V2ScoutAmateurFixturesCommandsUpdateCommand {
  amateurFixtureVersionId: number;
}

export interface V2ScoutAmateurFixturesCommandsDeleteCommand {
  scoutAmateurFixtureId: number;
}

export interface V2ScoutAmateurFixturesCommandsCreateCommand {
  amateurFixtureId: number;
  amateurFixtureVersionId: number;
}

export interface V2SchoolsQueriesSearchModelsSchoolTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesSearchModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesSearchModelsCountyModel {
  country?: V2SchoolsQueriesSearchModelsCountryModel;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesSearchModelsSchoolModel {
  county?: V2SchoolsQueriesSearchModelsCountyModel;
  id?: number;
  /** @nullable */
  name?: string | null;
  schoolType?: V2SchoolsQueriesSearchModelsSchoolTypeModel;
  /** @nullable */
  townName?: string | null;
}

export interface V2SchoolsQueriesSearchResponse {
  /** @nullable */
  items?: V2SchoolsQueriesSearchModelsSchoolModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2SchoolsQueriesMetadataSchoolTypesListModelsSchoolTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesMetadataSchoolTypesListResponse {
  /** @nullable */
  items?: V2SchoolsQueriesMetadataSchoolTypesListModelsSchoolTypeModel[] | null;
}

export interface V2SchoolsQueriesDetailsModelsSchoolTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesDetailsModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesDetailsModelsCountyModel {
  country?: V2SchoolsQueriesDetailsModelsCountryModel;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2SchoolsQueriesDetailsResponse {
  county?: V2SchoolsQueriesDetailsModelsCountyModel;
  id?: number;
  /** @nullable */
  name?: string | null;
  schoolType?: V2SchoolsQueriesDetailsModelsSchoolTypeModel;
  /** @nullable */
  townName?: string | null;
}

export interface V2ProLeaguesQueriesProClubsListQuery {
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId: number;
  proLeagueId?: number;
}

export interface V2ProLeaguesQueriesProClubsListModelsProClubModel {
  id?: number;
  isFollowing?: boolean;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2ProLeaguesQueriesProClubsListResponse {
  /** @nullable */
  items?: V2ProLeaguesQueriesProClubsListModelsProClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProLeaguesQueriesListModelsProLeagueModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  proClubsCount?: number | null;
}

export interface V2ProLeaguesQueriesListResponse {
  /** @nullable */
  items?: V2ProLeaguesQueriesListModelsProLeagueModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesTrialsListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesTrialsListModelsDrillModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesTrialsListQuery {
  /** @nullable */
  readonly drillIdentityIds?: string | null;
  readonly includeDrillIdentity?: boolean;
  includeTrialIdentity?: boolean;
  /** @nullable */
  isEnabled?: boolean | null;
  /** @nullable */
  isLocked?: boolean | null;
  /** @nullable */
  isStandalone?: boolean | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  proClubId?: number;
  /** @nullable */
  trialIdentityIds?: string | null;
  /** @nullable */
  trialTypes?: string | null;
}

export interface V2ProClubsQueriesTrialsListModelsDrillIdentityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ProClubsQueriesTrialsListModelsDrillModel {
  id?: number;
  isEnabled?: boolean;
  isLocked?: boolean;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
  trialIdentity?: V2ProClubsQueriesTrialsListModelsDrillIdentityModel;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2ProClubsQueriesTrialIdentityInstructionTemplatesStatesModelsDrillIdentityModel {
  hasInstructionTemplate?: boolean;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ProClubsQueriesTrialIdentityInstructionTemplatesStatesResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesTrialIdentityInstructionTemplatesStatesModelsDrillIdentityModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesTrialIdentityInstructionTemplatesListModelsDrillIdentityInstructionTemplateModel {
  /** @nullable */
  descriptionHtml?: string | null;
  /** @nullable */
  heading?: string | null;
  id?: number;
  /** @nullable */
  introductionHtml?: string | null;
  media?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  title?: string | null;
}

export interface V2ProClubsQueriesTrialIdentityInstructionTemplatesListResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesTrialIdentityInstructionTemplatesListModelsDrillIdentityInstructionTemplateModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesTrialGroupsListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesTrialGroupsListModelsTrialModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesTrialGroupsListQuery {
  includeDisabled?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  proClubId?: number;
  /** @nullable */
  trialCategoryId?: number | null;
  /** @nullable */
  trialTypes?: string | null;
}

export interface V2ProClubsQueriesTrialGroupsListModelsTrialModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  /** @nullable */
  infoScreenTitle?: string | null;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  shortDescription?: string | null;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2ProClubsQueriesTrialGroupsCountResponse {
  count?: number;
}

export interface V2ProClubsQueriesTrialGroupsCountQuery {
  proClubId?: number;
  /** @nullable */
  trialCategoryId?: number | null;
  /** @minLength 1 */
  trialTypes: string;
}

export interface V2ProClubsQueriesSummaryReportQuestionsListModelsSummaryReportQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2ProClubsQueriesSummaryReportQuestionsListModelsSummaryReportQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  /** @nullable */
  options?:
    | V2ProClubsQueriesSummaryReportQuestionsListModelsSummaryReportQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2ProClubsQueriesSummaryReportQuestionsListResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesSummaryReportQuestionsListModelsSummaryReportQuestionModel[]
    | null;
}

export interface V2ProClubsQueriesSummaryDetailsResponse {
  backgroundImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  followersCount?: number | null;
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  themeColorCode?: string | null;
}

export interface V2ProClubsQueriesSummaryBatchModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2ProClubsQueriesSummaryBatchResponse {
  /** @nullable */
  items?: V2ProClubsQueriesSummaryBatchModelsProClubModel[] | null;
}

export interface V2ProClubsQueriesSuggestedListQuery {
  includeFollowing?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId: number;
}

export interface V2ProClubsQueriesSuggestedListModelsProClubModel {
  id?: number;
  /** @nullable */
  isFollowing?: boolean | null;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2ProClubsQueriesSuggestedListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesSuggestedListModelsProClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesSearchesModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2ProClubsQueriesSearchesResponse {
  /** @nullable */
  items?: V2ProClubsQueriesSearchesModelsProClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesScoutsListModelsScoutModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2ProClubsQueriesScoutsListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesScoutsListModelsScoutModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesScoutsCountResponse {
  count?: number;
}

export interface V2ProClubsQueriesScoutingQuestionsListModelsScoutingQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2ProClubsQueriesScoutingQuestionsListModelsScoutingQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  /** @nullable */
  options?:
    | V2ProClubsQueriesScoutingQuestionsListModelsScoutingQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2ProClubsQueriesScoutingQuestionsListResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesScoutingQuestionsListModelsScoutingQuestionModel[]
    | null;
}

export interface V2ProClubsQueriesPostsListQuery {
  /** @nullable */
  lastPostId?: number | null;
  /**
   * @minimum 1
   * @maximum 100
   * @exclusiveMinimum
   * @exclusiveMaximum
   */
  limit?: number;
  proClubId?: number;
}

export interface V2ProClubsQueriesPostsListModelsPostModel {
  created?: string;
  /** @nullable */
  description?: string | null;
  id?: number;
  isPromoted?: boolean;
  /** @nullable */
  isReady?: boolean | null;
  likeCount?: number;
  /** @nullable */
  liked?: boolean | null;
  /** @nullable */
  media?: CommonResponsesMediaMediaItemResponse[] | null;
}

export interface V2ProClubsQueriesPostsListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesPostsListModelsPostModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2ProClubsQueriesPlayersListQuery {
  includeBiometrics?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  /** @nullable */
  playerProClubSignedTypes?: string | null;
  proClubId?: number;
  /** @nullable */
  searchTerm?: string | null;
}

export interface V2ProClubsQueriesPlayersListModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  height?: number | null;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  weight?: number | null;
}

export interface V2ProClubsQueriesPlayersListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesPlayersListModelsPlayerModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesPlayersCountResponse {
  count?: number;
}

export interface V2ProClubsQueriesPlayersCountQuery {
  /** @nullable */
  playerProClubSignedTypes?: string | null;
  proClubId?: number;
  /** @nullable */
  searchTerm?: string | null;
}

export interface V2ProClubsQueriesPlayerReportQuestionsListModelsPlayerReportQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2ProClubsQueriesPlayerReportQuestionsListModelsPlayerReportQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  isPlayerBasedQuestion?: boolean;
  /** @nullable */
  options?:
    | V2ProClubsQueriesPlayerReportQuestionsListModelsPlayerReportQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2ProClubsQueriesPlayerReportQuestionsListResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesPlayerReportQuestionsListModelsPlayerReportQuestionModel[]
    | null;
}

export interface V2ProClubsQueriesListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2ProClubsQueriesListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesListModelsProClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ProClubsQueriesGameReportQuestionsListResponse {
  /** @nullable */
  items?:
    | V2ProClubsQueriesGameReportQuestionsListModelsGameReportQuestionModel[]
    | null;
}

export interface V2ProClubsQueriesGameReportQuestionsListModelsGameReportQuestionOptionModel {
  id?: number;
  /** @nullable */
  value?: string | null;
}

export interface V2ProClubsQueriesGameReportQuestionsListModelsGameReportQuestionModel {
  dataType?: QuestionsEnumsQuestionDataType;
  id?: number;
  inputFieldDisplayType?: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isMultipleAnswersAllowed?: boolean;
  isTeamBasedQuestion?: boolean;
  /** @nullable */
  options?:
    | V2ProClubsQueriesGameReportQuestionsListModelsGameReportQuestionOptionModel[]
    | null;
  /** @nullable */
  questionText?: string | null;
}

export interface V2ProClubsQueriesFollowersCountResponse {
  count?: number;
}

export interface V2ProClubsQueriesFeaturesStatusResponse {
  isEnabled?: boolean;
}

export interface V2ProClubsQueriesFeaturesStatusQuery {
  /**
   * @maxLength 50
   * @nullable
   */
  featureId?: string | null;
  proClubId?: number;
}

export interface V2ProClubsQueriesBioDetailsModelsProClubVenueStatisticModel {
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2ProClubsQueriesBioDetailsModelsProClubVenueModel {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  bioTitle?: string | null;
  /** @nullable */
  statistics?:
    | V2ProClubsQueriesBioDetailsModelsProClubVenueStatisticModel[]
    | null;
}

export interface V2ProClubsQueriesBioDetailsModelsProClubHyperlinkModel {
  hyperlinkType?: ProClubsEnumsProClubHyperlinkType;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
  /** @nullable */
  valueDisplayText?: string | null;
}

export interface V2ProClubsQueriesBioDetailsModelsProClubBioModel {
  backgroundImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  facebookUrl?: string | null;
  /** @nullable */
  instagramUrl?: string | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  twitterUrl?: string | null;
  /** @nullable */
  youtubeUrl?: string | null;
}

export interface V2ProClubsQueriesBioDetailsResponse {
  /** @nullable */
  hyperlinks?: V2ProClubsQueriesBioDetailsModelsProClubHyperlinkModel[] | null;
  proClubBio?: V2ProClubsQueriesBioDetailsModelsProClubBioModel;
  /** @nullable */
  venues?: V2ProClubsQueriesBioDetailsModelsProClubVenueModel[] | null;
}

export interface V2ProClubsQueriesAcademiesListModelsAcademyModel {
  /** @nullable */
  caption?: string | null;
  hasSubscribed?: boolean;
  id?: number;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  subscriptionType?: AcademiesEnumsSubscriptionType;
}

export interface V2ProClubsQueriesAcademiesListResponse {
  /** @nullable */
  items?: V2ProClubsQueriesAcademiesListModelsAcademyModel[] | null;
}

export interface V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertModelsInstructionTemplateModel {
  /**
   * @minLength 1
   * @maxLength 24000
   */
  descriptionHtml: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  heading: string;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 24000
   */
  introductionHtml: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaRelativePath: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand {
  instructionTemplates: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertModelsInstructionTemplateModel[];
}

export interface V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand {
  /** @minItems 1 */
  entityIds: number[];
}

export interface V2ProClubsCommandsPostsCreateCommand {
  /**
   * @maxLength 500
   * @nullable
   */
  description?: string | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaRelativePath: string;
}

export interface V2ProClubsCommandsFeaturesEnableCommand {
  /**
   * @maxLength 50
   * @nullable
   */
  featureId?: string | null;
  proClubId?: number;
}

export interface V2ProClubsCommandsFeaturesDisableCommand {
  /**
   * @maxLength 50
   * @nullable
   */
  featureId?: string | null;
  proClubId?: number;
}

export interface V2ProClubsCommandsBioDetailsUpsertModelsProClubVenueStatisticModel {
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  value: string;
}

export interface V2ProClubsCommandsBioDetailsUpsertModelsProClubVenueModel {
  /**
   * @minLength 1
   * @maxLength 150
   */
  address: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  bioTitle: string;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  statistics?:
    | V2ProClubsCommandsBioDetailsUpsertModelsProClubVenueStatisticModel[]
    | null;
}

export interface V2ProClubsCommandsBioDetailsUpsertModelsProClubHyperlinkModel {
  hyperlinkType: ProClubsEnumsProClubHyperlinkType;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /** @nullable */
  value?: string | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  valueDisplayText: string;
}

export interface V2ProClubsCommandsBioDetailsUpsertModelsProClubBioModel {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  backgroundImagePath: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  description: string;
  /** @nullable */
  facebookUrl?: string | null;
  /** @nullable */
  instagramUrl?: string | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
  /** @nullable */
  twitterUrl?: string | null;
  /** @nullable */
  youtubeUrl?: string | null;
}

export interface V2ProClubsCommandsBioDetailsUpsertCommand {
  hyperlinks: V2ProClubsCommandsBioDetailsUpsertModelsProClubHyperlinkModel[];
  proClubBio: V2ProClubsCommandsBioDetailsUpsertModelsProClubBioModel;
  venues: V2ProClubsCommandsBioDetailsUpsertModelsProClubVenueModel[];
}

export interface V2PostsQueriesPlayerReactionsListModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
}

export interface V2PostsQueriesPlayerReactionsListModelsPlayerReactionModel {
  isFollowing?: boolean;
  player?: V2PostsQueriesPlayerReactionsListModelsPlayerModel;
}

export interface V2PostsQueriesPlayerReactionsListResponse {
  /** @nullable */
  items?: V2PostsQueriesPlayerReactionsListModelsPlayerReactionModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PostsQueriesDetailsModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2PostsQueriesDetailsModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2PostsQueriesDetailsResponse {
  created?: string;
  creatorType?: CommonEnumsPostsCreatorType;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  isReady?: boolean | null;
  likeCount?: number;
  liked?: boolean;
  /** @nullable */
  media?: CommonResponsesMediaMediaItemResponse[] | null;
  player?: V2PostsQueriesDetailsModelsPlayerModel;
  proClub?: V2PostsQueriesDetailsModelsProClubModel;
  shareType?: PostsEnumsShareType;
}

export interface V2PostsCommandsUpdatePromoteStatusCommand {
  isPromoted?: boolean;
}

export interface V2PostsCommandsUpdateDescriptionCommand {
  /**
   * @maxLength 4000
   * @nullable
   */
  description?: string | null;
}

export interface V2PostsCommandsReportCommand {
  /**
   * @minLength 15
   * @maxLength 100
   */
  reason: string;
}

export interface V2PostsCommandsPlayerReactionsUnlikeCommand {
  playerId: number;
}

export interface V2PostsCommandsPlayerReactionsLikeCommand {
  playerId: number;
}

export interface V2PostsCommandsCreateResponse {
  id?: number;
}

export interface V2PostsCommandsCreateCommand {
  /**
   * @maxLength 4000
   * @nullable
   */
  description?: string | null;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  mediaRelativePath: string;
  playerId: number;
  shareType?: PostsEnumsShareType;
}

export interface V2PositionsQueriesListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PositionsQueriesListResponse {
  /** @nullable */
  items?: V2PositionsQueriesListModelsPositionModel[] | null;
}

export interface V2PopUpQuestionsQueriesListModelsPopUpQuestionModel {
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  questionText?: string | null;
}

export interface V2PopUpQuestionsQueriesListResponse {
  /** @nullable */
  items?: V2PopUpQuestionsQueriesListModelsPopUpQuestionModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PopUpQuestionsCommandsUpdateModelsPopUpQuestionOptionModel {
  /** @nullable */
  id?: number | null;
  isEnabled?: boolean;
  /**
   * @minLength 1
   * @maxLength 500
   */
  value: string;
}

export interface V2PopUpQuestionsCommandsUpdateCommand {
  /**
   * @maxLength 1000
   * @nullable
   */
  description?: string | null;
  isEnabled?: boolean;
  options: V2PopUpQuestionsCommandsUpdateModelsPopUpQuestionOptionModel[];
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export interface V2PopUpQuestionsCommandsCreateModelsPopUpQuestionOptionModel {
  isEnabled?: boolean;
  /**
   * @minLength 1
   * @maxLength 500
   */
  value: string;
}

export interface V2PopUpQuestionsCommandsCreateCommand {
  dataType: QuestionsEnumsQuestionDataType;
  /**
   * @maxLength 1000
   * @nullable
   */
  description?: string | null;
  inputFieldDisplayType: QuestionsEnumsInputFieldDisplayType;
  isAlternateOptionAllowed?: boolean;
  isEnabled?: boolean;
  isMultipleAnswersAllowed?: boolean;
  options: V2PopUpQuestionsCommandsCreateModelsPopUpQuestionOptionModel[];
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export interface V2PlayersQueriesTrialsEntriesSummaryListModelsDrillEntryRatingModel {
  /** @nullable */
  maximumRating?: number | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  rating?: number | null;
  ratingPercentage?: number;
  /** @nullable */
  type?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesSummaryListModelsDrillEntryModel {
  /** @nullable */
  coachId?: number | null;
  createdTime?: string;
  id?: number;
  /** @nullable */
  ratedTime?: string | null;
  /** @nullable */
  ratings?:
    | V2PlayersQueriesTrialsEntriesSummaryListModelsDrillEntryRatingModel[]
    | null;
  /** @nullable */
  score?: number | null;
  trialEntryStatus?: SpotMeDomainEnumsDrillEntryStatus;
}

export interface V2PlayersQueriesTrialsEntriesSummaryListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesTrialsEntriesSummaryListModelsDrillEntryModel[]
    | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsReviewerModel {
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsPendingDrillEntryModel {
  entryVideo?: CommonResponsesMediaVideoResponse;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsErrorDrillEntryModel {
  /** @nullable */
  statusDescription?: string | null;
  /** @nullable */
  statusTitle?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsResponse {
  analysedTrialEntry?: V2PlayersQueriesTrialsEntriesDetailsModelsAnalysedDrillEntryModel;
  /** @nullable */
  coachId?: number | null;
  created?: string;
  entryId?: number;
  errorTrialEntry?: V2PlayersQueriesTrialsEntriesDetailsModelsErrorDrillEntryModel;
  isDeletable?: boolean;
  pendingTrialEntry?: V2PlayersQueriesTrialsEntriesDetailsModelsPendingDrillEntryModel;
  trialEntryStatus?: SpotMeDomainEnumsDrillEntryStatus;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsEntryMeasurementModel {
  /** @nullable */
  displayUnit?: string | null;
  /** @nullable */
  type?: string | null;
  /** @nullable */
  unit?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsDrillEntryRatingModel {
  /** @nullable */
  maximumRating?: number | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  rating?: number | null;
  /** @nullable */
  ratingLabelText?: string | null;
  ratingPercentage?: number;
  /** @nullable */
  type?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsDrillEntryFeedbackModel {
  /** @nullable */
  message?: string | null;
  reviewer?: V2PlayersQueriesTrialsEntriesDetailsModelsReviewerModel;
  scoutImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  scoutName?: string | null;
  /** @nullable */
  scoutTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayersQueriesTrialsEntriesDetailsModelsAnalysedDrillEntryModel {
  analysedVideo?: CommonResponsesMediaVideoResponse;
  entryVideo?: CommonResponsesMediaVideoResponse;
  readonly maximumScore?: number;
  /** @nullable */
  measurements?:
    | V2PlayersQueriesTrialsEntriesDetailsModelsEntryMeasurementModel[]
    | null;
  ratedAt?: string;
  /** @nullable */
  ratings?:
    | V2PlayersQueriesTrialsEntriesDetailsModelsDrillEntryRatingModel[]
    | null;
  /** @nullable */
  score?: number | null;
  trialReview?: V2PlayersQueriesTrialsEntriesDetailsModelsDrillEntryFeedbackModel;
}

export interface V2PlayersQueriesTrialGroupsListQuery {
  includeProClub?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId?: number;
  /** @minLength 1 */
  trialTypes: string;
}

export interface V2PlayersQueriesTrialGroupsListModelsTrialModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  /** @nullable */
  infoScreenTitle?: string | null;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  proClubId?: number;
  /** @nullable */
  shortDescription?: string | null;
  trialType?: SpotMeDomainEnumsTrialType;
}

export interface V2PlayersQueriesTrialGroupsListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2PlayersQueriesTrialGroupsListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  proClubs?: V2PlayersQueriesTrialGroupsListModelsProClubModel[] | null;
}

export interface V2PlayersQueriesTrialGroupsListResponse {
  /** @nullable */
  items?: V2PlayersQueriesTrialGroupsListModelsTrialModel[] | null;
  meta?: V2PlayersQueriesTrialGroupsListModelsMetadataModel;
}

export interface V2PlayersQueriesTrialEntriesStatsResponse {
  hasAnalysedTrialEntries?: boolean;
}

export interface V2PlayersQueriesTrialEntriesListResponse {
  /** @nullable */
  items?: V2PlayersQueriesTrialEntriesListModelsDrillEntryModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2PlayersQueriesTrialEntriesListModelsDrillModel {
  id?: number;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayersQueriesTrialEntriesListModelsDrillEntryRatingModel {
  maximumRating?: number;
  /** @nullable */
  name?: string | null;
  rating?: number;
  ratingPercentage?: number;
  /** @nullable */
  type?: string | null;
}

export interface V2PlayersQueriesTrialEntriesListModelsAnalysedDrillEntryModel {
  analysedVideo?: CommonResponsesMediaVideoResponse;
  entryVideo?: CommonResponsesMediaVideoResponse;
  maximumScore?: number;
  ratedAt?: string;
  /** @nullable */
  ratings?:
    | V2PlayersQueriesTrialEntriesListModelsDrillEntryRatingModel[]
    | null;
  score?: number;
}

export interface V2PlayersQueriesTrialEntriesListModelsDrillEntryModel {
  analysedTrialEntry?: V2PlayersQueriesTrialEntriesListModelsAnalysedDrillEntryModel;
  created?: string;
  entryId?: number;
  trial?: V2PlayersQueriesTrialEntriesListModelsDrillModel;
  trialEntryStatus?: SpotMeDomainEnumsDrillEntryStatus;
}

export interface V2PlayersQueriesTrainingSessionsStatsResponse {
  hasStandaloneTrainingSessions?: boolean;
}

export interface V2PlayersQueriesTrainingSessionsStatsQuery {
  /** @nullable */
  academyTeamId?: number | null;
  /** @nullable */
  availabilityTypes?: string | null;
  /** @nullable */
  isStarted?: boolean | null;
  playerId?: number;
}

export interface V2PlayersQueriesTrainingSessionsListQuery {
  /** @nullable */
  academyTeamId?: number | null;
  /** @nullable */
  availabilityTypes?: string | null;
  /** @nullable */
  isStarted?: boolean | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId?: number;
  /** @nullable */
  standaloneTrainingSessions?: boolean | null;
}

export interface V2PlayersQueriesTrainingSessionsListModelsTrainingSessionModel {
  /** @nullable */
  completedTime?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesTrainingSessionsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesTrainingSessionsListModelsTrainingSessionModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesTrainingSessionsEntryStatesModelsMetadataModel {
  /** @nullable */
  trialIdentities?:
    | V2PlayersQueriesTrainingSessionsEntryStatesModelsDrillIdentityModel[]
    | null;
}

export interface V2PlayersQueriesTrainingSessionsEntryStatesModelsDrillModel {
  /** @nullable */
  benchmarkScore?: number | null;
  id?: number;
  readonly maxPossibleScore?: number;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  trialIdentityId?: number | null;
}

export interface V2PlayersQueriesTrainingSessionsEntryStatesModelsEntryStateModel {
  hasFailedEntry?: boolean;
  hasPendingEntry?: boolean;
  hasRatedEntry?: boolean;
  /** @nullable */
  highestScore?: number | null;
  trial?: V2PlayersQueriesTrainingSessionsEntryStatesModelsDrillModel;
}

export interface V2PlayersQueriesTrainingSessionsEntryStatesResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesTrainingSessionsEntryStatesModelsEntryStateModel[]
    | null;
  meta?: V2PlayersQueriesTrainingSessionsEntryStatesModelsMetadataModel;
}

export interface V2PlayersQueriesTrainingSessionsEntryStatesModelsDrillIdentityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsTrainingSessionModel[]
    | null;
  meta?: V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsMetadataModel;
}

export interface V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListQuery {
  /** @minLength 1 */
  availabilityTypes: string;
  readonly includeDrillIdentities?: boolean;
  includeTrialIdentities?: boolean;
  /** @nullable */
  isStarted?: boolean | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId?: number;
  trainingSessionGroupId?: number;
}

export interface V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsTrainingSessionModel {
  /** @nullable */
  completedTime?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  trialIdentityIds?: number[] | null;
}

export interface V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsDrillIdentityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  trialIdentities?:
    | V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListModelsDrillIdentityModel[]
    | null;
}

export interface V2PlayersQueriesTrainingSessionGroupsListQuery {
  /** @nullable */
  academyTeamId?: number | null;
  /** @nullable */
  availabilityTypes?: string | null;
  /** @nullable */
  isStarted?: boolean | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  playerId?: number;
}

export interface V2PlayersQueriesTrainingSessionGroupsListModelsTrainingSessionGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  startTime?: string;
}

export interface V2PlayersQueriesTrainingSessionGroupsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesTrainingSessionGroupsListModelsTrainingSessionGroupModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesSummaryDetailsModelsTeamModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaBaseMediaResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsPlayerTeamModel {
  team?: V2PlayersQueriesSummaryDetailsModelsTeamModel;
}

export interface V2PlayersQueriesSummaryDetailsModelsPlayerPositionModel {
  id?: number;
  /** @nullable */
  isPrimary?: boolean | null;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsPassportAuthorityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsNationalityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsEthnicityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsCountyModel {
  countryId?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryDetailsResponse {
  academyMemberProClub?: V2PlayersQueriesSummaryDetailsModelsProClubModel;
  /** @nullable */
  amateurClubsCount?: number | null;
  /** @nullable */
  canPlayForCountries?:
    | V2PlayersQueriesSummaryDetailsModelsCountryModel[]
    | null;
  /** @nullable */
  city?: string | null;
  /** @nullable */
  customCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  email?: string | null;
  ethnicity?: V2PlayersQueriesSummaryDetailsModelsEthnicityModel;
  /** @nullable */
  fatherEmail?: string | null;
  /** @nullable */
  fatherHeight?: number | null;
  /** @nullable */
  fatherName?: string | null;
  /** @nullable */
  fatherPhone?: string | null;
  /** @nullable */
  firstName?: string | null;
  gender?: PlayersEnumsGender;
  /** @nullable */
  height?: number | null;
  homeCountry?: V2PlayersQueriesSummaryDetailsModelsCountryModel;
  homeCounty?: V2PlayersQueriesSummaryDetailsModelsCountyModel;
  id?: number;
  /** @nullable */
  lastName?: string | null;
  /** @nullable */
  motherEmail?: string | null;
  /** @nullable */
  motherHeight?: number | null;
  /** @nullable */
  motherName?: string | null;
  /** @nullable */
  motherPhone?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  nationalities?: V2PlayersQueriesSummaryDetailsModelsNationalityModel[] | null;
  /** @nullable */
  nationalRatingSystemScore?: number | null;
  /** @nullable */
  passportAuthorities?:
    | V2PlayersQueriesSummaryDetailsModelsPassportAuthorityModel[]
    | null;
  /** @nullable */
  personalStatement?: string | null;
  /** @nullable */
  phone?: string | null;
  /** @nullable */
  playerTeams?: V2PlayersQueriesSummaryDetailsModelsPlayerTeamModel[] | null;
  /** @nullable */
  playerTeamsCount?: number | null;
  /** @nullable */
  positions?: V2PlayersQueriesSummaryDetailsModelsPlayerPositionModel[] | null;
  preferredFoot?: PlayersEnumsPreferredFootType;
  proClubSignedType?: PlayersEnumsPlayerProClubSignedType;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  secondaryContactEmail?: string | null;
  /** @nullable */
  secondaryContactMobile?: string | null;
  /** @nullable */
  secondaryContactName?: string | null;
  secondaryContactRelationshipType?: PlayersEnumsSecondaryRelationshipType;
  signedProClub?: V2PlayersQueriesSummaryDetailsModelsProClubModel;
  /** @nullable */
  watchPositions?: V2PlayersQueriesSummaryDetailsModelsPositionModel[] | null;
  /** @nullable */
  watchPositionsCount?: number | null;
  /** @nullable */
  weight?: number | null;
}

export interface V2PlayersQueriesSummaryBatchResponse {
  /** @nullable */
  items?: V2PlayersQueriesSummaryBatchModelsPlayerModel[] | null;
}

export interface V2PlayersQueriesSummaryBatchQuery {
  /** @minLength 1 */
  ids: string;
  /** @nullable */
  includeCreatedPlayerReportDraftsCountByProClubId?: number | null;
  /** @nullable */
  includeFollowingScoutsCountByProClubId?: number | null;
  includeLocationDetails?: boolean;
  /** @nullable */
  includePlayerReportsCountByProClubId?: number | null;
  includePrimaryPosition?: boolean;
  includeProfileImage?: boolean;
}

export interface V2PlayersQueriesSummaryBatchModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryBatchModelsCountyModel {
  countryId?: number;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryBatchModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesSummaryBatchModelsPlayerModel {
  /** @nullable */
  city?: string | null;
  /** @nullable */
  createdPlayerReportDraftsCountByProClubId?: number | null;
  /** @nullable */
  customCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  firstName?: string | null;
  /** @nullable */
  followingScoutsCountByProClubId?: number | null;
  gender?: PlayersEnumsGender;
  homeCountry?: V2PlayersQueriesSummaryBatchModelsCountryModel;
  homeCounty?: V2PlayersQueriesSummaryBatchModelsCountyModel;
  id?: number;
  /** @nullable */
  lastName?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  playerReportsCountByProClubId?: number | null;
  preferredFoot?: PlayersEnumsPreferredFootType;
  primaryPosition?: V2PlayersQueriesSummaryBatchModelsPositionModel;
  profileImage?: CommonResponsesMediaResizedImageResponse;
}

export interface V2PlayersQueriesSignUpAgreementModelsTermsAgreementModel {
  /** @nullable */
  acceptButtonText?: string | null;
  /** @nullable */
  blurb?: string | null;
  /** @nullable */
  buttonText?: string | null;
  id?: number;
  /** @nullable */
  resourceUrl?: string | null;
}

export interface V2PlayersQueriesSignUpAgreementResponse {
  /** @nullable */
  guardianConsentedTime?: string | null;
  /** @nullable */
  hasGuardianConsent?: boolean | null;
  id?: number;
  signUpTierType?: SignUpTiersEnumsSignUpTierType;
  termsAgreement?: V2PlayersQueriesSignUpAgreementModelsTermsAgreementModel;
}

export interface V2PlayersQueriesSearchesListModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  /** @nullable */
  dateOfBirth?: string | null;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  watchPositionsCount?: number | null;
}

export interface V2PlayersQueriesSearchesListResponse {
  /** @nullable */
  items?: V2PlayersQueriesSearchesListModelsPlayerModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesSearchesCountResponse {
  count?: number;
}

export interface V2PlayersQueriesProfilesPersonalDetailsModelsUserModel {
  /** @nullable */
  email?: string | null;
}

export interface V2PlayersQueriesProfilesPersonalDetailsModelsNationalityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesPersonalDetailsModelsEthnicityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesPersonalDetailsModelsCountyModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesPersonalDetailsModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesPersonalDetailsResponse {
  /** @nullable */
  city?: string | null;
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  ethnicity?: V2PlayersQueriesProfilesPersonalDetailsModelsEthnicityModel;
  /** @nullable */
  firstName?: string | null;
  gender?: PlayersEnumsGender;
  homeCountry?: V2PlayersQueriesProfilesPersonalDetailsModelsCountryModel;
  homeCounty?: V2PlayersQueriesProfilesPersonalDetailsModelsCountyModel;
  id?: number;
  /** @nullable */
  lastName?: string | null;
  /** @nullable */
  nationalities?:
    | V2PlayersQueriesProfilesPersonalDetailsModelsNationalityModel[]
    | null;
  /** @nullable */
  phone?: string | null;
  /** @nullable */
  postCode?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
  user?: V2PlayersQueriesProfilesPersonalDetailsModelsUserModel;
}

export interface V2PlayersQueriesProfilesFootballDetailsModelsPositionModel {
  id?: number;
  /** @nullable */
  isPrimary?: boolean | null;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesFootballDetailsResponse {
  height?: number;
  id?: number;
  /** @nullable */
  positions?:
    | V2PlayersQueriesProfilesFootballDetailsModelsPositionModel[]
    | null;
  preferredFoot?: PlayersEnumsPreferredFootType;
  weight?: number;
}

export interface V2PlayersQueriesProfilesCompletedSectionsListModelsPlayerProfileSectionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesCompletedSectionsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesProfilesCompletedSectionsListModelsPlayerProfileSectionModel[]
    | null;
}

export interface V2PlayersQueriesProfilesAboutMeModelsPassportAuthorityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesAboutMeModelsNationalityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesAboutMeModelsEthnicityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesAboutMeModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesProfilesAboutMeResponse {
  /** @nullable */
  canPlayForCountries?:
    | V2PlayersQueriesProfilesAboutMeModelsCountryModel[]
    | null;
  ethnicity?: V2PlayersQueriesProfilesAboutMeModelsEthnicityModel;
  /** @nullable */
  fatherEmail?: string | null;
  fatherHeight?: number;
  fatherHomeCountry?: V2PlayersQueriesProfilesAboutMeModelsCountryModel;
  /** @nullable */
  fatherName?: string | null;
  /** @nullable */
  fatherPhone?: string | null;
  /** @nullable */
  guardianEmail?: string | null;
  guardianHomeCountry?: V2PlayersQueriesProfilesAboutMeModelsCountryModel;
  /** @nullable */
  guardianName?: string | null;
  /** @nullable */
  guardianPhone?: string | null;
  /** @nullable */
  motherEmail?: string | null;
  motherHeight?: number;
  motherHomeCountry?: V2PlayersQueriesProfilesAboutMeModelsCountryModel;
  /** @nullable */
  motherName?: string | null;
  /** @nullable */
  motherPhone?: string | null;
  /** @nullable */
  nationalities?:
    | V2PlayersQueriesProfilesAboutMeModelsNationalityModel[]
    | null;
  /** @nullable */
  passportAuthorities?:
    | V2PlayersQueriesProfilesAboutMeModelsPassportAuthorityModel[]
    | null;
  /** @nullable */
  personalStatement?: string | null;
  /** @nullable */
  secondaryContactEmail?: string | null;
  /** @nullable */
  secondaryContactMobile?: string | null;
  /** @nullable */
  secondaryContactName?: string | null;
  secondaryContactRelationshipType?: PlayersEnumsSecondaryRelationshipType;
}

export interface V2PlayersQueriesPostsListQuery {
  includePlayer?: boolean;
  /** @nullable */
  lastPostId?: number | null;
  /**
   * @minimum 1
   * @maximum 100
   * @exclusiveMinimum
   * @exclusiveMaximum
   */
  limit?: number;
  playerId?: number;
}

export interface V2PlayersQueriesPostsListModelsPostModel {
  created?: string;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  isReady?: boolean | null;
  likeCount?: number;
  /** @nullable */
  liked?: boolean | null;
  /** @nullable */
  media?: CommonResponsesMediaMediaItemResponse[] | null;
  shareType?: PostsEnumsShareType;
}

export interface V2PlayersQueriesPostsListResponse {
  /** @nullable */
  items?: V2PlayersQueriesPostsListModelsPostModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2PlayersQueriesPositionsListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesPositionsListResponse {
  /** @nullable */
  items?: V2PlayersQueriesPositionsListModelsPositionModel[] | null;
}

export interface V2PlayersQueriesPlayerWatchItemClubsResponse {
  isWatching?: boolean;
}

export interface V2PlayersQueriesPlayerSchoolsListResponse {
  /** @nullable */
  items?: V2PlayersQueriesPlayerSchoolsListModelsPlayerSchoolModel[] | null;
}

export interface V2PlayersQueriesPlayerSchoolsListModelsSchoolTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesPlayerSchoolsListModelsSchoolModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  schoolType?: V2PlayersQueriesPlayerSchoolsListModelsSchoolTypeModel;
  /** @nullable */
  townName?: string | null;
}

export interface V2PlayersQueriesPlayerSchoolsListModelsPlayerSchoolModel {
  /** @nullable */
  endAge?: number | null;
  /** @nullable */
  endDate?: string | null;
  id?: number;
  school?: V2PlayersQueriesPlayerSchoolsListModelsSchoolModel;
  startAge?: number;
  /** @nullable */
  startDate?: string | null;
}

export interface V2PlayersQueriesPlayerFollowersFollowingStatusResponse {
  isFollowing?: boolean;
}

export interface V2PlayersQueriesPlayerFollowersFollowingListModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2PlayersQueriesPlayerFollowersFollowingListModelsFollowingPlayerModel {
  isFollowing?: boolean;
  player?: V2PlayersQueriesPlayerFollowersFollowingListModelsPlayerModel;
}

export interface V2PlayersQueriesPlayerFollowersFollowingListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesPlayerFollowersFollowingListModelsFollowingPlayerModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesPlayerFollowersFollowingCountResponse {
  count?: number;
}

export interface V2PlayersQueriesPlayerFollowersFollowersListModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2PlayersQueriesPlayerFollowersFollowersListModelsPlayerFollowerModel {
  isFollowing?: boolean;
  player?: V2PlayersQueriesPlayerFollowersFollowersListModelsPlayerModel;
}

export interface V2PlayersQueriesPlayerFollowersFollowersListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesPlayerFollowersFollowersListModelsPlayerFollowerModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesPlayerFollowersFollowersCountResponse {
  count?: number;
}

export interface V2PlayersQueriesPlayerCountriesListModelsCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesPlayerCountriesListResponse {
  /** @nullable */
  items?: V2PlayersQueriesPlayerCountriesListModelsCountryModel[] | null;
}

export interface V2PlayersQueriesPlayerClubFollowersFollowingStatusResponse {
  isFollowing?: boolean;
}

export interface V2PlayersQueriesPlayerClubFollowersFollowingListModelsProLeagueModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesPlayerClubFollowersFollowingListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  proLeagues?:
    | V2PlayersQueriesPlayerClubFollowersFollowingListModelsProLeagueModel[]
    | null;
}

export interface V2PlayersQueriesPlayerClubFollowersFollowingListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesPlayerClubFollowersFollowingListModelsProClubModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesPlayerClubFollowersFollowingCountResponse {
  count?: number;
}

export interface V2PlayersQueriesPerformanceLabReportsListModelsPerformanceLabReportModel {
  created?: string;
  id?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayersQueriesPerformanceLabReportsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesPerformanceLabReportsListModelsPerformanceLabReportModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesOnboardingTrialsResponse {
  completedNrsTrialsPercentage?: number;
  isNationalRatingSystemTrialsCompleted?: boolean;
  /** @nullable */
  items?: V2PlayersQueriesOnboardingTrialsModelsDrillModel[] | null;
  nationalRatingSystemScore?: number;
}

export interface V2PlayersQueriesOnboardingTrialsQuery {
  playerId: number;
}

export interface V2PlayersQueriesOnboardingTrialsModelsDrillModel {
  /** @nullable */
  estimatedTime?: string | null;
  hasEntered?: boolean;
  id?: number;
  isLocked?: boolean;
  previewImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  shortDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayersQueriesOnboardingStatusResponse {
  isEligibleForNrs?: boolean;
  isOnboardingComplete?: boolean;
}

export interface V2PlayersQueriesOnboardingStatusQuery {
  playerId: number;
}

export interface V2PlayersQueriesOnboardingOpportunityDemoVideoResponse {
  video?: CommonResponsesMediaVideoResponse;
}

export interface V2PlayersQueriesOnboardingEnterClubTrialLogoResponse {
  logo?: CommonResponsesMediaMediaItemResponse;
}

export interface V2PlayersQueriesNotificationsListResponse {
  /** @nullable */
  items?: HandlerServicesV2NotificationHandlersModelsNotificationModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2PlayersQueriesNationalRatingSystemSummaryResponse {
  isCompleted?: boolean;
  score?: number;
}

export interface V2PlayersQueriesNationalRatingSystemSummaryQuery {
  playerId: number;
}

export interface V2PlayersQueriesNationalRatingSystemScoreResponse {
  nationalRatingSystemScore?: number;
}

export interface V2PlayersQueriesNationalRatingSystemScoreQuery {
  playerId: number;
}

export interface V2PlayersQueriesMetadataRelationshipTypesListModelsSecondaryRelationshipTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesMetadataRelationshipTypesListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesMetadataRelationshipTypesListModelsSecondaryRelationshipTypeModel[]
    | null;
}

export interface V2PlayersQueriesMetadataPreferredFootsListModelsPreferredFootModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesMetadataPreferredFootsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesMetadataPreferredFootsListModelsPreferredFootModel[]
    | null;
}

export interface V2PlayersQueriesMetadataGendersListModelsGenderModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesMetadataGendersListResponse {
  /** @nullable */
  items?: V2PlayersQueriesMetadataGendersListModelsGenderModel[] | null;
}

export interface V2PlayersQueriesLimitedAccessResponse {
  isLimitedAccess?: boolean;
}

export interface V2PlayersQueriesGuardiansSummaryListModelsGuardianModel {
  /** @nullable */
  email?: string | null;
  /** @nullable */
  firstName?: string | null;
  id?: number;
  /** @nullable */
  lastName?: string | null;
}

export interface V2PlayersQueriesGuardiansSummaryListResponse {
  /** @nullable */
  items?: V2PlayersQueriesGuardiansSummaryListModelsGuardianModel[] | null;
}

export interface V2PlayersQueriesGuardiansCountResponse {
  count?: number;
}

export interface V2PlayersQueriesFollowingCountResponse {
  count?: number;
}

export interface V2PlayersQueriesAmateurFixturesListModelsTeamModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureVersionModel {
  /** @nullable */
  address?: string | null;
  awayTeamAgeGroupId?: number;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureTypeId?: number;
  homeTeamAgeGroupId?: number;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  /** @nullable */
  localTimeZoneId?: string | null;
  /** @nullable */
  postCode?: string | null;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
  versionNumber?: number;
}

export interface V2PlayersQueriesAmateurFixturesListModelsScoutAmateurFixtureModel {
  acceptedVersion?: V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureVersionModel;
  attendanceStatus?: ScoutAmateurFixturesEnumsAttendanceStatus;
  id?: number;
}

export interface V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureTypeModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureModel {
  /** @nullable */
  address?: string | null;
  awayTeamAgeGroupId?: number;
  /** @nullable */
  awayTeamId?: number | null;
  /** @nullable */
  awayTeamName?: string | null;
  fixtureTypeId?: number;
  homeTeamAgeGroupId?: number;
  /** @nullable */
  homeTeamId?: number | null;
  /** @nullable */
  homeTeamName?: string | null;
  id?: number;
  /** @nullable */
  latestVersionId?: number | null;
  /** @nullable */
  localTimeZoneId?: string | null;
  /** @nullable */
  postCode?: string | null;
  scoutAmateurFixture?: V2PlayersQueriesAmateurFixturesListModelsScoutAmateurFixtureModel;
  startTimeLocal?: string;
  /** @nullable */
  venue?: string | null;
}

export interface V2PlayersQueriesAmateurFixturesListModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayersQueriesAmateurFixturesListModelsMetadataModel {
  /** @nullable */
  ageGroups?: V2PlayersQueriesAmateurFixturesListModelsAgeGroupModel[] | null;
  /** @nullable */
  fixtureTypes?:
    | V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureTypeModel[]
    | null;
  limit?: number;
  offset?: number;
  /** @nullable */
  teams?: V2PlayersQueriesAmateurFixturesListModelsTeamModel[] | null;
}

export interface V2PlayersQueriesAmateurFixturesListResponse {
  /** @nullable */
  items?: V2PlayersQueriesAmateurFixturesListModelsAmateurFixtureModel[] | null;
  meta?: V2PlayersQueriesAmateurFixturesListModelsMetadataModel;
}

export interface V2PlayersQueriesAdvancedSearchSearchQuery {
  limit?: number;
  offset?: number;
  player?: V2PlayersQueriesAdvancedSearchModelsRequestModelsPlayerModel;
  sortColumnType: SpotMeDomainQueriesPlayersRequestPlayerAdvancedSearchEnumsPlayerSortColumnType;
  sortDirectionType: SpotMeDomainEnumsSortDirectionType;
}

export interface V2PlayersQueriesAdvancedSearchSearchModelsPlayerModel {
  /** @nullable */
  customHomeCounty?: string | null;
  dateOfBirth?: string;
  /** @nullable */
  homeCountyName?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  watchPositionsCount?: number | null;
}

export interface V2PlayersQueriesAdvancedSearchSearchResponse {
  /** @nullable */
  items?: V2PlayersQueriesAdvancedSearchSearchModelsPlayerModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsTeamModel {
  division?: V2PlayersQueriesAdvancedSearchModelsRequestModelsDivisionModel;
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsPositionModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsNationalityModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsLeagueModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsHomeCountyModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsHomeCountryModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsEthnicityModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsPlayerModel {
  canPlayForCountry?: V2PlayersQueriesAdvancedSearchModelsRequestModelsCanPlayForCountryModel;
  /** @nullable */
  dateOfBirthMax?: string | null;
  /** @nullable */
  dateOfBirthMin?: string | null;
  ethnicity?: V2PlayersQueriesAdvancedSearchModelsRequestModelsEthnicityModel;
  genderId?: PlayersEnumsGender;
  homeCountry?: V2PlayersQueriesAdvancedSearchModelsRequestModelsHomeCountryModel;
  homeCounty?: V2PlayersQueriesAdvancedSearchModelsRequestModelsHomeCountyModel;
  /** @nullable */
  maxHeight?: number | null;
  /** @nullable */
  maxWeight?: number | null;
  /** @nullable */
  minHeight?: number | null;
  /** @nullable */
  minWeight?: number | null;
  nationality?: V2PlayersQueriesAdvancedSearchModelsRequestModelsNationalityModel;
  /** @nullable */
  nationalRatingScore?: number | null;
  position?: V2PlayersQueriesAdvancedSearchModelsRequestModelsPositionModel;
  preferredFootType?: PlayersEnumsPreferredFootType;
  team?: V2PlayersQueriesAdvancedSearchModelsRequestModelsTeamModel;
  /** @nullable */
  thirtyMeterTime?: number | null;
  /** @nullable */
  twentyMeterTime?: number | null;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsDivisionModel {
  league?: V2PlayersQueriesAdvancedSearchModelsRequestModelsLeagueModel;
}

export interface V2PlayersQueriesAdvancedSearchModelsRequestModelsCanPlayForCountryModel {
  /** @nullable */
  ids?: number[] | null;
}

export interface V2PlayersQueriesAdvancedSearchFiltersDateOfBirthQuery {
  [key: string]: unknown;
}

export interface V2PlayersQueriesAdvancedSearchFiltersDateOfBirthModelsDateOfBirthFilterModel {
  /** @nullable */
  label?: string | null;
  /** @nullable */
  lowerBound?: string | null;
  /** @nullable */
  upperBound?: string | null;
}

export interface V2PlayersQueriesAdvancedSearchFiltersDateOfBirthResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesAdvancedSearchFiltersDateOfBirthModelsDateOfBirthFilterModel[]
    | null;
}

export interface V2PlayersQueriesAdvancedSearchCountResponse {
  count?: number;
}

export interface V2PlayersQueriesAdvancedSearchCountQuery {
  player?: V2PlayersQueriesAdvancedSearchModelsRequestModelsPlayerModel;
}

export interface V2PlayersQueriesAcademySubscriptionsListResponse {
  /** @nullable */
  items?:
    | V2PlayersQueriesAcademySubscriptionsListModelsAcademySubscriptionModel[]
    | null;
  meta?: V2PlayersQueriesAcademySubscriptionsListModelsMetadataModel;
}

export interface V2PlayersQueriesAcademySubscriptionsListQuery {
  academyType: AcademiesEnumsAcademyType;
  includeProClub?: boolean;
  playerId?: number;
}

export interface V2PlayersQueriesAcademySubscriptionsListModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2PlayersQueriesAcademySubscriptionsListModelsMetadataModel {
  /** @nullable */
  proClubs?:
    | V2PlayersQueriesAcademySubscriptionsListModelsProClubModel[]
    | null;
}

export interface V2PlayersQueriesAcademySubscriptionsListModelsAcademyModel {
  academyType?: AcademiesEnumsAcademyType;
  id?: number;
  /** @nullable */
  name?: string | null;
  proClubId?: number;
}

export interface V2PlayersQueriesAcademySubscriptionsListModelsAcademySubscriptionModel {
  academy?: V2PlayersQueriesAcademySubscriptionsListModelsAcademyModel;
  id?: number;
}

export interface V2PlayersCommandsUpdateModelsPrimaryPlayingCountryModel {
  /** @nullable */
  id?: number | null;
}

export interface V2PlayersCommandsUpdateModelsCanPlayForCountriesModel {
  ids: number[];
}

export interface V2PlayersCommandsUpdateCommand {
  canPlayForCountries?: V2PlayersCommandsUpdateModelsCanPlayForCountriesModel;
  primaryPlayingCountry?: V2PlayersCommandsUpdateModelsPrimaryPlayingCountryModel;
}

export interface V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsReviewerModel {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 1024
   */
  profileImagePath: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  title: string;
}

export interface V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsPushNotificationModel {
  /**
   * @minLength 1
   * @maxLength 400
   */
  message: string;
  /**
   * @maxLength 100
   * @nullable
   */
  title?: string | null;
}

export interface V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsInAppNotificationModel {
  /**
   * @minLength 1
   * @maxLength 600
   */
  message: string;
}

export interface V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand {
  inAppNotification?: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsInAppNotificationModel;
  /**
   * @minLength 1
   * @maxLength 800
   */
  message: string;
  pushNotification?: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsPushNotificationModel;
  reviewer: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateModelsReviewerModel;
  /**
   * @minLength 1
   * @maxLength 100
   */
  title: string;
}

export interface V2PlayersCommandsTrialsEntriesRatingsDeleteCommand {
  readonly drillId?: number;
  entryId: number;
  playerId: number;
  trialId: number;
}

export interface V2PlayersCommandsTrialsEntriesDeleteCommand {
  readonly drillId?: number;
  entryId: number;
  playerId: number;
  trialId: number;
}

export interface V2PlayersCommandsTrialsEntriesCreateResponse {
  id?: number;
}

export interface V2PlayersCommandsTrialsEntriesCreateCommand {
  /**
   * @minimum 0
   * @exclusiveMinimum
   * @nullable
   */
  measurementFactValue?: number | null;
  /** @nullable */
  trainingSessionId?: number | null;
  /** @minLength 1 */
  videoEntryRelativePath: string;
}

export interface V2PlayersCommandsSignedProClubCreateCommand {
  /** @nullable */
  proClubSignedDate?: string | null;
  proClubSignedType?: PlayersEnumsPlayerProClubSignedType;
  /** @nullable */
  signedProClubId?: number | null;
}

export interface V2PlayersCommandsRegisterResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  name?: string | null;
  playerId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V2PlayersCommandsRegisterModelsAgreementModel {
  countryId: number;
  /** @minLength 1 */
  dateOfBirth: string;
  termsAgreementId: number;
}

export interface V2PlayersCommandsRegisterCommand {
  agreement?: V2PlayersCommandsRegisterModelsAgreementModel;
  /** @minLength 1 */
  dateOfBirth: string;
  disableGuardianConsentEmail?: boolean;
  disablePlayerWelcomeEmail?: boolean;
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  fatherEmail?: string | null;
  /** @nullable */
  fatherHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  fatherName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  fatherPhone?: string | null;
  /** @minLength 1 */
  fcmToken: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  gender: PlayersEnumsGender;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  guardianEmail?: string | null;
  /** @nullable */
  guardianHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  guardianName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  guardianPhone?: string | null;
  /** @nullable */
  homeCountryId?: number | null;
  /** @nullable */
  isReceiveMarketingContent?: boolean | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  motherEmail?: string | null;
  /** @nullable */
  motherHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  motherName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  motherPhone?: string | null;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  phone?: string | null;
}

export interface V2PlayersCommandsProfilesPersonalDetailsCommand {
  /** @nullable */
  city?: string | null;
  /** @nullable */
  customHomeCounty?: string | null;
  /** @minLength 1 */
  dateOfBirth: string;
  /** @nullable */
  ethnicityId?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  gender: PlayersEnumsGender;
  /** @nullable */
  homeCountryId?: number | null;
  /** @nullable */
  homeCountyId?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /** @nullable */
  nationalityIds?: number[] | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  phone?: string | null;
  /** @nullable */
  postCode?: string | null;
}

export interface V2PlayersCommandsProfilesFootballDetailsModelsPositionModel {
  id?: number;
  isPrimary?: boolean;
}

export interface V2PlayersCommandsProfilesFootballDetailsCommand {
  /** @nullable */
  height?: number | null;
  /** @nullable */
  positionIds?: number[] | null;
  /** @nullable */
  positions?:
    | V2PlayersCommandsProfilesFootballDetailsModelsPositionModel[]
    | null;
  preferredFootType?: PlayersEnumsPreferredFootType;
  /** @nullable */
  weight?: number | null;
}

export interface V2PlayersCommandsProfilesAboutMeCommand {
  /** @nullable */
  canPlayForCountryIds?: number[] | null;
  /** @nullable */
  ethnicityId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  fatherEmail?: string | null;
  /** @nullable */
  fatherHeight?: number | null;
  /** @nullable */
  fatherHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  fatherName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  fatherPhone?: string | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  guardianEmail?: string | null;
  /** @nullable */
  guardianHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  guardianName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  guardianPhone?: string | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  motherEmail?: string | null;
  /** @nullable */
  motherHeight?: number | null;
  /** @nullable */
  motherHomeCountryId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  motherName?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  motherPhone?: string | null;
  /** @nullable */
  nationalityIds?: number[] | null;
  /** @nullable */
  passportAuthorityIds?: number[] | null;
  /** @nullable */
  personalStatement?: string | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  secondaryContactEmail?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  secondaryContactMobile?: string | null;
  /** @nullable */
  secondaryContactName?: string | null;
  secondaryContactRelationshipType?: PlayersEnumsSecondaryRelationshipType;
}

export interface V2PlayersCommandsProfileImageCommand {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  path: string;
}

export interface V2PlayersCommandsPopUpQuestionsCreateModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /**
   * @minLength 1
   * @maxLength 500
   */
  value: string;
}

export interface V2PlayersCommandsPopUpQuestionsCreateModelsResponseModel {
  /** @minItems 1 */
  answers: V2PlayersCommandsPopUpQuestionsCreateModelsAnswerModel[];
  dataType: QuestionsEnumsQuestionDataType;
  questionId?: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export interface V2PlayersCommandsPopUpQuestionsCreateCommand {
  /** @minItems 1 */
  responses: V2PlayersCommandsPopUpQuestionsCreateModelsResponseModel[];
}

export interface V2PlayersCommandsPlayerWatchItemClubsCommand {
  proClubId: number;
}

export interface V2PlayersCommandsPlayerSchoolsUpdateCommand {
  /** @nullable */
  endAge?: number | null;
  /** @nullable */
  endDate?: string | null;
  startAge: number;
  /** @minLength 1 */
  startDate: string;
}

export interface V2PlayersCommandsPlayerSchoolsCreateCommand {
  /** @nullable */
  endAge?: number | null;
  /** @nullable */
  endDate?: string | null;
  schoolId: number;
  startAge: number;
  /** @minLength 1 */
  startDate: string;
}

export interface V2PlayersCommandsPlayerFollowersUnfollowCommand {
  unfollowPlayerId: number;
}

export interface V2PlayersCommandsPlayerFollowersFollowCommand {
  followPlayerId: number;
}

export interface V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand {
  diaryEntryId: number;
  /** @maxLength 1000 */
  note: string;
}

export interface V2PlayersCommandsPlayerClubFollowersUnfollowCommand {
  proClubId: number;
}

export interface V2PlayersCommandsPlayerClubFollowersFollowCommand {
  proClubId: number;
}

export interface V2PlayersCommandsOnboardingCompleteCommand {
  playerId: number;
}

export interface V2PlayersCommandsNationalRatingSystemScoreResetCommand {
  playerId: number;
}

export interface V2PlayersCommandsLimitedAccessDisableCommand {
  [key: string]: unknown;
}

export interface V2PlayersCommandsHighlightRequestsCreateCommand {
  /** @minLength 1 */
  url: string;
}

export interface V2PlayersCommandsGuardianInvitationCodesCreateResponse {
  /** @nullable */
  guardianInvitationCode?: string | null;
}

export interface V2PlayersCommandsDeleteRequestsCreateCommand {
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface V2PlayersCommandsBookmarksCreateCommand {
  coachingContentId: number;
}

export interface V2PlayersCommandsAmateurFixturesCreateModelsTeamModel {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  id?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  name?: string | null;
}

export interface V2PlayersCommandsAmateurFixturesCreateCommand {
  /**
   * @minLength 1
   * @maxLength 150
   */
  address: string;
  awayTeam?: V2PlayersCommandsAmateurFixturesCreateModelsTeamModel;
  fixtureTypeId: number;
  homeTeam?: V2PlayersCommandsAmateurFixturesCreateModelsTeamModel;
  /**
   * @minLength 1
   * @maxLength 35
   */
  localTimeZoneId: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  postCode: string;
  /** @minLength 1 */
  startTimeLocal: string;
  /** @minLength 1 */
  startTimeUtc: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  venue: string;
}

export interface V2PlayerReportsQueriesSummaryResponse {
  id?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayerReportsQueriesListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportsQueriesListModelsPlayerReportModel {
  created?: string;
  id?: number;
  participantId?: number;
  playerReportDraftId?: number;
  scoutId?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayerReportsQueriesListModelsPlayerModel {
  id?: number;
  primaryPosition?: V2PlayerReportsQueriesListModelsPositionModel;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2PlayerReportsQueriesListModelsParticipantModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  participantType?: SpotMeDomainEnumsReportDraftsReportParticipantType;
  player?: V2PlayerReportsQueriesListModelsPlayerModel;
}

export interface V2PlayerReportsQueriesListModelsMetadataModel {
  limit?: number;
  offset?: number;
  /** @nullable */
  participants?: V2PlayerReportsQueriesListModelsParticipantModel[] | null;
}

export interface V2PlayerReportsQueriesListResponse {
  /** @nullable */
  items?: V2PlayerReportsQueriesListModelsPlayerReportModel[] | null;
  meta?: V2PlayerReportsQueriesListModelsMetadataModel;
}

export interface V2PlayerReportsQueriesCountResponse {
  count?: number;
}

export interface V2PlayerReportsCommandsDeleteCommand {
  playerReportId: number;
}

export interface V2PlayerReportsCommandsCreateCommand {
  playerReportDraftId?: number;
  playerReportParticipantId?: number;
  /** @minLength 1 */
  sourceUrl: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2PlayerReportDraftsQueriesSummaryModelsPlayerModel {
  /** @nullable */
  age?: number | null;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsQueriesSummaryModelsPlayerReportParticipantModel {
  id?: number;
  player?: V2PlayerReportDraftsQueriesSummaryModelsPlayerModel;
  team?: V2PlayerReportDraftsQueriesSummaryModelsTeamModel;
}

export interface V2PlayerReportDraftsQueriesSummaryResponse {
  /** @nullable */
  awayTeamParticipantId?: number | null;
  created?: string;
  /** @nullable */
  homeTeamParticipantId?: number | null;
  id?: number;
  modified?: string;
  /** @nullable */
  participants?:
    | V2PlayerReportDraftsQueriesSummaryModelsPlayerReportParticipantModel[]
    | null;
  status?: PlayerReportDraftsEnumsPlayerReportDraftStatus;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayerReportDraftsQueriesSummaryModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsQueriesSummaryModelsTeamModel {
  ageGroup?: V2PlayerReportDraftsQueriesSummaryModelsAgeGroupModel;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsQueriesPlayerReportDraftNotesDetailsModelsVoiceNoteModel {
  transcriptionStatus?: CommonReportDraftNotesDraftNoteTranscriptionClientStatus;
}

export interface V2PlayerReportDraftsQueriesPlayerReportDraftNotesDetailsResponse {
  id?: number;
  noteType?: SpotMeDomainEnumsReportDraftsDraftNoteType;
  /** @nullable */
  textContent?: string | null;
  /** @nullable */
  title?: string | null;
  voiceNote?: V2PlayerReportDraftsQueriesPlayerReportDraftNotesDetailsModelsVoiceNoteModel;
}

export interface V2PlayerReportDraftsQueriesDetailsResponse {
  /** @nullable */
  awayTeamParticipantId?: number | null;
  created?: string;
  /** @nullable */
  homeTeamParticipantId?: number | null;
  /** @nullable */
  localTimeZoneId?: string | null;
  /** @nullable */
  notes?:
    | V2PlayerReportDraftsQueriesDetailsModelsPlayerReportDraftNoteModel[]
    | null;
  /** @nullable */
  participants?:
    | V2PlayerReportDraftsQueriesDetailsModelsPlayerReportParticipantModel[]
    | null;
  /** @nullable */
  responses?:
    | V2PlayerReportDraftsQueriesDetailsModelsPlayerReportDraftResponseModel[]
    | null;
  startTime?: string;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsTeamModel {
  ageGroup?: V2PlayerReportDraftsQueriesDetailsModelsAgeGroupModel;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsPlayerReportParticipantModel {
  participantId?: number;
  participantType?: SpotMeDomainEnumsReportDraftsReportParticipantType;
  player?: V2PlayerReportDraftsQueriesDetailsModelsPlayerModel;
  team?: V2PlayerReportDraftsQueriesDetailsModelsTeamModel;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsPlayerReportDraftNoteModel {
  id?: number;
  noteType?: SpotMeDomainEnumsReportDraftsDraftNoteType;
  startTime?: string;
  /** @nullable */
  textContent?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsPlayerModel {
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
  primaryPosition?: V2PlayerReportDraftsQueriesDetailsModelsPositionModel;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /** @nullable */
  value?: string | null;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsPlayerReportDraftResponseModel {
  /** @nullable */
  answers?: V2PlayerReportDraftsQueriesDetailsModelsAnswerModel[] | null;
  /** @nullable */
  participantId?: number | null;
  questionId?: number;
}

export interface V2PlayerReportDraftsQueriesDetailsModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PlayerReportDraftsCommandsUpdateModelsTeamParticipantModel {
  /** @nullable */
  ageGroupId?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @nullable */
  teamId?: number | null;
}

export interface V2PlayerReportDraftsCommandsUpdateCommand {
  awayTeamParticipant: V2PlayerReportDraftsCommandsUpdateModelsTeamParticipantModel;
  homeTeamParticipant: V2PlayerReportDraftsCommandsUpdateModelsTeamParticipantModel;
  /** @minLength 1 */
  localTimeZoneId: string;
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2PlayerReportDraftsCommandsResponsesUpdateModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /**
   * @minLength 1
   * @maxLength 3000
   */
  value: string;
}

export interface V2PlayerReportDraftsCommandsResponsesUpdateModelsResponseModel {
  /** @minItems 1 */
  answers: V2PlayerReportDraftsCommandsResponsesUpdateModelsAnswerModel[];
  /** @nullable */
  participantId?: number | null;
  questionId: number;
}

export interface V2PlayerReportDraftsCommandsResponsesUpdateCommand {
  /** @nullable */
  responses:
    | V2PlayerReportDraftsCommandsResponsesUpdateModelsResponseModel[]
    | null;
}

export interface V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand {
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 8000
   */
  textContent: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
}

export interface V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand {
  participantIds: number[];
}

export interface V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand {
  participantIds: number[];
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 8000
   */
  transcriptionText: string;
}

export interface V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchModelsPlayerReportDraftNoteModel {
  /** @nullable */
  audioFilePath?: string | null;
  /** @nullable */
  durationInMilliseconds?: number | null;
  noteType: SpotMeDomainEnumsReportDraftsDraftNoteType;
  participantClientIds: string[];
  /** @minLength 1 */
  startTime: string;
  /** @nullable */
  textContent?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
}

export interface V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand {
  /** @minItems 1 */
  notes: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchModelsPlayerReportDraftNoteModel[];
}

export interface V2PlayerReportDraftsCommandsParticipantsPlayersUpdateModelsPlayerParticipantModel {
  /**
   * @minimum 1
   * @maximum 100
   */
  age: number;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @nullable */
  playerId?: number | null;
}

export interface V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand {
  participants: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateModelsPlayerParticipantModel[];
}

export interface V2PlayerReportDraftsCommandsCreateModelsTeamModel {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface V2PlayerReportDraftsCommandsCreateModelsPlayerModel {
  /**
   * @minimum 1
   * @maximum 100
   * @nullable
   */
  age?: number | null;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface V2PlayerReportDraftsCommandsCreateModelsParticipantModel {
  /** @minLength 1 */
  clientSideParticipantId: string;
  participantType: SpotMeDomainEnumsReportDraftsReportParticipantType;
  player?: V2PlayerReportDraftsCommandsCreateModelsPlayerModel;
  team?: V2PlayerReportDraftsCommandsCreateModelsTeamModel;
}

export interface V2PlayerReportDraftsCommandsCreateCommand {
  /** @nullable */
  awayTeamClientSideParticipantId?: string | null;
  /** @nullable */
  homeTeamClientSideParticipantId?: string | null;
  /** @minLength 1 */
  localTimeZoneId: string;
  participants: V2PlayerReportDraftsCommandsCreateModelsParticipantModel[];
  responses: HandlerServicesQuestionValidationsParticipantBasedReportsPlayerReportsModelsPlayerReportDraftResponseModel[];
  /** @minLength 1 */
  startTimeUtc: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2PerformanceLabReportsCommandsCreateCommand {
  playerId: number;
  /**
   * @minLength 1
   * @maxLength 2048
   */
  sourceUrl: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2PassportAuthoritiesQueriesListQuery {
  [key: string]: unknown;
}

export interface V2PassportAuthoritiesQueriesListModelsPassportAuthorityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2PassportAuthoritiesQueriesListResponse {
  /** @nullable */
  items?: V2PassportAuthoritiesQueriesListModelsPassportAuthorityModel[] | null;
}

export interface V2ParentInvitationsQueriesVersionsListModelsParentInvitationVersionModel {
  /** @nullable */
  additionalNote?: string | null;
  /** @nullable */
  parentEmail?: string | null;
  /** @nullable */
  parentName?: string | null;
  /** @nullable */
  parentPhoneNumber?: string | null;
  /** @nullable */
  playerName?: string | null;
  /** @nullable */
  scoutEmail?: string | null;
  /** @nullable */
  scoutPhoneNumber?: string | null;
  /** @nullable */
  standardNote?: string | null;
}

export interface V2ParentInvitationsQueriesVersionsListResponse {
  /** @nullable */
  items?:
    | V2ParentInvitationsQueriesVersionsListModelsParentInvitationVersionModel[]
    | null;
}

export interface V2ParentInvitationsCommandsVersionsCreateCommand {
  /**
   * @maxLength 1000
   * @nullable
   */
  additionalNote?: string | null;
  /**
   * @maxLength 1000
   * @nullable
   */
  note?: string | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  parentEmail?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  parentPhoneNumber?: string | null;
}

export interface V2ParentInvitationsCommandsCreateCommand {
  includeScoutEmail?: boolean;
  includeScoutPhoneNumber?: boolean;
  /**
   * @maxLength 1000
   * @nullable
   */
  note?: string | null;
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  parentEmail?: string | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  parentName: string;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  parentPhoneNumber?: string | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  playerName: string;
  scoutBusinessCardId: number;
  scoutId?: number;
}

export interface V2OpportunityNodesQueriesProClubsModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryVenueName?: string | null;
}

export interface V2OpportunityNodesQueriesProClubsModelsNodeItemModel {
  isActive?: boolean;
  proClub?: V2OpportunityNodesQueriesProClubsModelsProClubModel;
}

export interface V2OpportunityNodesQueriesProClubsResponse {
  /** @nullable */
  items?: V2OpportunityNodesQueriesProClubsModelsNodeItemModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2OpportunityNodesQueriesListModelsOpportunityNodeModel {
  /** @nullable */
  activeLabelText?: string | null;
  childDisplayType?: OpportunityNodesEnumsOpportunityNodeDisplayType;
  hasChildNodes?: boolean;
  id?: number;
  /** @nullable */
  inactiveLabelText?: string | null;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2OpportunityNodesQueriesListResponse {
  /** @nullable */
  items?: V2OpportunityNodesQueriesListModelsOpportunityNodeModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2NotifyJobsCommandsCreateResponse {
  id?: number;
  userCount?: number;
}

export type V2NotifyJobsCommandsCreateModelsTrialsTabViewArgsModelAllOf = {
  notifyJobType?: NotifyJobsEnumsNotifyJobType;
};

export type V2NotifyJobsCommandsCreateModelsTrialsTabViewArgsModel =
  V2NotifyJobsCommandsCreateModelsBaseNotifyJobArgsModel &
    V2NotifyJobsCommandsCreateModelsTrialsTabViewArgsModelAllOf;

export type V2NotifyJobsCommandsCreateModelsPlayerProfileEditViewArgsModelAllOf =
  {
    notifyJobType?: NotifyJobsEnumsNotifyJobType;
  };

export type V2NotifyJobsCommandsCreateModelsPlayerProfileEditViewArgsModel =
  V2NotifyJobsCommandsCreateModelsBaseNotifyJobArgsModel &
    V2NotifyJobsCommandsCreateModelsPlayerProfileEditViewArgsModelAllOf;

export type V2NotifyJobsCommandsCreateModelsEmptyArgsModelAllOf = {
  notifyJobType?: NotifyJobsEnumsNotifyJobType;
};

export type V2NotifyJobsCommandsCreateModelsDrillViewArgsModelAllOf = {
  notifyJobType?: NotifyJobsEnumsNotifyJobType;
  trialId?: number;
};

export interface V2NotifyJobsCommandsCreateModelsBaseNotifyJobArgsModel {
  notifyJobType: NotifyJobsEnumsNotifyJobType;
}

export type V2NotifyJobsCommandsCreateModelsEmptyArgsModel =
  V2NotifyJobsCommandsCreateModelsBaseNotifyJobArgsModel &
    V2NotifyJobsCommandsCreateModelsEmptyArgsModelAllOf;

export type V2NotifyJobsCommandsCreateModelsDrillViewArgsModel =
  V2NotifyJobsCommandsCreateModelsBaseNotifyJobArgsModel &
    V2NotifyJobsCommandsCreateModelsDrillViewArgsModelAllOf;

export type V2NotifyJobsCommandsCreateCommandArguments =
  | V2NotifyJobsCommandsCreateModelsEmptyArgsModel
  | V2NotifyJobsCommandsCreateModelsDrillViewArgsModel
  | V2NotifyJobsCommandsCreateModelsPlayerProfileEditViewArgsModel
  | V2NotifyJobsCommandsCreateModelsTrialsTabViewArgsModel;

export interface V2NotifyJobsCommandsCreateCommand {
  arguments: V2NotifyJobsCommandsCreateCommandArguments;
  /**
   * @minimum 1
   * @maximum 100000
   */
  batchSize: number;
  /**
   * @minimum 0.0166666666666667
   * @maximum 10080
   */
  batchTimeGapInMinutes: number;
  /**
   * @minLength 1
   * @maxLength 500
   */
  fcmDescriptionTemplate: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  fcmTitleTemplate: string;
  /**
   * @minLength 1
   * @maxLength 8000
   */
  userIdSelectSqlScript: string;
  /** @nullable */
  utcStartTime?: string | null;
}

export interface V2NationalitiesQueriesListModelsNationalityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2NationalitiesQueriesListResponse {
  /** @nullable */
  items?: V2NationalitiesQueriesListModelsNationalityModel[] | null;
}

export interface V2MissingTeamApplicationsCommandsCreateResponse {
  id?: number;
}

export interface V2MissingTeamApplicationsCommandsCreateCommand {
  /**
   * @maxLength 100
   * @nullable
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  coachEmail?: string | null;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  coachMobile?: string | null;
  /**
   * @maxLength 50
   * @nullable
   */
  coachName?: string | null;
  countryId: number;
  /** @nullable */
  divisionId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  divisionName?: string | null;
  isMainTeam?: boolean;
  /** @nullable */
  leagueId?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  leagueName?: string | null;
  /** @nullable */
  playerEndDate?: string | null;
  /** @minLength 1 */
  playerStartDate: string;
  teamAgeGroupId: number;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  teamContactNumber?: string | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  teamName: string;
  /** @nullable */
  teamTypeId?: number | null;
  /**
   * @maxLength 30
   * @nullable
   */
  teamTypeName?: string | null;
}

export interface V2MissingSchoolApplicationsCommandsCreateResponse {
  id?: number;
}

export interface V2MissingSchoolApplicationsCommandsCreateCommand {
  countryId: number;
  /** @nullable */
  countyId?: number | null;
  /**
   * @maxLength 75
   * @nullable
   */
  countyName?: string | null;
  /**
   * @minimum 1
   * @maximum 65
   * @nullable
   */
  playerEndAge?: number | null;
  /** @nullable */
  playerEndDate?: string | null;
  /**
   * @minimum 1
   * @maximum 65
   */
  playerStartAge: number;
  /** @minLength 1 */
  playerStartDate: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  schoolName: string;
  /** @nullable */
  schoolTypeId?: number | null;
  /**
   * @maxLength 30
   * @nullable
   */
  schoolTypeName?: string | null;
  /**
   * @minLength 1
   * @maxLength 75
   */
  townName: string;
}

export interface V2LeaguesQueriesSearchModelsLeagueModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2LeaguesQueriesSearchResponse {
  /** @nullable */
  items?: V2LeaguesQueriesSearchModelsLeagueModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2LeaguesQueriesDivisionsListModelsDivisionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2LeaguesQueriesDivisionsListResponse {
  /** @nullable */
  items?: V2LeaguesQueriesDivisionsListModelsDivisionModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2InfoScreensQueriesDetailsQuery {
  infoScreenType?: InfoScreensEnumsInfoScreenType;
}

export interface V2InfoScreensQueriesDetailsModelsInfoScreenItemModel {
  hasEndSeparator?: boolean;
  /** @nullable */
  htmlDescription?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2InfoScreensQueriesDetailsResponse {
  icon?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  infoItems?: V2InfoScreensQueriesDetailsModelsInfoScreenItemModel[] | null;
  /** @nullable */
  title?: string | null;
}

export interface V2GuardiansQueriesSummaryResponse {
  /** @nullable */
  email?: string | null;
  /** @nullable */
  firstName?: string | null;
  id?: number;
  /** @nullable */
  lastName?: string | null;
}

export interface V2GuardiansQueriesPlayersListModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaResizedImageResponse;
}

export interface V2GuardiansQueriesPlayersListResponse {
  /** @nullable */
  items?: V2GuardiansQueriesPlayersListModelsPlayerModel[] | null;
}

export interface V2GuardiansCommandsRegisterResponse {
  /** @nullable */
  accessToken?: string | null;
  guardianId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V2GuardiansCommandsRegisterModelsAgreementModel {
  playerCountryId: number;
  /** @minLength 1 */
  playerDateOfBirth: string;
  termsAgreementId: number;
}

export interface V2GuardiansCommandsRegisterCommand {
  agreement: V2GuardiansCommandsRegisterModelsAgreementModel;
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /** @minLength 1 */
  fcmToken: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  homeCountryId?: number;
  /** @nullable */
  isReceiveMarketingContent?: boolean | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  phone?: string | null;
}

export interface V2GuardiansCommandsPlayersSwitchResponse {
  /** @nullable */
  accessToken?: string | null;
  guardianId?: number;
  isOnboardingComplete?: boolean;
  playerId?: number;
  /** @nullable */
  refreshToken?: string | null;
  userId?: string;
}

export interface V2GuardiansCommandsPlayersSwitchCommand {
  /** @minLength 1 */
  fcmToken: string;
}

export interface V2GuardiansCommandsPlayersRegisterResponse {
  playerId?: number;
}

export interface V2GuardiansCommandsPlayersRegisterCommand {
  /**
   * @maxLength 200
   * @nullable
   */
  city?: string | null;
  /**
   * @maxLength 200
   * @nullable
   */
  customHomeCounty?: string | null;
  /** @minLength 1 */
  dateOfBirth: string;
  /**
   * @minLength 1
   * @maxLength 100
   * @pattern (?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
   */
  email: string;
  /** @nullable */
  ethnicityId?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  gender: PlayersEnumsGender;
  guardianType: GuardiansEnumsGuardianType;
  homeCountryId: number;
  /** @nullable */
  homeCountyId?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  nationalityIds: number[];
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  /**
   * @minLength 3
   * @maxLength 15
   * @nullable
   * @pattern ^\+[1-9]\d{1,14}$
   */
  phone?: string | null;
  /**
   * @maxLength 100
   * @nullable
   */
  postCode?: string | null;
}

export interface V2GuardiansCommandsPlayersCreateResponse {
  playerId?: number;
}

export interface V2GuardiansCommandsPlayersCreateCommand {
  guardianType: GuardiansEnumsGuardianType;
  /**
   * @minLength 1
   * @maxLength 6
   */
  playerGuardianInvitationCode: string;
}

export interface V2GuardiansCommandsDeleteRequestsCreateCommand {
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface V2GuardiansCommandsDeleteCommand {
  /** @minLength 1 */
  userId: string;
}

export interface V2GameReportsQueriesSummaryResponse {
  id?: number;
  /** @nullable */
  sourceUrl?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2GameReportsCommandsDeleteCommand {
  gameReportId: number;
}

export interface V2GameReportsCommandsCreateCommand {
  gameReportDraftId?: number;
  gameReportParticipantId?: number;
  /** @minLength 1 */
  sourceUrl: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2GameReportDraftsQueriesSummaryModelsGameReportParticipantModel {
  id?: number;
  team?: V2GameReportDraftsQueriesSummaryModelsTeamModel;
}

export interface V2GameReportDraftsQueriesSummaryResponse {
  /** @nullable */
  awayTeamParticipantId?: number | null;
  created?: string;
  /** @nullable */
  homeTeamParticipantId?: number | null;
  id?: number;
  modified?: string;
  /** @nullable */
  participants?:
    | V2GameReportDraftsQueriesSummaryModelsGameReportParticipantModel[]
    | null;
  status?: PlayerReportDraftsEnumsPlayerReportDraftStatus;
  /** @nullable */
  title?: string | null;
}

export interface V2GameReportDraftsQueriesSummaryModelsAgeGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2GameReportDraftsQueriesSummaryModelsTeamModel {
  ageGroup?: V2GameReportDraftsQueriesSummaryModelsAgeGroupModel;
  /** @nullable */
  id?: number | null;
  /** @nullable */
  name?: string | null;
}

export interface V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand {
  participantIds: number[];
  /** @minLength 1 */
  startTime: string;
  /**
   * @minLength 1
   * @maxLength 8000
   */
  textContent: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
}

export interface V2GameReportDraftsCommandsGameReportDraftNotesAddBatchModelsGameReportDraftNoteModel {
  /** @nullable */
  audioFilePath?: string | null;
  /** @nullable */
  durationInMilliseconds?: number | null;
  noteType: SpotMeDomainEnumsReportDraftsDraftNoteType;
  participantClientIds: string[];
  /** @minLength 1 */
  startTime: string;
  /** @nullable */
  textContent?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  title: string;
}

export interface V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand {
  /** @minItems 1 */
  notes: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchModelsGameReportDraftNoteModel[];
}

export interface V2GameReportDraftsCommandsCreateModelsTeamModel {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
}

export interface V2GameReportDraftsCommandsCreateModelsParticipantModel {
  /** @minLength 1 */
  clientSideParticipantId: string;
  participantType: SpotMeDomainEnumsReportDraftsReportParticipantType;
  team: V2GameReportDraftsCommandsCreateModelsTeamModel;
}

export interface V2GameReportDraftsCommandsCreateCommand {
  /** @nullable */
  awayTeamClientSideParticipantId?: string | null;
  /** @nullable */
  homeTeamClientSideParticipantId?: string | null;
  /** @minLength 1 */
  localTimeZoneId: string;
  participants: V2GameReportDraftsCommandsCreateModelsParticipantModel[];
  responses: HandlerServicesQuestionValidationsParticipantBasedReportsGameReportsModelsGameReportDraftResponseModel[];
  /** @minLength 1 */
  startTimeUtc: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  title: string;
}

export interface V2FeedsQueriesPlayersHomeResponse {
  /** @nullable */
  feedItems?: V2FeedsQueriesPlayersHomeModelsFeedItemModel[] | null;
  meta?: V2FeedsQueriesPlayersHomeModelsResponseMetadataModel;
}

export interface V2FeedsQueriesPlayersHomeQuery {
  filterTime?: string;
  /** @nullable */
  lastPostId?: number | null;
  /**
   * @minimum 1
   * @maximum 100
   * @exclusiveMinimum
   * @exclusiveMaximum
   */
  limit?: number;
}

export interface V2FeedsQueriesPlayersHomeModelsProClubPostModel {
  created?: string;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  isReady?: boolean | null;
  likeCount?: number;
  liked?: boolean;
  /** @nullable */
  media?: CommonResponsesMediaMediaItemResponse[] | null;
  proClubId?: number;
}

export interface V2FeedsQueriesPlayersHomeModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2FeedsQueriesPlayersHomeModelsProClubsMetadataModel {
  /** @nullable */
  items?: V2FeedsQueriesPlayersHomeModelsProClubModel[] | null;
}

export interface V2FeedsQueriesPlayersHomeModelsPlayerPostModel {
  created?: string;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  isReady?: boolean | null;
  likeCount?: number;
  liked?: boolean;
  /** @nullable */
  media?: CommonResponsesMediaMediaItemResponse[] | null;
  playerId?: number;
  shareType?: PostsEnumsShareType;
}

export interface V2FeedsQueriesPlayersHomeModelsPlayerModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  signedProClubId?: number | null;
}

export interface V2FeedsQueriesPlayersHomeModelsPlayersMetadataModel {
  /** @nullable */
  items?: V2FeedsQueriesPlayersHomeModelsPlayerModel[] | null;
}

export interface V2FeedsQueriesPlayersHomeModelsResponseMetadataModel {
  players?: V2FeedsQueriesPlayersHomeModelsPlayersMetadataModel;
  proClubs?: V2FeedsQueriesPlayersHomeModelsProClubsMetadataModel;
}

export type V2FeedsQueriesPlayersHomeEnumsFeedItemType =
  typeof V2FeedsQueriesPlayersHomeEnumsFeedItemType[keyof typeof V2FeedsQueriesPlayersHomeEnumsFeedItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const V2FeedsQueriesPlayersHomeEnumsFeedItemType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export interface V2FeedsQueriesPlayersHomeModelsFeedItemModel {
  feedItemType?: V2FeedsQueriesPlayersHomeEnumsFeedItemType;
  playerPost?: V2FeedsQueriesPlayersHomeModelsPlayerPostModel;
  proClubPost?: V2FeedsQueriesPlayersHomeModelsProClubPostModel;
}

export interface V2ExploreLibrariesQueriesLibraryItemsListQuery {
  exploreLibraryId?: number;
  /** @nullable */
  libraryCategoryIds?: string | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  /** @nullable */
  searchTerm?: string | null;
}

export interface V2ExploreLibrariesQueriesLibraryItemsListModelsLibraryItemModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  resourceVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2ExploreLibrariesQueriesLibraryItemsListResponse {
  /** @nullable */
  items?:
    | V2ExploreLibrariesQueriesLibraryItemsListModelsLibraryItemModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2ExploreLibrariesQueriesLibraryItemsLibraryCategoriesListModelsExploreLibraryCategoryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2ExploreLibrariesQueriesLibraryItemsLibraryCategoriesListResponse {
  /** @nullable */
  items?:
    | V2ExploreLibrariesQueriesLibraryItemsLibraryCategoriesListModelsExploreLibraryCategoryModel[]
    | null;
}

export interface V2EthnicitiesQueriesListModelsEthnicityModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2EthnicitiesQueriesListResponse {
  /** @nullable */
  items?: V2EthnicitiesQueriesListModelsEthnicityModel[] | null;
}

export interface V2EquipmentsQueriesListModelsEquipmentModel {
  id?: number;
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2EquipmentsQueriesListResponse {
  /** @nullable */
  items?: V2EquipmentsQueriesListModelsEquipmentModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2EquipmentsQueriesDetailsResponse {
  id?: number;
  isDeletable?: boolean;
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2EquipmentsCommandsUpdateCommand {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  primaryImagePath: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  subTitle: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  title: string;
}

export interface V2EquipmentsCommandsDeleteCommand {
  equipmentId: number;
}

export interface V2EquipmentsCommandsCreateCommand {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  primaryImagePath: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  subTitle: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  title: string;
}

export interface V2EntryRatingTypesQueriesListModelsEntryRatingTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2EntryRatingTypesQueriesListResponse {
  /** @nullable */
  items?: V2EntryRatingTypesQueriesListModelsEntryRatingTypeModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2EntryRatingTypesQueriesDetailsResponse {
  /** @nullable */
  analysisInterpreterRatingId?: string | null;
  id?: number;
  isAvailableToCoach?: boolean;
  isAvailableToPlayer?: boolean;
  isAvailableToScout?: boolean;
  isAvailableToTurk?: boolean;
  isDeletable?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  turkUiText?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2EntryRatingTypesCommandsUpdateCommand {
  /** @nullable */
  analysisInterpreterRatingId?: string | null;
  isAvailableToCoach?: boolean;
  isAvailableToPlayer?: boolean;
  isAvailableToScout?: boolean;
  isAvailableToTurk?: boolean;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  turkUiText: string;
}

export interface V2EntryRatingTypesCommandsCreateCommand {
  /** @nullable */
  analysisInterpreterRatingId?: string | null;
  isAvailableToCoach?: boolean;
  isAvailableToPlayer?: boolean;
  isAvailableToScout?: boolean;
  isAvailableToTurk?: boolean;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  turkUiText: string;
}

export interface V2EntryMeasurementTypesQueriesListModelsEntryMeasurementTypesModel {
  /** @nullable */
  displayUnit?: string | null;
  id?: number;
  /** @nullable */
  measurementUnit?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2EntryMeasurementTypesQueriesListResponse {
  /** @nullable */
  items?:
    | V2EntryMeasurementTypesQueriesListModelsEntryMeasurementTypesModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2EntryMeasurementTypesQueriesDetailsResponse {
  /** @nullable */
  analysisInterpreterMeasurementId?: string | null;
  /** @nullable */
  displayUnit?: string | null;
  /** @nullable */
  highIsBest?: boolean | null;
  id?: number;
  isAvailableToPlayer?: boolean;
  isAvailableToTurk?: boolean;
  isDeletable?: boolean;
  /** @nullable */
  measurementUnit?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  turkUiText?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface V2EntryMeasurementTypesCommandsUpdateCommand {
  /** @nullable */
  analysisInterpreterMeasurementId?: string | null;
  /** @maxLength 5 */
  displayUnit: string;
  /** @nullable */
  highIsBest?: boolean | null;
  isAvailableToPlayer?: boolean;
  isAvailableToTurk?: boolean;
  /**
   * @minLength 1
   * @maxLength 100
   */
  measurementUnit: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  turkUiText: string;
}

export interface V2EntryMeasurementTypesCommandsCreateCommand {
  /** @nullable */
  analysisInterpreterMeasurementId?: string | null;
  /** @maxLength 5 */
  displayUnit: string;
  /** @nullable */
  highIsBest?: boolean | null;
  isAvailableToPlayer?: boolean;
  isAvailableToTurk?: boolean;
  /**
   * @minLength 1
   * @maxLength 100
   */
  measurementUnit: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  turkUiText: string;
}

export interface V2DiscoveryTrialsQueriesListQuery {
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  trialType: SpotMeDomainEnumsTrialType;
}

/**
 * @nullable
 */
export type V2DiscoveryTrialsQueriesListModelsDiscoveryRedirectModelResource =
  | CommonResponsesDiscoveryProClubTrialCategoryResourceModel
  | CommonResponsesDiscoveryProClubTrialGroupListResourceModel
  | CommonResponsesDiscoveryTrialDetailResourceModel
  | CommonResponsesDiscoveryTrialGroupResourceModel
  | CommonResponsesDiscoveryTrialCategoryResourceModel
  | null;

export interface V2DiscoveryTrialsQueriesListModelsDiscoveryRedirectModel {
  /** @nullable */
  resource?: V2DiscoveryTrialsQueriesListModelsDiscoveryRedirectModelResource;
}

export interface V2DiscoveryTrialsQueriesListModelsDiscoveryTrialModel {
  /** @nullable */
  actionButtonText?: string | null;
  bannerMedia?: CommonResponsesMediaImageResponse;
  discoveryRedirect?: V2DiscoveryTrialsQueriesListModelsDiscoveryRedirectModel;
  headerLogo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  heading?: string | null;
  id?: number;
  /** @nullable */
  ownerName?: string | null;
}

export interface V2DiscoveryTrialsQueriesListResponse {
  /** @nullable */
  items?: V2DiscoveryTrialsQueriesListModelsDiscoveryTrialModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

/**
 * @nullable
 */
export type V2DiscoverySponsorsQueriesListModelsDiscoveryRedirectModelResource =

    | CommonResponsesDiscoveryProClubTrialCategoryResourceModel
    | CommonResponsesDiscoveryProClubTrialGroupListResourceModel
    | CommonResponsesDiscoveryTrialDetailResourceModel
    | CommonResponsesDiscoveryTrialGroupResourceModel
    | CommonResponsesDiscoveryTrialCategoryResourceModel
    | null;

export interface V2DiscoverySponsorsQueriesListModelsDiscoveryRedirectModel {
  /** @nullable */
  resource?: V2DiscoverySponsorsQueriesListModelsDiscoveryRedirectModelResource;
}

export interface V2DiscoverySponsorsQueriesListModelsDiscoverySponsorModel {
  discoveryRedirect?: V2DiscoverySponsorsQueriesListModelsDiscoveryRedirectModel;
  logo?: CommonResponsesMediaImageResponse;
}

export interface V2DiscoverySponsorsQueriesListResponse {
  /** @nullable */
  items?: V2DiscoverySponsorsQueriesListModelsDiscoverySponsorModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2DiscoverySectionsQueriesListModelsDiscoverySectionModel {
  discoverySectionType?: DiscoverySectionsEnumsDiscoverySectionType;
  /** @nullable */
  entityId?: string | null;
}

export interface V2DiscoverySectionsQueriesListResponse {
  /** @nullable */
  items?: V2DiscoverySectionsQueriesListModelsDiscoverySectionModel[] | null;
}

/**
 * @nullable
 */
export type V2DiscoveryPostsQueriesBatchModelsDiscoveryRedirectModelResource =
  | CommonResponsesDiscoveryProClubTrialCategoryResourceModel
  | CommonResponsesDiscoveryProClubTrialGroupListResourceModel
  | CommonResponsesDiscoveryTrialDetailResourceModel
  | CommonResponsesDiscoveryTrialGroupResourceModel
  | CommonResponsesDiscoveryTrialCategoryResourceModel
  | null;

export interface V2DiscoveryPostsQueriesBatchModelsDiscoveryRedirectModel {
  /** @nullable */
  resource?: V2DiscoveryPostsQueriesBatchModelsDiscoveryRedirectModelResource;
}

export interface V2DiscoveryPostsQueriesBatchModelsDiscoveryPostModel {
  /** @nullable */
  description?: string | null;
  discoveryRedirect?: V2DiscoveryPostsQueriesBatchModelsDiscoveryRedirectModel;
  headerLogo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  heading?: string | null;
  id?: number;
  /** @nullable */
  ownerName?: string | null;
  postMedia?: CommonResponsesMediaBaseMediaResponse;
  /** @nullable */
  subHeading?: string | null;
}

export interface V2DiscoveryPostsQueriesBatchResponse {
  /** @nullable */
  items?: V2DiscoveryPostsQueriesBatchModelsDiscoveryPostModel[] | null;
}

export interface V2DiscoveryCarouselItemsQueriesListResponse {
  /** @nullable */
  items?:
    | V2DiscoveryCarouselItemsQueriesListModelsDiscoveryCarouselItemModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

/**
 * @nullable
 */
export type V2DiscoveryCarouselItemsQueriesListModelsDiscoveryRedirectModelResource =

    | CommonResponsesDiscoveryProClubTrialCategoryResourceModel
    | CommonResponsesDiscoveryProClubTrialGroupListResourceModel
    | CommonResponsesDiscoveryTrialDetailResourceModel
    | CommonResponsesDiscoveryTrialGroupResourceModel
    | CommonResponsesDiscoveryTrialCategoryResourceModel
    | null;

export interface V2DiscoveryCarouselItemsQueriesListModelsDiscoveryRedirectModel {
  /** @nullable */
  resource?: V2DiscoveryCarouselItemsQueriesListModelsDiscoveryRedirectModelResource;
}

export interface V2DiscoveryCarouselItemsQueriesListModelsDiscoveryCarouselItemModel {
  backgroundImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  description?: string | null;
  discoveryRedirect?: V2DiscoveryCarouselItemsQueriesListModelsDiscoveryRedirectModel;
  primaryImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  title?: string | null;
}

export interface V2CountriesQueriesListModelsCountryModel {
  flag?: CommonResponsesMediaMediaItemResponse;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2CountriesQueriesListResponse {
  /** @nullable */
  items?: V2CountriesQueriesListModelsCountryModel[] | null;
}

export interface V2CountriesQueriesCountiesListModelsCountyModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2CountriesQueriesCountiesListResponse {
  /** @nullable */
  items?: V2CountriesQueriesCountiesListModelsCountyModel[] | null;
}

export interface V2CoachesQueriesSummaryModelsProClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2CoachesQueriesSummaryResponse {
  /** @nullable */
  firstName?: string | null;
  id?: number;
  /** @nullable */
  lastName?: string | null;
  proClub?: V2CoachesQueriesSummaryModelsProClubModel;
  profileImage?: CommonResponsesMediaResizedImageResponse;
}

export interface V2CoachesQueriesNotificationsStatsResponse {
  hasUnseen?: boolean;
}

export interface V2CoachesQueriesNotificationsListResponse {
  /** @nullable */
  items?: HandlerServicesV2NotificationHandlersModelsNotificationModel[] | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2CoachesQueriesAcademyTeamsListModelsAcademyTeamModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2CoachesQueriesAcademyTeamsListResponse {
  /** @nullable */
  items?: V2CoachesQueriesAcademyTeamsListModelsAcademyTeamModel[] | null;
}

export interface V2CoachesCommandsNotificationsMarkAllAsSeenCommand {
  coachId?: number;
  /** @minLength 1 */
  lastSeen: string;
}

export interface V2CoachesCommandsCreateModelsUserModel {
  /**
   * @minLength 1
   * @maxLength 100
   */
  email: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface V2CoachesCommandsCreateCommand {
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  proClubId: number;
  user: V2CoachesCommandsCreateModelsUserModel;
}

export interface V2AppVersionsQueriesScoutAppResponse {
  buildNumber?: number;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  version?: string | null;
  versionNumber?: number;
}

export interface V2AppVersionsQueriesPlayerAppResponse {
  buildNumber?: number;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  version?: string | null;
  versionNumber?: number;
}

export interface V2AppVersionsQueriesCoachAppResponse {
  buildNumber?: number;
  /** @nullable */
  description?: string | null;
  id?: number;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  version?: string | null;
  versionNumber?: number;
}

export interface V2AnalysisInterpretersQueriesListModelsAnalysisInterpreterModel {
  /** @nullable */
  description?: string | null;
  id?: string;
}

export interface V2AnalysisInterpretersQueriesListResponse {
  /** @nullable */
  items?:
    | V2AnalysisInterpretersQueriesListModelsAnalysisInterpreterModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AnalysisInterpretersQueriesDetailsModelsAnalysisInterpreterRatingModel {
  /** @nullable */
  description?: string | null;
  id?: string;
}

export interface V2AnalysisInterpretersQueriesDetailsModelsAnalysisInterpreterMeasurementModel {
  /** @nullable */
  description?: string | null;
  id?: string;
}

export interface V2AnalysisInterpretersQueriesDetailsResponse {
  /** @nullable */
  description?: string | null;
  id?: string;
  /** @nullable */
  measurements?:
    | V2AnalysisInterpretersQueriesDetailsModelsAnalysisInterpreterMeasurementModel[]
    | null;
  /** @nullable */
  ratings?:
    | V2AnalysisInterpretersQueriesDetailsModelsAnalysisInterpreterRatingModel[]
    | null;
}

export interface V2AmateurFixturesQueriesMetadataFixtureTypesListModelsAmateurFixtureTypeModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AmateurFixturesQueriesMetadataFixtureTypesListResponse {
  /** @nullable */
  items?:
    | V2AmateurFixturesQueriesMetadataFixtureTypesListModelsAmateurFixtureTypeModel[]
    | null;
}

export interface V2AmateurFixturesCommandsUpdateModelsTeamModel {
  /** @nullable */
  ageGroupId?: number | null;
  /** @nullable */
  id?: number | null;
  /**
   * @maxLength 100
   * @nullable
   */
  name?: string | null;
}

export interface V2AmateurFixturesCommandsUpdateCommand {
  /**
   * @minLength 1
   * @maxLength 150
   */
  address: string;
  awayTeam?: V2AmateurFixturesCommandsUpdateModelsTeamModel;
  fixtureTypeId: number;
  homeTeam?: V2AmateurFixturesCommandsUpdateModelsTeamModel;
  /**
   * @minLength 1
   * @maxLength 35
   */
  localTimeZoneId: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  postCode: string;
  /** @minLength 1 */
  startTimeLocal: string;
  /** @minLength 1 */
  startTimeUtc: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  venue: string;
}

export interface V2AmateurFixturesCommandsDeleteCommand {
  amateurFixtureId: number;
}

export interface V2AmateurClubsQueriesSearchListModelsAmateurClubModel {
  id?: number;
  logo?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  name?: string | null;
}

export interface V2AmateurClubsQueriesSearchListResponse {
  /** @nullable */
  items?: V2AmateurClubsQueriesSearchListModelsAmateurClubModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AmateurClubsQueriesSearchCountResponse {
  count?: number;
}

export interface V2AcademyTeamsQueriesTrainingSessionsStatsResponse {
  hasStandaloneTrainingSessions?: boolean;
}

export interface V2AcademyTeamsQueriesTrainingSessionsListModelsTrainingSessionModel {
  /** @nullable */
  completedTime?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademyTeamsQueriesTrainingSessionsListResponse {
  /** @nullable */
  items?:
    | V2AcademyTeamsQueriesTrainingSessionsListModelsTrainingSessionModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AcademyTeamsQueriesTrainingSessionGroupsListModelsTrainingSessionGroupModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  startTime?: string;
}

export interface V2AcademyTeamsQueriesTrainingSessionGroupsListResponse {
  /** @nullable */
  items?:
    | V2AcademyTeamsQueriesTrainingSessionGroupsListModelsTrainingSessionGroupModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AcademyTeamsQueriesPlayersListModelsPlayerModel {
  dateOfBirth?: string;
  /** @nullable */
  height?: number | null;
  id?: number;
  /** @nullable */
  name?: string | null;
  profileImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  weight?: number | null;
}

export interface V2AcademyTeamsQueriesPlayersListResponse {
  /** @nullable */
  items?: V2AcademyTeamsQueriesPlayersListModelsPlayerModel[] | null;
}

export type V2AcademyTeamsQueriesPlayersListEnumsSortType =
  typeof V2AcademyTeamsQueriesPlayersListEnumsSortType[keyof typeof V2AcademyTeamsQueriesPlayersListEnumsSortType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const V2AcademyTeamsQueriesPlayersListEnumsSortType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export interface V2AcademyTeamsQueriesPlayersListQuery {
  academyTeamId?: number;
  sortType?: V2AcademyTeamsQueriesPlayersListEnumsSortType;
}

export interface V2AcademyTeamsQueriesCoachesTrainingSessionsListQuery {
  academyTeamId?: number;
  coachId?: number;
  /** @nullable */
  isCompleted?: boolean | null;
  /** @nullable */
  isStarted?: boolean | null;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  sortType: SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType;
}

export interface V2AcademyTeamsQueriesCoachesTrainingSessionsListModelsTrainingSessionModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  canReopen?: boolean;
  /** @nullable */
  completedTime?: string | null;
  id?: number;
  isCompleted?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  subtitle?: string | null;
}

export interface V2AcademyTeamsQueriesCoachesTrainingSessionsListResponse {
  /** @nullable */
  items?:
    | V2AcademyTeamsQueriesCoachesTrainingSessionsListModelsTrainingSessionModel[]
    | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AcademySubscriptionsCommandsDeleteCommand {
  academySubscriptionId: number;
}

export interface V2AcademySubscriptionsCommandsCreateCommand {
  academyId: number;
  playerId: number;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryModelsPlayerDiaryEntryNoteModel {
  /** @nullable */
  note?: string | null;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryModelsDiaryEntryInfoItemModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryResponse {
  /** @nullable */
  contentHtml?: string | null;
  id?: number;
  /** @nullable */
  infoItems?:
    | V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryModelsDiaryEntryInfoItemModel[]
    | null;
  playerDiaryEntryNote?: V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryModelsPlayerDiaryEntryNoteModel;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansCurrentQuery {
  academyId?: number;
  /** @minLength 1 */
  timeZoneId: string;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsDiaryEntryModel {
  /** @nullable */
  contentHtml?: string | null;
  id?: number;
  previewImage?: CommonResponsesMediaImageResponse;
  previewVideo?: CommonResponsesMediaVideoResponse;
  primaryVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  shortLineDescription?: string | null;
  /** @nullable */
  summaryText?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsMetadataModel {
  /** @nullable */
  diaryEntries?:
    | V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsDiaryEntryModel[]
    | null;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsDiaryDayModel {
  dayOfWeek?: SpotMeDomainEnumsDayOfTheWeek;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  diaryDayEntryIds?: number[] | null;
  /** @nullable */
  headlineTitle?: string | null;
}

export interface V2AcademiesQueriesWeeklyDiaryPlansCurrentResponse {
  /** @nullable */
  days?: V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsDiaryDayModel[] | null;
  meta?: V2AcademiesQueriesWeeklyDiaryPlansCurrentModelsMetadataModel;
  weeklyDiaryPlanId?: number;
}

export interface V2AcademiesQueriesTrialGroupsListModelsTrialModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  id?: number;
  isUnlocked?: boolean;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  shortDescription?: string | null;
}

export interface V2AcademiesQueriesTrialGroupsListResponse {
  /** @nullable */
  items?: V2AcademiesQueriesTrialGroupsListModelsTrialModel[] | null;
  meta?: CommonResponsesPagedListResponseMetadata;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryModelsWorkoutInfoItemModel {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryModelsEquipmentModel {
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryResponse {
  /** @nullable */
  contentHtml?: string | null;
  /** @nullable */
  equipments?:
    | V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryModelsEquipmentModel[]
    | null;
  id?: number;
  /** @nullable */
  infoItems?:
    | V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryModelsWorkoutInfoItemModel[]
    | null;
  previewImage?: CommonResponsesMediaImageResponse;
  previewVideo?: CommonResponsesMediaVideoResponse;
  primaryVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  shortLineDescription?: string | null;
  /** @nullable */
  summaryText?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentResponse {
  /** @nullable */
  dayPlans?:
    | V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayPlanModel[]
    | null;
  meta?: V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsMetadataModel;
  trainingScheduleWeekId?: number;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentQuery {
  academyId?: number;
  includePlayerStatuses?: boolean;
  /** @minLength 1 */
  timeZoneId: string;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsWorkoutModel {
  /** @nullable */
  contentHtml?: string | null;
  id?: number;
  previewImage?: CommonResponsesMediaImageResponse;
  previewVideo?: CommonResponsesMediaVideoResponse;
  primaryVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  shortLineDescription?: string | null;
  /** @nullable */
  summaryText?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayPlanWorkoutModel {
  id?: number;
  /** @nullable */
  isCompleted?: boolean | null;
  workoutId?: number;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayGoalModel {
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayPlanModel {
  dayOfTheWeek?: SpotMeDomainEnumsDayOfTheWeek;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  goals?:
    | V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayGoalModel[]
    | null;
  /** @nullable */
  headlineTitle?: string | null;
  /** @nullable */
  workouts?:
    | V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsTrainingScheduleDayPlanWorkoutModel[]
    | null;
}

export interface V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsMetadataModel {
  /** @nullable */
  workouts?:
    | V2AcademiesQueriesTrainingScheduleWeeksCurrentModelsWorkoutModel[]
    | null;
}

export interface V2AcademiesQueriesSummaryModelsTour360ExploreItemModel {
  coverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  tour360Url?: string | null;
}

export interface V2AcademiesQueriesSummaryModelsPlayerPageExploreItemModel {
  coverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesSummaryModelsPlaceholderExploreItemModel {
  coverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesSummaryModelsLibraryExploreItemModel {
  coverImage?: CommonResponsesMediaImageResponse;
  exploreLibraryId?: number;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesSummaryModelsAcademyExploreItemModel {
  exploreLibrary?: V2AcademiesQueriesSummaryModelsLibraryExploreItemModel;
  placeholderExploreItem?: V2AcademiesQueriesSummaryModelsPlaceholderExploreItemModel;
  playerPage?: V2AcademiesQueriesSummaryModelsPlayerPageExploreItemModel;
  tour360ExploreItem?: V2AcademiesQueriesSummaryModelsTour360ExploreItemModel;
  type?: AcademiesEnumsAcademyExploreItemType;
}

export interface V2AcademiesQueriesSummaryResponse {
  academyType?: AcademiesEnumsAcademyType;
  /** @nullable */
  caption?: string | null;
  coachingSectionImage?: CommonResponsesMediaImageResponse;
  confirmationCoverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  confirmationDescription?: string | null;
  /** @nullable */
  confirmationTitle?: string | null;
  diarySectionImage?: CommonResponsesMediaImageResponse;
  engagementSectionImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  engagementSectionSubtitle?: string | null;
  /** @nullable */
  engagementSectionTitle?: string | null;
  /** @nullable */
  exploreItems?:
    | V2AcademiesQueriesSummaryModelsAcademyExploreItemModel[]
    | null;
  hasSubscribed?: boolean;
  /** @nullable */
  homePageDescription?: string | null;
  homePageHeroImage?: CommonResponsesMediaImageResponse;
  id?: number;
  isUnlocked?: boolean;
  lockScreenCoverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  lockScreenDescription?: string | null;
  /** @nullable */
  lockScreenTitle?: string | null;
  /** @nullable */
  name?: string | null;
  subscriptionType?: AcademiesEnumsSubscriptionType;
  tourVideo?: CommonResponsesMediaVideoResponse;
  trainingScheduleSectionImage?: CommonResponsesMediaImageResponse;
}

export interface V2AcademiesQueriesProClubsPlayersListResponse {
  /** @nullable */
  items?: V2AcademiesQueriesProClubsPlayersListModelsPlayerModel[] | null;
  meta?: V2AcademiesQueriesProClubsPlayersListModelsMetadataModel;
}

export interface V2AcademiesQueriesProClubsPlayersListQuery {
  academyId?: number;
  includeAcademyTeams?: boolean;
  includePlayerFollowingStatus?: boolean;
  includePrimaryPlayerPosition?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /** @nullable */
  playerProClubSignedTypes?: string | null;
}

export interface V2AcademiesQueriesProClubsPlayersListModelsPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesProClubsPlayersListModelsPlayerModel {
  /** @nullable */
  academyTeamId?: number | null;
  id?: number;
  /** @nullable */
  isPlayerFollowing?: boolean | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  primaryPositionId?: number | null;
  profileImage?: CommonResponsesMediaImageResponse;
}

export interface V2AcademiesQueriesProClubsPlayersListModelsAcademyTeamModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesProClubsPlayersListModelsMetadataModel {
  /** @nullable */
  academyTeams?:
    | V2AcademiesQueriesProClubsPlayersListModelsAcademyTeamModel[]
    | null;
  limit?: number;
  /** @nullable */
  positions?: V2AcademiesQueriesProClubsPlayersListModelsPositionModel[] | null;
}

export interface V2AcademiesQueriesOnboardingPanelsListModelsAcademyOnboardingPanelModel {
  coverImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesOnboardingPanelsListResponse {
  /** @nullable */
  items?:
    | V2AcademiesQueriesOnboardingPanelsListModelsAcademyOnboardingPanelModel[]
    | null;
  meta?: CommonResponsesLimitedListResponseMetadata;
}

export interface V2AcademiesQueriesCoachingContentsSummaryModelsTpsCategoryModel {
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsSummaryModelsEquipmentModel {
  primaryImage?: CommonResponsesMediaMediaItemResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  subTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsSummaryModelsCoachingContentInfoItemModel {
  /** @nullable */
  name?: string | null;
  /** @nullable */
  value?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsSummaryModelsCoachingContentDifficultyLevelModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsSummaryResponse {
  bannerImage?: CommonResponsesMediaImageResponse;
  difficultyLevel?: V2AcademiesQueriesCoachingContentsSummaryModelsCoachingContentDifficultyLevelModel;
  /** @nullable */
  equipments?:
    | V2AcademiesQueriesCoachingContentsSummaryModelsEquipmentModel[]
    | null;
  /** @nullable */
  estimatedTime?: string | null;
  /** @nullable */
  extraInfoHtml?: string | null;
  id?: number;
  /** @nullable */
  infoItems?:
    | V2AcademiesQueriesCoachingContentsSummaryModelsCoachingContentInfoItemModel[]
    | null;
  instructionVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  tpsCategories?:
    | V2AcademiesQueriesCoachingContentsSummaryModelsTpsCategoryModel[]
    | null;
}

export interface V2AcademiesQueriesCoachingContentsListResponse {
  /** @nullable */
  items?:
    | V2AcademiesQueriesCoachingContentsListModelsCoachingContentModel[]
    | null;
  meta?: V2AcademiesQueriesCoachingContentsListModelsMetadataModel;
}

export interface V2AcademiesQueriesCoachingContentsListQuery {
  academyId?: number;
  bookmarkedContentsOnly?: boolean;
  /** @nullable */
  contentCreatorIds?: string | null;
  /** @nullable */
  difficultyLevelId?: number | null;
  includeContentCreators?: boolean;
  includeIsBookmarked?: boolean;
  includeTpsCategories?: boolean;
  /**
   * @minimum 1
   * @maximum 10000
   */
  limit?: number;
  /**
   * @minimum 0
   * @maximum 100
   * @nullable
   */
  maximumAge?: number | null;
  /**
   * @minimum 0
   * @maximum 100
   * @nullable
   */
  minimumAge?: number | null;
  /**
   * @minimum 0
   * @maximum 1000000
   */
  offset?: number;
  /** @nullable */
  searchTerm?: string | null;
  /** @nullable */
  tpsCategoryIds?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsListModelsTpsCategoryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsListModelsContentCreatorModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsListModelsCoachingContentModel {
  bannerImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  contentCreatorIds?: number[] | null;
  difficultyLevelId?: number;
  /** @nullable */
  estimatedTime?: string | null;
  id?: number;
  instructionVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  isBookmarked?: boolean | null;
  recommendedMaxAge?: number;
  recommendedMinAge?: number;
  /** @nullable */
  subtitle?: string | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  tpsCategoryIds?: number[] | null;
}

export interface V2AcademiesQueriesCoachingContentsListModelsCoachingContentDifficultyLevelModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsListModelsMetadataModel {
  /** @nullable */
  contentCreators?:
    | V2AcademiesQueriesCoachingContentsListModelsContentCreatorModel[]
    | null;
  /** @nullable */
  difficultyLevels?:
    | V2AcademiesQueriesCoachingContentsListModelsCoachingContentDifficultyLevelModel[]
    | null;
  limit?: number;
  offset?: number;
  /** @nullable */
  tpsCategories?:
    | V2AcademiesQueriesCoachingContentsListModelsTpsCategoryModel[]
    | null;
}

export interface V2AcademiesQueriesCoachingContentsFiltersModelsTpsCategoryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsFiltersModelsContentCreatorModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsFiltersModelsCoachingContentDifficultyLevelModel {
  /** @nullable */
  colorCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface V2AcademiesQueriesCoachingContentsFiltersModelsAgeRangeModel {
  maximumAge?: number;
  minimumAge?: number;
}

export interface V2AcademiesQueriesCoachingContentsFiltersResponse {
  ageRange?: V2AcademiesQueriesCoachingContentsFiltersModelsAgeRangeModel;
  /** @nullable */
  contentCreators?:
    | V2AcademiesQueriesCoachingContentsFiltersModelsContentCreatorModel[]
    | null;
  /** @nullable */
  difficultyLevels?:
    | V2AcademiesQueriesCoachingContentsFiltersModelsCoachingContentDifficultyLevelModel[]
    | null;
  /** @nullable */
  tpsCategories?:
    | V2AcademiesQueriesCoachingContentsFiltersModelsTpsCategoryModel[]
    | null;
}

export interface V2AcademiesCommandsAcademySubscriptionsCreateCommand {
  playerId: number;
}

export interface TrialsLegacyTrialFeedbackServicesModelsCoachingContentDto {
  bannerImage?: CommonResponsesMediaImageResponse;
  /** @nullable */
  estimatedTime?: string | null;
  id?: number;
  instructionVideo?: CommonResponsesMediaVideoResponse;
  /** @nullable */
  title?: string | null;
}

export interface TrialsLegacyTrialFeedbackServicesModelsTrialFeedbackDto {
  coachingContent?: TrialsLegacyTrialFeedbackServicesModelsCoachingContentDto;
  /** @nullable */
  message?: string | null;
  scoutImage?: CommonResponsesMediaResizedImageResponse;
  /** @nullable */
  scoutName?: string | null;
  /** @nullable */
  scoutTitle?: string | null;
  /** @nullable */
  title?: string | null;
}

export type TrialsEnumsTrialCompletionType =
  typeof TrialsEnumsTrialCompletionType[keyof typeof TrialsEnumsTrialCompletionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrialsEnumsTrialCompletionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type TrialsEnumsPlayerQuestionType =
  typeof TrialsEnumsPlayerQuestionType[keyof typeof TrialsEnumsPlayerQuestionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrialsEnumsPlayerQuestionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type TrainingSessionsEnumsTrainingAvailabilityType =
  typeof TrainingSessionsEnumsTrainingAvailabilityType[keyof typeof TrainingSessionsEnumsTrainingAvailabilityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrainingSessionsEnumsTrainingAvailabilityType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type SummaryReportDraftsEnumsSummaryReportDraftStatus =
  typeof SummaryReportDraftsEnumsSummaryReportDraftStatus[keyof typeof SummaryReportDraftsEnumsSummaryReportDraftStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SummaryReportDraftsEnumsSummaryReportDraftStatus = {
  NUMBER_0: 0,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType =
  typeof SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType[keyof typeof SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainQueriesTrainingSessionsRequestsAcademyTeamTrainingSessionRequestsEnumsSortType =
  {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
  } as const;

export type SpotMeDomainQueriesPlayersRequestPlayerAdvancedSearchEnumsPlayerSortColumnType =
  typeof SpotMeDomainQueriesPlayersRequestPlayerAdvancedSearchEnumsPlayerSortColumnType[keyof typeof SpotMeDomainQueriesPlayersRequestPlayerAdvancedSearchEnumsPlayerSortColumnType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainQueriesPlayersRequestPlayerAdvancedSearchEnumsPlayerSortColumnType =
  {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
  } as const;

export type SpotMeDomainQueriesCountiesRequestsEnumsCountySortType =
  typeof SpotMeDomainQueriesCountiesRequestsEnumsCountySortType[keyof typeof SpotMeDomainQueriesCountiesRequestsEnumsCountySortType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainQueriesCountiesRequestsEnumsCountySortType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type SpotMeDomainEnumsTrialType =
  typeof SpotMeDomainEnumsTrialType[keyof typeof SpotMeDomainEnumsTrialType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsTrialType = {
  NUMBER_0: 0,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
} as const;

export type SpotMeDomainEnumsSortDirectionType =
  typeof SpotMeDomainEnumsSortDirectionType[keyof typeof SpotMeDomainEnumsSortDirectionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsSortDirectionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type SpotMeDomainEnumsReportDraftsReportParticipantType =
  typeof SpotMeDomainEnumsReportDraftsReportParticipantType[keyof typeof SpotMeDomainEnumsReportDraftsReportParticipantType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsReportDraftsReportParticipantType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type SpotMeDomainEnumsReportDraftsDraftNoteType =
  typeof SpotMeDomainEnumsReportDraftsDraftNoteType[keyof typeof SpotMeDomainEnumsReportDraftsDraftNoteType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsReportDraftsDraftNoteType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type SpotMeDomainEnumsFileMediaType =
  typeof SpotMeDomainEnumsFileMediaType[keyof typeof SpotMeDomainEnumsFileMediaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsFileMediaType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type SpotMeDomainEnumsDrillEntryStatus =
  typeof SpotMeDomainEnumsDrillEntryStatus[keyof typeof SpotMeDomainEnumsDrillEntryStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsDrillEntryStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type SpotMeDomainEnumsDrillAnalyzedNotification =
  typeof SpotMeDomainEnumsDrillAnalyzedNotification[keyof typeof SpotMeDomainEnumsDrillAnalyzedNotification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsDrillAnalyzedNotification = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_3: 3,
  NUMBER_4000: 4000,
  NUMBER_4001: 4001,
  NUMBER_4002: 4002,
  NUMBER_4003: 4003,
  NUMBER_4004: 4004,
  NUMBER_4005: 4005,
  NUMBER_4006: 4006,
  NUMBER_4007: 4007,
  NUMBER_4008: 4008,
  NUMBER_4009: 4009,
  NUMBER_4010: 4010,
  NUMBER_4011: 4011,
  NUMBER_4012: 4012,
  NUMBER_4013: 4013,
  NUMBER_4014: 4014,
  NUMBER_4015: 4015,
  NUMBER_4016: 4016,
  NUMBER_4017: 4017,
  NUMBER_5000: 5000,
} as const;

export type SpotMeDomainEnumsDayOfTheWeek =
  typeof SpotMeDomainEnumsDayOfTheWeek[keyof typeof SpotMeDomainEnumsDayOfTheWeek];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotMeDomainEnumsDayOfTheWeek = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
} as const;

export type SignUpTiersEnumsSignUpTierType =
  typeof SignUpTiersEnumsSignUpTierType[keyof typeof SignUpTiersEnumsSignUpTierType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignUpTiersEnumsSignUpTierType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type ScoutsEnumsScoutDesignationType =
  typeof ScoutsEnumsScoutDesignationType[keyof typeof ScoutsEnumsScoutDesignationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoutsEnumsScoutDesignationType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
} as const;

export type ScoutAmateurFixturesEnumsAttendanceStatus =
  typeof ScoutAmateurFixturesEnumsAttendanceStatus[keyof typeof ScoutAmateurFixturesEnumsAttendanceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoutAmateurFixturesEnumsAttendanceStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type RuleEnginesEnumsRuleOperatorType =
  typeof RuleEnginesEnumsRuleOperatorType[keyof typeof RuleEnginesEnumsRuleOperatorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuleEnginesEnumsRuleOperatorType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
} as const;

export type RolesEnumsRoleType =
  typeof RolesEnumsRoleType[keyof typeof RolesEnumsRoleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RolesEnumsRoleType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
} as const;

export type QuestionsEnumsQuestionDataType =
  typeof QuestionsEnumsQuestionDataType[keyof typeof QuestionsEnumsQuestionDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionsEnumsQuestionDataType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type QuestionsEnumsInputFieldDisplayType =
  typeof QuestionsEnumsInputFieldDisplayType[keyof typeof QuestionsEnumsInputFieldDisplayType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionsEnumsInputFieldDisplayType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type ProClubsEnumsProClubHyperlinkType =
  typeof ProClubsEnumsProClubHyperlinkType[keyof typeof ProClubsEnumsProClubHyperlinkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProClubsEnumsProClubHyperlinkType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type PostsEnumsShareType =
  typeof PostsEnumsShareType[keyof typeof PostsEnumsShareType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostsEnumsShareType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type PlayersEnumsSecondaryRelationshipType =
  typeof PlayersEnumsSecondaryRelationshipType[keyof typeof PlayersEnumsSecondaryRelationshipType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayersEnumsSecondaryRelationshipType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type PlayersEnumsPreferredFootType =
  typeof PlayersEnumsPreferredFootType[keyof typeof PlayersEnumsPreferredFootType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayersEnumsPreferredFootType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type PlayersEnumsPlayerProClubSignedType =
  typeof PlayersEnumsPlayerProClubSignedType[keyof typeof PlayersEnumsPlayerProClubSignedType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayersEnumsPlayerProClubSignedType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type PlayersEnumsGender =
  typeof PlayersEnumsGender[keyof typeof PlayersEnumsGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayersEnumsGender = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type PlayerReportDraftsEnumsPlayerReportDraftStatus =
  typeof PlayerReportDraftsEnumsPlayerReportDraftStatus[keyof typeof PlayerReportDraftsEnumsPlayerReportDraftStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayerReportDraftsEnumsPlayerReportDraftStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type OpportunityNodesEnumsOpportunityNodeDisplayType =
  typeof OpportunityNodesEnumsOpportunityNodeDisplayType[keyof typeof OpportunityNodesEnumsOpportunityNodeDisplayType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpportunityNodesEnumsOpportunityNodeDisplayType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type NotifyJobsEnumsNotifyJobType =
  typeof NotifyJobsEnumsNotifyJobType[keyof typeof NotifyJobsEnumsNotifyJobType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotifyJobsEnumsNotifyJobType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type NotificationsEnumsNotificationType =
  typeof NotificationsEnumsNotificationType[keyof typeof NotificationsEnumsNotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsEnumsNotificationType = {
  NUMBER_0: 0,
  NUMBER_4: 4,
  NUMBER_13: 13,
  NUMBER_18: 18,
  NUMBER_19: 19,
  NUMBER_20: 20,
  NUMBER_22: 22,
  NUMBER_23: 23,
  NUMBER_24: 24,
  NUMBER_25: 25,
  NUMBER_1001: 1001,
  NUMBER_1002: 1002,
  NUMBER_1003: 1003,
  NUMBER_1004: 1004,
  NUMBER_1005: 1005,
  NUMBER_1006: 1006,
  NUMBER_2001: 2001,
} as const;

export type NotificationsEnumsNotificationMetadataType =
  typeof NotificationsEnumsNotificationMetadataType[keyof typeof NotificationsEnumsNotificationMetadataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsEnumsNotificationMetadataType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
} as const;

export type InfoScreensEnumsInfoScreenType =
  typeof InfoScreensEnumsInfoScreenType[keyof typeof InfoScreensEnumsInfoScreenType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InfoScreensEnumsInfoScreenType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export interface HandlerServicesV2NotificationHandlersModelsNotificationModel {
  created?: string;
  id?: number;
  isClicked?: boolean;
  isSeen?: boolean;
  notificationInfo?: HandlerServicesV2NotificationHandlersModelsNotificationInfoModel;
}

export interface HandlerServicesV2NotificationHandlersModelsNotificationInfoMetaModel {
  id?: number;
  notificationMetaType?: NotificationsEnumsNotificationMetadataType;
  thumbnail?: CommonResponsesMediaMediaItemResponse;
}

export interface HandlerServicesV2NotificationHandlersModelsCreatorModel {
  avatar?: CommonResponsesMediaBaseMediaResponse;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
}

export interface HandlerServicesV2NotificationHandlersModelsNotificationInfoModel {
  creator?: HandlerServicesV2NotificationHandlersModelsCreatorModel;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  metaData?:
    | HandlerServicesV2NotificationHandlersModelsNotificationInfoMetaModel[]
    | null;
  type?: NotificationsEnumsNotificationType;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPositionRule {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  positions?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataPositionModel[]
    | null;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPlayerEmailRule {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: string[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPlayerCountyRule {
  /** @nullable */
  counties?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataCountyModel[]
    | null;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesHomeCountryRule {
  /** @nullable */
  countries?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataCountryModel[]
    | null;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesGenderRule {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: PlayersEnumsGender[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataPositionModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataCountyModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataCountryModel {
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataAffiliationModel {
  /** @nullable */
  entryCode?: string | null;
  id?: number;
  /** @nullable */
  name?: string | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesCanPlayForCountryRule {
  /** @nullable */
  countries?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataCountryModel[]
    | null;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesAgeRule {
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesAffiliationRule {
  /** @nullable */
  affiliations?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesDataAffiliationModel[]
    | null;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  inverseOperator?: boolean;
  isValid?: boolean;
  operatorType?: RuleEnginesEnumsRuleOperatorType;
  /** @nullable */
  values?: number[] | null;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRuleDto {
  affiliationRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesAffiliationRule;
  ageRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesAgeRule;
  canPlayForCountryRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesCanPlayForCountryRule;
  genderRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesGenderRule;
  homeCountryRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesHomeCountryRule;
  playerCountyRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPlayerCountyRule;
  playerEmailRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPlayerEmailRule;
  positionRule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRulesPositionRule;
}

export interface HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto {
  /** @nullable */
  must?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto[]
    | null;
  rule?: HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsRuleDto;
  /** @nullable */
  should?:
    | HandlerServicesRuleHandlersPlayerBasedRuleHandlerModelsNodeDto[]
    | null;
}

export interface HandlerServicesQuestionValidationsModelsAnswerModel {
  /** @nullable */
  optionId?: number | null;
  /**
   * @minLength 1
   * @maxLength 3000
   */
  value: string;
}

export interface HandlerServicesQuestionValidationsSummaryReportsModelsSummaryReportDraftResponseModel {
  answers: HandlerServicesQuestionValidationsModelsAnswerModel[];
  dataType: QuestionsEnumsQuestionDataType;
  questionId: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export interface HandlerServicesQuestionValidationsParticipantBasedReportsPlayerReportsModelsPlayerReportDraftResponseModel {
  answers: HandlerServicesQuestionValidationsModelsAnswerModel[];
  dataType: QuestionsEnumsQuestionDataType;
  /** @nullable */
  participantClientId?: string | null;
  questionId: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export interface HandlerServicesQuestionValidationsParticipantBasedReportsGameReportsModelsGameReportDraftResponseModel {
  answers: HandlerServicesQuestionValidationsModelsAnswerModel[];
  dataType: QuestionsEnumsQuestionDataType;
  /** @nullable */
  participantClientId?: string | null;
  questionId: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  questionText: string;
}

export type GuardiansEnumsGuardianType =
  typeof GuardiansEnumsGuardianType[keyof typeof GuardiansEnumsGuardianType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GuardiansEnumsGuardianType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type GameReportDraftsEnumsGameReportDraftStatus =
  typeof GameReportDraftsEnumsGameReportDraftStatus[keyof typeof GameReportDraftsEnumsGameReportDraftStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameReportDraftsEnumsGameReportDraftStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type FilesEnumsFileEntityType =
  typeof FilesEnumsFileEntityType[keyof typeof FilesEnumsFileEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilesEnumsFileEntityType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
  NUMBER_16: 16,
  NUMBER_17: 17,
  NUMBER_18: 18,
  NUMBER_19: 19,
  NUMBER_20: 20,
  NUMBER_21: 21,
  NUMBER_22: 22,
  NUMBER_23: 23,
  NUMBER_24: 24,
  NUMBER_25: 25,
  NUMBER_26: 26,
  NUMBER_27: 27,
  NUMBER_28: 28,
  NUMBER_29: 29,
  NUMBER_30: 30,
} as const;

export type EntryAnalysisLogsEnumsOriginType =
  typeof EntryAnalysisLogsEnumsOriginType[keyof typeof EntryAnalysisLogsEnumsOriginType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntryAnalysisLogsEnumsOriginType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type DrillsEnumsDrillMeasurementFactType =
  typeof DrillsEnumsDrillMeasurementFactType[keyof typeof DrillsEnumsDrillMeasurementFactType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrillsEnumsDrillMeasurementFactType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type DrillEntriesEnumsDrillEntryFeedbackType =
  typeof DrillEntriesEnumsDrillEntryFeedbackType[keyof typeof DrillEntriesEnumsDrillEntryFeedbackType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrillEntriesEnumsDrillEntryFeedbackType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type DiscoverySectionsEnumsDiscoverySectionType =
  typeof DiscoverySectionsEnumsDiscoverySectionType[keyof typeof DiscoverySectionsEnumsDiscoverySectionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiscoverySectionsEnumsDiscoverySectionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type DiscoveryRedirectsEnumsDiscoveryRedirectType =
  typeof DiscoveryRedirectsEnumsDiscoveryRedirectType[keyof typeof DiscoveryRedirectsEnumsDiscoveryRedirectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiscoveryRedirectsEnumsDiscoveryRedirectType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export interface CommonResponsesSuccessResponse {
  [key: string]: unknown;
}

export interface CommonResponsesPagedListResponseMetadata {
  limit?: number;
  offset?: number;
}

export interface CommonResponsesNoContentResponse {
  [key: string]: unknown;
}

export type CommonResponsesMediaEnumsMediaResponseType =
  typeof CommonResponsesMediaEnumsMediaResponseType[keyof typeof CommonResponsesMediaEnumsMediaResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommonResponsesMediaEnumsMediaResponseType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export interface CommonResponsesMediaVideoResponse {
  mediaType?: CommonResponsesMediaEnumsMediaResponseType;
  /** @nullable */
  path?: string | null;
  /** @nullable */
  resizedUrl?: string | null;
  /** @nullable */
  thumbnail?: string | null;
  /** @nullable */
  url?: string | null;
}

export interface CommonResponsesMediaResizedImageResponse {
  dimensions?: CommonResponsesMediaDimensionsModel;
  mediaType?: CommonResponsesMediaEnumsMediaResponseType;
  /** @nullable */
  path?: string | null;
  /** @nullable */
  resizedUrl?: string | null;
  /** @nullable */
  thumbnail?: string | null;
  /** @nullable */
  url?: string | null;
}

export interface CommonResponsesMediaMediaItemResponse {
  mediaType?: CommonResponsesMediaEnumsMediaResponseType;
  /** @nullable */
  path?: string | null;
  /** @nullable */
  url?: string | null;
}

export interface CommonResponsesMediaDimensionsModel {
  height?: number;
  width?: number;
}

export interface CommonResponsesMediaImageResponse {
  dimensions?: CommonResponsesMediaDimensionsModel;
  mediaType?: CommonResponsesMediaEnumsMediaResponseType;
  /** @nullable */
  path?: string | null;
  /** @nullable */
  thumbnail?: string | null;
  /** @nullable */
  url?: string | null;
}

export interface CommonResponsesMediaBaseMediaResponse {
  mediaType?: CommonResponsesMediaEnumsMediaResponseType;
}

export interface CommonResponsesLimitedListResponseMetadata {
  limit?: number;
}

/**
 * @nullable
 */
export type CommonResponsesForbiddenAccessResponseErrors = {
  [key: string]: string[] | null;
} | null;

export interface CommonResponsesForbiddenAccessResponse {
  /** @nullable */
  codes?: string[] | null;
  /** @nullable */
  readonly errors?: CommonResponsesForbiddenAccessResponseErrors;
  /** @nullable */
  message?: string | null;
  /** @nullable */
  messages?: string[] | null;
}

export type CommonResponsesDiscoveryTrialGroupResourceModelAllOf = {
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
  trialGroupId?: number;
};

export type CommonResponsesDiscoveryTrialGroupResourceModel =
  CommonResponsesDiscoveryBaseRedirectResourceModel &
    CommonResponsesDiscoveryTrialGroupResourceModelAllOf;

export type CommonResponsesDiscoveryTrialDetailResourceModelAllOf = {
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
  trialId?: number;
};

export type CommonResponsesDiscoveryTrialDetailResourceModel =
  CommonResponsesDiscoveryBaseRedirectResourceModel &
    CommonResponsesDiscoveryTrialDetailResourceModelAllOf;

export type CommonResponsesDiscoveryTrialCategoryResourceModelAllOf = {
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
  trialCategoryId?: number;
};

export type CommonResponsesDiscoveryTrialCategoryResourceModel =
  CommonResponsesDiscoveryBaseRedirectResourceModel &
    CommonResponsesDiscoveryTrialCategoryResourceModelAllOf;

export type CommonResponsesDiscoveryProClubTrialGroupListResourceModelAllOf = {
  proClubId?: number;
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
};

export type CommonResponsesDiscoveryProClubTrialGroupListResourceModel =
  CommonResponsesDiscoveryBaseRedirectResourceModel &
    CommonResponsesDiscoveryProClubTrialGroupListResourceModelAllOf;

export type CommonResponsesDiscoveryProClubTrialCategoryResourceModelAllOf = {
  proClubId?: number;
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
  trialCategoryId?: number;
};

export interface CommonResponsesDiscoveryBaseRedirectResourceModel {
  resourceType?: DiscoveryRedirectsEnumsDiscoveryRedirectType;
}

export type CommonResponsesDiscoveryProClubTrialCategoryResourceModel =
  CommonResponsesDiscoveryBaseRedirectResourceModel &
    CommonResponsesDiscoveryProClubTrialCategoryResourceModelAllOf;

export interface CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e {
  id?: number;
}

export interface CommonResponsesCreatedResponse {
  [key: string]: unknown;
}

export interface CommonResponsesBaseResponse {
  [key: string]: unknown;
}

export interface CommonResponsesAcceptedResponse {
  [key: string]: unknown;
}

export type CommonReportDraftNotesDraftNoteTranscriptionClientStatus =
  typeof CommonReportDraftNotesDraftNoteTranscriptionClientStatus[keyof typeof CommonReportDraftNotesDraftNoteTranscriptionClientStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommonReportDraftNotesDraftNoteTranscriptionClientStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type CommonEnumsPostsCreatorType =
  typeof CommonEnumsPostsCreatorType[keyof typeof CommonEnumsPostsCreatorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommonEnumsPostsCreatorType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type AcademiesEnumsSubscriptionType =
  typeof AcademiesEnumsSubscriptionType[keyof typeof AcademiesEnumsSubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademiesEnumsSubscriptionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type AcademiesEnumsAcademyType =
  typeof AcademiesEnumsAcademyType[keyof typeof AcademiesEnumsAcademyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademiesEnumsAcademyType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type AcademiesEnumsAcademyExploreItemType =
  typeof AcademiesEnumsAcademyExploreItemType[keyof typeof AcademiesEnumsAcademyExploreItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademiesEnumsAcademyExploreItemType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdSummary = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesSummaryResponse>(
    {
      url: `/api/v2/academies/${academyId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdSummaryQueryKey = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams
) => {
  return [
    `/api/v2/academies/${academyId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademiesAcademyIdSummaryQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>
  > = ({ signal }) =>
    getApiV2AcademiesAcademyIdSummary(
      academyId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademiesAcademyIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>
>;
export type GetApiV2AcademiesAcademyIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: undefined | GetApiV2AcademiesAcademyIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AcademiesAcademyIdSummaryQueryOptions(
    academyId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2AcademiesAcademyIdSubscribe = (
  academyId: number,
  v2AcademiesCommandsAcademySubscriptionsCreateCommand: V2AcademiesCommandsAcademySubscriptionsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/academies/${academyId}/subscribe`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2AcademiesCommandsAcademySubscriptionsCreateCommand,
    },
    options
  );
};

export const getPostApiV2AcademiesAcademyIdSubscribeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>,
    TError,
    {
      academyId: number;
      data: V2AcademiesCommandsAcademySubscriptionsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>,
  TError,
  {
    academyId: number;
    data: V2AcademiesCommandsAcademySubscriptionsCreateCommand;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>,
    {
      academyId: number;
      data: V2AcademiesCommandsAcademySubscriptionsCreateCommand;
    }
  > = (props) => {
    const { academyId, data } = props ?? {};

    return postApiV2AcademiesAcademyIdSubscribe(
      academyId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2AcademiesAcademyIdSubscribeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>
>;
export type PostApiV2AcademiesAcademyIdSubscribeMutationBody =
  V2AcademiesCommandsAcademySubscriptionsCreateCommand;
export type PostApiV2AcademiesAcademyIdSubscribeMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2AcademiesAcademyIdSubscribe = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>,
    TError,
    {
      academyId: number;
      data: V2AcademiesCommandsAcademySubscriptionsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2AcademiesAcademyIdSubscribe>>,
  TError,
  {
    academyId: number;
    data: V2AcademiesCommandsAcademySubscriptionsCreateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2AcademiesAcademyIdSubscribeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdOnboardingpanels = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesOnboardingPanelsListResponse>(
    {
      url: `/api/v2/academies/${academyId}/onboardingpanels`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdOnboardingpanelsQueryKey = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams
) => {
  return [
    `/api/v2/academies/${academyId}/onboardingpanels`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdOnboardingpanelsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademiesAcademyIdOnboardingpanelsQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>
  > = ({ signal }) =>
    getApiV2AcademiesAcademyIdOnboardingpanels(
      academyId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademiesAcademyIdOnboardingpanelsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>
>;
export type GetApiV2AcademiesAcademyIdOnboardingpanelsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdOnboardingpanels<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: undefined | GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdOnboardingpanels<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdOnboardingpanels<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdOnboardingpanels<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdOnboardingpanelsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdOnboardingpanels>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdOnboardingpanelsQueryOptions(
      academyId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdCoachingcontentsFilters = (
  academyId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesCoachingContentsFiltersResponse>(
    {
      url: `/api/v2/academies/${academyId}/coachingcontents/filters`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryKey = (
  academyId: number
) => {
  return [`/api/v2/academies/${academyId}/coachingcontents/filters`] as const;
};

export const getGetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryKey(academyId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdCoachingcontentsFilters(
        academyId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
    >
  >;
export type GetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdCoachingcontentsFilters<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontentsFilters<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontentsFilters<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdCoachingcontentsFilters<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontentsFilters>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdCoachingcontentsFiltersQueryOptions(
      academyId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdCoachingcontents = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesCoachingContentsListResponse>(
    {
      url: `/api/v2/academies/${academyId}/coachingcontents`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdCoachingcontentsQueryKey = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams
) => {
  return [
    `/api/v2/academies/${academyId}/coachingcontents`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdCoachingcontentsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademiesAcademyIdCoachingcontentsQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>
  > = ({ signal }) =>
    getApiV2AcademiesAcademyIdCoachingcontents(
      academyId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademiesAcademyIdCoachingcontentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>
>;
export type GetApiV2AcademiesAcademyIdCoachingcontentsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdCoachingcontents<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: undefined | GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontents<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontents<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdCoachingcontents<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdCoachingcontents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdCoachingcontentsQueryOptions(
      academyId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdTrialgroups = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesTrialGroupsListResponse>(
    {
      url: `/api/v2/academies/${academyId}/trialgroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdTrialgroupsQueryKey = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams
) => {
  return [
    `/api/v2/academies/${academyId}/trialgroups`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdTrialgroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademiesAcademyIdTrialgroupsQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>
  > = ({ signal }) =>
    getApiV2AcademiesAcademyIdTrialgroups(
      academyId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademiesAcademyIdTrialgroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>
>;
export type GetApiV2AcademiesAcademyIdTrialgroupsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: undefined | GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AcademiesAcademyIdTrialgroupsQueryOptions(
    academyId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdProclubPlayers = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesProClubsPlayersListResponse>(
    {
      url: `/api/v2/academies/${academyId}/proclub/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdProclubPlayersQueryKey = (
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams
) => {
  return [
    `/api/v2/academies/${academyId}/proclub/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdProclubPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademiesAcademyIdProclubPlayersQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>
  > = ({ signal }) =>
    getApiV2AcademiesAcademyIdProclubPlayers(
      academyId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademiesAcademyIdProclubPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>
>;
export type GetApiV2AcademiesAcademyIdProclubPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdProclubPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: undefined | GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdProclubPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdProclubPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdProclubPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params?: GetApiV2AcademiesAcademyIdProclubPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademiesAcademyIdProclubPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AcademiesAcademyIdProclubPlayersQueryOptions(
    academyId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary =
  (
    academyId: number,
    coachingContentId: number,
    params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2AcademiesQueriesCoachingContentsSummaryResponse>(
      {
        url: `/api/v2/academies/${academyId}/coachingcontents/${coachingContentId}/summary`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryKey =
  (
    academyId: number,
    coachingContentId: number,
    params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams
  ) => {
    return [
      `/api/v2/academies/${academyId}/coachingcontents/${coachingContentId}/summary`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
      >
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    coachingContentId: number,
    params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryKey(
        academyId,
        coachingContentId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
        >
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary(
        academyId,
        coachingContentId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(academyId && coachingContentId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
      >
    >
  >;
export type GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  coachingContentId: number,
  params:
    | undefined
    | GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  coachingContentId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  coachingContentId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  coachingContentId: number,
  params?: GetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdCoachingcontentsCoachingContentIdSummaryQueryOptions(
      academyId,
      coachingContentId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary =
  (
    academyId: number,
    trainingScheduleWeekId: number,
    workoutId: number,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2AcademiesQueriesTrainingScheduleWeeksWorkoutsSummaryResponse>(
      {
        url: `/api/v2/academies/${academyId}/trainingscheduleweeks/${trainingScheduleWeekId}/workouts/${workoutId}/summary`,
        method: 'GET',
        signal,
      },
      options
    );
  };

export const getGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryKey =
  (academyId: number, trainingScheduleWeekId: number, workoutId: number) => {
    return [
      `/api/v2/academies/${academyId}/trainingscheduleweeks/${trainingScheduleWeekId}/workouts/${workoutId}/summary`,
    ] as const;
  };

export const getGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
      >
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    trainingScheduleWeekId: number,
    workoutId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryKey(
        academyId,
        trainingScheduleWeekId,
        workoutId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
        >
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary(
        academyId,
        trainingScheduleWeekId,
        workoutId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(academyId && trainingScheduleWeekId && workoutId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
      >
    >
  >;
export type GetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  trainingScheduleWeekId: number,
  workoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  trainingScheduleWeekId: number,
  workoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  trainingScheduleWeekId: number,
  workoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  trainingScheduleWeekId: number,
  workoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdTrainingscheduleweeksTrainingScheduleWeekIdWorkoutsWorkoutIdSummaryQueryOptions(
      academyId,
      trainingScheduleWeekId,
      workoutId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent = (
  academyId: number,
  params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesTrainingScheduleWeeksCurrentResponse>(
    {
      url: `/api/v2/academies/${academyId}/trainingscheduleweeks/current`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryKey =
  (
    academyId: number,
    params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams
  ) => {
    return [
      `/api/v2/academies/${academyId}/trainingscheduleweeks/current`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryKey(
        academyId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
        >
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent(
        academyId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
    >
  >;
export type GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdTrainingscheduleweeksCurrent
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdTrainingscheduleweeksCurrentQueryOptions(
      academyId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent = (
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademiesQueriesWeeklyDiaryPlansCurrentResponse>(
    {
      url: `/api/v2/academies/${academyId}/weeklydiaryplans/current`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryKey = (
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams
) => {
  return [
    `/api/v2/academies/${academyId}/weeklydiaryplans/current`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryKey(
        academyId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent(
        academyId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
    >
  >;
export type GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrent<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  params: GetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademiesAcademyIdWeeklydiaryplansCurrent>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdWeeklydiaryplansCurrentQueryOptions(
      academyId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary =
  (
    academyId: number,
    weeklyDiaryPlanId: number,
    diaryEntryId: number,
    params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2AcademiesQueriesWeeklyDiaryPlansDiaryEntriesSummaryResponse>(
      {
        url: `/api/v2/academies/${academyId}/weeklydiaryplans/${weeklyDiaryPlanId}/diaryentries/${diaryEntryId}/summary`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryKey =
  (
    academyId: number,
    weeklyDiaryPlanId: number,
    diaryEntryId: number,
    params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams
  ) => {
    return [
      `/api/v2/academies/${academyId}/weeklydiaryplans/${weeklyDiaryPlanId}/diaryentries/${diaryEntryId}/summary`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
      >
    >,
    TError = ErrorType<unknown>
  >(
    academyId: number,
    weeklyDiaryPlanId: number,
    diaryEntryId: number,
    params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryKey(
        academyId,
        weeklyDiaryPlanId,
        diaryEntryId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
        >
      >
    > = ({ signal }) =>
      getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary(
        academyId,
        weeklyDiaryPlanId,
        diaryEntryId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(academyId && weeklyDiaryPlanId && diaryEntryId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
      >
    >
  >;
export type GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  weeklyDiaryPlanId: number,
  diaryEntryId: number,
  params:
    | undefined
    | GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  weeklyDiaryPlanId: number,
  diaryEntryId: number,
  params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  weeklyDiaryPlanId: number,
  diaryEntryId: number,
  params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
    >
  >,
  TError = ErrorType<unknown>
>(
  academyId: number,
  weeklyDiaryPlanId: number,
  diaryEntryId: number,
  params?: GetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademiesAcademyIdWeeklydiaryplansWeeklyDiaryPlanIdDiaryentriesDiaryEntryIdSummaryQueryOptions(
      academyId,
      weeklyDiaryPlanId,
      diaryEntryId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2Academysubscriptions = (
  v2AcademySubscriptionsCommandsCreateCommand: V2AcademySubscriptionsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/academysubscriptions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2AcademySubscriptionsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2AcademysubscriptionsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Academysubscriptions>>,
    TError,
    { data: V2AcademySubscriptionsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Academysubscriptions>>,
  TError,
  { data: V2AcademySubscriptionsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Academysubscriptions>>,
    { data: V2AcademySubscriptionsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Academysubscriptions(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2AcademysubscriptionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Academysubscriptions>>
>;
export type PostApiV2AcademysubscriptionsMutationBody =
  V2AcademySubscriptionsCommandsCreateCommand;
export type PostApiV2AcademysubscriptionsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2Academysubscriptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Academysubscriptions>>,
    TError,
    { data: V2AcademySubscriptionsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Academysubscriptions>>,
  TError,
  { data: V2AcademySubscriptionsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2AcademysubscriptionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2AcademysubscriptionsAcademySubscriptionId = (
  academySubscriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/academysubscriptions/${academySubscriptionId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2AcademysubscriptionsAcademySubscriptionIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
      >,
      TError,
      { academySubscriptionId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
    >,
    TError,
    { academySubscriptionId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
      >,
      { academySubscriptionId: number }
    > = (props) => {
      const { academySubscriptionId } = props ?? {};

      return deleteApiV2AcademysubscriptionsAcademySubscriptionId(
        academySubscriptionId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2AcademysubscriptionsAcademySubscriptionIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
    >
  >;

export type DeleteApiV2AcademysubscriptionsAcademySubscriptionIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2AcademysubscriptionsAcademySubscriptionId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
    >,
    TError,
    { academySubscriptionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2AcademysubscriptionsAcademySubscriptionId>
  >,
  TError,
  { academySubscriptionId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2AcademysubscriptionsAcademySubscriptionIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdPlayers = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademyTeamsQueriesPlayersListResponse>(
    {
      url: `/api/v2/academyteams/${academyTeamId}/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademyteamsAcademyTeamIdPlayersQueryKey = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams
) => {
  return [
    `/api/v2/academyteams/${academyTeamId}/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademyteamsAcademyTeamIdPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AcademyteamsAcademyTeamIdPlayersQueryKey(academyTeamId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>
  > = ({ signal }) =>
    getApiV2AcademyteamsAcademyTeamIdPlayers(
      academyTeamId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyTeamId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AcademyteamsAcademyTeamIdPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>
>;
export type GetApiV2AcademyteamsAcademyTeamIdPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params: undefined | GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AcademyteamsAcademyTeamIdPlayersQueryOptions(
    academyTeamId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive =
  (
    academyTeamId: number,
    coachId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2AcademyTeamsQueriesCoachesTrainingSessionsListResponse>(
      {
        url: `/api/v2/academyteams/${academyTeamId}/coaches/${coachId}/trainingsessions/active`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryKey =
  (
    academyTeamId: number,
    coachId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams
  ) => {
    return [
      `/api/v2/academyteams/${academyTeamId}/coaches/${coachId}/trainingsessions/active`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
      >
    >,
    TError = ErrorType<unknown>
  >(
    academyTeamId: number,
    coachId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryKey(
        academyTeamId,
        coachId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
        >
      >
    > = ({ signal }) =>
      getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive(
        academyTeamId,
        coachId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(academyTeamId && coachId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
      >
    >
  >;
export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params:
    | undefined
    | GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActive
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsActiveQueryOptions(
      academyTeamId,
      coachId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions = (
  academyTeamId: number,
  coachId: number,
  params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademyTeamsQueriesCoachesTrainingSessionsListResponse>(
    {
      url: `/api/v2/academyteams/${academyTeamId}/coaches/${coachId}/trainingsessions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryKey =
  (
    academyTeamId: number,
    coachId: number,
    params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams
  ) => {
    return [
      `/api/v2/academyteams/${academyTeamId}/coaches/${coachId}/trainingsessions`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
      >
    >,
    TError = ErrorType<unknown>
  >(
    academyTeamId: number,
    coachId: number,
    params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryKey(
        academyTeamId,
        coachId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
        >
      >
    > = ({ signal }) =>
      getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions(
        academyTeamId,
        coachId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(academyTeamId && coachId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
      >
    >
  >;
export type GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  coachId: number,
  params: GetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademyteamsAcademyTeamIdCoachesCoachIdTrainingsessionsQueryOptions(
      academyTeamId,
      coachId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdTrainingsessions = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademyTeamsQueriesTrainingSessionsListResponse>(
    {
      url: `/api/v2/academyteams/${academyTeamId}/trainingsessions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryKey = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams
) => {
  return [
    `/api/v2/academyteams/${academyTeamId}/trainingsessions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
    >,
    TError = ErrorType<unknown>
  >(
    academyTeamId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryKey(
        academyTeamId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
      >
    > = ({ signal }) =>
      getApiV2AcademyteamsAcademyTeamIdTrainingsessions(
        academyTeamId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyTeamId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
    >
  >;
export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessions<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params: undefined | GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessions<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessions<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessions<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsQueryOptions(
      academyTeamId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademyTeamsQueriesTrainingSessionGroupsListResponse>(
    {
      url: `/api/v2/academyteams/${academyTeamId}/trainingsessiongroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryKey =
  (
    academyTeamId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams
  ) => {
    return [
      `/api/v2/academyteams/${academyTeamId}/trainingsessiongroups`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
    >,
    TError = ErrorType<unknown>
  >(
    academyTeamId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryKey(
        academyTeamId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
        >
      >
    > = ({ signal }) =>
      getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups(
        academyTeamId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyTeamId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
    >
  >;
export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params:
    | undefined
    | GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessiongroups
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademyteamsAcademyTeamIdTrainingsessiongroupsQueryOptions(
      academyTeamId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats = (
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AcademyTeamsQueriesTrainingSessionsStatsResponse>(
    {
      url: `/api/v2/academyteams/${academyTeamId}/trainingsessions/stats`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryKey =
  (
    academyTeamId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams
  ) => {
    return [
      `/api/v2/academyteams/${academyTeamId}/trainingsessions/stats`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
    >,
    TError = ErrorType<unknown>
  >(
    academyTeamId: number,
    params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryKey(
        academyTeamId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
        >
      >
    > = ({ signal }) =>
      getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats(
        academyTeamId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!academyTeamId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
    >
  >;
export type GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params:
    | undefined
    | GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  academyTeamId: number,
  params?: GetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2AcademyteamsAcademyTeamIdTrainingsessionsStats
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AcademyteamsAcademyTeamIdTrainingsessionsStatsQueryOptions(
      academyTeamId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2Agegroups = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3AgeGroupsQueriesListResponse>(
    { url: `/api/v2/agegroups`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2AgegroupsQueryKey = () => {
  return [`/api/v2/agegroups`] as const;
};

export const getGetApiV2AgegroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Agegroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Agegroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2AgegroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Agegroups>>
  > = ({ signal }) => getApiV2Agegroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Agegroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AgegroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Agegroups>>
>;
export type GetApiV2AgegroupsQueryError = ErrorType<unknown>;

export function useGetApiV2Agegroups<
  TData = Awaited<ReturnType<typeof getApiV2Agegroups>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Agegroups>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Agegroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Agegroups<
  TData = Awaited<ReturnType<typeof getApiV2Agegroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Agegroups>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Agegroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Agegroups<
  TData = Awaited<ReturnType<typeof getApiV2Agegroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Agegroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2Agegroups<
  TData = Awaited<ReturnType<typeof getApiV2Agegroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Agegroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AgegroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2AmateurclubsSearch = (
  params?: GetApiV2AmateurclubsSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AmateurClubsQueriesSearchListResponse>(
    { url: `/api/v2/amateurclubs/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2AmateurclubsSearchQueryKey = (
  params?: GetApiV2AmateurclubsSearchParams
) => {
  return [`/api/v2/amateurclubs/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2AmateurclubsSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AmateurclubsSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>
  > = ({ signal }) =>
    getApiV2AmateurclubsSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AmateurclubsSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>
>;
export type GetApiV2AmateurclubsSearchQueryError = ErrorType<unknown>;

export function useGetApiV2AmateurclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2AmateurclubsSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2AmateurclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AmateurclubsSearchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2AmateurclubsSearchCount = (
  params?: GetApiV2AmateurclubsSearchCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AmateurClubsQueriesSearchCountResponse>(
    { url: `/api/v2/amateurclubs/search/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2AmateurclubsSearchCountQueryKey = (
  params?: GetApiV2AmateurclubsSearchCountParams
) => {
  return [
    `/api/v2/amateurclubs/search/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AmateurclubsSearchCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2AmateurclubsSearchCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>
  > = ({ signal }) =>
    getApiV2AmateurclubsSearchCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AmateurclubsSearchCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>
>;
export type GetApiV2AmateurclubsSearchCountQueryError = ErrorType<unknown>;

export function useGetApiV2AmateurclubsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2AmateurclubsSearchCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurclubsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurclubsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2AmateurclubsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AmateurclubsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2AmateurclubsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AmateurclubsSearchCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const patchApiV2AmateurfixturesAmateurFixtureId = (
  amateurFixtureId: number,
  v2AmateurFixturesCommandsUpdateCommand: V2AmateurFixturesCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/amateurfixtures/${amateurFixtureId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2AmateurFixturesCommandsUpdateCommand,
    },
    options
  );
};

export const getPatchApiV2AmateurfixturesAmateurFixtureIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>,
    TError,
    { amateurFixtureId: number; data: V2AmateurFixturesCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>,
  TError,
  { amateurFixtureId: number; data: V2AmateurFixturesCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>,
    { amateurFixtureId: number; data: V2AmateurFixturesCommandsUpdateCommand }
  > = (props) => {
    const { amateurFixtureId, data } = props ?? {};

    return patchApiV2AmateurfixturesAmateurFixtureId(
      amateurFixtureId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2AmateurfixturesAmateurFixtureIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>
  >;
export type PatchApiV2AmateurfixturesAmateurFixtureIdMutationBody =
  V2AmateurFixturesCommandsUpdateCommand;
export type PatchApiV2AmateurfixturesAmateurFixtureIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePatchApiV2AmateurfixturesAmateurFixtureId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>,
    TError,
    { amateurFixtureId: number; data: V2AmateurFixturesCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2AmateurfixturesAmateurFixtureId>>,
  TError,
  { amateurFixtureId: number; data: V2AmateurFixturesCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2AmateurfixturesAmateurFixtureIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2AmateurfixturesAmateurFixtureId = (
  amateurFixtureId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/amateurfixtures/${amateurFixtureId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2AmateurfixturesAmateurFixtureIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>,
    TError,
    { amateurFixtureId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>,
  TError,
  { amateurFixtureId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>,
    { amateurFixtureId: number }
  > = (props) => {
    const { amateurFixtureId } = props ?? {};

    return deleteApiV2AmateurfixturesAmateurFixtureId(
      amateurFixtureId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2AmateurfixturesAmateurFixtureIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>
  >;

export type DeleteApiV2AmateurfixturesAmateurFixtureIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2AmateurfixturesAmateurFixtureId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>,
    TError,
    { amateurFixtureId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2AmateurfixturesAmateurFixtureId>>,
  TError,
  { amateurFixtureId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2AmateurfixturesAmateurFixtureIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */
export const getApiV2AmateurfixturesFixturetypes = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AmateurFixturesQueriesMetadataFixtureTypesListResponse>(
    { url: `/api/v2/amateurfixtures/fixturetypes`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2AmateurfixturesFixturetypesQueryKey = () => {
  return [`/api/v2/amateurfixtures/fixturetypes`] as const;
};

export const getGetApiV2AmateurfixturesFixturetypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AmateurfixturesFixturetypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>
  > = ({ signal }) =>
    getApiV2AmateurfixturesFixturetypes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AmateurfixturesFixturetypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>
>;
export type GetApiV2AmateurfixturesFixturetypesQueryError = ErrorType<unknown>;

export function useGetApiV2AmateurfixturesFixturetypes<
  TData = Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurfixturesFixturetypes<
  TData = Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AmateurfixturesFixturetypes<
  TData = Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */

export function useGetApiV2AmateurfixturesFixturetypes<
  TData = Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AmateurfixturesFixturetypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AmateurfixturesFixturetypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2Analysisinterpreters = (
  params?: GetApiV2AnalysisinterpretersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AnalysisInterpretersQueriesListResponse>(
    { url: `/api/v2/analysisinterpreters`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2AnalysisinterpretersQueryKey = (
  params?: GetApiV2AnalysisinterpretersParams
) => {
  return [`/api/v2/analysisinterpreters`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2AnalysisinterpretersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AnalysisinterpretersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AnalysisinterpretersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>
  > = ({ signal }) =>
    getApiV2Analysisinterpreters(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AnalysisinterpretersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>
>;
export type GetApiV2AnalysisinterpretersQueryError = ErrorType<unknown>;

export function useGetApiV2Analysisinterpreters<
  TData = Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2AnalysisinterpretersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Analysisinterpreters<
  TData = Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AnalysisinterpretersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Analysisinterpreters<
  TData = Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AnalysisinterpretersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2Analysisinterpreters<
  TData = Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2AnalysisinterpretersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Analysisinterpreters>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AnalysisinterpretersQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2AnalysisinterpretersAnalysisInterpreterId = (
  analysisInterpreterId: string,
  params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AnalysisInterpretersQueriesDetailsResponse>(
    {
      url: `/api/v2/analysisinterpreters/${analysisInterpreterId}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryKey = (
  analysisInterpreterId: string,
  params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams
) => {
  return [
    `/api/v2/analysisinterpreters/${analysisInterpreterId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
    >,
    TError = ErrorType<unknown>
  >(
    analysisInterpreterId: string,
    params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryKey(
        analysisInterpreterId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
      >
    > = ({ signal }) =>
      getApiV2AnalysisinterpretersAnalysisInterpreterId(
        analysisInterpreterId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!analysisInterpreterId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
    >
  >;
export type GetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2AnalysisinterpretersAnalysisInterpreterId<
  TData = Awaited<
    ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
  >,
  TError = ErrorType<unknown>
>(
  analysisInterpreterId: string,
  params: undefined | GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AnalysisinterpretersAnalysisInterpreterId<
  TData = Awaited<
    ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
  >,
  TError = ErrorType<unknown>
>(
  analysisInterpreterId: string,
  params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AnalysisinterpretersAnalysisInterpreterId<
  TData = Awaited<
    ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
  >,
  TError = ErrorType<unknown>
>(
  analysisInterpreterId: string,
  params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2AnalysisinterpretersAnalysisInterpreterId<
  TData = Awaited<
    ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
  >,
  TError = ErrorType<unknown>
>(
  analysisInterpreterId: string,
  params?: GetApiV2AnalysisinterpretersAnalysisInterpreterIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2AnalysisinterpretersAnalysisInterpreterId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2AnalysisinterpretersAnalysisInterpreterIdQueryOptions(
      analysisInterpreterId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2AppversionsPlayer = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AppVersionsQueriesPlayerAppResponse>(
    { url: `/api/v2/appversions/player`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2AppversionsPlayerQueryKey = () => {
  return [`/api/v2/appversions/player`] as const;
};

export const getGetApiV2AppversionsPlayerQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AppversionsPlayerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>
  > = ({ signal }) => getApiV2AppversionsPlayer(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AppversionsPlayerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>
>;
export type GetApiV2AppversionsPlayerQueryError = ErrorType<unknown>;

export function useGetApiV2AppversionsPlayer<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsPlayer<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsPlayer<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2AppversionsPlayer<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsPlayer>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AppversionsPlayerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2AppversionsScout = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AppVersionsQueriesScoutAppResponse>(
    { url: `/api/v2/appversions/scout`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2AppversionsScoutQueryKey = () => {
  return [`/api/v2/appversions/scout`] as const;
};

export const getGetApiV2AppversionsScoutQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AppversionsScoutQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AppversionsScout>>
  > = ({ signal }) => getApiV2AppversionsScout(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AppversionsScoutQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AppversionsScout>>
>;
export type GetApiV2AppversionsScoutQueryError = ErrorType<unknown>;

export function useGetApiV2AppversionsScout<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsScout<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsScout<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2AppversionsScout<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsScout>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AppversionsScoutQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2AppversionsCoach = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2AppVersionsQueriesCoachAppResponse>(
    { url: `/api/v2/appversions/coach`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2AppversionsCoachQueryKey = () => {
  return [`/api/v2/appversions/coach`] as const;
};

export const getGetApiV2AppversionsCoachQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2AppversionsCoachQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2AppversionsCoach>>
  > = ({ signal }) => getApiV2AppversionsCoach(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2AppversionsCoachQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AppversionsCoach>>
>;
export type GetApiV2AppversionsCoachQueryError = ErrorType<unknown>;

export function useGetApiV2AppversionsCoach<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsCoach<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2AppversionsCoach<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2AppversionsCoach<
  TData = Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2AppversionsCoach>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2AppversionsCoachQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Coaches = (
  v2CoachesCommandsCreateCommand: V2CoachesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/coaches`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2CoachesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2CoachesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Coaches>>,
    TError,
    { data: V2CoachesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Coaches>>,
  TError,
  { data: V2CoachesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Coaches>>,
    { data: V2CoachesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Coaches(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2CoachesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Coaches>>
>;
export type PostApiV2CoachesMutationBody = V2CoachesCommandsCreateCommand;
export type PostApiV2CoachesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Coaches = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Coaches>>,
    TError,
    { data: V2CoachesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Coaches>>,
  TError,
  { data: V2CoachesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2CoachesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2CoachesCoachIdAcademyteams = (
  coachId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CoachesQueriesAcademyTeamsListResponse>(
    { url: `/api/v2/coaches/${coachId}/academyteams`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2CoachesCoachIdAcademyteamsQueryKey = (
  coachId: number
) => {
  return [`/api/v2/coaches/${coachId}/academyteams`] as const;
};

export const getGetApiV2CoachesCoachIdAcademyteamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2CoachesCoachIdAcademyteamsQueryKey(coachId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>
  > = ({ signal }) =>
    getApiV2CoachesCoachIdAcademyteams(coachId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!coachId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CoachesCoachIdAcademyteamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>
>;
export type GetApiV2CoachesCoachIdAcademyteamsQueryError = ErrorType<unknown>;

export function useGetApiV2CoachesCoachIdAcademyteams<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdAcademyteams<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdAcademyteams<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2CoachesCoachIdAcademyteams<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdAcademyteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CoachesCoachIdAcademyteamsQueryOptions(
    coachId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2CoachesCoachIdSummary = (
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CoachesQueriesSummaryResponse>(
    {
      url: `/api/v2/coaches/${coachId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2CoachesCoachIdSummaryQueryKey = (
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams
) => {
  return [
    `/api/v2/coaches/${coachId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2CoachesCoachIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2CoachesCoachIdSummaryQueryKey(coachId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>
  > = ({ signal }) =>
    getApiV2CoachesCoachIdSummary(coachId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!coachId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CoachesCoachIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>
>;
export type GetApiV2CoachesCoachIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2CoachesCoachIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params: undefined | GetApiV2CoachesCoachIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2CoachesCoachIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CoachesCoachIdSummaryQueryOptions(
    coachId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2CoachesCoachIdNotifications = (
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CoachesQueriesNotificationsListResponse>(
    {
      url: `/api/v2/coaches/${coachId}/notifications`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2CoachesCoachIdNotificationsQueryKey = (
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams
) => {
  return [
    `/api/v2/coaches/${coachId}/notifications`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2CoachesCoachIdNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2CoachesCoachIdNotificationsQueryKey(coachId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>
  > = ({ signal }) =>
    getApiV2CoachesCoachIdNotifications(
      coachId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!coachId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CoachesCoachIdNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>
>;
export type GetApiV2CoachesCoachIdNotificationsQueryError = ErrorType<unknown>;

export function useGetApiV2CoachesCoachIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params: undefined | GetApiV2CoachesCoachIdNotificationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2CoachesCoachIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  params?: GetApiV2CoachesCoachIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CoachesCoachIdNotificationsQueryOptions(
    coachId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2CoachesCoachIdNotificationsStats = (
  coachId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CoachesQueriesNotificationsStatsResponse>(
    {
      url: `/api/v2/coaches/${coachId}/notifications/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2CoachesCoachIdNotificationsStatsQueryKey = (
  coachId: number
) => {
  return [`/api/v2/coaches/${coachId}/notifications/stats`] as const;
};

export const getGetApiV2CoachesCoachIdNotificationsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2CoachesCoachIdNotificationsStatsQueryKey(coachId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>
  > = ({ signal }) =>
    getApiV2CoachesCoachIdNotificationsStats(coachId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!coachId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CoachesCoachIdNotificationsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>
>;
export type GetApiV2CoachesCoachIdNotificationsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2CoachesCoachIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CoachesCoachIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2CoachesCoachIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  coachId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CoachesCoachIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CoachesCoachIdNotificationsStatsQueryOptions(
    coachId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const putApiV2CoachesCoachIdNotificationsSeen = (
  coachId: number,
  params: PutApiV2CoachesCoachIdNotificationsSeenParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/coaches/${coachId}/notifications/seen`,
      method: 'PUT',
      params,
    },
    options
  );
};

export const getPutApiV2CoachesCoachIdNotificationsSeenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>,
    TError,
    { coachId: number; params: PutApiV2CoachesCoachIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>,
  TError,
  { coachId: number; params: PutApiV2CoachesCoachIdNotificationsSeenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>,
    { coachId: number; params: PutApiV2CoachesCoachIdNotificationsSeenParams }
  > = (props) => {
    const { coachId, params } = props ?? {};

    return putApiV2CoachesCoachIdNotificationsSeen(
      coachId,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2CoachesCoachIdNotificationsSeenMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>
>;

export type PutApiV2CoachesCoachIdNotificationsSeenMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Coach)
 */
export const usePutApiV2CoachesCoachIdNotificationsSeen = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>,
    TError,
    { coachId: number; params: PutApiV2CoachesCoachIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2CoachesCoachIdNotificationsSeen>>,
  TError,
  { coachId: number; params: PutApiV2CoachesCoachIdNotificationsSeenParams },
  TContext
> => {
  const mutationOptions =
    getPutApiV2CoachesCoachIdNotificationsSeenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2Counties = (
  params: GetApiV2CountiesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3CountiesQueriesListResponse>(
    { url: `/api/v2/counties`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2CountiesQueryKey = (params: GetApiV2CountiesParams) => {
  return [`/api/v2/counties`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2CountiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Counties>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2CountiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Counties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2CountiesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Counties>>
  > = ({ signal }) => getApiV2Counties(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Counties>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CountiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Counties>>
>;
export type GetApiV2CountiesQueryError = ErrorType<unknown>;

export function useGetApiV2Counties<
  TData = Awaited<ReturnType<typeof getApiV2Counties>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2CountiesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Counties>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Counties>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Counties<
  TData = Awaited<ReturnType<typeof getApiV2Counties>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2CountiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Counties>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Counties>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Counties<
  TData = Awaited<ReturnType<typeof getApiV2Counties>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2CountiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Counties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Counties<
  TData = Awaited<ReturnType<typeof getApiV2Counties>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2CountiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Counties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CountiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2Countries = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CountriesQueriesListResponse>(
    { url: `/api/v2/countries`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2CountriesQueryKey = () => {
  return [`/api/v2/countries`] as const;
};

export const getGetApiV2CountriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Countries>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Countries>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2CountriesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Countries>>
  > = ({ signal }) => getApiV2Countries(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Countries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CountriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Countries>>
>;
export type GetApiV2CountriesQueryError = ErrorType<unknown>;

export function useGetApiV2Countries<
  TData = Awaited<ReturnType<typeof getApiV2Countries>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Countries>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Countries>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Countries<
  TData = Awaited<ReturnType<typeof getApiV2Countries>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Countries>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Countries>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Countries<
  TData = Awaited<ReturnType<typeof getApiV2Countries>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Countries>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Countries<
  TData = Awaited<ReturnType<typeof getApiV2Countries>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Countries>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2CountriesCountryIdCounties = (
  countryId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2CountriesQueriesCountiesListResponse>(
    { url: `/api/v2/countries/${countryId}/counties`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2CountriesCountryIdCountiesQueryKey = (
  countryId: number
) => {
  return [`/api/v2/countries/${countryId}/counties`] as const;
};

export const getGetApiV2CountriesCountryIdCountiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
  TError = ErrorType<unknown>
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2CountriesCountryIdCountiesQueryKey(countryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>
  > = ({ signal }) =>
    getApiV2CountriesCountryIdCounties(countryId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!countryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2CountriesCountryIdCountiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>
>;
export type GetApiV2CountriesCountryIdCountiesQueryError = ErrorType<unknown>;

export function useGetApiV2CountriesCountryIdCounties<
  TData = Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
  TError = ErrorType<unknown>
>(
  countryId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CountriesCountryIdCounties<
  TData = Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
  TError = ErrorType<unknown>
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2CountriesCountryIdCounties<
  TData = Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
  TError = ErrorType<unknown>
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2CountriesCountryIdCounties<
  TData = Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
  TError = ErrorType<unknown>
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2CountriesCountryIdCounties>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2CountriesCountryIdCountiesQueryOptions(
    countryId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Discoverycarouselitems = (
  params?: GetApiV2DiscoverycarouselitemsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2DiscoveryCarouselItemsQueriesListResponse>(
    { url: `/api/v2/discoverycarouselitems`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2DiscoverycarouselitemsQueryKey = (
  params?: GetApiV2DiscoverycarouselitemsParams
) => {
  return [
    `/api/v2/discoverycarouselitems`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2DiscoverycarouselitemsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverycarouselitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2DiscoverycarouselitemsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>
  > = ({ signal }) =>
    getApiV2Discoverycarouselitems(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2DiscoverycarouselitemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>
>;
export type GetApiV2DiscoverycarouselitemsQueryError = ErrorType<unknown>;

export function useGetApiV2Discoverycarouselitems<
  TData = Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2DiscoverycarouselitemsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverycarouselitems<
  TData = Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverycarouselitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverycarouselitems<
  TData = Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverycarouselitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Discoverycarouselitems<
  TData = Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverycarouselitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverycarouselitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2DiscoverycarouselitemsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2DiscoverypostsBatch = (
  params?: GetApiV2DiscoverypostsBatchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2DiscoveryPostsQueriesBatchResponse>(
    { url: `/api/v2/discoveryposts/batch`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2DiscoverypostsBatchQueryKey = (
  params?: GetApiV2DiscoverypostsBatchParams
) => {
  return [`/api/v2/discoveryposts/batch`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2DiscoverypostsBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverypostsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2DiscoverypostsBatchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>
  > = ({ signal }) =>
    getApiV2DiscoverypostsBatch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2DiscoverypostsBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>
>;
export type GetApiV2DiscoverypostsBatchQueryError = ErrorType<unknown>;

export function useGetApiV2DiscoverypostsBatch<
  TData = Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2DiscoverypostsBatchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2DiscoverypostsBatch<
  TData = Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverypostsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2DiscoverypostsBatch<
  TData = Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverypostsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2DiscoverypostsBatch<
  TData = Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverypostsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2DiscoverypostsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2DiscoverypostsBatchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Discoverysections = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2DiscoverySectionsQueriesListResponse>(
    { url: `/api/v2/discoverysections`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2DiscoverysectionsQueryKey = () => {
  return [`/api/v2/discoverysections`] as const;
};

export const getGetApiV2DiscoverysectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Discoverysections>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Discoverysections>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2DiscoverysectionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Discoverysections>>
  > = ({ signal }) => getApiV2Discoverysections(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Discoverysections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2DiscoverysectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Discoverysections>>
>;
export type GetApiV2DiscoverysectionsQueryError = ErrorType<unknown>;

export function useGetApiV2Discoverysections<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysections>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Discoverysections>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysections>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverysections<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysections>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Discoverysections>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysections>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverysections<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysections>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Discoverysections>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Discoverysections<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysections>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Discoverysections>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2DiscoverysectionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Discoverysponsors = (
  params?: GetApiV2DiscoverysponsorsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2DiscoverySponsorsQueriesListResponse>(
    { url: `/api/v2/discoverysponsors`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2DiscoverysponsorsQueryKey = (
  params?: GetApiV2DiscoverysponsorsParams
) => {
  return [`/api/v2/discoverysponsors`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2DiscoverysponsorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverysponsorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2DiscoverysponsorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Discoverysponsors>>
  > = ({ signal }) => getApiV2Discoverysponsors(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2DiscoverysponsorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Discoverysponsors>>
>;
export type GetApiV2DiscoverysponsorsQueryError = ErrorType<unknown>;

export function useGetApiV2Discoverysponsors<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2DiscoverysponsorsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverysponsors<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverysponsorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverysponsors<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverysponsorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Discoverysponsors<
  TData = Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2DiscoverysponsorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverysponsors>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2DiscoverysponsorsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Discoverytrials = (
  params: GetApiV2DiscoverytrialsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2DiscoveryTrialsQueriesListResponse>(
    { url: `/api/v2/discoverytrials`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2DiscoverytrialsQueryKey = (
  params: GetApiV2DiscoverytrialsParams
) => {
  return [`/api/v2/discoverytrials`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2DiscoverytrialsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2DiscoverytrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2DiscoverytrialsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Discoverytrials>>
  > = ({ signal }) => getApiV2Discoverytrials(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2DiscoverytrialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Discoverytrials>>
>;
export type GetApiV2DiscoverytrialsQueryError = ErrorType<unknown>;

export function useGetApiV2Discoverytrials<
  TData = Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2DiscoverytrialsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverytrials<
  TData = Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2DiscoverytrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Discoverytrials<
  TData = Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2DiscoverytrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Discoverytrials<
  TData = Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2DiscoverytrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Discoverytrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2DiscoverytrialsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2Entrymeasurementtypes = (
  params?: GetApiV2EntrymeasurementtypesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EntryMeasurementTypesQueriesListResponse>(
    { url: `/api/v2/entrymeasurementtypes`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2EntrymeasurementtypesQueryKey = (
  params?: GetApiV2EntrymeasurementtypesParams
) => {
  return [
    `/api/v2/entrymeasurementtypes`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2EntrymeasurementtypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EntrymeasurementtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2EntrymeasurementtypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>
  > = ({ signal }) =>
    getApiV2Entrymeasurementtypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EntrymeasurementtypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>
>;
export type GetApiV2EntrymeasurementtypesQueryError = ErrorType<unknown>;

export function useGetApiV2Entrymeasurementtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2EntrymeasurementtypesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Entrymeasurementtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EntrymeasurementtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Entrymeasurementtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EntrymeasurementtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2Entrymeasurementtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EntrymeasurementtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entrymeasurementtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EntrymeasurementtypesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Entrymeasurementtypes = (
  v2EntryMeasurementTypesCommandsCreateCommand: V2EntryMeasurementTypesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/entrymeasurementtypes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2EntryMeasurementTypesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2EntrymeasurementtypesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>,
    TError,
    { data: V2EntryMeasurementTypesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>,
  TError,
  { data: V2EntryMeasurementTypesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>,
    { data: V2EntryMeasurementTypesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Entrymeasurementtypes(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2EntrymeasurementtypesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>
>;
export type PostApiV2EntrymeasurementtypesMutationBody =
  V2EntryMeasurementTypesCommandsCreateCommand;
export type PostApiV2EntrymeasurementtypesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Entrymeasurementtypes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>,
    TError,
    { data: V2EntryMeasurementTypesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Entrymeasurementtypes>>,
  TError,
  { data: V2EntryMeasurementTypesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2EntrymeasurementtypesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2EntrymeasurementtypesEntryMeasurementTypeId = (
  entryMeasurementTypeId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EntryMeasurementTypesQueriesDetailsResponse>(
    {
      url: `/api/v2/entrymeasurementtypes/${entryMeasurementTypeId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryKey = (
  entryMeasurementTypeId: number
) => {
  return [`/api/v2/entrymeasurementtypes/${entryMeasurementTypeId}`] as const;
};

export const getGetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >,
    TError = ErrorType<unknown>
  >(
    entryMeasurementTypeId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryKey(
        entryMeasurementTypeId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
      >
    > = ({ signal }) =>
      getApiV2EntrymeasurementtypesEntryMeasurementTypeId(
        entryMeasurementTypeId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!entryMeasurementTypeId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >
  >;
export type GetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2EntrymeasurementtypesEntryMeasurementTypeId<
  TData = Awaited<
    ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError = ErrorType<unknown>
>(
  entryMeasurementTypeId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EntrymeasurementtypesEntryMeasurementTypeId<
  TData = Awaited<
    ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError = ErrorType<unknown>
>(
  entryMeasurementTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EntrymeasurementtypesEntryMeasurementTypeId<
  TData = Awaited<
    ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError = ErrorType<unknown>
>(
  entryMeasurementTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2EntrymeasurementtypesEntryMeasurementTypeId<
  TData = Awaited<
    ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError = ErrorType<unknown>
>(
  entryMeasurementTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2EntrymeasurementtypesEntryMeasurementTypeId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2EntrymeasurementtypesEntryMeasurementTypeIdQueryOptions(
      entryMeasurementTypeId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId = (
  entryMeasurementTypeId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/entrymeasurementtypes/${entryMeasurementTypeId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId
        >
      >,
      TError,
      { entryMeasurementTypeId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >,
    TError,
    { entryMeasurementTypeId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId
        >
      >,
      { entryMeasurementTypeId: number }
    > = (props) => {
      const { entryMeasurementTypeId } = props ?? {};

      return deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId(
        entryMeasurementTypeId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >
  >;

export type DeleteApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2EntrymeasurementtypesEntryMeasurementTypeId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >,
    TError,
    { entryMeasurementTypeId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError,
  { entryMeasurementTypeId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2EntrymeasurementtypesEntryMeasurementTypeId = (
  entryMeasurementTypeId: number,
  v2EntryMeasurementTypesCommandsUpdateCommand: V2EntryMeasurementTypesCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/entrymeasurementtypes/${entryMeasurementTypeId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2EntryMeasurementTypesCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
      >,
      TError,
      {
        entryMeasurementTypeId: number;
        data: V2EntryMeasurementTypesCommandsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >,
    TError,
    {
      entryMeasurementTypeId: number;
      data: V2EntryMeasurementTypesCommandsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
      >,
      {
        entryMeasurementTypeId: number;
        data: V2EntryMeasurementTypesCommandsUpdateCommand;
      }
    > = (props) => {
      const { entryMeasurementTypeId, data } = props ?? {};

      return putApiV2EntrymeasurementtypesEntryMeasurementTypeId(
        entryMeasurementTypeId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >
  >;
export type PutApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationBody =
  V2EntryMeasurementTypesCommandsUpdateCommand;
export type PutApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2EntrymeasurementtypesEntryMeasurementTypeId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
    >,
    TError,
    {
      entryMeasurementTypeId: number;
      data: V2EntryMeasurementTypesCommandsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2EntrymeasurementtypesEntryMeasurementTypeId>
  >,
  TError,
  {
    entryMeasurementTypeId: number;
    data: V2EntryMeasurementTypesCommandsUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2EntrymeasurementtypesEntryMeasurementTypeIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator,Scout)
 */
export const getApiV2Entryratingtypes = (
  params?: GetApiV2EntryratingtypesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EntryRatingTypesQueriesListResponse>(
    { url: `/api/v2/entryratingtypes`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2EntryratingtypesQueryKey = (
  params?: GetApiV2EntryratingtypesParams
) => {
  return [`/api/v2/entryratingtypes`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2EntryratingtypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2EntryratingtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2EntryratingtypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Entryratingtypes>>
  > = ({ signal }) => getApiV2Entryratingtypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EntryratingtypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Entryratingtypes>>
>;
export type GetApiV2EntryratingtypesQueryError = ErrorType<void>;

export function useGetApiV2Entryratingtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
  TError = ErrorType<void>
>(
  params: undefined | GetApiV2EntryratingtypesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Entryratingtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2EntryratingtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Entryratingtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2EntryratingtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout)
 */

export function useGetApiV2Entryratingtypes<
  TData = Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2EntryratingtypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Entryratingtypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EntryratingtypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Entryratingtypes = (
  v2EntryRatingTypesCommandsCreateCommand: V2EntryRatingTypesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/entryratingtypes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2EntryRatingTypesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2EntryratingtypesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Entryratingtypes>>,
    TError,
    { data: V2EntryRatingTypesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Entryratingtypes>>,
  TError,
  { data: V2EntryRatingTypesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Entryratingtypes>>,
    { data: V2EntryRatingTypesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Entryratingtypes(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2EntryratingtypesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Entryratingtypes>>
>;
export type PostApiV2EntryratingtypesMutationBody =
  V2EntryRatingTypesCommandsCreateCommand;
export type PostApiV2EntryratingtypesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Entryratingtypes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Entryratingtypes>>,
    TError,
    { data: V2EntryRatingTypesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Entryratingtypes>>,
  TError,
  { data: V2EntryRatingTypesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2EntryratingtypesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2EntryratingtypesEntryRatingTypeId = (
  entryRatingTypeId: number,
  v2EntryRatingTypesCommandsUpdateCommand: V2EntryRatingTypesCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/entryratingtypes/${entryRatingTypeId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2EntryRatingTypesCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2EntryratingtypesEntryRatingTypeIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>,
    TError,
    {
      entryRatingTypeId: number;
      data: V2EntryRatingTypesCommandsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>,
  TError,
  { entryRatingTypeId: number; data: V2EntryRatingTypesCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>,
    { entryRatingTypeId: number; data: V2EntryRatingTypesCommandsUpdateCommand }
  > = (props) => {
    const { entryRatingTypeId, data } = props ?? {};

    return putApiV2EntryratingtypesEntryRatingTypeId(
      entryRatingTypeId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2EntryratingtypesEntryRatingTypeIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>
  >;
export type PutApiV2EntryratingtypesEntryRatingTypeIdMutationBody =
  V2EntryRatingTypesCommandsUpdateCommand;
export type PutApiV2EntryratingtypesEntryRatingTypeIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2EntryratingtypesEntryRatingTypeId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>,
    TError,
    {
      entryRatingTypeId: number;
      data: V2EntryRatingTypesCommandsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2EntryratingtypesEntryRatingTypeId>>,
  TError,
  { entryRatingTypeId: number; data: V2EntryRatingTypesCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2EntryratingtypesEntryRatingTypeIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2EntryratingtypesEntryRatingTypeId = (
  entryRatingTypeId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EntryRatingTypesQueriesDetailsResponse>(
    {
      url: `/api/v2/entryratingtypes/${entryRatingTypeId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2EntryratingtypesEntryRatingTypeIdQueryKey = (
  entryRatingTypeId: number
) => {
  return [`/api/v2/entryratingtypes/${entryRatingTypeId}`] as const;
};

export const getGetApiV2EntryratingtypesEntryRatingTypeIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
  TError = ErrorType<unknown>
>(
  entryRatingTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2EntryratingtypesEntryRatingTypeIdQueryKey(entryRatingTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>
  > = ({ signal }) =>
    getApiV2EntryratingtypesEntryRatingTypeId(
      entryRatingTypeId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!entryRatingTypeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EntryratingtypesEntryRatingTypeIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>
>;
export type GetApiV2EntryratingtypesEntryRatingTypeIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2EntryratingtypesEntryRatingTypeId<
  TData = Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
  TError = ErrorType<unknown>
>(
  entryRatingTypeId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EntryratingtypesEntryRatingTypeId<
  TData = Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
  TError = ErrorType<unknown>
>(
  entryRatingTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EntryratingtypesEntryRatingTypeId<
  TData = Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
  TError = ErrorType<unknown>
>(
  entryRatingTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2EntryratingtypesEntryRatingTypeId<
  TData = Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
  TError = ErrorType<unknown>
>(
  entryRatingTypeId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EntryratingtypesEntryRatingTypeId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EntryratingtypesEntryRatingTypeIdQueryOptions(
    entryRatingTypeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2EntryratingtypesEntryRatingTypeId = (
  entryRatingTypeId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/entryratingtypes/${entryRatingTypeId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2EntryratingtypesEntryRatingTypeIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>,
    TError,
    { entryRatingTypeId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>,
  TError,
  { entryRatingTypeId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>,
    { entryRatingTypeId: number }
  > = (props) => {
    const { entryRatingTypeId } = props ?? {};

    return deleteApiV2EntryratingtypesEntryRatingTypeId(
      entryRatingTypeId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2EntryratingtypesEntryRatingTypeIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>
  >;

export type DeleteApiV2EntryratingtypesEntryRatingTypeIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2EntryratingtypesEntryRatingTypeId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>,
    TError,
    { entryRatingTypeId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2EntryratingtypesEntryRatingTypeId>>,
  TError,
  { entryRatingTypeId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2EntryratingtypesEntryRatingTypeIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2Equipments = (
  params?: GetApiV2EquipmentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EquipmentsQueriesListResponse>(
    { url: `/api/v2/equipments`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2EquipmentsQueryKey = (
  params?: GetApiV2EquipmentsParams
) => {
  return [`/api/v2/equipments`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2EquipmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Equipments>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EquipmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Equipments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2EquipmentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Equipments>>
  > = ({ signal }) => getApiV2Equipments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Equipments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EquipmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Equipments>>
>;
export type GetApiV2EquipmentsQueryError = ErrorType<unknown>;

export function useGetApiV2Equipments<
  TData = Awaited<ReturnType<typeof getApiV2Equipments>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2EquipmentsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Equipments>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Equipments>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Equipments<
  TData = Awaited<ReturnType<typeof getApiV2Equipments>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EquipmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Equipments>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Equipments>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Equipments<
  TData = Awaited<ReturnType<typeof getApiV2Equipments>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EquipmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Equipments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2Equipments<
  TData = Awaited<ReturnType<typeof getApiV2Equipments>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2EquipmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Equipments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EquipmentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Equipments = (
  v2EquipmentsCommandsCreateCommand: V2EquipmentsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/equipments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2EquipmentsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2EquipmentsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Equipments>>,
    TError,
    { data: V2EquipmentsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Equipments>>,
  TError,
  { data: V2EquipmentsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Equipments>>,
    { data: V2EquipmentsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Equipments(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2EquipmentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Equipments>>
>;
export type PostApiV2EquipmentsMutationBody = V2EquipmentsCommandsCreateCommand;
export type PostApiV2EquipmentsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Equipments = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Equipments>>,
    TError,
    { data: V2EquipmentsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Equipments>>,
  TError,
  { data: V2EquipmentsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2EquipmentsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2EquipmentsEquipmentId = (
  equipmentId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EquipmentsQueriesDetailsResponse>(
    { url: `/api/v2/equipments/${equipmentId}`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2EquipmentsEquipmentIdQueryKey = (
  equipmentId: number
) => {
  return [`/api/v2/equipments/${equipmentId}`] as const;
};

export const getGetApiV2EquipmentsEquipmentIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
  TError = ErrorType<unknown>
>(
  equipmentId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2EquipmentsEquipmentIdQueryKey(equipmentId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>
  > = ({ signal }) =>
    getApiV2EquipmentsEquipmentId(equipmentId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!equipmentId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EquipmentsEquipmentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>
>;
export type GetApiV2EquipmentsEquipmentIdQueryError = ErrorType<unknown>;

export function useGetApiV2EquipmentsEquipmentId<
  TData = Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
  TError = ErrorType<unknown>
>(
  equipmentId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EquipmentsEquipmentId<
  TData = Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
  TError = ErrorType<unknown>
>(
  equipmentId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2EquipmentsEquipmentId<
  TData = Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
  TError = ErrorType<unknown>
>(
  equipmentId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2EquipmentsEquipmentId<
  TData = Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
  TError = ErrorType<unknown>
>(
  equipmentId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2EquipmentsEquipmentId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EquipmentsEquipmentIdQueryOptions(
    equipmentId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2EquipmentsEquipmentId = (
  equipmentId: number,
  v2EquipmentsCommandsUpdateCommand: V2EquipmentsCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/equipments/${equipmentId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2EquipmentsCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2EquipmentsEquipmentIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>,
    TError,
    { equipmentId: number; data: V2EquipmentsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>,
  TError,
  { equipmentId: number; data: V2EquipmentsCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>,
    { equipmentId: number; data: V2EquipmentsCommandsUpdateCommand }
  > = (props) => {
    const { equipmentId, data } = props ?? {};

    return putApiV2EquipmentsEquipmentId(equipmentId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2EquipmentsEquipmentIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>
>;
export type PutApiV2EquipmentsEquipmentIdMutationBody =
  V2EquipmentsCommandsUpdateCommand;
export type PutApiV2EquipmentsEquipmentIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2EquipmentsEquipmentId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>,
    TError,
    { equipmentId: number; data: V2EquipmentsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2EquipmentsEquipmentId>>,
  TError,
  { equipmentId: number; data: V2EquipmentsCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2EquipmentsEquipmentIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2EquipmentsEquipmentId = (
  equipmentId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/equipments/${equipmentId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2EquipmentsEquipmentIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>,
    TError,
    { equipmentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>,
  TError,
  { equipmentId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>,
    { equipmentId: number }
  > = (props) => {
    const { equipmentId } = props ?? {};

    return deleteApiV2EquipmentsEquipmentId(equipmentId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2EquipmentsEquipmentIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>
>;

export type DeleteApiV2EquipmentsEquipmentIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2EquipmentsEquipmentId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>,
    TError,
    { equipmentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2EquipmentsEquipmentId>>,
  TError,
  { equipmentId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2EquipmentsEquipmentIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2Ethnicities = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2EthnicitiesQueriesListResponse>(
    { url: `/api/v2/ethnicities`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2EthnicitiesQueryKey = () => {
  return [`/api/v2/ethnicities`] as const;
};

export const getGetApiV2EthnicitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Ethnicities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Ethnicities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2EthnicitiesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Ethnicities>>
  > = ({ signal }) => getApiV2Ethnicities(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Ethnicities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2EthnicitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Ethnicities>>
>;
export type GetApiV2EthnicitiesQueryError = ErrorType<unknown>;

export function useGetApiV2Ethnicities<
  TData = Awaited<ReturnType<typeof getApiV2Ethnicities>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Ethnicities>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Ethnicities>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Ethnicities<
  TData = Awaited<ReturnType<typeof getApiV2Ethnicities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Ethnicities>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Ethnicities>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Ethnicities<
  TData = Awaited<ReturnType<typeof getApiV2Ethnicities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Ethnicities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Ethnicities<
  TData = Awaited<ReturnType<typeof getApiV2Ethnicities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Ethnicities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2EthnicitiesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ExplorelibrariesExploreLibraryIdLibraryitems = (
  exploreLibraryId: number,
  params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ExploreLibrariesQueriesLibraryItemsListResponse>(
    {
      url: `/api/v2/explorelibraries/${exploreLibraryId}/libraryitems`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryKey = (
  exploreLibraryId: number,
  params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams
) => {
  return [
    `/api/v2/explorelibraries/${exploreLibraryId}/libraryitems`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
    >,
    TError = ErrorType<unknown>
  >(
    exploreLibraryId: number,
    params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryKey(
        exploreLibraryId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
      >
    > = ({ signal }) =>
      getApiV2ExplorelibrariesExploreLibraryIdLibraryitems(
        exploreLibraryId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!exploreLibraryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
    >
  >;
export type GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitems<
  TData = Awaited<
    ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  params:
    | undefined
    | GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitems<
  TData = Awaited<
    ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitems<
  TData = Awaited<
    ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitems<
  TData = Awaited<
    ReturnType<typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems>
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  params?: GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitems
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsQueryOptions(
      exploreLibraryId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories =
  (
    exploreLibraryId: number,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2ExploreLibrariesQueriesLibraryItemsLibraryCategoriesListResponse>(
      {
        url: `/api/v2/explorelibraries/${exploreLibraryId}/libraryitems/librarycategories`,
        method: 'GET',
        signal,
      },
      options
    );
  };

export const getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryKey =
  (exploreLibraryId: number) => {
    return [
      `/api/v2/explorelibraries/${exploreLibraryId}/libraryitems/librarycategories`,
    ] as const;
  };

export const getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
      >
    >,
    TError = ErrorType<unknown>
  >(
    exploreLibraryId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryKey(
        exploreLibraryId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
        >
      >
    > = ({ signal }) =>
      getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories(
        exploreLibraryId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!exploreLibraryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
      >
    >
  >;
export type GetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryError =
  ErrorType<unknown>;

export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
    >
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
    >
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
    >
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
    >
  >,
  TError = ErrorType<unknown>
>(
  exploreLibraryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategories
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ExplorelibrariesExploreLibraryIdLibraryitemsLibrarycategoriesQueryOptions(
      exploreLibraryId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2FeedsPlayersHome = (
  params?: GetApiV2FeedsPlayersHomeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2FeedsQueriesPlayersHomeResponse>(
    { url: `/api/v2/feeds/players/home`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2FeedsPlayersHomeQueryKey = (
  params?: GetApiV2FeedsPlayersHomeParams
) => {
  return [`/api/v2/feeds/players/home`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2FeedsPlayersHomeQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2FeedsPlayersHomeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2FeedsPlayersHomeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>
  > = ({ signal }) => getApiV2FeedsPlayersHome(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2FeedsPlayersHomeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>
>;
export type GetApiV2FeedsPlayersHomeQueryError = ErrorType<unknown>;

export function useGetApiV2FeedsPlayersHome<
  TData = Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2FeedsPlayersHomeParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FeedsPlayersHome<
  TData = Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2FeedsPlayersHomeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FeedsPlayersHome<
  TData = Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2FeedsPlayersHomeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2FeedsPlayersHome<
  TData = Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2FeedsPlayersHomeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FeedsPlayersHome>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2FeedsPlayersHomeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2FilesUploadurl = (
  params: GetApiV2FilesUploadurlParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3FilesQueriesUploadUrlSingleUrlResponse>(
    { url: `/api/v2/files/uploadurl`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2FilesUploadurlQueryKey = (
  params: GetApiV2FilesUploadurlParams
) => {
  return [`/api/v2/files/uploadurl`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2FilesUploadurlQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2FilesUploadurlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2FilesUploadurl>>
  > = ({ signal }) => getApiV2FilesUploadurl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2FilesUploadurlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2FilesUploadurl>>
>;
export type GetApiV2FilesUploadurlQueryError = ErrorType<unknown>;

export function useGetApiV2FilesUploadurl<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FilesUploadurl<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FilesUploadurl<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2FilesUploadurl<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2FilesUploadurlQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2FilesUploadurlBatch = (
  params: GetApiV2FilesUploadurlBatchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3FilesQueriesUploadUrlBatchUrlResponse>(
    { url: `/api/v2/files/uploadurl/batch`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2FilesUploadurlBatchQueryKey = (
  params: GetApiV2FilesUploadurlBatchParams
) => {
  return [
    `/api/v2/files/uploadurl/batch`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2FilesUploadurlBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2FilesUploadurlBatchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>
  > = ({ signal }) =>
    getApiV2FilesUploadurlBatch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2FilesUploadurlBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>
>;
export type GetApiV2FilesUploadurlBatchQueryError = ErrorType<unknown>;

export function useGetApiV2FilesUploadurlBatch<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlBatchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FilesUploadurlBatch<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2FilesUploadurlBatch<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2FilesUploadurlBatch<
  TData = Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2FilesUploadurlBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2FilesUploadurlBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2FilesUploadurlBatchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2GamereportdraftsGameReportDraftIdSummary = (
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2GameReportDraftsQueriesSummaryResponse>(
    {
      url: `/api/v2/gamereportdrafts/${gameReportDraftId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2GamereportdraftsGameReportDraftIdSummaryQueryKey = (
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams
) => {
  return [
    `/api/v2/gamereportdrafts/${gameReportDraftId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2GamereportdraftsGameReportDraftIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2GamereportdraftsGameReportDraftIdSummaryQueryKey(
      gameReportDraftId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>>
  > = ({ signal }) =>
    getApiV2GamereportdraftsGameReportDraftIdSummary(
      gameReportDraftId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!gameReportDraftId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2GamereportdraftsGameReportDraftIdSummaryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>>
  >;
export type GetApiV2GamereportdraftsGameReportDraftIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2GamereportdraftsGameReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  gameReportDraftId: number,
  params: undefined | GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GamereportdraftsGameReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GamereportdraftsGameReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2GamereportdraftsGameReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  gameReportDraftId: number,
  params?: GetApiV2GamereportdraftsGameReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2GamereportdraftsGameReportDraftIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2GamereportdraftsGameReportDraftIdSummaryQueryOptions(
      gameReportDraftId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2GamereportdraftsGameReportDraftIdStatusCreated = (
  gameReportDraftId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/gamereportdrafts/${gameReportDraftId}/status/created`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2GamereportdraftsGameReportDraftIdStatusCreatedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated
        >
      >,
      TError,
      { gameReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated
      >
    >,
    TError,
    { gameReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated
        >
      >,
      { gameReportDraftId: number }
    > = (props) => {
      const { gameReportDraftId } = props ?? {};

      return patchApiV2GamereportdraftsGameReportDraftIdStatusCreated(
        gameReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2GamereportdraftsGameReportDraftIdStatusCreatedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated
      >
    >
  >;

export type PatchApiV2GamereportdraftsGameReportDraftIdStatusCreatedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2GamereportdraftsGameReportDraftIdStatusCreated = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated
      >
    >,
    TError,
    { gameReportDraftId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCreated>
  >,
  TError,
  { gameReportDraftId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2GamereportdraftsGameReportDraftIdStatusCreatedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted = (
  gameReportDraftId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/gamereportdrafts/${gameReportDraftId}/status/completed`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2GamereportdraftsGameReportDraftIdStatusCompletedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
        >
      >,
      TError,
      { gameReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
      >
    >,
    TError,
    { gameReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
        >
      >,
      { gameReportDraftId: number }
    > = (props) => {
      const { gameReportDraftId } = props ?? {};

      return patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted(
        gameReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2GamereportdraftsGameReportDraftIdStatusCompletedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
      >
    >
  >;

export type PatchApiV2GamereportdraftsGameReportDraftIdStatusCompletedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2GamereportdraftsGameReportDraftIdStatusCompleted = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
      >
    >,
    TError,
    { gameReportDraftId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof patchApiV2GamereportdraftsGameReportDraftIdStatusCompleted
    >
  >,
  TError,
  { gameReportDraftId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2GamereportdraftsGameReportDraftIdStatusCompletedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Gamereportdrafts = (
  v2GameReportDraftsCommandsCreateCommand: V2GameReportDraftsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/gamereportdrafts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GameReportDraftsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2GamereportdraftsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>,
    TError,
    { data: V2GameReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>,
  TError,
  { data: V2GameReportDraftsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>,
    { data: V2GameReportDraftsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Gamereportdrafts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GamereportdraftsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>
>;
export type PostApiV2GamereportdraftsMutationBody =
  V2GameReportDraftsCommandsCreateCommand;
export type PostApiV2GamereportdraftsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Gamereportdrafts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>,
    TError,
    { data: V2GameReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Gamereportdrafts>>,
  TError,
  { data: V2GameReportDraftsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2GamereportdraftsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2GamereportdraftsGameReportDraftIdNotesBatch = (
  gameReportDraftId: number,
  v2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/gamereportdrafts/${gameReportDraftId}/notes/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand,
    },
    options
  );
};

export const getPostApiV2GamereportdraftsGameReportDraftIdNotesBatchMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
      >,
      TError,
      {
        gameReportDraftId: number;
        data: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
    >,
    TError,
    {
      gameReportDraftId: number;
      data: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
      >,
      {
        gameReportDraftId: number;
        data: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
      }
    > = (props) => {
      const { gameReportDraftId, data } = props ?? {};

      return postApiV2GamereportdraftsGameReportDraftIdNotesBatch(
        gameReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2GamereportdraftsGameReportDraftIdNotesBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
    >
  >;
export type PostApiV2GamereportdraftsGameReportDraftIdNotesBatchMutationBody =
  V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
export type PostApiV2GamereportdraftsGameReportDraftIdNotesBatchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2GamereportdraftsGameReportDraftIdNotesBatch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
    >,
    TError,
    {
      gameReportDraftId: number;
      data: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesBatch>
  >,
  TError,
  {
    gameReportDraftId: number;
    data: V2GameReportDraftsCommandsGameReportDraftNotesAddBatchCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GamereportdraftsGameReportDraftIdNotesBatchMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2GamereportdraftsGameReportDraftIdNotesText = (
  gameReportDraftId: number,
  v2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/gamereportdrafts/${gameReportDraftId}/notes/text`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand,
    },
    options
  );
};

export const getPostApiV2GamereportdraftsGameReportDraftIdNotesTextMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
      >,
      TError,
      {
        gameReportDraftId: number;
        data: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
    >,
    TError,
    {
      gameReportDraftId: number;
      data: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
      >,
      {
        gameReportDraftId: number;
        data: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
      }
    > = (props) => {
      const { gameReportDraftId, data } = props ?? {};

      return postApiV2GamereportdraftsGameReportDraftIdNotesText(
        gameReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2GamereportdraftsGameReportDraftIdNotesTextMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
    >
  >;
export type PostApiV2GamereportdraftsGameReportDraftIdNotesTextMutationBody =
  V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
export type PostApiV2GamereportdraftsGameReportDraftIdNotesTextMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2GamereportdraftsGameReportDraftIdNotesText = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
    >,
    TError,
    {
      gameReportDraftId: number;
      data: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2GamereportdraftsGameReportDraftIdNotesText>
  >,
  TError,
  {
    gameReportDraftId: number;
    data: V2GameReportDraftsCommandsGameReportDraftNotesAddTextCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GamereportdraftsGameReportDraftIdNotesTextMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Gamereports = (
  v2GameReportsCommandsCreateCommand: V2GameReportsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/gamereports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GameReportsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2GamereportsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Gamereports>>,
    TError,
    { data: V2GameReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Gamereports>>,
  TError,
  { data: V2GameReportsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Gamereports>>,
    { data: V2GameReportsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Gamereports(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GamereportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Gamereports>>
>;
export type PostApiV2GamereportsMutationBody =
  V2GameReportsCommandsCreateCommand;
export type PostApiV2GamereportsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Gamereports = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Gamereports>>,
    TError,
    { data: V2GameReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Gamereports>>,
  TError,
  { data: V2GameReportsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2GamereportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2GamereportsGameReportId = (
  gameReportId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/gamereports/${gameReportId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2GamereportsGameReportIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>,
    TError,
    { gameReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>,
  TError,
  { gameReportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>,
    { gameReportId: number }
  > = (props) => {
    const { gameReportId } = props ?? {};

    return deleteApiV2GamereportsGameReportId(gameReportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2GamereportsGameReportIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>
>;

export type DeleteApiV2GamereportsGameReportIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2GamereportsGameReportId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>,
    TError,
    { gameReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2GamereportsGameReportId>>,
  TError,
  { gameReportId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2GamereportsGameReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2GamereportsGameReportIdSummary = (
  gameReportId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2GameReportsQueriesSummaryResponse>(
    {
      url: `/api/v2/gamereports/${gameReportId}/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2GamereportsGameReportIdSummaryQueryKey = (
  gameReportId: number
) => {
  return [`/api/v2/gamereports/${gameReportId}/summary`] as const;
};

export const getGetApiV2GamereportsGameReportIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
  TError = ErrorType<unknown>
>(
  gameReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2GamereportsGameReportIdSummaryQueryKey(gameReportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>
  > = ({ signal }) =>
    getApiV2GamereportsGameReportIdSummary(
      gameReportId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!gameReportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2GamereportsGameReportIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>
>;
export type GetApiV2GamereportsGameReportIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2GamereportsGameReportIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
  TError = ErrorType<unknown>
>(
  gameReportId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GamereportsGameReportIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
  TError = ErrorType<unknown>
>(
  gameReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GamereportsGameReportIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
  TError = ErrorType<unknown>
>(
  gameReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2GamereportsGameReportIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
  TError = ErrorType<unknown>
>(
  gameReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GamereportsGameReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2GamereportsGameReportIdSummaryQueryOptions(
    gameReportId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postApiV2GuardiansRegister = (
  v2GuardiansCommandsRegisterCommand: V2GuardiansCommandsRegisterCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2GuardiansCommandsRegisterResponse>(
    {
      url: `/api/v2/guardians/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsRegisterCommand,
    },
    options
  );
};

export const getPostApiV2GuardiansRegisterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansRegister>>,
    TError,
    { data: V2GuardiansCommandsRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2GuardiansRegister>>,
  TError,
  { data: V2GuardiansCommandsRegisterCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2GuardiansRegister>>,
    { data: V2GuardiansCommandsRegisterCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2GuardiansRegister(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GuardiansRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2GuardiansRegister>>
>;
export type PostApiV2GuardiansRegisterMutationBody =
  V2GuardiansCommandsRegisterCommand;
export type PostApiV2GuardiansRegisterMutationError = ErrorType<unknown>;

export const usePostApiV2GuardiansRegister = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansRegister>>,
    TError,
    { data: V2GuardiansCommandsRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2GuardiansRegister>>,
  TError,
  { data: V2GuardiansCommandsRegisterCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2GuardiansRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const getApiV2GuardiansGuardianIdSummary = (
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2GuardiansQueriesSummaryResponse>(
    {
      url: `/api/v2/guardians/${guardianId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2GuardiansGuardianIdSummaryQueryKey = (
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams
) => {
  return [
    `/api/v2/guardians/${guardianId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2GuardiansGuardianIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2GuardiansGuardianIdSummaryQueryKey(guardianId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>
  > = ({ signal }) =>
    getApiV2GuardiansGuardianIdSummary(
      guardianId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!guardianId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2GuardiansGuardianIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>
>;
export type GetApiV2GuardiansGuardianIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2GuardiansGuardianIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  params: undefined | GetApiV2GuardiansGuardianIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GuardiansGuardianIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GuardiansGuardianIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Guardian,Player)
 */

export function useGetApiV2GuardiansGuardianIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  params?: GetApiV2GuardiansGuardianIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2GuardiansGuardianIdSummaryQueryOptions(
    guardianId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Guardian)
 */
export const postApiV2GuardiansGuardianIdDeleterequests = (
  guardianId: number,
  v2GuardiansCommandsDeleteRequestsCreateCommand: V2GuardiansCommandsDeleteRequestsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesAcceptedResponse>(
    {
      url: `/api/v2/guardians/${guardianId}/deleterequests`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsDeleteRequestsCreateCommand,
    },
    options
  );
};

export const getPostApiV2GuardiansGuardianIdDeleterequestsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>,
    TError,
    {
      guardianId: number;
      data: V2GuardiansCommandsDeleteRequestsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsDeleteRequestsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>,
    { guardianId: number; data: V2GuardiansCommandsDeleteRequestsCreateCommand }
  > = (props) => {
    const { guardianId, data } = props ?? {};

    return postApiV2GuardiansGuardianIdDeleterequests(
      guardianId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GuardiansGuardianIdDeleterequestsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>
  >;
export type PostApiV2GuardiansGuardianIdDeleterequestsMutationBody =
  V2GuardiansCommandsDeleteRequestsCreateCommand;
export type PostApiV2GuardiansGuardianIdDeleterequestsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Guardian)
 */
export const usePostApiV2GuardiansGuardianIdDeleterequests = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>,
    TError,
    {
      guardianId: number;
      data: V2GuardiansCommandsDeleteRequestsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdDeleterequests>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsDeleteRequestsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GuardiansGuardianIdDeleterequestsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2GuardiansGuardianId = (
  guardianId: number,
  v2GuardiansCommandsDeleteCommand: V2GuardiansCommandsDeleteCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/guardians/${guardianId}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsDeleteCommand,
    },
    options
  );
};

export const getDeleteApiV2GuardiansGuardianIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsDeleteCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>,
    { guardianId: number; data: V2GuardiansCommandsDeleteCommand }
  > = (props) => {
    const { guardianId, data } = props ?? {};

    return deleteApiV2GuardiansGuardianId(guardianId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2GuardiansGuardianIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>
>;
export type DeleteApiV2GuardiansGuardianIdMutationBody =
  V2GuardiansCommandsDeleteCommand;
export type DeleteApiV2GuardiansGuardianIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2GuardiansGuardianId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2GuardiansGuardianId>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsDeleteCommand },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2GuardiansGuardianIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Guardian)
 */
export const postApiV2GuardiansGuardianIdPlayersRegister = (
  guardianId: number,
  v2GuardiansCommandsPlayersRegisterCommand: V2GuardiansCommandsPlayersRegisterCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2GuardiansCommandsPlayersRegisterResponse>(
    {
      url: `/api/v2/guardians/${guardianId}/players/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsPlayersRegisterCommand,
    },
    options
  );
};

export const getPostApiV2GuardiansGuardianIdPlayersRegisterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsPlayersRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsPlayersRegisterCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>,
    { guardianId: number; data: V2GuardiansCommandsPlayersRegisterCommand }
  > = (props) => {
    const { guardianId, data } = props ?? {};

    return postApiV2GuardiansGuardianIdPlayersRegister(
      guardianId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GuardiansGuardianIdPlayersRegisterMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>
  >;
export type PostApiV2GuardiansGuardianIdPlayersRegisterMutationBody =
  V2GuardiansCommandsPlayersRegisterCommand;
export type PostApiV2GuardiansGuardianIdPlayersRegisterMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Guardian)
 */
export const usePostApiV2GuardiansGuardianIdPlayersRegister = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsPlayersRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersRegister>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsPlayersRegisterCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GuardiansGuardianIdPlayersRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch = (
  guardianId: number,
  playerId: number,
  v2GuardiansCommandsPlayersSwitchCommand: V2GuardiansCommandsPlayersSwitchCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2GuardiansCommandsPlayersSwitchResponse>(
    {
      url: `/api/v2/guardians/${guardianId}/players/${playerId}/switch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsPlayersSwitchCommand,
    },
    options
  );
};

export const getPostApiV2GuardiansGuardianIdPlayersPlayerIdSwitchMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>
      >,
      TError,
      {
        guardianId: number;
        playerId: number;
        data: V2GuardiansCommandsPlayersSwitchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>
    >,
    TError,
    {
      guardianId: number;
      playerId: number;
      data: V2GuardiansCommandsPlayersSwitchCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>
      >,
      {
        guardianId: number;
        playerId: number;
        data: V2GuardiansCommandsPlayersSwitchCommand;
      }
    > = (props) => {
      const { guardianId, playerId, data } = props ?? {};

      return postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch(
        guardianId,
        playerId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2GuardiansGuardianIdPlayersPlayerIdSwitchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>
    >
  >;
export type PostApiV2GuardiansGuardianIdPlayersPlayerIdSwitchMutationBody =
  V2GuardiansCommandsPlayersSwitchCommand;
export type PostApiV2GuardiansGuardianIdPlayersPlayerIdSwitchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const usePostApiV2GuardiansGuardianIdPlayersPlayerIdSwitch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>
    >,
    TError,
    {
      guardianId: number;
      playerId: number;
      data: V2GuardiansCommandsPlayersSwitchCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayersPlayerIdSwitch>>,
  TError,
  {
    guardianId: number;
    playerId: number;
    data: V2GuardiansCommandsPlayersSwitchCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GuardiansGuardianIdPlayersPlayerIdSwitchMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const getApiV2GuardiansGuardianIdPlayers = (
  guardianId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2GuardiansQueriesPlayersListResponse>(
    { url: `/api/v2/guardians/${guardianId}/players`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2GuardiansGuardianIdPlayersQueryKey = (
  guardianId: number
) => {
  return [`/api/v2/guardians/${guardianId}/players`] as const;
};

export const getGetApiV2GuardiansGuardianIdPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2GuardiansGuardianIdPlayersQueryKey(guardianId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>
  > = ({ signal }) =>
    getApiV2GuardiansGuardianIdPlayers(guardianId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!guardianId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2GuardiansGuardianIdPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>
>;
export type GetApiV2GuardiansGuardianIdPlayersQueryError = ErrorType<unknown>;

export function useGetApiV2GuardiansGuardianIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GuardiansGuardianIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2GuardiansGuardianIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Guardian,Player)
 */

export function useGetApiV2GuardiansGuardianIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
  TError = ErrorType<unknown>
>(
  guardianId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2GuardiansGuardianIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2GuardiansGuardianIdPlayersQueryOptions(
    guardianId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const postApiV2GuardiansGuardianIdPlayers = (
  guardianId: number,
  v2GuardiansCommandsPlayersCreateCommand: V2GuardiansCommandsPlayersCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2GuardiansCommandsPlayersCreateResponse>(
    {
      url: `/api/v2/guardians/${guardianId}/players`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2GuardiansCommandsPlayersCreateCommand,
    },
    options
  );
};

export const getPostApiV2GuardiansGuardianIdPlayersMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsPlayersCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsPlayersCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>,
    { guardianId: number; data: V2GuardiansCommandsPlayersCreateCommand }
  > = (props) => {
    const { guardianId, data } = props ?? {};

    return postApiV2GuardiansGuardianIdPlayers(
      guardianId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2GuardiansGuardianIdPlayersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>
>;
export type PostApiV2GuardiansGuardianIdPlayersMutationBody =
  V2GuardiansCommandsPlayersCreateCommand;
export type PostApiV2GuardiansGuardianIdPlayersMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Guardian,Player)
 */
export const usePostApiV2GuardiansGuardianIdPlayers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>,
    TError,
    { guardianId: number; data: V2GuardiansCommandsPlayersCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2GuardiansGuardianIdPlayers>>,
  TError,
  { guardianId: number; data: V2GuardiansCommandsPlayersCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2GuardiansGuardianIdPlayersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2InfoscreensInfoscreentypeInfoScreenType = (
  infoScreenType?: InfoScreensEnumsInfoScreenType,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2InfoScreensQueriesDetailsResponse>(
    {
      url: `/api/v2/infoscreens/infoscreentype/${infoScreenType}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryKey = (
  infoScreenType?: InfoScreensEnumsInfoScreenType
) => {
  return [`/api/v2/infoscreens/infoscreentype/${infoScreenType}`] as const;
};

export const getGetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
  >,
  TError = ErrorType<unknown>
>(
  infoScreenType?: InfoScreensEnumsInfoScreenType,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryKey(infoScreenType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>>
  > = ({ signal }) =>
    getApiV2InfoscreensInfoscreentypeInfoScreenType(
      infoScreenType,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!infoScreenType,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>>
  >;
export type GetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryError =
  ErrorType<unknown>;

export function useGetApiV2InfoscreensInfoscreentypeInfoScreenType<
  TData = Awaited<
    ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
  >,
  TError = ErrorType<unknown>
>(
  infoScreenType: undefined | InfoScreensEnumsInfoScreenType,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2InfoscreensInfoscreentypeInfoScreenType<
  TData = Awaited<
    ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
  >,
  TError = ErrorType<unknown>
>(
  infoScreenType?: InfoScreensEnumsInfoScreenType,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2InfoscreensInfoscreentypeInfoScreenType<
  TData = Awaited<
    ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
  >,
  TError = ErrorType<unknown>
>(
  infoScreenType?: InfoScreensEnumsInfoScreenType,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2InfoscreensInfoscreentypeInfoScreenType<
  TData = Awaited<
    ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
  >,
  TError = ErrorType<unknown>
>(
  infoScreenType?: InfoScreensEnumsInfoScreenType,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2InfoscreensInfoscreentypeInfoScreenType>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2InfoscreensInfoscreentypeInfoScreenTypeQueryOptions(
      infoScreenType,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2LeaguesSearch = (
  params?: GetApiV2LeaguesSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2LeaguesQueriesSearchResponse>(
    { url: `/api/v2/leagues/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2LeaguesSearchQueryKey = (
  params?: GetApiV2LeaguesSearchParams
) => {
  return [`/api/v2/leagues/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2LeaguesSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2LeaguesSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2LeaguesSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2LeaguesSearch>>
  > = ({ signal }) => getApiV2LeaguesSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2LeaguesSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2LeaguesSearch>>
>;
export type GetApiV2LeaguesSearchQueryError = ErrorType<unknown>;

export function useGetApiV2LeaguesSearch<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2LeaguesSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2LeaguesSearch<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2LeaguesSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2LeaguesSearch<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2LeaguesSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2LeaguesSearch<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2LeaguesSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2LeaguesSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2LeaguesLeagueIdDivisions = (
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2LeaguesQueriesDivisionsListResponse>(
    {
      url: `/api/v2/leagues/${leagueId}/divisions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2LeaguesLeagueIdDivisionsQueryKey = (
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams
) => {
  return [
    `/api/v2/leagues/${leagueId}/divisions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2LeaguesLeagueIdDivisionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
  TError = ErrorType<unknown>
>(
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2LeaguesLeagueIdDivisionsQueryKey(leagueId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>
  > = ({ signal }) =>
    getApiV2LeaguesLeagueIdDivisions(leagueId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!leagueId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2LeaguesLeagueIdDivisionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>
>;
export type GetApiV2LeaguesLeagueIdDivisionsQueryError = ErrorType<unknown>;

export function useGetApiV2LeaguesLeagueIdDivisions<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
  TError = ErrorType<unknown>
>(
  leagueId: number,
  params: undefined | GetApiV2LeaguesLeagueIdDivisionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2LeaguesLeagueIdDivisions<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
  TError = ErrorType<unknown>
>(
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2LeaguesLeagueIdDivisions<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
  TError = ErrorType<unknown>
>(
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2LeaguesLeagueIdDivisions<
  TData = Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
  TError = ErrorType<unknown>
>(
  leagueId: number,
  params?: GetApiV2LeaguesLeagueIdDivisionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2LeaguesLeagueIdDivisions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2LeaguesLeagueIdDivisionsQueryOptions(
    leagueId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2Missingschoolapplications = (
  v2MissingSchoolApplicationsCommandsCreateCommand: V2MissingSchoolApplicationsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2MissingSchoolApplicationsCommandsCreateResponse>(
    {
      url: `/api/v2/missingschoolapplications`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2MissingSchoolApplicationsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2MissingschoolapplicationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>,
    TError,
    { data: V2MissingSchoolApplicationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>,
  TError,
  { data: V2MissingSchoolApplicationsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>,
    { data: V2MissingSchoolApplicationsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Missingschoolapplications(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2MissingschoolapplicationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>
>;
export type PostApiV2MissingschoolapplicationsMutationBody =
  V2MissingSchoolApplicationsCommandsCreateCommand;
export type PostApiV2MissingschoolapplicationsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2Missingschoolapplications = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>,
    TError,
    { data: V2MissingSchoolApplicationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Missingschoolapplications>>,
  TError,
  { data: V2MissingSchoolApplicationsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2MissingschoolapplicationsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2Missingteamapplications = (
  v2MissingTeamApplicationsCommandsCreateCommand: V2MissingTeamApplicationsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2MissingTeamApplicationsCommandsCreateResponse>(
    {
      url: `/api/v2/missingteamapplications`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2MissingTeamApplicationsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2MissingteamapplicationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Missingteamapplications>>,
    TError,
    { data: V2MissingTeamApplicationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Missingteamapplications>>,
  TError,
  { data: V2MissingTeamApplicationsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Missingteamapplications>>,
    { data: V2MissingTeamApplicationsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Missingteamapplications(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2MissingteamapplicationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Missingteamapplications>>
>;
export type PostApiV2MissingteamapplicationsMutationBody =
  V2MissingTeamApplicationsCommandsCreateCommand;
export type PostApiV2MissingteamapplicationsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2Missingteamapplications = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Missingteamapplications>>,
    TError,
    { data: V2MissingTeamApplicationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Missingteamapplications>>,
  TError,
  { data: V2MissingTeamApplicationsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2MissingteamapplicationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2Nationalities = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2NationalitiesQueriesListResponse>(
    { url: `/api/v2/nationalities`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2NationalitiesQueryKey = () => {
  return [`/api/v2/nationalities`] as const;
};

export const getGetApiV2NationalitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Nationalities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Nationalities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2NationalitiesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Nationalities>>
  > = ({ signal }) => getApiV2Nationalities(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Nationalities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2NationalitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Nationalities>>
>;
export type GetApiV2NationalitiesQueryError = ErrorType<unknown>;

export function useGetApiV2Nationalities<
  TData = Awaited<ReturnType<typeof getApiV2Nationalities>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Nationalities>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Nationalities>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Nationalities<
  TData = Awaited<ReturnType<typeof getApiV2Nationalities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Nationalities>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Nationalities>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Nationalities<
  TData = Awaited<ReturnType<typeof getApiV2Nationalities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Nationalities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Nationalities<
  TData = Awaited<ReturnType<typeof getApiV2Nationalities>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Nationalities>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2NationalitiesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteApiV2NotificationsNotificationId = (
  notificationId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/notifications/${notificationId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2NotificationsNotificationIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>,
    TError,
    { notificationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>,
  TError,
  { notificationId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>,
    { notificationId: number }
  > = (props) => {
    const { notificationId } = props ?? {};

    return deleteApiV2NotificationsNotificationId(
      notificationId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2NotificationsNotificationIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>
>;

export type DeleteApiV2NotificationsNotificationIdMutationError =
  ErrorType<unknown>;

export const useDeleteApiV2NotificationsNotificationId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>,
    TError,
    { notificationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2NotificationsNotificationId>>,
  TError,
  { notificationId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2NotificationsNotificationIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putApiV2NotificationsNotificationIdClick = (
  notificationId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/notifications/${notificationId}/click`, method: 'PUT' },
    options
  );
};

export const getPutApiV2NotificationsNotificationIdClickMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>,
    TError,
    { notificationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>,
  TError,
  { notificationId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>,
    { notificationId: number }
  > = (props) => {
    const { notificationId } = props ?? {};

    return putApiV2NotificationsNotificationIdClick(
      notificationId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2NotificationsNotificationIdClickMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>
  >;

export type PutApiV2NotificationsNotificationIdClickMutationError =
  ErrorType<unknown>;

export const usePutApiV2NotificationsNotificationIdClick = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>,
    TError,
    { notificationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2NotificationsNotificationIdClick>>,
  TError,
  { notificationId: number },
  TContext
> => {
  const mutationOptions =
    getPutApiV2NotificationsNotificationIdClickMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Notifyjobs = (
  v2NotifyJobsCommandsCreateCommand: V2NotifyJobsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2NotifyJobsCommandsCreateResponse>(
    {
      url: `/api/v2/notifyjobs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2NotifyJobsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2NotifyjobsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Notifyjobs>>,
    TError,
    { data: V2NotifyJobsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Notifyjobs>>,
  TError,
  { data: V2NotifyJobsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Notifyjobs>>,
    { data: V2NotifyJobsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Notifyjobs(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2NotifyjobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Notifyjobs>>
>;
export type PostApiV2NotifyjobsMutationBody = V2NotifyJobsCommandsCreateCommand;
export type PostApiV2NotifyjobsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Notifyjobs = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Notifyjobs>>,
    TError,
    { data: V2NotifyJobsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Notifyjobs>>,
  TError,
  { data: V2NotifyJobsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2NotifyjobsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Opportunitynodes = (
  params?: GetApiV2OpportunitynodesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2OpportunityNodesQueriesListResponse>(
    { url: `/api/v2/opportunitynodes`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2OpportunitynodesQueryKey = (
  params?: GetApiV2OpportunitynodesParams
) => {
  return [`/api/v2/opportunitynodes`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2OpportunitynodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2OpportunitynodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2OpportunitynodesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Opportunitynodes>>
  > = ({ signal }) => getApiV2Opportunitynodes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2OpportunitynodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Opportunitynodes>>
>;
export type GetApiV2OpportunitynodesQueryError = ErrorType<unknown>;

export function useGetApiV2Opportunitynodes<
  TData = Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2OpportunitynodesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Opportunitynodes<
  TData = Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2OpportunitynodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Opportunitynodes<
  TData = Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2OpportunitynodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Opportunitynodes<
  TData = Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2OpportunitynodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Opportunitynodes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2OpportunitynodesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2OpportunitynodesNodeIdProclubs = (
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2OpportunityNodesQueriesProClubsResponse>(
    {
      url: `/api/v2/opportunitynodes/${nodeId}/proclubs`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2OpportunitynodesNodeIdProclubsQueryKey = (
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams
) => {
  return [
    `/api/v2/opportunitynodes/${nodeId}/proclubs`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2OpportunitynodesNodeIdProclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
  TError = ErrorType<unknown>
>(
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2OpportunitynodesNodeIdProclubsQueryKey(nodeId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>
  > = ({ signal }) =>
    getApiV2OpportunitynodesNodeIdProclubs(
      nodeId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!nodeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2OpportunitynodesNodeIdProclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>
>;
export type GetApiV2OpportunitynodesNodeIdProclubsQueryError =
  ErrorType<unknown>;

export function useGetApiV2OpportunitynodesNodeIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
  TError = ErrorType<unknown>
>(
  nodeId: number,
  params: undefined | GetApiV2OpportunitynodesNodeIdProclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2OpportunitynodesNodeIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
  TError = ErrorType<unknown>
>(
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2OpportunitynodesNodeIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
  TError = ErrorType<unknown>
>(
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2OpportunitynodesNodeIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
  TError = ErrorType<unknown>
>(
  nodeId: number,
  params?: GetApiV2OpportunitynodesNodeIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2OpportunitynodesNodeIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2OpportunitynodesNodeIdProclubsQueryOptions(
    nodeId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postApiV2Parentinvitations = (
  v2ParentInvitationsCommandsCreateCommand: V2ParentInvitationsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/parentinvitations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ParentInvitationsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ParentinvitationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Parentinvitations>>,
    TError,
    { data: V2ParentInvitationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Parentinvitations>>,
  TError,
  { data: V2ParentInvitationsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Parentinvitations>>,
    { data: V2ParentInvitationsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Parentinvitations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ParentinvitationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Parentinvitations>>
>;
export type PostApiV2ParentinvitationsMutationBody =
  V2ParentInvitationsCommandsCreateCommand;
export type PostApiV2ParentinvitationsMutationError = ErrorType<unknown>;

export const usePostApiV2Parentinvitations = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Parentinvitations>>,
    TError,
    { data: V2ParentInvitationsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Parentinvitations>>,
  TError,
  { data: V2ParentInvitationsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2ParentinvitationsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2ParentinvitationsParentInvitationIdVersions = (
  parentInvitationId: number,
  v2ParentInvitationsCommandsVersionsCreateCommand: V2ParentInvitationsCommandsVersionsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/parentinvitations/${parentInvitationId}/versions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ParentInvitationsCommandsVersionsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ParentinvitationsParentInvitationIdVersionsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
      >,
      TError,
      {
        parentInvitationId: number;
        data: V2ParentInvitationsCommandsVersionsCreateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
    >,
    TError,
    {
      parentInvitationId: number;
      data: V2ParentInvitationsCommandsVersionsCreateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
      >,
      {
        parentInvitationId: number;
        data: V2ParentInvitationsCommandsVersionsCreateCommand;
      }
    > = (props) => {
      const { parentInvitationId, data } = props ?? {};

      return postApiV2ParentinvitationsParentInvitationIdVersions(
        parentInvitationId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2ParentinvitationsParentInvitationIdVersionsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
    >
  >;
export type PostApiV2ParentinvitationsParentInvitationIdVersionsMutationBody =
  V2ParentInvitationsCommandsVersionsCreateCommand;
export type PostApiV2ParentinvitationsParentInvitationIdVersionsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2ParentinvitationsParentInvitationIdVersions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
    >,
    TError,
    {
      parentInvitationId: number;
      data: V2ParentInvitationsCommandsVersionsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2ParentinvitationsParentInvitationIdVersions>
  >,
  TError,
  {
    parentInvitationId: number;
    data: V2ParentInvitationsCommandsVersionsCreateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ParentinvitationsParentInvitationIdVersionsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ParentinvitationsParentInvitationIdVersions = (
  parentInvitationId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ParentInvitationsQueriesVersionsListResponse>(
    {
      url: `/api/v2/parentinvitations/${parentInvitationId}/versions`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ParentinvitationsParentInvitationIdVersionsQueryKey = (
  parentInvitationId: number
) => {
  return [`/api/v2/parentinvitations/${parentInvitationId}/versions`] as const;
};

export const getGetApiV2ParentinvitationsParentInvitationIdVersionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
    >,
    TError = ErrorType<unknown>
  >(
    parentInvitationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ParentinvitationsParentInvitationIdVersions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ParentinvitationsParentInvitationIdVersionsQueryKey(
        parentInvitationId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
      >
    > = ({ signal }) =>
      getApiV2ParentinvitationsParentInvitationIdVersions(
        parentInvitationId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!parentInvitationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ParentinvitationsParentInvitationIdVersionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
    >
  >;
export type GetApiV2ParentinvitationsParentInvitationIdVersionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ParentinvitationsParentInvitationIdVersions<
  TData = Awaited<
    ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
  >,
  TError = ErrorType<unknown>
>(
  parentInvitationId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ParentinvitationsParentInvitationIdVersions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ParentinvitationsParentInvitationIdVersions<
  TData = Awaited<
    ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
  >,
  TError = ErrorType<unknown>
>(
  parentInvitationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ParentinvitationsParentInvitationIdVersions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ParentinvitationsParentInvitationIdVersions<
  TData = Awaited<
    ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
  >,
  TError = ErrorType<unknown>
>(
  parentInvitationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ParentinvitationsParentInvitationIdVersions<
  TData = Awaited<
    ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
  >,
  TError = ErrorType<unknown>
>(
  parentInvitationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ParentinvitationsParentInvitationIdVersions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ParentinvitationsParentInvitationIdVersionsQueryOptions(
      parentInvitationId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2Passportauthorities = (
  params?: GetApiV2PassportauthoritiesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PassportAuthoritiesQueriesListResponse>(
    { url: `/api/v2/passportauthorities`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PassportauthoritiesQueryKey = (
  params?: GetApiV2PassportauthoritiesParams
) => {
  return [`/api/v2/passportauthorities`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PassportauthoritiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PassportauthoritiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PassportauthoritiesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Passportauthorities>>
  > = ({ signal }) =>
    getApiV2Passportauthorities(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PassportauthoritiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Passportauthorities>>
>;
export type GetApiV2PassportauthoritiesQueryError = ErrorType<unknown>;

export function useGetApiV2Passportauthorities<
  TData = Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PassportauthoritiesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Passportauthorities<
  TData = Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PassportauthoritiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Passportauthorities<
  TData = Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PassportauthoritiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Passportauthorities<
  TData = Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PassportauthoritiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Passportauthorities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PassportauthoritiesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Performancelabreports = (
  v2PerformanceLabReportsCommandsCreateCommand: V2PerformanceLabReportsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/performancelabreports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PerformanceLabReportsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PerformancelabreportsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Performancelabreports>>,
    TError,
    { data: V2PerformanceLabReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Performancelabreports>>,
  TError,
  { data: V2PerformanceLabReportsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Performancelabreports>>,
    { data: V2PerformanceLabReportsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Performancelabreports(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PerformancelabreportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Performancelabreports>>
>;
export type PostApiV2PerformancelabreportsMutationBody =
  V2PerformanceLabReportsCommandsCreateCommand;
export type PostApiV2PerformancelabreportsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Performancelabreports = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Performancelabreports>>,
    TError,
    { data: V2PerformanceLabReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Performancelabreports>>,
  TError,
  { data: V2PerformanceLabReportsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PerformancelabreportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayerreportdraftsPlayerReportDraftId = (
  playerReportDraftId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayerReportDraftsQueriesDetailsResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdQueryKey = (
  playerReportDraftId: number
) => {
  return [`/api/v2/playerreportdrafts/${playerReportDraftId}`] as const;
};

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayerreportdraftsPlayerReportDraftIdQueryKey(
      playerReportDraftId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>>
  > = ({ signal }) =>
    getApiV2PlayerreportdraftsPlayerReportDraftId(
      playerReportDraftId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerReportDraftId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayerreportdraftsPlayerReportDraftIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>>
  >;
export type GetApiV2PlayerreportdraftsPlayerReportDraftIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayerreportdraftsPlayerReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayerreportdraftsPlayerReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayerreportdraftsPlayerReportDraftIdQueryOptions(
      playerReportDraftId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2PlayerreportdraftsPlayerReportDraftId = (
  playerReportDraftId: number,
  v2PlayerReportDraftsCommandsUpdateCommand: V2PlayerReportDraftsCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportDraftsCommandsUpdateCommand,
    },
    options
  );
};

export const getPatchApiV2PlayerreportdraftsPlayerReportDraftIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>>,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>
      >,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsUpdateCommand;
      }
    > = (props) => {
      const { playerReportDraftId, data } = props ?? {};

      return patchApiV2PlayerreportdraftsPlayerReportDraftId(
        playerReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>>
  >;
export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdMutationBody =
  V2PlayerReportDraftsCommandsUpdateCommand;
export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2PlayerreportdraftsPlayerReportDraftId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>>,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PlayerreportdraftsPlayerReportDraftId>>,
  TError,
  {
    playerReportDraftId: number;
    data: V2PlayerReportDraftsCommandsUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PlayerreportdraftsPlayerReportDraftIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated = (
  playerReportDraftId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/status/created`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreatedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
        >
      >,
      TError,
      { playerReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
      >
    >,
    TError,
    { playerReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
        >
      >,
      { playerReportDraftId: number }
    > = (props) => {
      const { playerReportDraftId } = props ?? {};

      return patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated(
        playerReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreatedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
      >
    >
  >;

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreatedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
      >
    >,
    TError,
    { playerReportDraftId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreated
    >
  >,
  TError,
  { playerReportDraftId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCreatedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted = (
  playerReportDraftId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/status/completed`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompletedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
        >
      >,
      TError,
      { playerReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
      >
    >,
    TError,
    { playerReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
        >
      >,
      { playerReportDraftId: number }
    > = (props) => {
      const { playerReportDraftId } = props ?? {};

      return patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted(
        playerReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompletedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
      >
    >
  >;

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompletedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
        >
      >,
      TError,
      { playerReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompleted
      >
    >,
    TError,
    { playerReportDraftId: number },
    TContext
  > => {
    const mutationOptions =
      getPatchApiV2PlayerreportdraftsPlayerReportDraftIdStatusCompletedMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2PlayerreportdraftsPlayerReportDraftIdResponses = (
  playerReportDraftId: number,
  v2PlayerReportDraftsCommandsResponsesUpdateCommand: V2PlayerReportDraftsCommandsResponsesUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/responses`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportDraftsCommandsResponsesUpdateCommand,
    },
    options
  );
};

export const getPutApiV2PlayerreportdraftsPlayerReportDraftIdResponsesMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsResponsesUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses>
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsResponsesUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses
        >
      >,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsResponsesUpdateCommand;
      }
    > = (props) => {
      const { playerReportDraftId, data } = props ?? {};

      return putApiV2PlayerreportdraftsPlayerReportDraftIdResponses(
        playerReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2PlayerreportdraftsPlayerReportDraftIdResponsesMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses>
    >
  >;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdResponsesMutationBody =
  V2PlayerReportDraftsCommandsResponsesUpdateCommand;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdResponsesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2PlayerreportdraftsPlayerReportDraftIdResponses = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses>
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsResponsesUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2PlayerreportdraftsPlayerReportDraftIdResponses>
  >,
  TError,
  {
    playerReportDraftId: number;
    data: V2PlayerReportDraftsCommandsResponsesUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayerreportdraftsPlayerReportDraftIdResponsesMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Playerreportdrafts = (
  v2PlayerReportDraftsCommandsCreateCommand: V2PlayerReportDraftsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/playerreportdrafts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportDraftsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayerreportdraftsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>,
    TError,
    { data: V2PlayerReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>,
  TError,
  { data: V2PlayerReportDraftsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>,
    { data: V2PlayerReportDraftsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Playerreportdrafts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayerreportdraftsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>
>;
export type PostApiV2PlayerreportdraftsMutationBody =
  V2PlayerReportDraftsCommandsCreateCommand;
export type PostApiV2PlayerreportdraftsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Playerreportdrafts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>,
    TError,
    { data: V2PlayerReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Playerreportdrafts>>,
  TError,
  { data: V2PlayerReportDraftsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayerreportdraftsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers =
  (
    playerReportDraftId: number,
    v2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/playerreportdrafts/${playerReportDraftId}/participants/players`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: v2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand,
      },
      options
    );
  };

export const getPutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayersMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
        >
      >,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
      }
    > = (props) => {
      const { playerReportDraftId, data } = props ?? {};

      return putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers(
        playerReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayersMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
      >
    >
  >;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayersMutationBody =
  V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayersMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayers
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsParticipantsPlayersUpdateCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPutApiV2PlayerreportdraftsPlayerReportDraftIdParticipantsPlayersMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayerreportdraftsPlayerReportDraftIdSummary = (
  playerReportDraftId: number,
  params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayerReportDraftsQueriesSummaryResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryKey = (
  playerReportDraftId: number,
  params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams
) => {
  return [
    `/api/v2/playerreportdrafts/${playerReportDraftId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
    >,
    TError = ErrorType<unknown>
  >(
    playerReportDraftId: number,
    params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryKey(
        playerReportDraftId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
      >
    > = ({ signal }) =>
      getApiV2PlayerreportdraftsPlayerReportDraftIdSummary(
        playerReportDraftId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!playerReportDraftId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
    >
  >;
export type GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  params:
    | undefined
    | GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  params?: GetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayerreportdraftsPlayerReportDraftIdSummaryQueryOptions(
      playerReportDraftId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants =
  (
    playerReportDraftId: number,
    playerReportDraftNoteId: number,
    v2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/${playerReportDraftNoteId}/participants`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: v2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand,
      },
      options
    );
  };

export const getPutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipantsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      playerReportDraftNoteId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
        >
      >,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
      }
    > = (props) => {
      const { playerReportDraftId, playerReportDraftNoteId, data } =
        props ?? {};

      return putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants(
        playerReportDraftId,
        playerReportDraftNoteId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipantsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
      >
    >
  >;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipantsMutationBody =
  V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
export type PutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipantsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof putApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipants
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      playerReportDraftNoteId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesParticipantsUpdateCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPutApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdParticipantsMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId =
  (
    playerReportDraftId: number,
    playerReportDraftNoteId: number,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2PlayerReportDraftsQueriesPlayerReportDraftNotesDetailsResponse>(
      {
        url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/${playerReportDraftNoteId}`,
        method: 'GET',
        signal,
      },
      options
    );
  };

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryKey =
  (playerReportDraftId: number, playerReportDraftNoteId: number) => {
    return [
      `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/${playerReportDraftNoteId}`,
    ] as const;
  };

export const getGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >,
    TError = ErrorType<unknown>
  >(
    playerReportDraftId: number,
    playerReportDraftNoteId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryKey(
        playerReportDraftId,
        playerReportDraftNoteId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >
    > = ({ signal }) =>
      getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId(
        playerReportDraftId,
        playerReportDraftNoteId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerReportDraftId && playerReportDraftNoteId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >
  >;
export type GetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
    >
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  playerReportDraftNoteId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
    >
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  playerReportDraftNoteId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
    >
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  playerReportDraftNoteId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
    >
  >,
  TError = ErrorType<unknown>
>(
  playerReportDraftId: number,
  playerReportDraftNoteId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdQueryOptions(
      playerReportDraftId,
      playerReportDraftNoteId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId =
  (
    playerReportDraftId: number,
    playerReportDraftNoteId: number,
    v2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/${playerReportDraftNoteId}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: v2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand,
      },
      options
    );
  };

export const getPatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      playerReportDraftNoteId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
      }
    > = (props) => {
      const { playerReportDraftId, playerReportDraftNoteId, data } =
        props ?? {};

      return patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId(
        playerReportDraftId,
        playerReportDraftNoteId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >
  >;
export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationBody =
  V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
export type PatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        playerReportDraftNoteId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      playerReportDraftNoteId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesUpdateCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPatchApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId =
  (
    playerReportDraftId: number,
    playerReportDraftNoteId: number,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesNoContentResponse>(
      {
        url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/${playerReportDraftNoteId}`,
        method: 'DELETE',
      },
      options
    );
  };

export const getDeleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      TError,
      { playerReportDraftId: number; playerReportDraftNoteId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >,
    TError,
    { playerReportDraftId: number; playerReportDraftNoteId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      { playerReportDraftId: number; playerReportDraftNoteId: number }
    > = (props) => {
      const { playerReportDraftId, playerReportDraftNoteId } = props ?? {};

      return deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId(
        playerReportDraftId,
        playerReportDraftNoteId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >
  >;

export type DeleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
        >
      >,
      TError,
      { playerReportDraftId: number; playerReportDraftNoteId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteId
      >
    >,
    TError,
    { playerReportDraftId: number; playerReportDraftNoteId: number },
    TContext
  > => {
    const mutationOptions =
      getDeleteApiV2PlayerreportdraftsPlayerReportDraftIdNotesPlayerReportDraftNoteIdMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText = (
  playerReportDraftId: number,
  v2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/text`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand,
    },
    options
  );
};

export const getPostApiV2PlayerreportdraftsPlayerReportDraftIdNotesTextMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText>
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText
        >
      >,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
      }
    > = (props) => {
      const { playerReportDraftId, data } = props ?? {};

      return postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText(
        playerReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesTextMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText>
    >
  >;
export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesTextMutationBody =
  V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesTextMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2PlayerreportdraftsPlayerReportDraftIdNotesText = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText>
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesText>
  >,
  TError,
  {
    playerReportDraftId: number;
    data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddTextCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayerreportdraftsPlayerReportDraftIdNotesTextMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch = (
  playerReportDraftId: number,
  v2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/playerreportdrafts/${playerReportDraftId}/notes/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand,
    },
    options
  );
};

export const getPostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatchMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch
        >
      >,
      TError,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch
        >
      >,
      {
        playerReportDraftId: number;
        data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
      }
    > = (props) => {
      const { playerReportDraftId, data } = props ?? {};

      return postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch(
        playerReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch
      >
    >
  >;
export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatchMutationBody =
  V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
export type PostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch
      >
    >,
    TError,
    {
      playerReportDraftId: number;
      data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatch>
  >,
  TError,
  {
    playerReportDraftId: number;
    data: V2PlayerReportDraftsCommandsPlayerReportDraftNotesAddBatchCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayerreportdraftsPlayerReportDraftIdNotesBatchMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2PlayerreportsPlayerReportId = (
  playerReportId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/playerreports/${playerReportId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2PlayerreportsPlayerReportIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>,
    TError,
    { playerReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>,
  TError,
  { playerReportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>,
    { playerReportId: number }
  > = (props) => {
    const { playerReportId } = props ?? {};

    return deleteApiV2PlayerreportsPlayerReportId(
      playerReportId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PlayerreportsPlayerReportIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>
>;

export type DeleteApiV2PlayerreportsPlayerReportIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2PlayerreportsPlayerReportId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>,
    TError,
    { playerReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PlayerreportsPlayerReportId>>,
  TError,
  { playerReportId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayerreportsPlayerReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayerreportsPlayerReportIdSummary = (
  playerReportId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayerReportsQueriesSummaryResponse>(
    {
      url: `/api/v2/playerreports/${playerReportId}/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayerreportsPlayerReportIdSummaryQueryKey = (
  playerReportId: number
) => {
  return [`/api/v2/playerreports/${playerReportId}/summary`] as const;
};

export const getGetApiV2PlayerreportsPlayerReportIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayerreportsPlayerReportIdSummaryQueryKey(playerReportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>
  > = ({ signal }) =>
    getApiV2PlayerreportsPlayerReportIdSummary(
      playerReportId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerReportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayerreportsPlayerReportIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>
>;
export type GetApiV2PlayerreportsPlayerReportIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayerreportsPlayerReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportsPlayerReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportsPlayerReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayerreportsPlayerReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsPlayerReportIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayerreportsPlayerReportIdSummaryQueryOptions(
      playerReportId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Playerreports = (
  v2PlayerReportsCommandsCreateCommand: V2PlayerReportsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/playerreports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayerReportsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayerreportsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Playerreports>>,
    TError,
    { data: V2PlayerReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Playerreports>>,
  TError,
  { data: V2PlayerReportsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Playerreports>>,
    { data: V2PlayerReportsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Playerreports(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayerreportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Playerreports>>
>;
export type PostApiV2PlayerreportsMutationBody =
  V2PlayerReportsCommandsCreateCommand;
export type PostApiV2PlayerreportsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Playerreports = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Playerreports>>,
    TError,
    { data: V2PlayerReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Playerreports>>,
  TError,
  { data: V2PlayerReportsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PlayerreportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2Playerreports = (
  params?: GetApiV2PlayerreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayerReportsQueriesListResponse>(
    { url: `/api/v2/playerreports`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayerreportsQueryKey = (
  params?: GetApiV2PlayerreportsParams
) => {
  return [`/api/v2/playerreports`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PlayerreportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Playerreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Playerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayerreportsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Playerreports>>
  > = ({ signal }) => getApiV2Playerreports(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Playerreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayerreportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Playerreports>>
>;
export type GetApiV2PlayerreportsQueryError = ErrorType<unknown>;

export function useGetApiV2Playerreports<
  TData = Awaited<ReturnType<typeof getApiV2Playerreports>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PlayerreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Playerreports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Playerreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Playerreports<
  TData = Awaited<ReturnType<typeof getApiV2Playerreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Playerreports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Playerreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Playerreports<
  TData = Awaited<ReturnType<typeof getApiV2Playerreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Playerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2Playerreports<
  TData = Awaited<ReturnType<typeof getApiV2Playerreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Playerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayerreportsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayerreportsCount = (
  params?: GetApiV2PlayerreportsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayerReportsQueriesCountResponse>(
    { url: `/api/v2/playerreports/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayerreportsCountQueryKey = (
  params?: GetApiV2PlayerreportsCountParams
) => {
  return [`/api/v2/playerreports/count`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PlayerreportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayerreportsCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>
  > = ({ signal }) =>
    getApiV2PlayerreportsCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayerreportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>
>;
export type GetApiV2PlayerreportsCountQueryError = ErrorType<unknown>;

export function useGetApiV2PlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PlayerreportsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayerreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayerreportsCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary This should only be used for testing purposes in v3.
 */
export const postApiV2PlayersLogin = (
  v3PlayersCommandsLoginCommand: V3PlayersCommandsLoginCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3PlayersCommandsLoginResponse>(
    {
      url: `/api/v2/players/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsLoginCommand,
    },
    options
  );
};

export const getPostApiV2PlayersLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersLogin>>,
    TError,
    { data: V3PlayersCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersLogin>>,
  TError,
  { data: V3PlayersCommandsLoginCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersLogin>>,
    { data: V3PlayersCommandsLoginCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2PlayersLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersLogin>>
>;
export type PostApiV2PlayersLoginMutationBody = V3PlayersCommandsLoginCommand;
export type PostApiV2PlayersLoginMutationError = ErrorType<unknown>;

/**
 * @summary This should only be used for testing purposes in v3.
 */
export const usePostApiV2PlayersLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersLogin>>,
    TError,
    { data: V3PlayersCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersLogin>>,
  TError,
  { data: V3PlayersCommandsLoginCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PlayersLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator,Player)
 */
export const deleteApiV2PlayersPlayerId = (
  playerId: number,
  v3PlayersCommandsDeleteCommand: V3PlayersCommandsDeleteCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsDeleteCommand,
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>,
    TError,
    { playerId: number; data: V3PlayersCommandsDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>,
  TError,
  { playerId: number; data: V3PlayersCommandsDeleteCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>,
    { playerId: number; data: V3PlayersCommandsDeleteCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return deleteApiV2PlayersPlayerId(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PlayersPlayerIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>
>;
export type DeleteApiV2PlayersPlayerIdMutationBody =
  V3PlayersCommandsDeleteCommand;
export type DeleteApiV2PlayersPlayerIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator,Player)
 */
export const useDeleteApiV2PlayersPlayerId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>,
    TError,
    { playerId: number; data: V3PlayersCommandsDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerId>>,
  TError,
  { playerId: number; data: V3PlayersCommandsDeleteCommand },
  TContext
> => {
  const mutationOptions = getDeleteApiV2PlayersPlayerIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const patchApiV2PlayersPlayerId = (
  playerId: number,
  v2PlayersCommandsUpdateCommand: V2PlayersCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsUpdateCommand,
    },
    options
  );
};

export const getPatchApiV2PlayersPlayerIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>,
    TError,
    { playerId: number; data: V2PlayersCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>,
  TError,
  { playerId: number; data: V2PlayersCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>,
    { playerId: number; data: V2PlayersCommandsUpdateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return patchApiV2PlayersPlayerId(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2PlayersPlayerIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>
>;
export type PatchApiV2PlayersPlayerIdMutationBody =
  V2PlayersCommandsUpdateCommand;
export type PatchApiV2PlayersPlayerIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePatchApiV2PlayersPlayerId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>,
    TError,
    { playerId: number; data: V2PlayersCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerId>>,
  TError,
  { playerId: number; data: V2PlayersCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions = getPatchApiV2PlayersPlayerIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdGuardianconsent = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3PlayersQueriesGuardianConsentResponse>(
    {
      url: `/api/v2/players/${playerId}/guardianconsent`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdGuardianconsentQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/guardianconsent`] as const;
};

export const getGetApiV2PlayersPlayerIdGuardianconsentQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdGuardianconsentQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdGuardianconsent(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdGuardianconsentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>
>;
export type GetApiV2PlayersPlayerIdGuardianconsentQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdGuardianconsent<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardianconsent<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardianconsent<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdGuardianconsent<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardianconsent>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdGuardianconsentQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdGuardianconsent = (
  playerId: number,
  v3PlayersCommandsGuardianConsentSendCommand: V3PlayersCommandsGuardianConsentSendCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/guardianconsent`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsGuardianConsentSendCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdGuardianconsentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>,
    TError,
    { playerId: number; data: V3PlayersCommandsGuardianConsentSendCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>,
  TError,
  { playerId: number; data: V3PlayersCommandsGuardianConsentSendCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>,
    { playerId: number; data: V3PlayersCommandsGuardianConsentSendCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdGuardianconsent(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdGuardianconsentMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>
>;
export type PostApiV2PlayersPlayerIdGuardianconsentMutationBody =
  V3PlayersCommandsGuardianConsentSendCommand;
export type PostApiV2PlayersPlayerIdGuardianconsentMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdGuardianconsent = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>,
    TError,
    { playerId: number; data: V3PlayersCommandsGuardianConsentSendCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianconsent>>,
  TError,
  { playerId: number; data: V3PlayersCommandsGuardianConsentSendCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdGuardianconsentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdGuardianconsent = (
  playerId: number,
  v3PlayersCommandsGuardianConsentUpdateCommand: V3PlayersCommandsGuardianConsentUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/guardianconsent`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsGuardianConsentUpdateCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdGuardianconsentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>,
    TError,
    { playerId: number; data: V3PlayersCommandsGuardianConsentUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>,
  TError,
  { playerId: number; data: V3PlayersCommandsGuardianConsentUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>,
    { playerId: number; data: V3PlayersCommandsGuardianConsentUpdateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return putApiV2PlayersPlayerIdGuardianconsent(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdGuardianconsentMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>
>;
export type PutApiV2PlayersPlayerIdGuardianconsentMutationBody =
  V3PlayersCommandsGuardianConsentUpdateCommand;
export type PutApiV2PlayersPlayerIdGuardianconsentMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdGuardianconsent = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>,
    TError,
    { playerId: number; data: V3PlayersCommandsGuardianConsentUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdGuardianconsent>>,
  TError,
  { playerId: number; data: V3PlayersCommandsGuardianConsentUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdGuardianconsentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdNotificationsStats = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3PlayersQueriesNotificationsStatsResponse>(
    {
      url: `/api/v2/players/${playerId}/notifications/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdNotificationsStatsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/notifications/stats`] as const;
};

export const getGetApiV2PlayersPlayerIdNotificationsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdNotificationsStatsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdNotificationsStats(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdNotificationsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>
>;
export type GetApiV2PlayersPlayerIdNotificationsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdNotificationsStatsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdNotificationsSeen = (
  playerId: number,
  params: PutApiV2PlayersPlayerIdNotificationsSeenParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/notifications/seen`,
      method: 'PUT',
      params,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdNotificationsSeenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>,
    TError,
    {
      playerId: number;
      params: PutApiV2PlayersPlayerIdNotificationsSeenParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>,
  TError,
  { playerId: number; params: PutApiV2PlayersPlayerIdNotificationsSeenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>,
    { playerId: number; params: PutApiV2PlayersPlayerIdNotificationsSeenParams }
  > = (props) => {
    const { playerId, params } = props ?? {};

    return putApiV2PlayersPlayerIdNotificationsSeen(
      playerId,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdNotificationsSeenMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>
  >;

export type PutApiV2PlayersPlayerIdNotificationsSeenMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdNotificationsSeen = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>,
    TError,
    {
      playerId: number;
      params: PutApiV2PlayersPlayerIdNotificationsSeenParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdNotificationsSeen>>,
  TError,
  { playerId: number; params: PutApiV2PlayersPlayerIdNotificationsSeenParams },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdNotificationsSeenMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdAffiliations = (
  playerId: number,
  v3PlayersCommandsPlayerAffiliationsCreateCommand: V3PlayersCommandsPlayerAffiliationsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/affiliations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsPlayerAffiliationsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdAffiliationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>,
    TError,
    {
      playerId: number;
      data: V3PlayersCommandsPlayerAffiliationsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>,
  TError,
  { playerId: number; data: V3PlayersCommandsPlayerAffiliationsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>,
    { playerId: number; data: V3PlayersCommandsPlayerAffiliationsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdAffiliations(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdAffiliationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>
>;
export type PostApiV2PlayersPlayerIdAffiliationsMutationBody =
  V3PlayersCommandsPlayerAffiliationsCreateCommand;
export type PostApiV2PlayersPlayerIdAffiliationsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdAffiliations = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>,
    TError,
    {
      playerId: number;
      data: V3PlayersCommandsPlayerAffiliationsCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAffiliations>>,
  TError,
  { playerId: number; data: V3PlayersCommandsPlayerAffiliationsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdAffiliationsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdAffiliations = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3PlayersQueriesPlayerAffiliationsListResponse>(
    { url: `/api/v2/players/${playerId}/affiliations`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdAffiliationsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/affiliations`] as const;
};

export const getGetApiV2PlayersPlayerIdAffiliationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdAffiliationsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdAffiliations(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdAffiliationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>
>;
export type GetApiV2PlayersPlayerIdAffiliationsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdAffiliations<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAffiliations<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAffiliations<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdAffiliations<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAffiliations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdAffiliationsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdAffiliationsAffiliationId = (
  playerId: number,
  affiliationId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}/affiliations/${affiliationId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdAffiliationsAffiliationIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
      >,
      TError,
      { playerId: number; affiliationId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
    >,
    TError,
    { playerId: number; affiliationId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
      >,
      { playerId: number; affiliationId: number }
    > = (props) => {
      const { playerId, affiliationId } = props ?? {};

      return deleteApiV2PlayersPlayerIdAffiliationsAffiliationId(
        playerId,
        affiliationId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdAffiliationsAffiliationIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
    >
  >;

export type DeleteApiV2PlayersPlayerIdAffiliationsAffiliationIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdAffiliationsAffiliationId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
    >,
    TError,
    { playerId: number; affiliationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2PlayersPlayerIdAffiliationsAffiliationId>
  >,
  TError,
  { playerId: number; affiliationId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdAffiliationsAffiliationIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdPlayerteams = (
  playerId: number,
  v3PlayersCommandsPlayerTeamsCreateCommand: V3PlayersCommandsPlayerTeamsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/playerteams`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsPlayerTeamsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdPlayerteamsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>,
    TError,
    { playerId: number; data: V3PlayersCommandsPlayerTeamsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>,
  TError,
  { playerId: number; data: V3PlayersCommandsPlayerTeamsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>,
    { playerId: number; data: V3PlayersCommandsPlayerTeamsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdPlayerteams(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdPlayerteamsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>
>;
export type PostApiV2PlayersPlayerIdPlayerteamsMutationBody =
  V3PlayersCommandsPlayerTeamsCreateCommand;
export type PostApiV2PlayersPlayerIdPlayerteamsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdPlayerteams = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>,
    TError,
    { playerId: number; data: V3PlayersCommandsPlayerTeamsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerteams>>,
  TError,
  { playerId: number; data: V3PlayersCommandsPlayerTeamsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdPlayerteamsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayersPlayerIdPlayerteams = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3PlayersQueriesPlayerTeamsListResponse>(
    { url: `/api/v2/players/${playerId}/playerteams`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPlayerteamsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/playerteams`] as const;
};

export const getGetApiV2PlayersPlayerIdPlayerteamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPlayerteamsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPlayerteams(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPlayerteamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>
>;
export type GetApiV2PlayersPlayerIdPlayerteamsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPlayerteams<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPlayerteams<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPlayerteams<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayersPlayerIdPlayerteams<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerteams>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdPlayerteamsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId = (
  playerId: number,
  playerTeamId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}/playerteams/${playerTeamId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
      >,
      TError,
      { playerId: number; playerTeamId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
    >,
    TError,
    { playerId: number; playerTeamId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
      >,
      { playerId: number; playerTeamId: number }
    > = (props) => {
      const { playerId, playerTeamId } = props ?? {};

      return deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId(
        playerId,
        playerTeamId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
    >
  >;

export type DeleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
    >,
    TError,
    { playerId: number; playerTeamId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>>,
  TError,
  { playerId: number; playerTeamId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId = (
  playerId: number,
  playerTeamId: number,
  v3PlayersCommandsPlayerTeamsUpdateCommand: V3PlayersCommandsPlayerTeamsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/playerteams/${playerTeamId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v3PlayersCommandsPlayerTeamsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
      >,
      TError,
      {
        playerId: number;
        playerTeamId: number;
        data: V3PlayersCommandsPlayerTeamsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>>,
    TError,
    {
      playerId: number;
      playerTeamId: number;
      data: V3PlayersCommandsPlayerTeamsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>
      >,
      {
        playerId: number;
        playerTeamId: number;
        data: V3PlayersCommandsPlayerTeamsUpdateCommand;
      }
    > = (props) => {
      const { playerId, playerTeamId, data } = props ?? {};

      return putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId(
        playerId,
        playerTeamId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>>
  >;
export type PutApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationBody =
  V3PlayersCommandsPlayerTeamsUpdateCommand;
export type PutApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdPlayerteamsPlayerTeamId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>>,
    TError,
    {
      playerId: number;
      playerTeamId: number;
      data: V3PlayersCommandsPlayerTeamsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdPlayerteamsPlayerTeamId>>,
  TError,
  {
    playerId: number;
    playerTeamId: number;
    data: V3PlayersCommandsPlayerTeamsUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdPlayerteamsPlayerTeamIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2PlayersAdvancedsearch = (
  v2PlayersQueriesAdvancedSearchSearchQuery: V2PlayersQueriesAdvancedSearchSearchQuery,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PlayersQueriesAdvancedSearchSearchResponse>(
    {
      url: `/api/v2/players/advancedsearch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersQueriesAdvancedSearchSearchQuery,
    },
    options
  );
};

export const getPostApiV2PlayersAdvancedsearchMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>,
    TError,
    { data: V2PlayersQueriesAdvancedSearchSearchQuery },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>,
  TError,
  { data: V2PlayersQueriesAdvancedSearchSearchQuery },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>,
    { data: V2PlayersQueriesAdvancedSearchSearchQuery }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2PlayersAdvancedsearch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersAdvancedsearchMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>
>;
export type PostApiV2PlayersAdvancedsearchMutationBody =
  V2PlayersQueriesAdvancedSearchSearchQuery;
export type PostApiV2PlayersAdvancedsearchMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2PlayersAdvancedsearch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>,
    TError,
    { data: V2PlayersQueriesAdvancedSearchSearchQuery },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearch>>,
  TError,
  { data: V2PlayersQueriesAdvancedSearchSearchQuery },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersAdvancedsearchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2PlayersAdvancedsearchCount = (
  v2PlayersQueriesAdvancedSearchCountQuery: V2PlayersQueriesAdvancedSearchCountQuery,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PlayersQueriesAdvancedSearchCountResponse>(
    {
      url: `/api/v2/players/advancedsearch/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersQueriesAdvancedSearchCountQuery,
    },
    options
  );
};

export const getPostApiV2PlayersAdvancedsearchCountMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>,
    TError,
    { data: V2PlayersQueriesAdvancedSearchCountQuery },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>,
  TError,
  { data: V2PlayersQueriesAdvancedSearchCountQuery },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>,
    { data: V2PlayersQueriesAdvancedSearchCountQuery }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2PlayersAdvancedsearchCount(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersAdvancedsearchCountMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>
>;
export type PostApiV2PlayersAdvancedsearchCountMutationBody =
  V2PlayersQueriesAdvancedSearchCountQuery;
export type PostApiV2PlayersAdvancedsearchCountMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2PlayersAdvancedsearchCount = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>,
    TError,
    { data: V2PlayersQueriesAdvancedSearchCountQuery },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersAdvancedsearchCount>>,
  TError,
  { data: V2PlayersQueriesAdvancedSearchCountQuery },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersAdvancedsearchCountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Coach,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayersAdvancedsearchFiltersDateofbirth = (
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesAdvancedSearchFiltersDateOfBirthResponse>(
    {
      url: `/api/v2/players/advancedsearch/filters/dateofbirth`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryKey = (
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams
) => {
  return [
    `/api/v2/players/advancedsearch/filters/dateofbirth`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
  >,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>>
  > = ({ signal }) =>
    getApiV2PlayersAdvancedsearchFiltersDateofbirth(
      params,
      requestOptions,
      signal
    );

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>>
  >;
export type GetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersAdvancedsearchFiltersDateofbirth<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
  >,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersAdvancedsearchFiltersDateofbirth<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
  >,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersAdvancedsearchFiltersDateofbirth<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
  >,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayersAdvancedsearchFiltersDateofbirth<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
  >,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersAdvancedsearchFiltersDateofbirthParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersAdvancedsearchFiltersDateofbirth>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersAdvancedsearchFiltersDateofbirthQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayersPlayerIdAmateurfixtures = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesAmateurFixturesListResponse>(
    {
      url: `/api/v2/players/${playerId}/amateurfixtures`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdAmateurfixturesQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams
) => {
  return [
    `/api/v2/players/${playerId}/amateurfixtures`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdAmateurfixturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdAmateurfixturesQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdAmateurfixtures(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdAmateurfixturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>
>;
export type GetApiV2PlayersPlayerIdAmateurfixturesQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdAmateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAmateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAmateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayersPlayerIdAmateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdAmateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAmateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdAmateurfixturesQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdAmateurfixtures = (
  playerId: number,
  v2PlayersCommandsAmateurFixturesCreateCommand: V2PlayersCommandsAmateurFixturesCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/players/${playerId}/amateurfixtures`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsAmateurFixturesCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdAmateurfixturesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>,
    TError,
    { playerId: number; data: V2PlayersCommandsAmateurFixturesCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>,
  TError,
  { playerId: number; data: V2PlayersCommandsAmateurFixturesCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>,
    { playerId: number; data: V2PlayersCommandsAmateurFixturesCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdAmateurfixtures(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdAmateurfixturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>
>;
export type PostApiV2PlayersPlayerIdAmateurfixturesMutationBody =
  V2PlayersCommandsAmateurFixturesCreateCommand;
export type PostApiV2PlayersPlayerIdAmateurfixturesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdAmateurfixtures = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>,
    TError,
    { playerId: number; data: V2PlayersCommandsAmateurFixturesCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdAmateurfixtures>>,
  TError,
  { playerId: number; data: V2PlayersCommandsAmateurFixturesCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdAmateurfixturesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId =
  (
    playerId: number,
    coachingContentId: number,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesNoContentResponse>(
      {
        url: `/api/v2/players/${playerId}/bookmarks/coachingcontents/${coachingContentId}`,
        method: 'DELETE',
      },
      options
    );
  };

export const getDeleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
        >
      >,
      TError,
      { playerId: number; coachingContentId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
      >
    >,
    TError,
    { playerId: number; coachingContentId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
        >
      >,
      { playerId: number; coachingContentId: number }
    > = (props) => {
      const { playerId, coachingContentId } = props ?? {};

      return deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId(
        playerId,
        coachingContentId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
      >
    >
  >;

export type DeleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
        >
      >,
      TError,
      { playerId: number; coachingContentId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentId
      >
    >,
    TError,
    { playerId: number; coachingContentId: number },
    TContext
  > => {
    const mutationOptions =
      getDeleteApiV2PlayersPlayerIdBookmarksCoachingcontentsCoachingContentIdMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdBookmarksCoachingcontents = (
  playerId: number,
  v2PlayersCommandsBookmarksCreateCommand: V2PlayersCommandsBookmarksCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/bookmarks/coachingcontents`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsBookmarksCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdBookmarksCoachingcontentsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>
      >,
      TError,
      { playerId: number; data: V2PlayersCommandsBookmarksCreateCommand },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>
    >,
    TError,
    { playerId: number; data: V2PlayersCommandsBookmarksCreateCommand },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>
      >,
      { playerId: number; data: V2PlayersCommandsBookmarksCreateCommand }
    > = (props) => {
      const { playerId, data } = props ?? {};

      return postApiV2PlayersPlayerIdBookmarksCoachingcontents(
        playerId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayersPlayerIdBookmarksCoachingcontentsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>
    >
  >;
export type PostApiV2PlayersPlayerIdBookmarksCoachingcontentsMutationBody =
  V2PlayersCommandsBookmarksCreateCommand;
export type PostApiV2PlayersPlayerIdBookmarksCoachingcontentsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdBookmarksCoachingcontents = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>
    >,
    TError,
    { playerId: number; data: V2PlayersCommandsBookmarksCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdBookmarksCoachingcontents>>,
  TError,
  { playerId: number; data: V2PlayersCommandsBookmarksCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdBookmarksCoachingcontentsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

export const postApiV2PlayersRegister = (
  v2PlayersCommandsRegisterCommand: V2PlayersCommandsRegisterCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PlayersCommandsRegisterResponse>(
    {
      url: `/api/v2/players/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsRegisterCommand,
    },
    options
  );
};

export const getPostApiV2PlayersRegisterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersRegister>>,
    TError,
    { data: V2PlayersCommandsRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersRegister>>,
  TError,
  { data: V2PlayersCommandsRegisterCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersRegister>>,
    { data: V2PlayersCommandsRegisterCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2PlayersRegister(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersRegister>>
>;
export type PostApiV2PlayersRegisterMutationBody =
  V2PlayersCommandsRegisterCommand;
export type PostApiV2PlayersRegisterMutationError = ErrorType<unknown>;

export const usePostApiV2PlayersRegister = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersRegister>>,
    TError,
    { data: V2PlayersCommandsRegisterCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersRegister>>,
  TError,
  { data: V2PlayersCommandsRegisterCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PlayersRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2PlayersSearch = (
  params?: GetApiV2PlayersSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesSearchesListResponse>(
    { url: `/api/v2/players/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayersSearchQueryKey = (
  params?: GetApiV2PlayersSearchParams
) => {
  return [`/api/v2/players/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PlayersSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersSearch>>
  > = ({ signal }) => getApiV2PlayersSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersSearch>>
>;
export type GetApiV2PlayersSearchQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersSearch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PlayersSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSearch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSearch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersSearch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersSearchCount = (
  params?: GetApiV2PlayersSearchCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesSearchesCountResponse>(
    { url: `/api/v2/players/search/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayersSearchCountQueryKey = (
  params?: GetApiV2PlayersSearchCountParams
) => {
  return [`/api/v2/players/search/count`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PlayersSearchCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersSearchCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>
  > = ({ signal }) =>
    getApiV2PlayersSearchCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersSearchCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>
>;
export type GetApiV2PlayersSearchCountQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PlayersSearchCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PlayersSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersSearchCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersPlayerIdCanplayforcountries = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerCountriesListResponse>(
    {
      url: `/api/v2/players/${playerId}/canplayforcountries`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdCanplayforcountriesQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/canplayforcountries`] as const;
};

export const getGetApiV2PlayersPlayerIdCanplayforcountriesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdCanplayforcountriesQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdCanplayforcountries(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdCanplayforcountriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>
>;
export type GetApiV2PlayersPlayerIdCanplayforcountriesQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdCanplayforcountries<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdCanplayforcountries<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdCanplayforcountries<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersPlayerIdCanplayforcountries<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdCanplayforcountries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdCanplayforcountriesQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersPlayerIdPositions = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPositionsListResponse>(
    { url: `/api/v2/players/${playerId}/positions`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPositionsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/positions`] as const;
};

export const getGetApiV2PlayersPlayerIdPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPositionsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPositions(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPositionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>
>;
export type GetApiV2PlayersPlayerIdPositionsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPositions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPositions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPositions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersPlayerIdPositions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPositions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdPositionsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdDeleterequests = (
  playerId: number,
  v2PlayersCommandsDeleteRequestsCreateCommand: V2PlayersCommandsDeleteRequestsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesAcceptedResponse>(
    {
      url: `/api/v2/players/${playerId}/deleterequests`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsDeleteRequestsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdDeleterequestsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>,
    TError,
    { playerId: number; data: V2PlayersCommandsDeleteRequestsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>,
  TError,
  { playerId: number; data: V2PlayersCommandsDeleteRequestsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>,
    { playerId: number; data: V2PlayersCommandsDeleteRequestsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdDeleterequests(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdDeleterequestsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>
>;
export type PostApiV2PlayersPlayerIdDeleterequestsMutationBody =
  V2PlayersCommandsDeleteRequestsCreateCommand;
export type PostApiV2PlayersPlayerIdDeleterequestsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdDeleterequests = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>,
    TError,
    { playerId: number; data: V2PlayersCommandsDeleteRequestsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDeleterequests>>,
  TError,
  { playerId: number; data: V2PlayersCommandsDeleteRequestsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdDeleterequestsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2PlayersPlayerIdSummary = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesSummaryDetailsResponse>(
    {
      url: `/api/v2/players/${playerId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdSummaryQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams
) => {
  return [
    `/api/v2/players/${playerId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdSummaryQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdSummary(playerId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>
>;
export type GetApiV2PlayersPlayerIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2PlayersPlayerIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdSummaryQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2PlayersSummaryBatch = (
  params: GetApiV2PlayersSummaryBatchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesSummaryBatchResponse>(
    { url: `/api/v2/players/summary/batch`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayersSummaryBatchQueryKey = (
  params: GetApiV2PlayersSummaryBatchParams
) => {
  return [
    `/api/v2/players/summary/batch`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersSummaryBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2PlayersSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersSummaryBatchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>
  > = ({ signal }) =>
    getApiV2PlayersSummaryBatch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersSummaryBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>
>;
export type GetApiV2PlayersSummaryBatchQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2PlayersSummaryBatchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2PlayersSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2PlayersSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2PlayersSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2PlayersSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersSummaryBatchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const putApiV2PlayersPlayerIdProfileimage = (
  playerId: number,
  v2PlayersCommandsProfileImageCommand: V2PlayersCommandsProfileImageCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/profileimage`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsProfileImageCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdProfileimageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfileImageCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfileImageCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>,
    { playerId: number; data: V2PlayersCommandsProfileImageCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return putApiV2PlayersPlayerIdProfileimage(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdProfileimageMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>
>;
export type PutApiV2PlayersPlayerIdProfileimageMutationBody =
  V2PlayersCommandsProfileImageCommand;
export type PutApiV2PlayersPlayerIdProfileimageMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const usePutApiV2PlayersPlayerIdProfileimage = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfileImageCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdProfileimage>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfileImageCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdProfileimageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdHighlightrequest = (
  playerId: number,
  v2PlayersCommandsHighlightRequestsCreateCommand: V2PlayersCommandsHighlightRequestsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse>(
    {
      url: `/api/v2/players/${playerId}/highlightrequest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsHighlightRequestsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdHighlightrequestMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>,
    TError,
    { playerId: number; data: V2PlayersCommandsHighlightRequestsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>,
  TError,
  { playerId: number; data: V2PlayersCommandsHighlightRequestsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>,
    { playerId: number; data: V2PlayersCommandsHighlightRequestsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdHighlightrequest(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdHighlightrequestMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>
  >;
export type PostApiV2PlayersPlayerIdHighlightrequestMutationBody =
  V2PlayersCommandsHighlightRequestsCreateCommand;
export type PostApiV2PlayersPlayerIdHighlightrequestMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdHighlightrequest = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>,
    TError,
    { playerId: number; data: V2PlayersCommandsHighlightRequestsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdHighlightrequest>>,
  TError,
  { playerId: number; data: V2PlayersCommandsHighlightRequestsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdHighlightrequestMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdWatchitemsProclubs = (
  playerId: number,
  v2PlayersCommandsPlayerWatchItemClubsCommand: V2PlayersCommandsPlayerWatchItemClubsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse>(
    {
      url: `/api/v2/players/${playerId}/watchitems/proclubs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerWatchItemClubsCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdWatchitemsProclubsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerWatchItemClubsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerWatchItemClubsCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>,
    { playerId: number; data: V2PlayersCommandsPlayerWatchItemClubsCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdWatchitemsProclubs(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdWatchitemsProclubsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>
  >;
export type PostApiV2PlayersPlayerIdWatchitemsProclubsMutationBody =
  V2PlayersCommandsPlayerWatchItemClubsCommand;
export type PostApiV2PlayersPlayerIdWatchitemsProclubsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdWatchitemsProclubs = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerWatchItemClubsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdWatchitemsProclubs>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerWatchItemClubsCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdWatchitemsProclubsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus = (
  playerId: number,
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerWatchItemClubsResponse>(
    {
      url: `/api/v2/players/${playerId}/watchitems/proclubs/${proClubId}/status`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryKey =
  (playerId: number, proClubId: number) => {
    return [
      `/api/v2/players/${playerId}/watchitems/proclubs/${proClubId}/status`,
    ] as const;
  };

export const getGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
      >
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    proClubId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryKey(
        playerId,
        proClubId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
        >
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus(
        playerId,
        proClubId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && proClubId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
      >
    >
  >;
export type GetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdWatchitemsProclubsProClubIdStatusQueryOptions(
      playerId,
      proClubId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdTrialgroups = (
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrialGroupsListResponse>(
    {
      url: `/api/v2/players/${playerId}/trialgroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrialgroupsQueryKey = (
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams
) => {
  return [
    `/api/v2/players/${playerId}/trialgroups`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrialgroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrialgroupsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrialgroups(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrialgroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>
>;
export type GetApiV2PlayersPlayerIdTrialgroupsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdTrialgroupsQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersPlayerIdAcademysubscriptions = (
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesAcademySubscriptionsListResponse>(
    {
      url: `/api/v2/players/${playerId}/academysubscriptions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdAcademysubscriptionsQueryKey = (
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams
) => {
  return [
    `/api/v2/players/${playerId}/academysubscriptions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdAcademysubscriptionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdAcademysubscriptionsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdAcademysubscriptions(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdAcademysubscriptionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>
  >;
export type GetApiV2PlayersPlayerIdAcademysubscriptionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdAcademysubscriptions<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAcademysubscriptions<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdAcademysubscriptions<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersPlayerIdAcademysubscriptions<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: GetApiV2PlayersPlayerIdAcademysubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdAcademysubscriptions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdAcademysubscriptionsQueryOptions(
      playerId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdPopupquestionsBatch = (
  playerId: number,
  v2PlayersCommandsPopUpQuestionsCreateCommand: V2PlayersCommandsPopUpQuestionsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/popupquestions/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPopUpQuestionsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdPopupquestionsBatchMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPopUpQuestionsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPopUpQuestionsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>,
    { playerId: number; data: V2PlayersCommandsPopUpQuestionsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdPopupquestionsBatch(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdPopupquestionsBatchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>
  >;
export type PostApiV2PlayersPlayerIdPopupquestionsBatchMutationBody =
  V2PlayersCommandsPopUpQuestionsCreateCommand;
export type PostApiV2PlayersPlayerIdPopupquestionsBatchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdPopupquestionsBatch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPopUpQuestionsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPopupquestionsBatch>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPopUpQuestionsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdPopupquestionsBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdGuardianinvitationcodes = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PlayersCommandsGuardianInvitationCodesCreateResponse>(
    {
      url: `/api/v2/players/${playerId}/guardianinvitationcodes`,
      method: 'POST',
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdGuardianinvitationcodesMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>
      >,
      TError,
      { playerId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>>,
    TError,
    { playerId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>
      >,
      { playerId: number }
    > = (props) => {
      const { playerId } = props ?? {};

      return postApiV2PlayersPlayerIdGuardianinvitationcodes(
        playerId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayersPlayerIdGuardianinvitationcodesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>>
  >;

export type PostApiV2PlayersPlayerIdGuardianinvitationcodesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdGuardianinvitationcodes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>>,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdGuardianinvitationcodes>>,
  TError,
  { playerId: number },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdGuardianinvitationcodesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdGuardiansCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesGuardiansCountResponse>(
    {
      url: `/api/v2/players/${playerId}/guardians/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdGuardiansCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/guardians/count`] as const;
};

export const getGetApiV2PlayersPlayerIdGuardiansCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdGuardiansCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdGuardiansCount(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdGuardiansCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>
>;
export type GetApiV2PlayersPlayerIdGuardiansCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdGuardiansCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardiansCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardiansCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdGuardiansCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdGuardiansCountQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdDiaryentrynotes = (
  playerId: number,
  v2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/diaryentrynotes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdDiaryentrynotesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>,
  TError,
  {
    playerId: number;
    data: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
    }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdDiaryentrynotes(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdDiaryentrynotesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>
>;
export type PostApiV2PlayersPlayerIdDiaryentrynotesMutationBody =
  V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
export type PostApiV2PlayersPlayerIdDiaryentrynotesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdDiaryentrynotes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdDiaryentrynotes>>,
  TError,
  {
    playerId: number;
    data: V2PlayersCommandsPlayerDiaryEntryNotesUpsertCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdDiaryentrynotesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdGuardiansSummary = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesGuardiansSummaryListResponse>(
    {
      url: `/api/v2/players/${playerId}/guardians/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdGuardiansSummaryQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams
) => {
  return [
    `/api/v2/players/${playerId}/guardians/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdGuardiansSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdGuardiansSummaryQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdGuardiansSummary(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdGuardiansSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>
>;
export type GetApiV2PlayersPlayerIdGuardiansSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdGuardiansSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardiansSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdGuardiansSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdGuardiansSummary<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdGuardiansSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdGuardiansSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdGuardiansSummaryQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2PlayersPlayerIdSignedproclub = (
  playerId: number,
  v2PlayersCommandsSignedProClubCreateCommand: V2PlayersCommandsSignedProClubCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/signedproclub`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsSignedProClubCreateCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdSignedproclubMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>,
    TError,
    { playerId: number; data: V2PlayersCommandsSignedProClubCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>,
  TError,
  { playerId: number; data: V2PlayersCommandsSignedProClubCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>,
    { playerId: number; data: V2PlayersCommandsSignedProClubCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return putApiV2PlayersPlayerIdSignedproclub(playerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdSignedproclubMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>
>;
export type PutApiV2PlayersPlayerIdSignedproclubMutationBody =
  V2PlayersCommandsSignedProClubCreateCommand;
export type PutApiV2PlayersPlayerIdSignedproclubMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2PlayersPlayerIdSignedproclub = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>,
    TError,
    { playerId: number; data: V2PlayersCommandsSignedProClubCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdSignedproclub>>,
  TError,
  { playerId: number; data: V2PlayersCommandsSignedProClubCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdSignedproclubMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdSignupagreement = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesSignUpAgreementResponse>(
    {
      url: `/api/v2/players/${playerId}/signupagreement`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdSignupagreementQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams
) => {
  return [
    `/api/v2/players/${playerId}/signupagreement`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdSignupagreementQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdSignupagreementQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdSignupagreement(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdSignupagreementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>
>;
export type GetApiV2PlayersPlayerIdSignupagreementQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdSignupagreement<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdSignupagreementParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdSignupagreement<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdSignupagreement<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdSignupagreement<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdSignupagreementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdSignupagreement>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdSignupagreementQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesFollowingCountResponse>(
    {
      url: `/api/v2/players/${playerId}/following/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/following/count`] as const;
};

export const getGetApiV2PlayersPlayerIdFollowingCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowingCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowingCount(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowingCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>
>;
export type GetApiV2PlayersPlayerIdFollowingCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdFollowingCountQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdFollowingProclubs = (
  playerId: number,
  v2PlayersCommandsPlayerClubFollowersFollowCommand: V2PlayersCommandsPlayerClubFollowersFollowCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/following/proclubs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerClubFollowersFollowCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdFollowingProclubsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersFollowCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerClubFollowersFollowCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersFollowCommand;
    }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdFollowingProclubs(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdFollowingProclubsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>
  >;
export type PostApiV2PlayersPlayerIdFollowingProclubsMutationBody =
  V2PlayersCommandsPlayerClubFollowersFollowCommand;
export type PostApiV2PlayersPlayerIdFollowingProclubsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdFollowingProclubs = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersFollowCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingProclubs>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerClubFollowersFollowCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdFollowingProclubsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdFollowingProclubs = (
  playerId: number,
  v2PlayersCommandsPlayerClubFollowersUnfollowCommand: V2PlayersCommandsPlayerClubFollowersUnfollowCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/following/proclubs`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerClubFollowersUnfollowCommand,
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdFollowingProclubsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>,
  TError,
  {
    playerId: number;
    data: V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
    }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return deleteApiV2PlayersPlayerIdFollowingProclubs(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PlayersPlayerIdFollowingProclubsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>
  >;
export type DeleteApiV2PlayersPlayerIdFollowingProclubsMutationBody =
  V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
export type DeleteApiV2PlayersPlayerIdFollowingProclubsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdFollowingProclubs = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>,
    TError,
    {
      playerId: number;
      data: V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingProclubs>>,
  TError,
  {
    playerId: number;
    data: V2PlayersCommandsPlayerClubFollowersUnfollowCommand;
  },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdFollowingProclubsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingProclubs = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerClubFollowersFollowingListResponse>(
    {
      url: `/api/v2/players/${playerId}/following/proclubs`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingProclubsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams
) => {
  return [
    `/api/v2/players/${playerId}/following/proclubs`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdFollowingProclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowingProclubsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowingProclubs(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowingProclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>
>;
export type GetApiV2PlayersPlayerIdFollowingProclubsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingProclubs<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubs<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubs<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingProclubs<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdFollowingProclubsQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingProclubsCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerClubFollowersFollowingCountResponse>(
    {
      url: `/api/v2/players/${playerId}/following/proclubs/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingProclubsCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/following/proclubs/count`] as const;
};

export const getGetApiV2PlayersPlayerIdFollowingProclubsCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowingProclubsCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowingProclubsCount(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowingProclubsCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>>
  >;
export type GetApiV2PlayersPlayerIdFollowingProclubsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingProclubsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingProclubsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdFollowingProclubsCountQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus = (
  playerId: number,
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerClubFollowersFollowingStatusResponse>(
    {
      url: `/api/v2/players/${playerId}/following/proclubs/${proClubId}/status`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryKey =
  (playerId: number, proClubId: number) => {
    return [
      `/api/v2/players/${playerId}/following/proclubs/${proClubId}/status`,
    ] as const;
  };

export const getGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    proClubId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryKey(
        playerId,
        proClubId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
        >
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus(
        playerId,
        proClubId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && proClubId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
    >
  >;
export type GetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingProclubsProClubIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdFollowingProclubsProClubIdStatusQueryOptions(
      playerId,
      proClubId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdFollowingPlayers = (
  playerId: number,
  v2PlayersCommandsPlayerFollowersFollowCommand: V2PlayersCommandsPlayerFollowersFollowCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/following/players`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerFollowersFollowCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdFollowingPlayersMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersFollowCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerFollowersFollowCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersFollowCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdFollowingPlayers(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdFollowingPlayersMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>
  >;
export type PostApiV2PlayersPlayerIdFollowingPlayersMutationBody =
  V2PlayersCommandsPlayerFollowersFollowCommand;
export type PostApiV2PlayersPlayerIdFollowingPlayersMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdFollowingPlayers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersFollowCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdFollowingPlayers>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerFollowersFollowCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdFollowingPlayersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdFollowingPlayers = (
  playerId: number,
  v2PlayersCommandsPlayerFollowersUnfollowCommand: V2PlayersCommandsPlayerFollowersUnfollowCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/following/players`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerFollowersUnfollowCommand,
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdFollowingPlayersMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersUnfollowCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerFollowersUnfollowCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersUnfollowCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return deleteApiV2PlayersPlayerIdFollowingPlayers(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PlayersPlayerIdFollowingPlayersMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>
  >;
export type DeleteApiV2PlayersPlayerIdFollowingPlayersMutationBody =
  V2PlayersCommandsPlayerFollowersUnfollowCommand;
export type DeleteApiV2PlayersPlayerIdFollowingPlayersMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdFollowingPlayers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerFollowersUnfollowCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PlayersPlayerIdFollowingPlayers>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerFollowersUnfollowCommand },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdFollowingPlayersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingPlayers = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerFollowersFollowingListResponse>(
    {
      url: `/api/v2/players/${playerId}/following/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingPlayersQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams
) => {
  return [
    `/api/v2/players/${playerId}/following/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdFollowingPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowingPlayersQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowingPlayers(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowingPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>
>;
export type GetApiV2PlayersPlayerIdFollowingPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdFollowingPlayersQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingPlayersCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerFollowersFollowingCountResponse>(
    {
      url: `/api/v2/players/${playerId}/following/players/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingPlayersCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/following/players/count`] as const;
};

export const getGetApiV2PlayersPlayerIdFollowingPlayersCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowingPlayersCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowingPlayersCount(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowingPlayersCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>>
  >;
export type GetApiV2PlayersPlayerIdFollowingPlayersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowingPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdFollowingPlayersCountQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus = (
  playerId: number,
  followingPlayerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerFollowersFollowingStatusResponse>(
    {
      url: `/api/v2/players/${playerId}/following/players/${followingPlayerId}/status`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryKey =
  (playerId: number, followingPlayerId: number) => {
    return [
      `/api/v2/players/${playerId}/following/players/${followingPlayerId}/status`,
    ] as const;
  };

export const getGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
      >
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    followingPlayerId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryKey(
        playerId,
        followingPlayerId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
        >
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus(
        playerId,
        followingPlayerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && followingPlayerId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
      >
    >
  >;
export type GetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  followingPlayerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  followingPlayerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  followingPlayerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  followingPlayerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatus
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdFollowingPlayersFollowingPlayerIdStatusQueryOptions(
      playerId,
      followingPlayerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowersPlayers = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerFollowersFollowersListResponse>(
    {
      url: `/api/v2/players/${playerId}/followers/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowersPlayersQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams
) => {
  return [
    `/api/v2/players/${playerId}/followers/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdFollowersPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowersPlayersQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowersPlayers(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowersPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>
>;
export type GetApiV2PlayersPlayerIdFollowersPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowersPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowersPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowersPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowersPlayers<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdFollowersPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdFollowersPlayersQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdFollowersPlayersCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerFollowersFollowersCountResponse>(
    {
      url: `/api/v2/players/${playerId}/followers/players/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdFollowersPlayersCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/followers/players/count`] as const;
};

export const getGetApiV2PlayersPlayerIdFollowersPlayersCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdFollowersPlayersCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdFollowersPlayersCount(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdFollowersPlayersCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>>
  >;
export type GetApiV2PlayersPlayerIdFollowersPlayersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdFollowersPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowersPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdFollowersPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdFollowersPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdFollowersPlayersCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdFollowersPlayersCountQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdLimitedaccess = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesLimitedAccessResponse>(
    { url: `/api/v2/players/${playerId}/limitedaccess`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdLimitedaccessQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/limitedaccess`] as const;
};

export const getGetApiV2PlayersPlayerIdLimitedaccessQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdLimitedaccessQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdLimitedaccess(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdLimitedaccessQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>
>;
export type GetApiV2PlayersPlayerIdLimitedaccessQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdLimitedaccess<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdLimitedaccess<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdLimitedaccess<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdLimitedaccess<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdLimitedaccess>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdLimitedaccessQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdLimitedaccessEnabled = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/players/${playerId}/limitedaccess/enabled`, method: 'PUT' },
    options
  );
};

export const getPutApiV2PlayersPlayerIdLimitedaccessEnabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>,
  TError,
  { playerId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>,
    { playerId: number }
  > = (props) => {
    const { playerId } = props ?? {};

    return putApiV2PlayersPlayerIdLimitedaccessEnabled(
      playerId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdLimitedaccessEnabledMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>
  >;

export type PutApiV2PlayersPlayerIdLimitedaccessEnabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdLimitedaccessEnabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessEnabled>>,
  TError,
  { playerId: number },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdLimitedaccessEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdLimitedaccessDisabled = (
  playerId: number,
  v2PlayersCommandsLimitedAccessDisableCommand: V2PlayersCommandsLimitedAccessDisableCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/limitedaccess/disabled`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsLimitedAccessDisableCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdLimitedaccessDisabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>,
    TError,
    { playerId: number; data: V2PlayersCommandsLimitedAccessDisableCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>,
  TError,
  { playerId: number; data: V2PlayersCommandsLimitedAccessDisableCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>,
    { playerId: number; data: V2PlayersCommandsLimitedAccessDisableCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return putApiV2PlayersPlayerIdLimitedaccessDisabled(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PlayersPlayerIdLimitedaccessDisabledMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>
  >;
export type PutApiV2PlayersPlayerIdLimitedaccessDisabledMutationBody =
  V2PlayersCommandsLimitedAccessDisableCommand;
export type PutApiV2PlayersPlayerIdLimitedaccessDisabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdLimitedaccessDisabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>,
    TError,
    { playerId: number; data: V2PlayersCommandsLimitedAccessDisableCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PlayersPlayerIdLimitedaccessDisabled>>,
  TError,
  { playerId: number; data: V2PlayersCommandsLimitedAccessDisableCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdLimitedaccessDisabledMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2PlayersGenders = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesMetadataGendersListResponse>(
    { url: `/api/v2/players/genders`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersGendersQueryKey = () => {
  return [`/api/v2/players/genders`] as const;
};

export const getGetApiV2PlayersGendersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersGendersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersGenders>>
  > = ({ signal }) => getApiV2PlayersGenders(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersGendersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersGenders>>
>;
export type GetApiV2PlayersGendersQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersGenders<
  TData = Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersGenders<
  TData = Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersGenders<
  TData = Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersGenders<
  TData = Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersGenders>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersGendersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */
export const getApiV2PlayersPreferredfoots = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesMetadataPreferredFootsListResponse>(
    { url: `/api/v2/players/preferredfoots`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPreferredfootsQueryKey = () => {
  return [`/api/v2/players/preferredfoots`] as const;
};

export const getGetApiV2PlayersPreferredfootsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersPreferredfootsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>
  > = ({ signal }) => getApiV2PlayersPreferredfoots(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPreferredfootsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>
>;
export type GetApiV2PlayersPreferredfootsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPreferredfoots<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPreferredfoots<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPreferredfoots<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */

export function useGetApiV2PlayersPreferredfoots<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersPreferredfoots>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPreferredfootsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */
export const getApiV2PlayersRelationshiptypes = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesMetadataRelationshipTypesListResponse>(
    { url: `/api/v2/players/relationshiptypes`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersRelationshiptypesQueryKey = () => {
  return [`/api/v2/players/relationshiptypes`] as const;
};

export const getGetApiV2PlayersRelationshiptypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PlayersRelationshiptypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>
  > = ({ signal }) => getApiV2PlayersRelationshiptypes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersRelationshiptypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>
>;
export type GetApiV2PlayersRelationshiptypesQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersRelationshiptypes<
  TData = Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersRelationshiptypes<
  TData = Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersRelationshiptypes<
  TData = Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */

export function useGetApiV2PlayersRelationshiptypes<
  TData = Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersRelationshiptypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersRelationshiptypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdNationalratingsystemscore = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesNationalRatingSystemScoreResponse>(
    {
      url: `/api/v2/players/${playerId}/nationalratingsystemscore`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdNationalratingsystemscoreQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/nationalratingsystemscore`] as const;
};

export const getGetApiV2PlayersPlayerIdNationalratingsystemscoreQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdNationalratingsystemscoreQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdNationalratingsystemscore(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdNationalratingsystemscoreQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>>
  >;
export type GetApiV2PlayersPlayerIdNationalratingsystemscoreQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdNationalratingsystemscore<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNationalratingsystemscore<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNationalratingsystemscore<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdNationalratingsystemscore<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemscore>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdNationalratingsystemscoreQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdNationalratingsystemSummary = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesNationalRatingSystemSummaryResponse>(
    {
      url: `/api/v2/players/${playerId}/nationalratingsystem/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/nationalratingsystem/summary`] as const;
};

export const getGetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdNationalratingsystemSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryKey(playerId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdNationalratingsystemSummary(
        playerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!playerId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
    >
  >;
export type GetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdNationalratingsystemSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdNationalratingsystemSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNationalratingsystemSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdNationalratingsystemSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNationalratingsystemSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdNationalratingsystemSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdNationalratingsystemSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdNationalratingsystemSummaryQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2PlayersPlayerIdNationalratingsystemReset = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/nationalratingsystem/reset`,
      method: 'POST',
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdNationalratingsystemResetMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>
      >,
      TError,
      { playerId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>
    >,
    TError,
    { playerId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>
      >,
      { playerId: number }
    > = (props) => {
      const { playerId } = props ?? {};

      return postApiV2PlayersPlayerIdNationalratingsystemReset(
        playerId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayersPlayerIdNationalratingsystemResetMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>
    >
  >;

export type PostApiV2PlayersPlayerIdNationalratingsystemResetMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2PlayersPlayerIdNationalratingsystemReset = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>
    >,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdNationalratingsystemReset>>,
  TError,
  { playerId: number },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdNationalratingsystemResetMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdNotifications = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesNotificationsListResponse>(
    {
      url: `/api/v2/players/${playerId}/notifications`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdNotificationsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams
) => {
  return [
    `/api/v2/players/${playerId}/notifications`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdNotificationsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdNotifications(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>
>;
export type GetApiV2PlayersPlayerIdNotificationsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdNotificationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdNotificationsQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdOnboardingComplete = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/players/${playerId}/onboarding/complete`, method: 'POST' },
    options
  );
};

export const getPostApiV2PlayersPlayerIdOnboardingCompleteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>,
  TError,
  { playerId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>,
    { playerId: number }
  > = (props) => {
    const { playerId } = props ?? {};

    return postApiV2PlayersPlayerIdOnboardingComplete(playerId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdOnboardingCompleteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>
  >;

export type PostApiV2PlayersPlayerIdOnboardingCompleteMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdOnboardingComplete = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>,
    TError,
    { playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdOnboardingComplete>>,
  TError,
  { playerId: number },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdOnboardingCompleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersOnboardingEnterclubtrialslogo = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesOnboardingEnterClubTrialLogoResponse>(
    {
      url: `/api/v2/players/onboarding/enterclubtrialslogo`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersOnboardingEnterclubtrialslogoQueryKey = () => {
  return [`/api/v2/players/onboarding/enterclubtrialslogo`] as const;
};

export const getGetApiV2PlayersOnboardingEnterclubtrialslogoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersOnboardingEnterclubtrialslogoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>
  > = ({ signal }) =>
    getApiV2PlayersOnboardingEnterclubtrialslogo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersOnboardingEnterclubtrialslogoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>
  >;
export type GetApiV2PlayersOnboardingEnterclubtrialslogoQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersOnboardingEnterclubtrialslogo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
  >,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersOnboardingEnterclubtrialslogo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersOnboardingEnterclubtrialslogo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersOnboardingEnterclubtrialslogo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingEnterclubtrialslogo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersOnboardingEnterclubtrialslogoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdOnboardingStatus = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesOnboardingStatusResponse>(
    {
      url: `/api/v2/players/${playerId}/onboarding/status`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdOnboardingStatusQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/onboarding/status`] as const;
};

export const getGetApiV2PlayersPlayerIdOnboardingStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdOnboardingStatusQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdOnboardingStatus(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdOnboardingStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>
>;
export type GetApiV2PlayersPlayerIdOnboardingStatusQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdOnboardingStatus<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdOnboardingStatus<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdOnboardingStatus<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdOnboardingStatus<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdOnboardingStatusQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdOnboardingTrials = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesOnboardingTrialsResponse>(
    {
      url: `/api/v2/players/${playerId}/onboarding/trials`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdOnboardingTrialsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/onboarding/trials`] as const;
};

export const getGetApiV2PlayersPlayerIdOnboardingTrialsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdOnboardingTrialsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdOnboardingTrials(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdOnboardingTrialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>
>;
export type GetApiV2PlayersPlayerIdOnboardingTrialsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdOnboardingTrials<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdOnboardingTrials<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdOnboardingTrials<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdOnboardingTrials<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdOnboardingTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdOnboardingTrialsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersOnboardingOpportunitydemovideo = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesOnboardingOpportunityDemoVideoResponse>(
    {
      url: `/api/v2/players/onboarding/opportunitydemovideo`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersOnboardingOpportunitydemovideoQueryKey = () => {
  return [`/api/v2/players/onboarding/opportunitydemovideo`] as const;
};

export const getGetApiV2PlayersOnboardingOpportunitydemovideoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersOnboardingOpportunitydemovideoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>
  > = ({ signal }) =>
    getApiV2PlayersOnboardingOpportunitydemovideo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersOnboardingOpportunitydemovideoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>
  >;
export type GetApiV2PlayersOnboardingOpportunitydemovideoQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersOnboardingOpportunitydemovideo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
  >,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersOnboardingOpportunitydemovideo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersOnboardingOpportunitydemovideo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersOnboardingOpportunitydemovideo<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2PlayersOnboardingOpportunitydemovideo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersOnboardingOpportunitydemovideoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdPerformancelabreports = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPerformanceLabReportsListResponse>(
    {
      url: `/api/v2/players/${playerId}/performancelabreports`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPerformancelabreportsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams
) => {
  return [
    `/api/v2/players/${playerId}/performancelabreports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdPerformancelabreportsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPerformancelabreportsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPerformancelabreports(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPerformancelabreportsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>>
  >;
export type GetApiV2PlayersPlayerIdPerformancelabreportsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPerformancelabreports<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPerformancelabreports<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPerformancelabreports<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdPerformancelabreports<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPerformancelabreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdPerformancelabreports>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdPerformancelabreportsQueryOptions(
      playerId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersPlayerIdPlayerschools = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPlayerSchoolsListResponse>(
    { url: `/api/v2/players/${playerId}/playerschools`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPlayerschoolsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/playerschools`] as const;
};

export const getGetApiV2PlayersPlayerIdPlayerschoolsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPlayerschoolsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPlayerschools(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPlayerschoolsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>
>;
export type GetApiV2PlayersPlayerIdPlayerschoolsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPlayerschools<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPlayerschools<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPlayerschools<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersPlayerIdPlayerschools<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPlayerschools>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdPlayerschoolsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PlayersPlayerIdPlayerschools = (
  playerId: number,
  v2PlayersCommandsPlayerSchoolsCreateCommand: V2PlayersCommandsPlayerSchoolsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/playerschools`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerSchoolsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdPlayerschoolsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerSchoolsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerSchoolsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>,
    { playerId: number; data: V2PlayersCommandsPlayerSchoolsCreateCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return postApiV2PlayersPlayerIdPlayerschools(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdPlayerschoolsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>
>;
export type PostApiV2PlayersPlayerIdPlayerschoolsMutationBody =
  V2PlayersCommandsPlayerSchoolsCreateCommand;
export type PostApiV2PlayersPlayerIdPlayerschoolsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PlayersPlayerIdPlayerschools = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>,
    TError,
    { playerId: number; data: V2PlayersCommandsPlayerSchoolsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdPlayerschools>>,
  TError,
  { playerId: number; data: V2PlayersCommandsPlayerSchoolsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdPlayerschoolsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId = (
  playerId: number,
  playerSchoolId: number,
  v2PlayersCommandsPlayerSchoolsUpdateCommand: V2PlayersCommandsPlayerSchoolsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/playerschools/${playerSchoolId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsPlayerSchoolsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
      >,
      TError,
      {
        playerId: number;
        playerSchoolId: number;
        data: V2PlayersCommandsPlayerSchoolsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >,
    TError,
    {
      playerId: number;
      playerSchoolId: number;
      data: V2PlayersCommandsPlayerSchoolsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
      >,
      {
        playerId: number;
        playerSchoolId: number;
        data: V2PlayersCommandsPlayerSchoolsUpdateCommand;
      }
    > = (props) => {
      const { playerId, playerSchoolId, data } = props ?? {};

      return putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId(
        playerId,
        playerSchoolId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >
  >;
export type PutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationBody =
  V2PlayersCommandsPlayerSchoolsUpdateCommand;
export type PutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >,
    TError,
    {
      playerId: number;
      playerSchoolId: number;
      data: V2PlayersCommandsPlayerSchoolsUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
  >,
  TError,
  {
    playerId: number;
    playerSchoolId: number;
    data: V2PlayersCommandsPlayerSchoolsUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId = (
  playerId: number,
  playerSchoolId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}/playerschools/${playerSchoolId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
      >,
      TError,
      { playerId: number; playerSchoolId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >,
    TError,
    { playerId: number; playerSchoolId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
      >,
      { playerId: number; playerSchoolId: number }
    > = (props) => {
      const { playerId, playerSchoolId } = props ?? {};

      return deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId(
        playerId,
        playerSchoolId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >
  >;

export type DeleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
    >,
    TError,
    { playerId: number; playerSchoolId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolId>
  >,
  TError,
  { playerId: number; playerSchoolId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdPlayerschoolsPlayerSchoolIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PlayersPlayerIdPostsCount = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/players/${playerId}/posts/count`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPostsCountQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/posts/count`] as const;
};

export const getGetApiV2PlayersPlayerIdPostsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPostsCountQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPostsCount(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPostsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>
>;
export type GetApiV2PlayersPlayerIdPostsCountQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPostsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPostsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPostsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PlayersPlayerIdPostsCount<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPostsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdPostsCountQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2PlayersPlayerIdPosts = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesPostsListResponse>(
    { url: `/api/v2/players/${playerId}/posts`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PlayersPlayerIdPostsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams
) => {
  return [
    `/api/v2/players/${playerId}/posts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdPostsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdPostsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdPosts(playerId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdPostsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>
>;
export type GetApiV2PlayersPlayerIdPostsQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdPostsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2PlayersPlayerIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdPostsQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const patchApiV2PlayersPlayerIdProfileFootballdetails = (
  playerId: number,
  v2PlayersCommandsProfilesFootballDetailsCommand: V2PlayersCommandsProfilesFootballDetailsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/footballdetails`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsProfilesFootballDetailsCommand,
    },
    options
  );
};

export const getPatchApiV2PlayersPlayerIdProfileFootballdetailsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>
      >,
      TError,
      {
        playerId: number;
        data: V2PlayersCommandsProfilesFootballDetailsCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesFootballDetailsCommand },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>
      >,
      {
        playerId: number;
        data: V2PlayersCommandsProfilesFootballDetailsCommand;
      }
    > = (props) => {
      const { playerId, data } = props ?? {};

      return patchApiV2PlayersPlayerIdProfileFootballdetails(
        playerId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayersPlayerIdProfileFootballdetailsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>>
  >;
export type PatchApiV2PlayersPlayerIdProfileFootballdetailsMutationBody =
  V2PlayersCommandsProfilesFootballDetailsCommand;
export type PatchApiV2PlayersPlayerIdProfileFootballdetailsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const usePatchApiV2PlayersPlayerIdProfileFootballdetails = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesFootballDetailsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileFootballdetails>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfilesFootballDetailsCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PlayersPlayerIdProfileFootballdetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */
export const getApiV2PlayersPlayerIdProfileFootballdetails = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesProfilesFootballDetailsResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/footballdetails`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdProfileFootballdetailsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/profile/footballdetails`] as const;
};

export const getGetApiV2PlayersPlayerIdProfileFootballdetailsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdProfileFootballdetailsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdProfileFootballdetails(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdProfileFootballdetailsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>>
  >;
export type GetApiV2PlayersPlayerIdProfileFootballdetailsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdProfileFootballdetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileFootballdetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileFootballdetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */

export function useGetApiV2PlayersPlayerIdProfileFootballdetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileFootballdetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdProfileFootballdetailsQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const patchApiV2PlayersPlayerIdProfileAboutme = (
  playerId: number,
  v2PlayersCommandsProfilesAboutMeCommand: V2PlayersCommandsProfilesAboutMeCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/aboutme`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsProfilesAboutMeCommand,
    },
    options
  );
};

export const getPatchApiV2PlayersPlayerIdProfileAboutmeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesAboutMeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfilesAboutMeCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>,
    { playerId: number; data: V2PlayersCommandsProfilesAboutMeCommand }
  > = (props) => {
    const { playerId, data } = props ?? {};

    return patchApiV2PlayersPlayerIdProfileAboutme(
      playerId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2PlayersPlayerIdProfileAboutmeMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>
>;
export type PatchApiV2PlayersPlayerIdProfileAboutmeMutationBody =
  V2PlayersCommandsProfilesAboutMeCommand;
export type PatchApiV2PlayersPlayerIdProfileAboutmeMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const usePatchApiV2PlayersPlayerIdProfileAboutme = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesAboutMeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfileAboutme>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfilesAboutMeCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PlayersPlayerIdProfileAboutmeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */
export const getApiV2PlayersPlayerIdProfileAboutme = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesProfilesAboutMeResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/aboutme`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdProfileAboutmeQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/profile/aboutme`] as const;
};

export const getGetApiV2PlayersPlayerIdProfileAboutmeQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdProfileAboutmeQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdProfileAboutme(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdProfileAboutmeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>
>;
export type GetApiV2PlayersPlayerIdProfileAboutmeQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdProfileAboutme<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileAboutme<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileAboutme<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */

export function useGetApiV2PlayersPlayerIdProfileAboutme<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileAboutme>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdProfileAboutmeQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const patchApiV2PlayersPlayerIdProfilePersonaldetails = (
  playerId: number,
  v2PlayersCommandsProfilesPersonalDetailsCommand: V2PlayersCommandsProfilesPersonalDetailsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/personaldetails`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsProfilesPersonalDetailsCommand,
    },
    options
  );
};

export const getPatchApiV2PlayersPlayerIdProfilePersonaldetailsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>
      >,
      TError,
      {
        playerId: number;
        data: V2PlayersCommandsProfilesPersonalDetailsCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesPersonalDetailsCommand },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>
      >,
      {
        playerId: number;
        data: V2PlayersCommandsProfilesPersonalDetailsCommand;
      }
    > = (props) => {
      const { playerId, data } = props ?? {};

      return patchApiV2PlayersPlayerIdProfilePersonaldetails(
        playerId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2PlayersPlayerIdProfilePersonaldetailsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>>
  >;
export type PatchApiV2PlayersPlayerIdProfilePersonaldetailsMutationBody =
  V2PlayersCommandsProfilesPersonalDetailsCommand;
export type PatchApiV2PlayersPlayerIdProfilePersonaldetailsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const usePatchApiV2PlayersPlayerIdProfilePersonaldetails = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>>,
    TError,
    { playerId: number; data: V2PlayersCommandsProfilesPersonalDetailsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PlayersPlayerIdProfilePersonaldetails>>,
  TError,
  { playerId: number; data: V2PlayersCommandsProfilesPersonalDetailsCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PlayersPlayerIdProfilePersonaldetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */
export const getApiV2PlayersPlayerIdProfilePersonaldetails = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesProfilesPersonalDetailsResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/personaldetails`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdProfilePersonaldetailsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/profile/personaldetails`] as const;
};

export const getGetApiV2PlayersPlayerIdProfilePersonaldetailsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdProfilePersonaldetailsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdProfilePersonaldetails(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdProfilePersonaldetailsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>>
  >;
export type GetApiV2PlayersPlayerIdProfilePersonaldetailsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdProfilePersonaldetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfilePersonaldetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfilePersonaldetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player,Guardian)
 */

export function useGetApiV2PlayersPlayerIdProfilePersonaldetails<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfilePersonaldetails>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdProfilePersonaldetailsQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Guardian)
 */
export const getApiV2PlayersPlayerIdProfileCompletedsections = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesProfilesCompletedSectionsListResponse>(
    {
      url: `/api/v2/players/${playerId}/profile/completedsections`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdProfileCompletedsectionsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/profile/completedsections`] as const;
};

export const getGetApiV2PlayersPlayerIdProfileCompletedsectionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdProfileCompletedsectionsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdProfileCompletedsections(
      playerId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdProfileCompletedsectionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>>
  >;
export type GetApiV2PlayersPlayerIdProfileCompletedsectionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdProfileCompletedsections<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileCompletedsections<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdProfileCompletedsections<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Guardian)
 */

export function useGetApiV2PlayersPlayerIdProfileCompletedsections<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdProfileCompletedsections>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdProfileCompletedsectionsQueryOptions(
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2PlayersPlayerIdTrainingsessiongroups = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrainingSessionGroupsListResponse>(
    {
      url: `/api/v2/players/${playerId}/trainingsessiongroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrainingsessiongroupsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams
) => {
  return [
    `/api/v2/players/${playerId}/trainingsessiongroups`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrainingsessiongroupsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrainingsessiongroupsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrainingsessiongroups(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrainingsessiongroupsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>>
  >;
export type GetApiV2PlayersPlayerIdTrainingsessiongroupsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2PlayersPlayerIdTrainingsessiongroups<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessiongroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessiongroups>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrainingsessiongroupsQueryOptions(
      playerId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions =
  (
    playerId: number,
    trainingSessionGroupId: number,
    params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2PlayersQueriesTrainingSessionGroupsTrainingSessionsListResponse>(
      {
        url: `/api/v2/players/${playerId}/trainingsessiongroups/${trainingSessionGroupId}/trainingsessions`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryKey =
  (
    playerId: number,
    trainingSessionGroupId: number,
    params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams
  ) => {
    return [
      `/api/v2/players/${playerId}/trainingsessiongroups/${trainingSessionGroupId}/trainingsessions`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
      >
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    trainingSessionGroupId: number,
    params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryKey(
        playerId,
        trainingSessionGroupId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
        >
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions(
        playerId,
        trainingSessionGroupId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && trainingSessionGroupId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
      >
    >
  >;
export type GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionGroupId: number,
  params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionGroupId: number,
  params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionGroupId: number,
  params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionGroupId: number,
  params: GetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryOptions(
      playerId,
      trainingSessionGroupId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2PlayersPlayerIdTrainingsessions = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrainingSessionsListResponse>(
    {
      url: `/api/v2/players/${playerId}/trainingsessions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrainingsessionsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams
) => {
  return [
    `/api/v2/players/${playerId}/trainingsessions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrainingsessionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrainingsessionsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrainingsessions(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrainingsessionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>
>;
export type GetApiV2PlayersPlayerIdTrainingsessionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrainingsessions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2PlayersPlayerIdTrainingsessions<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdTrainingsessionsQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2PlayersPlayerIdTrainingsessionsStats = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrainingSessionsStatsResponse>(
    {
      url: `/api/v2/players/${playerId}/trainingsessions/stats`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrainingsessionsStatsQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams
) => {
  return [
    `/api/v2/players/${playerId}/trainingsessions/stats`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrainingsessionsStatsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrainingsessionsStatsQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrainingsessionsStats(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrainingsessionsStatsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>>
  >;
export type GetApiV2PlayersPlayerIdTrainingsessionsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2PlayersPlayerIdTrainingsessionsStats<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrainingsessionsStats>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrainingsessionsStatsQueryOptions(
      playerId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates =
  (
    playerId: number,
    trainingSessionId: number,
    params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2PlayersQueriesTrainingSessionsEntryStatesResponse>(
      {
        url: `/api/v2/players/${playerId}/trainingsessions/${trainingSessionId}/trialentrystates`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryKey =
  (
    playerId: number,
    trainingSessionId: number,
    params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams
  ) => {
    return [
      `/api/v2/players/${playerId}/trainingsessions/${trainingSessionId}/trialentrystates`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
      >
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    trainingSessionId: number,
    params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryKey(
        playerId,
        trainingSessionId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
        >
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates(
        playerId,
        trainingSessionId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && trainingSessionId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
      >
    >
  >;
export type GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionId: number,
  params:
    | undefined
    | GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
    >
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trainingSessionId: number,
  params?: GetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrainingsessionsTrainingSessionIdTrialentrystatesQueryOptions(
      playerId,
      trainingSessionId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Coach)
 */
export const getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId = (
  playerId: number,
  trialId: number,
  entryId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrialsEntriesDetailsResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/${trialId}/entries/${entryId}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryKey = (
  playerId: number,
  trialId: number,
  entryId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams
) => {
  return [
    `/api/v2/players/${playerId}/trials/${trialId}/entries/${entryId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    trialId: number,
    entryId: number,
    params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryKey(
        playerId,
        trialId,
        entryId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId(
        playerId,
        trialId,
        entryId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && trialId && entryId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
    >
  >;
export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  entryId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  entryId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  entryId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Coach)
 */

export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  entryId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdQueryOptions(
      playerId,
      trialId,
      entryId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Coach,Player)
 */
export const deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId = (
  playerId: number,
  trialId: number,
  entryId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/${trialId}/entries/${entryId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
      >,
      TError,
      { playerId: number; trialId: number; entryId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
    >,
    TError,
    { playerId: number; trialId: number; entryId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
      >,
      { playerId: number; trialId: number; entryId: number }
    > = (props) => {
      const { playerId, trialId, entryId } = props ?? {};

      return deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId(
        playerId,
        trialId,
        entryId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
    >
  >;

export type DeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator,Coach,Player)
 */
export const useDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
    >,
    TError,
    { playerId: number; trialId: number; entryId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryId>
  >,
  TError,
  { playerId: number; trialId: number; entryId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary = (
  playerId: number,
  trialId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrialsEntriesSummaryListResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/${trialId}/entries/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryKey = (
  playerId: number,
  trialId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams
) => {
  return [
    `/api/v2/players/${playerId}/trials/${trialId}/entries/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
    >,
    TError = ErrorType<unknown>
  >(
    playerId: number,
    trialId: number,
    params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryKey(
        playerId,
        trialId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
      >
    > = ({ signal }) =>
      getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary(
        playerId,
        trialId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(playerId && trialId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
    >
  >;
export type GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
  >,
  TError = ErrorType<unknown>
>(
  playerId: number,
  trialId: number,
  params?: GetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2PlayersPlayerIdTrialsTrialIdEntriesSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2PlayersPlayerIdTrialsTrialIdEntriesSummaryQueryOptions(
      playerId,
      trialId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach,Player)
 */
export const postApiV2PlayersPlayerIdTrialsTrialIdEntries = (
  playerId: number,
  trialId: number,
  v2PlayersCommandsTrialsEntriesCreateCommand: V2PlayersCommandsTrialsEntriesCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PlayersCommandsTrialsEntriesCreateResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/${trialId}/entries`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PlayersCommandsTrialsEntriesCreateCommand,
    },
    options
  );
};

export const getPostApiV2PlayersPlayerIdTrialsTrialIdEntriesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>,
    TError,
    {
      playerId: number;
      trialId: number;
      data: V2PlayersCommandsTrialsEntriesCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>,
  TError,
  {
    playerId: number;
    trialId: number;
    data: V2PlayersCommandsTrialsEntriesCreateCommand;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>,
    {
      playerId: number;
      trialId: number;
      data: V2PlayersCommandsTrialsEntriesCreateCommand;
    }
  > = (props) => {
    const { playerId, trialId, data } = props ?? {};

    return postApiV2PlayersPlayerIdTrialsTrialIdEntries(
      playerId,
      trialId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>
  >;
export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesMutationBody =
  V2PlayersCommandsTrialsEntriesCreateCommand;
export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Coach,Player)
 */
export const usePostApiV2PlayersPlayerIdTrialsTrialIdEntries = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>,
    TError,
    {
      playerId: number;
      trialId: number;
      data: V2PlayersCommandsTrialsEntriesCreateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PlayersPlayerIdTrialsTrialIdEntries>>,
  TError,
  {
    playerId: number;
    trialId: number;
    data: V2PlayersCommandsTrialsEntriesCreateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2PlayersPlayerIdTrialsTrialIdEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayersPlayerIdTrialsEntries = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrialEntriesListResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/entries`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrialsEntriesQueryKey = (
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams
) => {
  return [
    `/api/v2/players/${playerId}/trials/entries`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PlayersPlayerIdTrialsEntriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrialsEntriesQueryKey(playerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrialsEntries(
      playerId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrialsEntriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>
>;
export type GetApiV2PlayersPlayerIdTrialsEntriesQueryError = ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrialsEntries<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params: undefined | GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsEntries<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsEntries<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayersPlayerIdTrialsEntries<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  params?: GetApiV2PlayersPlayerIdTrialsEntriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntries>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdTrialsEntriesQueryOptions(
    playerId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2PlayersPlayerIdTrialsEntriesStats = (
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PlayersQueriesTrialEntriesStatsResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/entries/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2PlayersPlayerIdTrialsEntriesStatsQueryKey = (
  playerId: number
) => {
  return [`/api/v2/players/${playerId}/trials/entries/stats`] as const;
};

export const getGetApiV2PlayersPlayerIdTrialsEntriesStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PlayersPlayerIdTrialsEntriesStatsQueryKey(playerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>
  > = ({ signal }) =>
    getApiV2PlayersPlayerIdTrialsEntriesStats(playerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!playerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PlayersPlayerIdTrialsEntriesStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>
>;
export type GetApiV2PlayersPlayerIdTrialsEntriesStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2PlayersPlayerIdTrialsEntriesStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsEntriesStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PlayersPlayerIdTrialsEntriesStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2PlayersPlayerIdTrialsEntriesStats<
  TData = Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
  TError = ErrorType<unknown>
>(
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PlayersPlayerIdTrialsEntriesStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PlayersPlayerIdTrialsEntriesStatsQueryOptions(
    playerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach,Scout,Administrator)
 */
export const postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom =
  (
    playerId: number,
    trialId: number,
    entryId: number,
    v2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
      {
        url: `/api/v2/players/${playerId}/trials/${trialId}/entries/${entryId}/reviews/custom`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: v2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand,
      },
      options
    );
  };

export const getPostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustomMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
        >
      >,
      TError,
      {
        playerId: number;
        trialId: number;
        entryId: number;
        data: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
      >
    >,
    TError,
    {
      playerId: number;
      trialId: number;
      entryId: number;
      data: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
        >
      >,
      {
        playerId: number;
        trialId: number;
        entryId: number;
        data: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
      }
    > = (props) => {
      const { playerId, trialId, entryId, data } = props ?? {};

      return postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom(
        playerId,
        trialId,
        entryId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustomMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
      >
    >
  >;
export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustomMutationBody =
  V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
export type PostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustomMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Coach,Scout,Administrator)
 */
export const usePostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
        >
      >,
      TError,
      {
        playerId: number;
        trialId: number;
        entryId: number;
        data: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustom
      >
    >,
    TError,
    {
      playerId: number;
      trialId: number;
      entryId: number;
      data: V2PlayersCommandsTrialsTrialEntryCustomReviewsCreateCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPostApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdReviewsCustomMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings = (
  playerId: number,
  trialId: number,
  entryId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/players/${playerId}/trials/${trialId}/entries/${entryId}/ratings`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatingsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
        >
      >,
      TError,
      { playerId: number; trialId: number; entryId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
      >
    >,
    TError,
    { playerId: number; trialId: number; entryId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
        >
      >,
      { playerId: number; trialId: number; entryId: number }
    > = (props) => {
      const { playerId, trialId, entryId } = props ?? {};

      return deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings(
        playerId,
        trialId,
        entryId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatingsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
      >
    >
  >;

export type DeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatingsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
      >
    >,
    TError,
    { playerId: number; trialId: number; entryId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof deleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatings
    >
  >,
  TError,
  { playerId: number; trialId: number; entryId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PlayersPlayerIdTrialsTrialIdEntriesEntryIdRatingsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2Popupquestions = (
  params?: GetApiV2PopupquestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PopUpQuestionsQueriesListResponse>(
    { url: `/api/v2/popupquestions`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PopupquestionsQueryKey = (
  params?: GetApiV2PopupquestionsParams
) => {
  return [`/api/v2/popupquestions`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PopupquestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Popupquestions>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Popupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PopupquestionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Popupquestions>>
  > = ({ signal }) => getApiV2Popupquestions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Popupquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PopupquestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Popupquestions>>
>;
export type GetApiV2PopupquestionsQueryError = ErrorType<unknown>;

export function useGetApiV2Popupquestions<
  TData = Awaited<ReturnType<typeof getApiV2Popupquestions>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2PopupquestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Popupquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Popupquestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Popupquestions<
  TData = Awaited<ReturnType<typeof getApiV2Popupquestions>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Popupquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Popupquestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Popupquestions<
  TData = Awaited<ReturnType<typeof getApiV2Popupquestions>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Popupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2Popupquestions<
  TData = Awaited<ReturnType<typeof getApiV2Popupquestions>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2PopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Popupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PopupquestionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Popupquestions = (
  v2PopUpQuestionsCommandsCreateCommand: V2PopUpQuestionsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/popupquestions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PopUpQuestionsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PopupquestionsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Popupquestions>>,
    TError,
    { data: V2PopUpQuestionsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Popupquestions>>,
  TError,
  { data: V2PopUpQuestionsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Popupquestions>>,
    { data: V2PopUpQuestionsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Popupquestions(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PopupquestionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Popupquestions>>
>;
export type PostApiV2PopupquestionsMutationBody =
  V2PopUpQuestionsCommandsCreateCommand;
export type PostApiV2PopupquestionsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Popupquestions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Popupquestions>>,
    TError,
    { data: V2PopUpQuestionsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Popupquestions>>,
  TError,
  { data: V2PopUpQuestionsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PopupquestionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2PopupquestionsPopUpQuestionId = (
  popUpQuestionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/popupquestions/${popUpQuestionId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2PopupquestionsPopUpQuestionIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>,
    TError,
    { popUpQuestionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>,
  TError,
  { popUpQuestionId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>,
    { popUpQuestionId: number }
  > = (props) => {
    const { popUpQuestionId } = props ?? {};

    return deleteApiV2PopupquestionsPopUpQuestionId(
      popUpQuestionId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PopupquestionsPopUpQuestionIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>
  >;

export type DeleteApiV2PopupquestionsPopUpQuestionIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2PopupquestionsPopUpQuestionId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>,
    TError,
    { popUpQuestionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PopupquestionsPopUpQuestionId>>,
  TError,
  { popUpQuestionId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PopupquestionsPopUpQuestionIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2PopupquestionsPopUpQuestionId = (
  popUpQuestionId: number,
  v2PopUpQuestionsCommandsUpdateCommand: V2PopUpQuestionsCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/popupquestions/${popUpQuestionId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2PopUpQuestionsCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2PopupquestionsPopUpQuestionIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>,
    TError,
    { popUpQuestionId: number; data: V2PopUpQuestionsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>,
  TError,
  { popUpQuestionId: number; data: V2PopUpQuestionsCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>,
    { popUpQuestionId: number; data: V2PopUpQuestionsCommandsUpdateCommand }
  > = (props) => {
    const { popUpQuestionId, data } = props ?? {};

    return putApiV2PopupquestionsPopUpQuestionId(
      popUpQuestionId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PopupquestionsPopUpQuestionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>
>;
export type PutApiV2PopupquestionsPopUpQuestionIdMutationBody =
  V2PopUpQuestionsCommandsUpdateCommand;
export type PutApiV2PopupquestionsPopUpQuestionIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2PopupquestionsPopUpQuestionId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>,
    TError,
    { popUpQuestionId: number; data: V2PopUpQuestionsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PopupquestionsPopUpQuestionId>>,
  TError,
  { popUpQuestionId: number; data: V2PopUpQuestionsCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PopupquestionsPopUpQuestionIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2Positions = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PositionsQueriesListResponse>(
    { url: `/api/v2/positions`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2PositionsQueryKey = () => {
  return [`/api/v2/positions`] as const;
};

export const getGetApiV2PositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Positions>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Positions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2PositionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Positions>>
  > = ({ signal }) => getApiV2Positions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Positions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PositionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Positions>>
>;
export type GetApiV2PositionsQueryError = ErrorType<unknown>;

export function useGetApiV2Positions<
  TData = Awaited<ReturnType<typeof getApiV2Positions>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Positions>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Positions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Positions<
  TData = Awaited<ReturnType<typeof getApiV2Positions>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Positions>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2Positions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Positions<
  TData = Awaited<ReturnType<typeof getApiV2Positions>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Positions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Positions<
  TData = Awaited<ReturnType<typeof getApiV2Positions>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2Positions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PositionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const putApiV2PostsMediareadystatus = (
  v3PostsCommandsUpdateMediaReadyStatusCommand: V3PostsCommandsUpdateMediaReadyStatusCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/posts/mediareadystatus`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v3PostsCommandsUpdateMediaReadyStatusCommand,
    },
    options
  );
};

export const getPutApiV2PostsMediareadystatusMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>,
    TError,
    { data: V3PostsCommandsUpdateMediaReadyStatusCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>,
  TError,
  { data: V3PostsCommandsUpdateMediaReadyStatusCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>,
    { data: V3PostsCommandsUpdateMediaReadyStatusCommand }
  > = (props) => {
    const { data } = props ?? {};

    return putApiV2PostsMediareadystatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2PostsMediareadystatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>
>;
export type PutApiV2PostsMediareadystatusMutationBody =
  V3PostsCommandsUpdateMediaReadyStatusCommand;
export type PutApiV2PostsMediareadystatusMutationError = ErrorType<unknown>;

export const usePutApiV2PostsMediareadystatus = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>,
    TError,
    { data: V3PostsCommandsUpdateMediaReadyStatusCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2PostsMediareadystatus>>,
  TError,
  { data: V3PostsCommandsUpdateMediaReadyStatusCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2PostsMediareadystatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player,Administrator)
 */
export const deleteApiV2PostsPostId = (
  postId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/posts/${postId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2PostsPostIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PostsPostId>>,
    TError,
    { postId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PostsPostId>>,
  TError,
  { postId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PostsPostId>>,
    { postId: number }
  > = (props) => {
    const { postId } = props ?? {};

    return deleteApiV2PostsPostId(postId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PostsPostIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2PostsPostId>>
>;

export type DeleteApiV2PostsPostIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player,Administrator)
 */
export const useDeleteApiV2PostsPostId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PostsPostId>>,
    TError,
    { postId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PostsPostId>>,
  TError,
  { postId: number },
  TContext
> => {
  const mutationOptions = getDeleteApiV2PostsPostIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */
export const getApiV2PostsPostId = (
  postId: number,
  params?: GetApiV2PostsPostIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PostsQueriesDetailsResponse>(
    { url: `/api/v2/posts/${postId}`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PostsPostIdQueryKey = (
  postId: number,
  params?: GetApiV2PostsPostIdParams
) => {
  return [`/api/v2/posts/${postId}`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2PostsPostIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PostsPostId>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2PostsPostIdQueryKey(postId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PostsPostId>>
  > = ({ signal }) =>
    getApiV2PostsPostId(postId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PostsPostId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PostsPostIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PostsPostId>>
>;
export type GetApiV2PostsPostIdQueryError = ErrorType<unknown>;

export function useGetApiV2PostsPostId<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostId>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params: undefined | GetApiV2PostsPostIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PostsPostId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PostsPostId<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostId>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PostsPostId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PostsPostId<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostId>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Player)
 */

export function useGetApiV2PostsPostId<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostId>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PostsPostIdQueryOptions(
    postId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PostsPostIdReport = (
  postId: number,
  v2PostsCommandsReportCommand: V2PostsCommandsReportCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/posts/${postId}/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsReportCommand,
    },
    options
  );
};

export const getPostApiV2PostsPostIdReportMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>,
    TError,
    { postId: number; data: V2PostsCommandsReportCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>,
  TError,
  { postId: number; data: V2PostsCommandsReportCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>,
    { postId: number; data: V2PostsCommandsReportCommand }
  > = (props) => {
    const { postId, data } = props ?? {};

    return postApiV2PostsPostIdReport(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PostsPostIdReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>
>;
export type PostApiV2PostsPostIdReportMutationBody =
  V2PostsCommandsReportCommand;
export type PostApiV2PostsPostIdReportMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PostsPostIdReport = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>,
    TError,
    { postId: number; data: V2PostsCommandsReportCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PostsPostIdReport>>,
  TError,
  { postId: number; data: V2PostsCommandsReportCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PostsPostIdReportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2PostsPostIdLikes = (
  postId: number,
  v2PostsCommandsPlayerReactionsLikeCommand: V2PostsCommandsPlayerReactionsLikeCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/posts/${postId}/likes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsPlayerReactionsLikeCommand,
    },
    options
  );
};

export const getPostApiV2PostsPostIdLikesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>,
    TError,
    { postId: number; data: V2PostsCommandsPlayerReactionsLikeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>,
  TError,
  { postId: number; data: V2PostsCommandsPlayerReactionsLikeCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>,
    { postId: number; data: V2PostsCommandsPlayerReactionsLikeCommand }
  > = (props) => {
    const { postId, data } = props ?? {};

    return postApiV2PostsPostIdLikes(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PostsPostIdLikesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>
>;
export type PostApiV2PostsPostIdLikesMutationBody =
  V2PostsCommandsPlayerReactionsLikeCommand;
export type PostApiV2PostsPostIdLikesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2PostsPostIdLikes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>,
    TError,
    { postId: number; data: V2PostsCommandsPlayerReactionsLikeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2PostsPostIdLikes>>,
  TError,
  { postId: number; data: V2PostsCommandsPlayerReactionsLikeCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PostsPostIdLikesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const deleteApiV2PostsPostIdLikes = (
  postId: number,
  v2PostsCommandsPlayerReactionsUnlikeCommand: V2PostsCommandsPlayerReactionsUnlikeCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/posts/${postId}/likes`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsPlayerReactionsUnlikeCommand,
    },
    options
  );
};

export const getDeleteApiV2PostsPostIdLikesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>,
    TError,
    { postId: number; data: V2PostsCommandsPlayerReactionsUnlikeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>,
  TError,
  { postId: number; data: V2PostsCommandsPlayerReactionsUnlikeCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>,
    { postId: number; data: V2PostsCommandsPlayerReactionsUnlikeCommand }
  > = (props) => {
    const { postId, data } = props ?? {};

    return deleteApiV2PostsPostIdLikes(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2PostsPostIdLikesMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>
>;
export type DeleteApiV2PostsPostIdLikesMutationBody =
  V2PostsCommandsPlayerReactionsUnlikeCommand;
export type DeleteApiV2PostsPostIdLikesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const useDeleteApiV2PostsPostIdLikes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>,
    TError,
    { postId: number; data: V2PostsCommandsPlayerReactionsUnlikeCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2PostsPostIdLikes>>,
  TError,
  { postId: number; data: V2PostsCommandsPlayerReactionsUnlikeCommand },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2PostsPostIdLikesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2PostsPostIdLikes = (
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2PostsQueriesPlayerReactionsListResponse>(
    { url: `/api/v2/posts/${postId}/likes`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2PostsPostIdLikesQueryKey = (
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams
) => {
  return [
    `/api/v2/posts/${postId}/likes`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2PostsPostIdLikesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2PostsPostIdLikesQueryKey(postId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>
  > = ({ signal }) =>
    getApiV2PostsPostIdLikes(postId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2PostsPostIdLikesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>
>;
export type GetApiV2PostsPostIdLikesQueryError = ErrorType<unknown>;

export function useGetApiV2PostsPostIdLikes<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params: undefined | GetApiV2PostsPostIdLikesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PostsPostIdLikes<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2PostsPostIdLikes<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2PostsPostIdLikes<
  TData = Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
  TError = ErrorType<unknown>
>(
  postId: number,
  params?: GetApiV2PostsPostIdLikesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2PostsPostIdLikes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2PostsPostIdLikesQueryOptions(
    postId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const postApiV2Posts = (
  v2PostsCommandsCreateCommand: V2PostsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2PostsCommandsCreateResponse>(
    {
      url: `/api/v2/posts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2PostsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Posts>>,
    TError,
    { data: V2PostsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Posts>>,
  TError,
  { data: V2PostsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Posts>>,
    { data: V2PostsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Posts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2PostsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Posts>>
>;
export type PostApiV2PostsMutationBody = V2PostsCommandsCreateCommand;
export type PostApiV2PostsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePostApiV2Posts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Posts>>,
    TError,
    { data: V2PostsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Posts>>,
  TError,
  { data: V2PostsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2PostsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2PostsPostIdPromote = (
  postId: number,
  v2PostsCommandsUpdatePromoteStatusCommand: V2PostsCommandsUpdatePromoteStatusCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/posts/${postId}/promote`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsUpdatePromoteStatusCommand,
    },
    options
  );
};

export const getPatchApiV2PostsPostIdPromoteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>,
    TError,
    { postId: number; data: V2PostsCommandsUpdatePromoteStatusCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>,
  TError,
  { postId: number; data: V2PostsCommandsUpdatePromoteStatusCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>,
    { postId: number; data: V2PostsCommandsUpdatePromoteStatusCommand }
  > = (props) => {
    const { postId, data } = props ?? {};

    return patchApiV2PostsPostIdPromote(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2PostsPostIdPromoteMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>
>;
export type PatchApiV2PostsPostIdPromoteMutationBody =
  V2PostsCommandsUpdatePromoteStatusCommand;
export type PatchApiV2PostsPostIdPromoteMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2PostsPostIdPromote = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>,
    TError,
    { postId: number; data: V2PostsCommandsUpdatePromoteStatusCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdPromote>>,
  TError,
  { postId: number; data: V2PostsCommandsUpdatePromoteStatusCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PostsPostIdPromoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const patchApiV2PostsPostIdDescription = (
  postId: number,
  v2PostsCommandsUpdateDescriptionCommand: V2PostsCommandsUpdateDescriptionCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/posts/${postId}/description`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2PostsCommandsUpdateDescriptionCommand,
    },
    options
  );
};

export const getPatchApiV2PostsPostIdDescriptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>,
    TError,
    { postId: number; data: V2PostsCommandsUpdateDescriptionCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>,
  TError,
  { postId: number; data: V2PostsCommandsUpdateDescriptionCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>,
    { postId: number; data: V2PostsCommandsUpdateDescriptionCommand }
  > = (props) => {
    const { postId, data } = props ?? {};

    return patchApiV2PostsPostIdDescription(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2PostsPostIdDescriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>
>;
export type PatchApiV2PostsPostIdDescriptionMutationBody =
  V2PostsCommandsUpdateDescriptionCommand;
export type PatchApiV2PostsPostIdDescriptionMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePatchApiV2PostsPostIdDescription = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>,
    TError,
    { postId: number; data: V2PostsCommandsUpdateDescriptionCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2PostsPostIdDescription>>,
  TError,
  { postId: number; data: V2PostsCommandsUpdateDescriptionCommand },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2PostsPostIdDescriptionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsSuggested = (
  params: GetApiV2ProclubsSuggestedParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesSuggestedListResponse>(
    { url: `/api/v2/proclubs/suggested`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ProclubsSuggestedQueryKey = (
  params: GetApiV2ProclubsSuggestedParams
) => {
  return [`/api/v2/proclubs/suggested`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ProclubsSuggestedQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2ProclubsSuggestedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ProclubsSuggestedQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>
  > = ({ signal }) => getApiV2ProclubsSuggested(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsSuggestedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>
>;
export type GetApiV2ProclubsSuggestedQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsSuggested<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2ProclubsSuggestedParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSuggested<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2ProclubsSuggestedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSuggested<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2ProclubsSuggestedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsSuggested<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2ProclubsSuggestedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSuggested>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsSuggestedQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsSearch = (
  params?: GetApiV2ProclubsSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesSearchesResponse>(
    { url: `/api/v2/proclubs/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ProclubsSearchQueryKey = (
  params?: GetApiV2ProclubsSearchParams
) => {
  return [`/api/v2/proclubs/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ProclubsSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ProclubsSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsSearch>>
  > = ({ signal }) => getApiV2ProclubsSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsSearch>>
>;
export type GetApiV2ProclubsSearchQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ProclubsSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsSearch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Administrator)
 */
export const getApiV2ProclubsProClubIdTrialgroups = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesTrialGroupsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/trialgroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdTrialgroupsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/trialgroups`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdTrialgroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdTrialgroupsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdTrialgroups(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdTrialgroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>
>;
export type GetApiV2ProclubsProClubIdTrialgroupsQueryError =
  ErrorType<CommonResponsesForbiddenAccessResponse>;

export function useGetApiV2ProclubsProClubIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdTrialgroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Administrator)
 */

export function useGetApiV2ProclubsProClubIdTrialgroups<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdTrialgroupsQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsProClubIdTrialgroupsCount = (
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesTrialGroupsCountResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/trialgroups/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdTrialgroupsCountQueryKey = (
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/trialgroups/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdTrialgroupsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdTrialgroupsCountQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdTrialgroupsCount(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdTrialgroupsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>
>;
export type GetApiV2ProclubsProClubIdTrialgroupsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdTrialgroupsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialgroupsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialgroupsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsProClubIdTrialgroupsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: GetApiV2ProclubsProClubIdTrialgroupsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrialgroupsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdTrialgroupsCountQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Coach)
 */
export const getApiV2ProclubsProClubIdSummary = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesSummaryDetailsResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdSummaryQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdSummaryQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdSummary(proClubId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>
>;
export type GetApiV2ProclubsProClubIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Coach)
 */

export function useGetApiV2ProclubsProClubIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdSummaryQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsProClubIdFollowersCount = (
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesFollowersCountResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/followers/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdFollowersCountQueryKey = (
  proClubId: number
) => {
  return [`/api/v2/proclubs/${proClubId}/followers/count`] as const;
};

export const getGetApiV2ProclubsProClubIdFollowersCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdFollowersCountQueryKey(proClubId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdFollowersCount(proClubId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdFollowersCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>
>;
export type GetApiV2ProclubsProClubIdFollowersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdFollowersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdFollowersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdFollowersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsProClubIdFollowersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFollowersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdFollowersCountQueryOptions(
    proClubId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdPosts = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesPostsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/posts`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdPostsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/posts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdPostsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdPostsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdPosts(proClubId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdPostsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>
>;
export type GetApiV2ProclubsProClubIdPostsQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdPostsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdPosts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPostsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPosts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdPostsQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2ProclubsProClubIdPosts = (
  proClubId: number,
  v2ProClubsCommandsPostsCreateCommand: V2ProClubsCommandsPostsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/proclubs/${proClubId}/posts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ProClubsCommandsPostsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ProclubsProClubIdPostsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>,
    TError,
    { proClubId: number; data: V2ProClubsCommandsPostsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>,
  TError,
  { proClubId: number; data: V2ProClubsCommandsPostsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>,
    { proClubId: number; data: V2ProClubsCommandsPostsCreateCommand }
  > = (props) => {
    const { proClubId, data } = props ?? {};

    return postApiV2ProclubsProClubIdPosts(proClubId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ProclubsProClubIdPostsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>
>;
export type PostApiV2ProclubsProClubIdPostsMutationBody =
  V2ProClubsCommandsPostsCreateCommand;
export type PostApiV2ProclubsProClubIdPostsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2ProclubsProClubIdPosts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>,
    TError,
    { proClubId: number; data: V2ProClubsCommandsPostsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2ProclubsProClubIdPosts>>,
  TError,
  { proClubId: number; data: V2ProClubsCommandsPostsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ProclubsProClubIdPostsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2ProclubsProClubIdPlayers = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesPlayersListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdPlayersQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdPlayersQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdPlayers(proClubId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>
>;
export type GetApiV2ProclubsProClubIdPlayersQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2ProclubsProClubIdPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdPlayersQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2ProclubsProClubIdPlayersCount = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesPlayersCountResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/players/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdPlayersCountQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/players/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdPlayersCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdPlayersCountQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdPlayersCount(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdPlayersCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>
>;
export type GetApiV2ProclubsProClubIdPlayersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdPlayersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdPlayersCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2ProclubsProClubIdPlayersCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdPlayersCountQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsProClubIdAcademies = (
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesAcademiesListResponse>(
    { url: `/api/v2/proclubs/${proClubId}/academies`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2ProclubsProClubIdAcademiesQueryKey = (
  proClubId: number
) => {
  return [`/api/v2/proclubs/${proClubId}/academies`] as const;
};

export const getGetApiV2ProclubsProClubIdAcademiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdAcademiesQueryKey(proClubId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdAcademies(proClubId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdAcademiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>
>;
export type GetApiV2ProclubsProClubIdAcademiesQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdAcademies<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdAcademies<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdAcademies<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsProClubIdAcademies<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdAcademies>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdAcademiesQueryOptions(
    proClubId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProclubsSummaryBatch = (
  params?: GetApiV2ProclubsSummaryBatchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesSummaryBatchResponse>(
    { url: `/api/v2/proclubs/summary/batch`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ProclubsSummaryBatchQueryKey = (
  params?: GetApiV2ProclubsSummaryBatchParams
) => {
  return [
    `/api/v2/proclubs/summary/batch`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsSummaryBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ProclubsSummaryBatchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>
  > = ({ signal }) =>
    getApiV2ProclubsSummaryBatch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsSummaryBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>
>;
export type GetApiV2ProclubsSummaryBatchQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ProclubsSummaryBatchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProclubsSummaryBatch<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsSummaryBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsSummaryBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsSummaryBatchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Administrator)
 */
export const getApiV2ProclubsProClubIdBiodetails = (
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesBioDetailsResponse>(
    { url: `/api/v2/proclubs/${proClubId}/biodetails`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2ProclubsProClubIdBiodetailsQueryKey = (
  proClubId: number
) => {
  return [`/api/v2/proclubs/${proClubId}/biodetails`] as const;
};

export const getGetApiV2ProclubsProClubIdBiodetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdBiodetailsQueryKey(proClubId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdBiodetails(proClubId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdBiodetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>
>;
export type GetApiV2ProclubsProClubIdBiodetailsQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdBiodetails<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdBiodetails<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdBiodetails<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Administrator)
 */

export function useGetApiV2ProclubsProClubIdBiodetails<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdBiodetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdBiodetailsQueryOptions(
    proClubId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2ProclubsProClubIdBiodetails = (
  proClubId: number,
  v2ProClubsCommandsBioDetailsUpsertCommand: V2ProClubsCommandsBioDetailsUpsertCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/biodetails`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2ProClubsCommandsBioDetailsUpsertCommand,
    },
    options
  );
};

export const getPutApiV2ProclubsProClubIdBiodetailsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>,
    TError,
    { proClubId: number; data: V2ProClubsCommandsBioDetailsUpsertCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>,
  TError,
  { proClubId: number; data: V2ProClubsCommandsBioDetailsUpsertCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>,
    { proClubId: number; data: V2ProClubsCommandsBioDetailsUpsertCommand }
  > = (props) => {
    const { proClubId, data } = props ?? {};

    return putApiV2ProclubsProClubIdBiodetails(proClubId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2ProclubsProClubIdBiodetailsMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>
>;
export type PutApiV2ProclubsProClubIdBiodetailsMutationBody =
  V2ProClubsCommandsBioDetailsUpsertCommand;
export type PutApiV2ProclubsProClubIdBiodetailsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2ProclubsProClubIdBiodetails = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>,
    TError,
    { proClubId: number; data: V2ProClubsCommandsBioDetailsUpsertCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2ProclubsProClubIdBiodetails>>,
  TError,
  { proClubId: number; data: V2ProClubsCommandsBioDetailsUpsertCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2ProclubsProClubIdBiodetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2Proclubs = (
  params?: GetApiV2ProclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesListResponse>(
    { url: `/api/v2/proclubs`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ProclubsQueryKey = (
  params?: GetApiV2ProclubsParams
) => {
  return [`/api/v2/proclubs`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ProclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Proclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ProclubsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Proclubs>>
  > = ({ signal }) => getApiV2Proclubs(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Proclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Proclubs>>
>;
export type GetApiV2ProclubsQueryError = ErrorType<unknown>;

export function useGetApiV2Proclubs<
  TData = Awaited<ReturnType<typeof getApiV2Proclubs>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ProclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Proclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Proclubs<
  TData = Awaited<ReturnType<typeof getApiV2Proclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Proclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Proclubs<
  TData = Awaited<ReturnType<typeof getApiV2Proclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2Proclubs<
  TData = Awaited<ReturnType<typeof getApiV2Proclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2ProclubsProClubIdTrials = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesTrialsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/trials`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdTrialsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/trials`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdTrialsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdTrialsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdTrials(proClubId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdTrialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>
>;
export type GetApiV2ProclubsProClubIdTrialsQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdTrials<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdTrialsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrials<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrials<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2ProclubsProClubIdTrials<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdTrials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdTrialsQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const getApiV2ProclubsProClubIdScouts = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesScoutsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/scouts`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdScoutsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/scouts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdScoutsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdScoutsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdScouts(proClubId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdScoutsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>
>;
export type GetApiV2ProclubsProClubIdScoutsQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdScouts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdScoutsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScouts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScouts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */

export function useGetApiV2ProclubsProClubIdScouts<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScouts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdScoutsQueryOptions(
    proClubId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const getApiV2ProclubsProClubIdScoutsCount = (
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesScoutsCountResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/scouts/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdScoutsCountQueryKey = (
  proClubId: number
) => {
  return [`/api/v2/proclubs/${proClubId}/scouts/count`] as const;
};

export const getGetApiV2ProclubsProClubIdScoutsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdScoutsCountQueryKey(proClubId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdScoutsCount(proClubId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdScoutsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>
>;
export type GetApiV2ProclubsProClubIdScoutsCountQueryError = ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdScoutsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScoutsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScoutsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */

export function useGetApiV2ProclubsProClubIdScoutsCount<
  TData = Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProclubsProClubIdScoutsCountQueryOptions(
    proClubId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdFeaturesFeatureIdStatus = (
  proClubId?: number,
  featureId?: string | null,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesFeaturesStatusResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/features/${featureId}/status`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryKey = (
  proClubId?: number,
  featureId?: string | null
) => {
  return [
    `/api/v2/proclubs/${proClubId}/features/${featureId}/status`,
  ] as const;
};

export const getGetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  proClubId?: number,
  featureId?: string | null,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryKey(
      proClubId,
      featureId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdFeaturesFeatureIdStatus(
      proClubId,
      featureId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(proClubId && featureId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>>
  >;
export type GetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdFeaturesFeatureIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: undefined | number,
  featureId: undefined | string | null,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdFeaturesFeatureIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  proClubId?: number,
  featureId?: string | null,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdFeaturesFeatureIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  proClubId?: number,
  featureId?: string | null,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdFeaturesFeatureIdStatus<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
  >,
  TError = ErrorType<unknown>
>(
  proClubId?: number,
  featureId?: string | null,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdFeaturesFeatureIdStatus>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdFeaturesFeatureIdStatusQueryOptions(
      proClubId,
      featureId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled = (
  proClubId?: number,
  featureId?: string | null,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/features/${featureId}/enabled`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2ProclubsProClubIdFeaturesFeatureIdEnabledMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
      >,
      TError,
      { proClubId?: number; featureId?: string | null },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
    >,
    TError,
    { proClubId?: number; featureId?: string | null },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
      >,
      { proClubId?: number; featureId?: string | null }
    > = (props) => {
      const { proClubId, featureId } = props ?? {};

      return patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled(
        proClubId,
        featureId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ProclubsProClubIdFeaturesFeatureIdEnabledMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
    >
  >;

export type PatchApiV2ProclubsProClubIdFeaturesFeatureIdEnabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
    >,
    TError,
    { proClubId?: number; featureId?: string | null },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdEnabled>
  >,
  TError,
  { proClubId?: number; featureId?: string | null },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2ProclubsProClubIdFeaturesFeatureIdEnabledMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled = (
  proClubId?: number,
  featureId?: string | null,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/features/${featureId}/disabled`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2ProclubsProClubIdFeaturesFeatureIdDisabledMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
      >,
      TError,
      { proClubId?: number; featureId?: string | null },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
    >,
    TError,
    { proClubId?: number; featureId?: string | null },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
      >,
      { proClubId?: number; featureId?: string | null }
    > = (props) => {
      const { proClubId, featureId } = props ?? {};

      return patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled(
        proClubId,
        featureId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ProclubsProClubIdFeaturesFeatureIdDisabledMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
    >
  >;

export type PatchApiV2ProclubsProClubIdFeaturesFeatureIdDisabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
    >,
    TError,
    { proClubId?: number; featureId?: string | null },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2ProclubsProClubIdFeaturesFeatureIdDisabled>
  >,
  TError,
  { proClubId?: number; featureId?: string | null },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2ProclubsProClubIdFeaturesFeatureIdDisabledMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdScoutingquestions = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesScoutingQuestionsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/scoutingquestions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdScoutingquestionsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/scoutingquestions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdScoutingquestionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdScoutingquestionsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdScoutingquestions(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdScoutingquestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>
>;
export type GetApiV2ProclubsProClubIdScoutingquestionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdScoutingquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScoutingquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdScoutingquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdScoutingquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdScoutingquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProclubsProClubIdScoutingquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdScoutingquestionsQueryOptions(
      proClubId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdPlayerreportquestions = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesPlayerReportQuestionsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/playerreportquestions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdPlayerreportquestionsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/playerreportquestions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdPlayerreportquestionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdPlayerreportquestionsQueryKey(
      proClubId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdPlayerreportquestions(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdPlayerreportquestionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>>
  >;
export type GetApiV2ProclubsProClubIdPlayerreportquestionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdPlayerreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayerreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdPlayerreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdPlayerreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdPlayerreportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdPlayerreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdPlayerreportquestionsQueryOptions(
      proClubId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdGamereportquestions = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesGameReportQuestionsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/gamereportquestions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdGamereportquestionsQueryKey = (
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams
) => {
  return [
    `/api/v2/proclubs/${proClubId}/gamereportquestions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProclubsProClubIdGamereportquestionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdGamereportquestionsQueryKey(proClubId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdGamereportquestions(
      proClubId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdGamereportquestionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>>
  >;
export type GetApiV2ProclubsProClubIdGamereportquestionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdGamereportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params: undefined | GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdGamereportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdGamereportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdGamereportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdGamereportquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdGamereportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdGamereportquestionsQueryOptions(
      proClubId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ProclubsProClubIdSummaryreportquestions = (
  proClubId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProClubsQueriesSummaryReportQuestionsListResponse>(
    {
      url: `/api/v2/proclubs/${proClubId}/summaryreportquestions`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ProclubsProClubIdSummaryreportquestionsQueryKey = (
  proClubId: number
) => {
  return [`/api/v2/proclubs/${proClubId}/summaryreportquestions`] as const;
};

export const getGetApiV2ProclubsProClubIdSummaryreportquestionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProclubsProClubIdSummaryreportquestionsQueryKey(proClubId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>>
  > = ({ signal }) =>
    getApiV2ProclubsProClubIdSummaryreportquestions(
      proClubId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proClubId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProclubsProClubIdSummaryreportquestionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>>
  >;
export type GetApiV2ProclubsProClubIdSummaryreportquestionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdSummaryreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdSummaryreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdSummaryreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ProclubsProClubIdSummaryreportquestions<
  TData = Awaited<
    ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ProclubsProClubIdSummaryreportquestions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdSummaryreportquestionsQueryOptions(
      proClubId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId =
  (
    proClubId: number,
    trialIdentityId: number,
    instructionTemplateId: number,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesNoContentResponse>(
      {
        url: `/api/v2/proclubs/${proClubId}/trialidentities/${trialIdentityId}/instructiontemplates/${instructionTemplateId}`,
        method: 'DELETE',
      },
      options
    );
  };

export const getDeleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        instructionTemplateId: number;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      instructionTemplateId: number;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
        >
      >,
      {
        proClubId: number;
        trialIdentityId: number;
        instructionTemplateId: number;
      }
    > = (props) => {
      const { proClubId, trialIdentityId, instructionTemplateId } = props ?? {};

      return deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId(
        proClubId,
        trialIdentityId,
        instructionTemplateId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
      >
    >
  >;

export type DeleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        instructionTemplateId: number;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateId
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      instructionTemplateId: number;
    },
    TContext
  > => {
    const mutationOptions =
      getDeleteApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesInstructionTemplateIdMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort =
  (
    proClubId: number,
    trialIdentityId: number,
    v2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/proclubs/${proClubId}/trialidentities/${trialIdentityId}/instructiontemplates/sort`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: v2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand,
      },
      options
    );
  };

export const getPatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSortMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      data: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
        >
      >,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
      }
    > = (props) => {
      const { proClubId, trialIdentityId, data } = props ?? {};

      return patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort(
        proClubId,
        trialIdentityId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSortMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
      >
    >
  >;
export type PatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSortMutationBody =
  V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
export type PatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSortMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSort
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      data: V2ProClubsCommandsTrialIdentityInstructionTemplatesSortCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPatchApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesSortMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates =
  (
    proClubId: number,
    trialIdentityId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2ProClubsQueriesTrialIdentityInstructionTemplatesListResponse>(
      {
        url: `/api/v2/proclubs/${proClubId}/trialidentities/${trialIdentityId}/instructiontemplates`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryKey =
  (
    proClubId: number,
    trialIdentityId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams
  ) => {
    return [
      `/api/v2/proclubs/${proClubId}/trialidentities/${trialIdentityId}/instructiontemplates`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
      >
    >,
    TError = ErrorType<unknown>
  >(
    proClubId: number,
    trialIdentityId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryKey(
        proClubId,
        trialIdentityId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
        >
      >
    > = ({ signal }) =>
      getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates(
        proClubId,
        trialIdentityId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(proClubId && trialIdentityId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
      >
    >
  >;
export type GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  trialIdentityId: number,
  params:
    | undefined
    | GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  trialIdentityId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  trialIdentityId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  trialIdentityId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesQueryOptions(
      proClubId,
      trialIdentityId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates =
  (
    proClubId: number,
    trialIdentityId: number,
    v2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/proclubs/${proClubId}/trialidentities/${trialIdentityId}/instructiontemplates`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: v2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand,
      },
      options
    );
  };

export const getPutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      data: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
        >
      >,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
      }
    > = (props) => {
      const { proClubId, trialIdentityId, data } = props ?? {};

      return putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates(
        proClubId,
        trialIdentityId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
      >
    >
  >;
export type PutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesMutationBody =
  V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
export type PutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
        >
      >,
      TError,
      {
        proClubId: number;
        trialIdentityId: number;
        data: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof putApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplates
      >
    >,
    TError,
    {
      proClubId: number;
      trialIdentityId: number;
      data: V2ProClubsCommandsTrialIdentityInstructionTemplatesUpsertCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPutApiV2ProclubsProClubIdTrialidentitiesTrialIdentityIdInstructiontemplatesMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates =
  (
    proClubId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2ProClubsQueriesTrialIdentityInstructionTemplatesStatesResponse>(
      {
        url: `/api/v2/proclubs/${proClubId}/trialidentities/instructiontemplatestates`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryKey =
  (
    proClubId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams
  ) => {
    return [
      `/api/v2/proclubs/${proClubId}/trialidentities/instructiontemplatestates`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
      >
    >,
    TError = ErrorType<unknown>
  >(
    proClubId: number,
    params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryKey(
        proClubId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
        >
      >
    > = ({ signal }) =>
      getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates(
        proClubId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!proClubId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
      >
    >
  >;
export type GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params:
    | undefined
    | GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
    >
  >,
  TError = ErrorType<unknown>
>(
  proClubId: number,
  params?: GetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestates
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ProclubsProClubIdTrialidentitiesInstructiontemplatestatesQueryOptions(
      proClubId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Proleagues = (
  params?: GetApiV2ProleaguesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProLeaguesQueriesListResponse>(
    { url: `/api/v2/proleagues`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ProleaguesQueryKey = (
  params?: GetApiV2ProleaguesParams
) => {
  return [`/api/v2/proleagues`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ProleaguesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Proleagues>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProleaguesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proleagues>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ProleaguesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Proleagues>>
  > = ({ signal }) => getApiV2Proleagues(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Proleagues>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProleaguesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Proleagues>>
>;
export type GetApiV2ProleaguesQueryError = ErrorType<unknown>;

export function useGetApiV2Proleagues<
  TData = Awaited<ReturnType<typeof getApiV2Proleagues>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ProleaguesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proleagues>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Proleagues>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Proleagues<
  TData = Awaited<ReturnType<typeof getApiV2Proleagues>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProleaguesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proleagues>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Proleagues>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Proleagues<
  TData = Awaited<ReturnType<typeof getApiV2Proleagues>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProleaguesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proleagues>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Proleagues<
  TData = Awaited<ReturnType<typeof getApiV2Proleagues>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ProleaguesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Proleagues>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProleaguesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2ProleaguesProLeagueIdProclubs = (
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ProLeaguesQueriesProClubsListResponse>(
    {
      url: `/api/v2/proleagues/${proLeagueId}/proclubs`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ProleaguesProLeagueIdProclubsQueryKey = (
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams
) => {
  return [
    `/api/v2/proleagues/${proLeagueId}/proclubs`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ProleaguesProLeagueIdProclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
  TError = ErrorType<unknown>
>(
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ProleaguesProLeagueIdProclubsQueryKey(proLeagueId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>
  > = ({ signal }) =>
    getApiV2ProleaguesProLeagueIdProclubs(
      proLeagueId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!proLeagueId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ProleaguesProLeagueIdProclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>
>;
export type GetApiV2ProleaguesProLeagueIdProclubsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ProleaguesProLeagueIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
  TError = ErrorType<unknown>
>(
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProleaguesProLeagueIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
  TError = ErrorType<unknown>
>(
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ProleaguesProLeagueIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
  TError = ErrorType<unknown>
>(
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2ProleaguesProLeagueIdProclubs<
  TData = Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
  TError = ErrorType<unknown>
>(
  proLeagueId: number,
  params: GetApiV2ProleaguesProLeagueIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ProleaguesProLeagueIdProclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ProleaguesProLeagueIdProclubsQueryOptions(
    proLeagueId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2SchoolsSearch = (
  params?: GetApiV2SchoolsSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SchoolsQueriesSearchResponse>(
    { url: `/api/v2/schools/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2SchoolsSearchQueryKey = (
  params?: GetApiV2SchoolsSearchParams
) => {
  return [`/api/v2/schools/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2SchoolsSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SchoolsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SchoolsSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SchoolsSearch>>
  > = ({ signal }) => getApiV2SchoolsSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SchoolsSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SchoolsSearch>>
>;
export type GetApiV2SchoolsSearchQueryError = ErrorType<unknown>;

export function useGetApiV2SchoolsSearch<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2SchoolsSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSearch<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SchoolsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSearch<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SchoolsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2SchoolsSearch<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SchoolsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SchoolsSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2SchoolsSchoolId = (
  schoolId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SchoolsQueriesDetailsResponse>(
    { url: `/api/v2/schools/${schoolId}`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2SchoolsSchoolIdQueryKey = (schoolId: number) => {
  return [`/api/v2/schools/${schoolId}`] as const;
};

export const getGetApiV2SchoolsSchoolIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
  TError = ErrorType<unknown>
>(
  schoolId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SchoolsSchoolIdQueryKey(schoolId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>
  > = ({ signal }) => getApiV2SchoolsSchoolId(schoolId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!schoolId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SchoolsSchoolIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>
>;
export type GetApiV2SchoolsSchoolIdQueryError = ErrorType<unknown>;

export function useGetApiV2SchoolsSchoolId<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
  TError = ErrorType<unknown>
>(
  schoolId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSchoolId<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
  TError = ErrorType<unknown>
>(
  schoolId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSchoolId<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
  TError = ErrorType<unknown>
>(
  schoolId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2SchoolsSchoolId<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
  TError = ErrorType<unknown>
>(
  schoolId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchoolId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SchoolsSchoolIdQueryOptions(
    schoolId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2SchoolsSchooltypes = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SchoolsQueriesMetadataSchoolTypesListResponse>(
    { url: `/api/v2/schools/schooltypes`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2SchoolsSchooltypesQueryKey = () => {
  return [`/api/v2/schools/schooltypes`] as const;
};

export const getGetApiV2SchoolsSchooltypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SchoolsSchooltypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>
  > = ({ signal }) => getApiV2SchoolsSchooltypes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SchoolsSchooltypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>
>;
export type GetApiV2SchoolsSchooltypesQueryError = ErrorType<unknown>;

export function useGetApiV2SchoolsSchooltypes<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSchooltypes<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SchoolsSchooltypes<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2SchoolsSchooltypes<
  TData = Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2SchoolsSchooltypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SchoolsSchooltypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Scoutamateurfixtures = (
  v2ScoutAmateurFixturesCommandsCreateCommand: V2ScoutAmateurFixturesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/scoutamateurfixtures`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutAmateurFixturesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ScoutamateurfixturesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>,
    TError,
    { data: V2ScoutAmateurFixturesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>,
  TError,
  { data: V2ScoutAmateurFixturesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>,
    { data: V2ScoutAmateurFixturesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Scoutamateurfixtures(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutamateurfixturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>
>;
export type PostApiV2ScoutamateurfixturesMutationBody =
  V2ScoutAmateurFixturesCommandsCreateCommand;
export type PostApiV2ScoutamateurfixturesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Scoutamateurfixtures = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>,
    TError,
    { data: V2ScoutAmateurFixturesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Scoutamateurfixtures>>,
  TError,
  { data: V2ScoutAmateurFixturesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutamateurfixturesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance = (
  scoutAmateurFixtureId: number,
  v2ScoutAmateurFixturesCommandsUpdateCommand: V2ScoutAmateurFixturesCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scoutamateurfixtures/${scoutAmateurFixtureId}/attendance`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutAmateurFixturesCommandsUpdateCommand,
    },
    options
  );
};

export const getPatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendanceMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
        >
      >,
      TError,
      {
        scoutAmateurFixtureId: number;
        data: V2ScoutAmateurFixturesCommandsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
      >
    >,
    TError,
    {
      scoutAmateurFixtureId: number;
      data: V2ScoutAmateurFixturesCommandsUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
        >
      >,
      {
        scoutAmateurFixtureId: number;
        data: V2ScoutAmateurFixturesCommandsUpdateCommand;
      }
    > = (props) => {
      const { scoutAmateurFixtureId, data } = props ?? {};

      return patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance(
        scoutAmateurFixtureId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendanceMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
      >
    >
  >;
export type PatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendanceMutationBody =
  V2ScoutAmateurFixturesCommandsUpdateCommand;
export type PatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendanceMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
        >
      >,
      TError,
      {
        scoutAmateurFixtureId: number;
        data: V2ScoutAmateurFixturesCommandsUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendance
      >
    >,
    TError,
    {
      scoutAmateurFixtureId: number;
      data: V2ScoutAmateurFixturesCommandsUpdateCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPatchApiV2ScoutamateurfixturesScoutAmateurFixtureIdAttendanceMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutamateurfixturesScoutAmateurFixtureId = (
  scoutAmateurFixtureId: number,
  params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutAmateurFixturesQueriesDetailsResponse>(
    {
      url: `/api/v2/scoutamateurfixtures/${scoutAmateurFixtureId}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryKey = (
  scoutAmateurFixtureId: number,
  params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams
) => {
  return [
    `/api/v2/scoutamateurfixtures/${scoutAmateurFixtureId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
    >,
    TError = ErrorType<unknown>
  >(
    scoutAmateurFixtureId: number,
    params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryKey(
        scoutAmateurFixtureId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
      >
    > = ({ signal }) =>
      getApiV2ScoutamateurfixturesScoutAmateurFixtureId(
        scoutAmateurFixtureId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!scoutAmateurFixtureId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
    >
  >;
export type GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutamateurfixturesScoutAmateurFixtureId<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
  >,
  TError = ErrorType<unknown>
>(
  scoutAmateurFixtureId: number,
  params: undefined | GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutamateurfixturesScoutAmateurFixtureId<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
  >,
  TError = ErrorType<unknown>
>(
  scoutAmateurFixtureId: number,
  params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutamateurfixturesScoutAmateurFixtureId<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
  >,
  TError = ErrorType<unknown>
>(
  scoutAmateurFixtureId: number,
  params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutamateurfixturesScoutAmateurFixtureId<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
  >,
  TError = ErrorType<unknown>
>(
  scoutAmateurFixtureId: number,
  params?: GetApiV2ScoutamateurfixturesScoutAmateurFixtureIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutamateurfixturesScoutAmateurFixtureId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutamateurfixturesScoutAmateurFixtureIdQueryOptions(
      scoutAmateurFixtureId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId = (
  scoutAmateurFixtureId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/scoutamateurfixtures/${scoutAmateurFixtureId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2ScoutamateurfixturesScoutAmateurFixtureIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
      >,
      TError,
      { scoutAmateurFixtureId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
    >,
    TError,
    { scoutAmateurFixtureId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
      >,
      { scoutAmateurFixtureId: number }
    > = (props) => {
      const { scoutAmateurFixtureId } = props ?? {};

      return deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId(
        scoutAmateurFixtureId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2ScoutamateurfixturesScoutAmateurFixtureIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
    >
  >;

export type DeleteApiV2ScoutamateurfixturesScoutAmateurFixtureIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2ScoutamateurfixturesScoutAmateurFixtureId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
    >,
    TError,
    { scoutAmateurFixtureId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2ScoutamateurfixturesScoutAmateurFixtureId>
  >,
  TError,
  { scoutAmateurFixtureId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2ScoutamateurfixturesScoutAmateurFixtureIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch = (
  scoutingNoteId: number,
  v2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/scoutingvoicenotes/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand,
    },
    options
  );
};

export const getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatchMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
        >
      >,
      TError,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
        >
      >,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
      }
    > = (props) => {
      const { scoutingNoteId, data } = props ?? {};

      return postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch(
        scoutingNoteId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
      >
    >
  >;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatchMutationBody =
  V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatch
    >
  >,
  TError,
  {
    scoutingNoteId: number;
    data: V2ScoutingNotesCommandsScoutingVoiceNotesAddBatchCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesBatchMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch = (
  scoutingNoteId: number,
  v2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/scoutingvoicenotes/text/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand,
    },
    options
  );
};

export const getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatchMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
        >
      >,
      TError,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
        >
      >,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
      }
    > = (props) => {
      const { scoutingNoteId, data } = props ?? {};

      return postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch(
        scoutingNoteId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
      >
    >
  >;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatchMutationBody =
  V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
        >
      >,
      TError,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatch
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextBatchCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextBatchMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText = (
  scoutingNoteId: number,
  v2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/scoutingvoicenotes/text`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand,
    },
    options
  );
};

export const getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
        >
      >,
      TError,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
        >
      >,
      {
        scoutingNoteId: number;
        data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
      }
    > = (props) => {
      const { scoutingNoteId, data } = props ?? {};

      return postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText(
        scoutingNoteId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
      >
    >
  >;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextMutationBody =
  V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
export type PostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
      >
    >,
    TError,
    {
      scoutingNoteId: number;
      data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof postApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesText
    >
  >,
  TError,
  {
    scoutingNoteId: number;
    data: V2ScoutingNotesCommandsScoutingVoiceNotesAddTextCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutingnotesScoutingNoteIdScoutingvoicenotesTextMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Scoutingnotes = (
  v2ScoutingNotesCommandsCreateCommand: V2ScoutingNotesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/scoutingnotes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutingNotesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ScoutingnotesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutingnotes>>,
    TError,
    { data: V2ScoutingNotesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Scoutingnotes>>,
  TError,
  { data: V2ScoutingNotesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Scoutingnotes>>,
    { data: V2ScoutingNotesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Scoutingnotes(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutingnotesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Scoutingnotes>>
>;
export type PostApiV2ScoutingnotesMutationBody =
  V2ScoutingNotesCommandsCreateCommand;
export type PostApiV2ScoutingnotesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Scoutingnotes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutingnotes>>,
    TError,
    { data: V2ScoutingNotesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Scoutingnotes>>,
  TError,
  { data: V2ScoutingNotesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2ScoutingnotesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2Scoutingnotes = (
  params?: GetApiV2ScoutingnotesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutingNotesQueriesListResponse>(
    { url: `/api/v2/scoutingnotes`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ScoutingnotesQueryKey = (
  params?: GetApiV2ScoutingnotesParams
) => {
  return [`/api/v2/scoutingnotes`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ScoutingnotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ScoutingnotesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Scoutingnotes>>
  > = ({ signal }) => getApiV2Scoutingnotes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutingnotesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Scoutingnotes>>
>;
export type GetApiV2ScoutingnotesQueryError = ErrorType<unknown>;

export function useGetApiV2Scoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ScoutingnotesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Scoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Scoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2Scoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Scoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutingnotesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutingnotesCount = (
  params?: GetApiV2ScoutingnotesCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutingNotesQueriesCountResponse>(
    { url: `/api/v2/scoutingnotes/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2ScoutingnotesCountQueryKey = (
  params?: GetApiV2ScoutingnotesCountParams
) => {
  return [`/api/v2/scoutingnotes/count`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2ScoutingnotesCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ScoutingnotesCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>
  > = ({ signal }) =>
    getApiV2ScoutingnotesCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutingnotesCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>
>;
export type GetApiV2ScoutingnotesCountQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2ScoutingnotesCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2ScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutingnotesCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted = (
  scoutingNoteId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/status/completed`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2ScoutingnotesScoutingNoteIdStatusCompletedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
      >,
      TError,
      { scoutingNoteId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
    >,
    TError,
    { scoutingNoteId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
      >,
      { scoutingNoteId: number }
    > = (props) => {
      const { scoutingNoteId } = props ?? {};

      return patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted(
        scoutingNoteId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ScoutingnotesScoutingNoteIdStatusCompletedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
    >
  >;

export type PatchApiV2ScoutingnotesScoutingNoteIdStatusCompletedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2ScoutingnotesScoutingNoteIdStatusCompleted = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
    >,
    TError,
    { scoutingNoteId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCompleted>
  >,
  TError,
  { scoutingNoteId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2ScoutingnotesScoutingNoteIdStatusCompletedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2ScoutingnotesScoutingNoteIdStatusCreated = (
  scoutingNoteId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/status/created`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2ScoutingnotesScoutingNoteIdStatusCreatedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
      >,
      TError,
      { scoutingNoteId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
    >,
    TError,
    { scoutingNoteId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
      >,
      { scoutingNoteId: number }
    > = (props) => {
      const { scoutingNoteId } = props ?? {};

      return patchApiV2ScoutingnotesScoutingNoteIdStatusCreated(
        scoutingNoteId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2ScoutingnotesScoutingNoteIdStatusCreatedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
    >
  >;

export type PatchApiV2ScoutingnotesScoutingNoteIdStatusCreatedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2ScoutingnotesScoutingNoteIdStatusCreated = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
    >,
    TError,
    { scoutingNoteId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2ScoutingnotesScoutingNoteIdStatusCreated>
  >,
  TError,
  { scoutingNoteId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2ScoutingnotesScoutingNoteIdStatusCreatedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutingnotesScoutingNoteIdSummary = (
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutingNotesQueriesSummaryResponse>(
    {
      url: `/api/v2/scoutingnotes/${scoutingNoteId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutingnotesScoutingNoteIdSummaryQueryKey = (
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams
) => {
  return [
    `/api/v2/scoutingnotes/${scoutingNoteId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutingnotesScoutingNoteIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutingnotesScoutingNoteIdSummaryQueryKey(
      scoutingNoteId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>
  > = ({ signal }) =>
    getApiV2ScoutingnotesScoutingNoteIdSummary(
      scoutingNoteId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutingNoteId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutingnotesScoutingNoteIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>
>;
export type GetApiV2ScoutingnotesScoutingNoteIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutingnotesScoutingNoteIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingNoteId: number,
  params: undefined | GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingnotesScoutingNoteIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingnotesScoutingNoteIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutingnotesScoutingNoteIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingNoteId: number,
  params?: GetApiV2ScoutingnotesScoutingNoteIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutingnotesScoutingNoteIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutingnotesScoutingNoteIdSummaryQueryOptions(
      scoutingNoteId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2ScoutingreportsScoutingReportId = (
  scoutingReportId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/scoutingreports/${scoutingReportId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2ScoutingreportsScoutingReportIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>,
    TError,
    { scoutingReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>,
  TError,
  { scoutingReportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>,
    { scoutingReportId: number }
  > = (props) => {
    const { scoutingReportId } = props ?? {};

    return deleteApiV2ScoutingreportsScoutingReportId(
      scoutingReportId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2ScoutingreportsScoutingReportIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>
  >;

export type DeleteApiV2ScoutingreportsScoutingReportIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2ScoutingreportsScoutingReportId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>,
    TError,
    { scoutingReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2ScoutingreportsScoutingReportId>>,
  TError,
  { scoutingReportId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2ScoutingreportsScoutingReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutingreportsScoutingReportIdSummary = (
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutingReportsQueriesSummaryResponse>(
    {
      url: `/api/v2/scoutingreports/${scoutingReportId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutingreportsScoutingReportIdSummaryQueryKey = (
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams
) => {
  return [
    `/api/v2/scoutingreports/${scoutingReportId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutingreportsScoutingReportIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutingreportsScoutingReportIdSummaryQueryKey(
      scoutingReportId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>>
  > = ({ signal }) =>
    getApiV2ScoutingreportsScoutingReportIdSummary(
      scoutingReportId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutingReportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutingreportsScoutingReportIdSummaryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>>
  >;
export type GetApiV2ScoutingreportsScoutingReportIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutingreportsScoutingReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingreportsScoutingReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutingreportsScoutingReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutingreportsScoutingReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  scoutingReportId: number,
  params: GetApiV2ScoutingreportsScoutingReportIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutingreportsScoutingReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutingreportsScoutingReportIdSummaryQueryOptions(
      scoutingReportId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Scoutingreports = (
  v2ScoutingReportsCommandsCreateCommand: V2ScoutingReportsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/scoutingreports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutingReportsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ScoutingreportsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutingreports>>,
    TError,
    { data: V2ScoutingReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Scoutingreports>>,
  TError,
  { data: V2ScoutingReportsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Scoutingreports>>,
    { data: V2ScoutingReportsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Scoutingreports(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutingreportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Scoutingreports>>
>;
export type PostApiV2ScoutingreportsMutationBody =
  V2ScoutingReportsCommandsCreateCommand;
export type PostApiV2ScoutingreportsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Scoutingreports = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scoutingreports>>,
    TError,
    { data: V2ScoutingReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Scoutingreports>>,
  TError,
  { data: V2ScoutingReportsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2ScoutingreportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Scouts = (
  v2ScoutsCommandsCreateCommand: V2ScoutsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/scouts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ScoutsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scouts>>,
    TError,
    { data: V2ScoutsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Scouts>>,
  TError,
  { data: V2ScoutsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Scouts>>,
    { data: V2ScoutsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Scouts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Scouts>>
>;
export type PostApiV2ScoutsMutationBody = V2ScoutsCommandsCreateCommand;
export type PostApiV2ScoutsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Scouts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Scouts>>,
    TError,
    { data: V2ScoutsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Scouts>>,
  TError,
  { data: V2ScoutsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2ScoutsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2ScoutsLogin = (
  v2ScoutsCommandsLoginCommand: V2ScoutsCommandsLoginCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2ScoutsCommandsLoginResponse>(
    {
      url: `/api/v2/scouts/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsLoginCommand,
    },
    options
  );
};

export const getPostApiV2ScoutsLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsLogin>>,
    TError,
    { data: V2ScoutsCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2ScoutsLogin>>,
  TError,
  { data: V2ScoutsCommandsLoginCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2ScoutsLogin>>,
    { data: V2ScoutsCommandsLoginCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2ScoutsLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutsLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ScoutsLogin>>
>;
export type PostApiV2ScoutsLoginMutationBody = V2ScoutsCommandsLoginCommand;
export type PostApiV2ScoutsLoginMutationError = ErrorType<unknown>;

export const usePostApiV2ScoutsLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsLogin>>,
    TError,
    { data: V2ScoutsCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2ScoutsLogin>>,
  TError,
  { data: V2ScoutsCommandsLoginCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2ScoutsLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutId = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesDetailsResponse>(
    { url: `/api/v2/scouts/${scoutId}`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2ScoutsScoutIdQueryKey = (scoutId: number) => {
  return [`/api/v2/scouts/${scoutId}`] as const;
};

export const getGetApiV2ScoutsScoutIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2ScoutsScoutIdQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>
  > = ({ signal }) => getApiV2ScoutsScoutId(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>
>;
export type GetApiV2ScoutsScoutIdQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutId<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutId<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutId<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutId<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdQueryOptions(scoutId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdAchievements = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesAchievementsListResponse>(
    { url: `/api/v2/scouts/${scoutId}/achievements`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2ScoutsScoutIdAchievementsQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/achievements`] as const;
};

export const getGetApiV2ScoutsScoutIdAchievementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdAchievementsQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdAchievements(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdAchievementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>
>;
export type GetApiV2ScoutsScoutIdAchievementsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdAchievements<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdAchievements<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdAchievements<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdAchievements<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAchievements>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdAchievementsQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdBusinesscards = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesBusinessCardsResponse>(
    { url: `/api/v2/scouts/${scoutId}/businesscards`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2ScoutsScoutIdBusinesscardsQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/businesscards`] as const;
};

export const getGetApiV2ScoutsScoutIdBusinesscardsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdBusinesscardsQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdBusinesscards(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdBusinesscardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>
>;
export type GetApiV2ScoutsScoutIdBusinesscardsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdBusinesscards<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdBusinesscards<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdBusinesscards<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdBusinesscards<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdBusinesscards>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdBusinesscardsQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdParentinvitations = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesParentInvitationsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/parentinvitations`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdParentinvitationsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/parentinvitations`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdParentinvitationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdParentinvitationsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdParentinvitations(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdParentinvitationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>
>;
export type GetApiV2ScoutsScoutIdParentinvitationsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdParentinvitations<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdParentinvitationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdParentinvitations<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdParentinvitations<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdParentinvitations<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdParentinvitationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdParentinvitations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdParentinvitationsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2ScoutsScoutIdProfileimage = (
  scoutId: number,
  v2ScoutsCommandsProfileImageCommand: V2ScoutsCommandsProfileImageCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/profileimage`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsProfileImageCommand,
    },
    options
  );
};

export const getPutApiV2ScoutsScoutIdProfileimageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsProfileImageCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsProfileImageCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>,
    { scoutId: number; data: V2ScoutsCommandsProfileImageCommand }
  > = (props) => {
    const { scoutId, data } = props ?? {};

    return putApiV2ScoutsScoutIdProfileimage(scoutId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2ScoutsScoutIdProfileimageMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>
>;
export type PutApiV2ScoutsScoutIdProfileimageMutationBody =
  V2ScoutsCommandsProfileImageCommand;
export type PutApiV2ScoutsScoutIdProfileimageMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2ScoutsScoutIdProfileimage = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsProfileImageCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdProfileimage>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsProfileImageCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2ScoutsScoutIdProfileimageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const getApiV2ScoutsScoutIdAvailableproclubs = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesAvailableProClubsResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/availableproclubs`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdAvailableproclubsQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/availableproclubs`] as const;
};

export const getGetApiV2ScoutsScoutIdAvailableproclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdAvailableproclubsQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdAvailableproclubs(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdAvailableproclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>
>;
export type GetApiV2ScoutsScoutIdAvailableproclubsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdAvailableproclubs<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdAvailableproclubs<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdAvailableproclubs<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */

export function useGetApiV2ScoutsScoutIdAvailableproclubs<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdAvailableproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdAvailableproclubsQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const postApiV2ScoutsScoutIdAppointedproclubs = (
  scoutId: number,
  v2ScoutsCommandsAppointedProClubsCreateCommand: V2ScoutsCommandsAppointedProClubsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/appointedproclubs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsAppointedProClubsCreateCommand,
    },
    options
  );
};

export const getPostApiV2ScoutsScoutIdAppointedproclubsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsAppointedProClubsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsAppointedProClubsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>,
    { scoutId: number; data: V2ScoutsCommandsAppointedProClubsCreateCommand }
  > = (props) => {
    const { scoutId, data } = props ?? {};

    return postApiV2ScoutsScoutIdAppointedproclubs(
      scoutId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutsScoutIdAppointedproclubsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>
>;
export type PostApiV2ScoutsScoutIdAppointedproclubsMutationBody =
  V2ScoutsCommandsAppointedProClubsCreateCommand;
export type PostApiV2ScoutsScoutIdAppointedproclubsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const usePostApiV2ScoutsScoutIdAppointedproclubs = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsAppointedProClubsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2ScoutsScoutIdAppointedproclubs>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsAppointedProClubsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutsScoutIdAppointedproclubsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,SeniorScout,GeneralScout,Administrator)
 */
export const deleteApiV2ScoutsScoutIdAppointedproclubsProClubId = (
  scoutId: number,
  proClubId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/appointedproclubs/${proClubId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2ScoutsScoutIdAppointedproclubsProClubIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
      >,
      TError,
      { scoutId: number; proClubId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
    >,
    TError,
    { scoutId: number; proClubId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
      >,
      { scoutId: number; proClubId: number }
    > = (props) => {
      const { scoutId, proClubId } = props ?? {};

      return deleteApiV2ScoutsScoutIdAppointedproclubsProClubId(
        scoutId,
        proClubId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2ScoutsScoutIdAppointedproclubsProClubIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
    >
  >;

export type DeleteApiV2ScoutsScoutIdAppointedproclubsProClubIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,SeniorScout,GeneralScout,Administrator)
 */
export const useDeleteApiV2ScoutsScoutIdAppointedproclubsProClubId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
    >,
    TError,
    { scoutId: number; proClubId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2ScoutsScoutIdAppointedproclubsProClubId>
  >,
  TError,
  { scoutId: number; proClubId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2ScoutsScoutIdAppointedproclubsProClubIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const putApiV2ScoutsScoutIdScoutdesignationtype = (
  scoutId: number,
  v2ScoutsCommandsScoutDesignationUpdateCommand: V2ScoutsCommandsScoutDesignationUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutdesignationtype`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsScoutDesignationUpdateCommand,
    },
    options
  );
};

export const getPutApiV2ScoutsScoutIdScoutdesignationtypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsScoutDesignationUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsScoutDesignationUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>,
    { scoutId: number; data: V2ScoutsCommandsScoutDesignationUpdateCommand }
  > = (props) => {
    const { scoutId, data } = props ?? {};

    return putApiV2ScoutsScoutIdScoutdesignationtype(
      scoutId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2ScoutsScoutIdScoutdesignationtypeMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>
  >;
export type PutApiV2ScoutsScoutIdScoutdesignationtypeMutationBody =
  V2ScoutsCommandsScoutDesignationUpdateCommand;
export type PutApiV2ScoutsScoutIdScoutdesignationtypeMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout,Administrator)
 */
export const usePutApiV2ScoutsScoutIdScoutdesignationtype = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>,
    TError,
    { scoutId: number; data: V2ScoutsCommandsScoutDesignationUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdScoutdesignationtype>>,
  TError,
  { scoutId: number; data: V2ScoutsCommandsScoutDesignationUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2ScoutsScoutIdScoutdesignationtypeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions = (
  scoutId: number,
  v2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2ScoutsCommandsRefreshTokensUpdatedPermissionsResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/refreshtokens/updatedpermissions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand,
    },
    options
  );
};

export const getPostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissionsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
      >,
      TError,
      {
        scoutId: number;
        data: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
    >,
    TError,
    {
      scoutId: number;
      data: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
      >,
      {
        scoutId: number;
        data: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
      }
    > = (props) => {
      const { scoutId, data } = props ?? {};

      return postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions(
        scoutId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissionsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
    >
  >;
export type PostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissionsMutationBody =
  V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
export type PostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissionsMutationError =
  ErrorType<unknown>;

export const usePostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
    >,
    TError,
    {
      scoutId: number;
      data: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postApiV2ScoutsScoutIdRefreshtokensUpdatedpermissions>
  >,
  TError,
  {
    scoutId: number;
    data: V2ScoutsCommandsRefreshTokensUpdatedPermissionsCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutsScoutIdRefreshtokensUpdatedpermissionsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2ScoutsScoutIdProclubsProClubIdSwitch = (
  scoutId: number,
  proClubId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2ScoutsCommandsProClubsSwitchResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/proclubs/${proClubId}/switch`,
      method: 'POST',
    },
    options
  );
};

export const getPostApiV2ScoutsScoutIdProclubsProClubIdSwitchMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>,
    TError,
    { scoutId: number; proClubId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>,
  TError,
  { scoutId: number; proClubId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>,
    { scoutId: number; proClubId: number }
  > = (props) => {
    const { scoutId, proClubId } = props ?? {};

    return postApiV2ScoutsScoutIdProclubsProClubIdSwitch(
      scoutId,
      proClubId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2ScoutsScoutIdProclubsProClubIdSwitchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>
  >;

export type PostApiV2ScoutsScoutIdProclubsProClubIdSwitchMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2ScoutsScoutIdProclubsProClubIdSwitch = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>,
    TError,
    { scoutId: number; proClubId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2ScoutsScoutIdProclubsProClubIdSwitch>>,
  TError,
  { scoutId: number; proClubId: number },
  TContext
> => {
  const mutationOptions =
    getPostApiV2ScoutsScoutIdProclubsProClubIdSwitchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdFollowingPlayers = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesFollowingPlayersListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/following/players`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdFollowingPlayersQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdFollowingPlayers(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdFollowingPlayersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>
>;
export type GetApiV2ScoutsScoutIdFollowingPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdFollowingPlayers<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdFollowingPlayersQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdFollowingPlayersCount = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesFollowingPlayersCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersCountQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/following/players/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdFollowingPlayersCountQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdFollowingPlayersCount(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdFollowingPlayersCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>
>;
export type GetApiV2ScoutsScoutIdFollowingPlayersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdFollowingPlayersCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdFollowingPlayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdFollowingPlayersCountQueryOptions(
      scoutId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions = (
  scoutId: number,
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesFollowingPlayersPositionsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players/${playerId}/positions`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryKey =
  (scoutId: number, playerId: number) => {
    return [
      `/api/v2/scouts/${scoutId}/following/players/${playerId}/positions`,
    ] as const;
  };

export const getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
    >,
    TError = ErrorType<unknown>
  >(
    scoutId: number,
    playerId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryKey(
        scoutId,
        playerId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
        >
      >
    > = ({ signal }) =>
      getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions(
        scoutId,
        playerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(scoutId && playerId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
    >
  >;
export type GetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsQueryOptions(
      scoutId,
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions = (
  scoutId: number,
  playerId: number,
  v2ScoutsCommandsFollowingPlayersPositionsUpsertCommand: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players/${playerId}/positions`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2ScoutsCommandsFollowingPlayersPositionsUpsertCommand,
    },
    options
  );
};

export const getPutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
        >
      >,
      TError,
      {
        scoutId: number;
        playerId: number;
        data: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
    >,
    TError,
    {
      scoutId: number;
      playerId: number;
      data: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions
        >
      >,
      {
        scoutId: number;
        playerId: number;
        data: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
      }
    > = (props) => {
      const { scoutId, playerId, data } = props ?? {};

      return putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions(
        scoutId,
        playerId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
    >
  >;
export type PutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsMutationBody =
  V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
export type PutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
    >,
    TError,
    {
      scoutId: number;
      playerId: number;
      data: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions>
  >,
  TError,
  {
    scoutId: number;
    playerId: number;
    data: V2ScoutsCommandsFollowingPlayersPositionsUpsertCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId = (
  scoutId: number,
  playerId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players/${playerId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2ScoutsScoutIdFollowingPlayersPlayerIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>
      >,
      TError,
      { scoutId: number; playerId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>
    >,
    TError,
    { scoutId: number; playerId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>
      >,
      { scoutId: number; playerId: number }
    > = (props) => {
      const { scoutId, playerId } = props ?? {};

      return deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId(
        scoutId,
        playerId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2ScoutsScoutIdFollowingPlayersPlayerIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>>
  >;

export type DeleteApiV2ScoutsScoutIdFollowingPlayersPlayerIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2ScoutsScoutIdFollowingPlayersPlayerId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>
    >,
    TError,
    { scoutId: number; playerId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2ScoutsScoutIdFollowingPlayersPlayerId>>,
  TError,
  { scoutId: number; playerId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2ScoutsScoutIdFollowingPlayersPlayerIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount = (
  scoutId: number,
  playerId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CommonResponsesCreatedResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/following/players/${playerId}/positions/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryKey =
  (scoutId: number, playerId: number) => {
    return [
      `/api/v2/scouts/${scoutId}/following/players/${playerId}/positions/count`,
    ] as const;
  };

export const getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
      >
    >,
    TError = ErrorType<unknown>
  >(
    scoutId: number,
    playerId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryKey(
        scoutId,
        playerId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
        >
      >
    > = ({ signal }) =>
      getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount(
        scoutId,
        playerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(scoutId && playerId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
      >
    >
  >;
export type GetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  playerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositionsCountQueryOptions(
      scoutId,
      playerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdNotifications = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesNotificationsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/notifications`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdNotificationsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/notifications`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdNotificationsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdNotifications(scoutId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>
>;
export type GetApiV2ScoutsScoutIdNotificationsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdNotificationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdNotificationsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdNotificationsStats = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesNotificationsStatsResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/notifications/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdNotificationsStatsQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/notifications/stats`] as const;
};

export const getGetApiV2ScoutsScoutIdNotificationsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdNotificationsStatsQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdNotificationsStats(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdNotificationsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>
>;
export type GetApiV2ScoutsScoutIdNotificationsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdNotificationsStatsQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2ScoutsScoutIdNotificationsSeen = (
  scoutId: number,
  params: PutApiV2ScoutsScoutIdNotificationsSeenParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/notifications/seen`,
      method: 'PUT',
      params,
    },
    options
  );
};

export const getPutApiV2ScoutsScoutIdNotificationsSeenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>,
    TError,
    { scoutId: number; params: PutApiV2ScoutsScoutIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>,
  TError,
  { scoutId: number; params: PutApiV2ScoutsScoutIdNotificationsSeenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>,
    { scoutId: number; params: PutApiV2ScoutsScoutIdNotificationsSeenParams }
  > = (props) => {
    const { scoutId, params } = props ?? {};

    return putApiV2ScoutsScoutIdNotificationsSeen(
      scoutId,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2ScoutsScoutIdNotificationsSeenMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>
>;

export type PutApiV2ScoutsScoutIdNotificationsSeenMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2ScoutsScoutIdNotificationsSeen = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>,
    TError,
    { scoutId: number; params: PutApiV2ScoutsScoutIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2ScoutsScoutIdNotificationsSeen>>,
  TError,
  { scoutId: number; params: PutApiV2ScoutsScoutIdNotificationsSeenParams },
  TContext
> => {
  const mutationOptions =
    getPutApiV2ScoutsScoutIdNotificationsSeenMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutingreports = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutingReportsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutingreports`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutingreportsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/scoutingreports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdScoutingreportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutingreportsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutingreports(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutingreportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>
>;
export type GetApiV2ScoutsScoutIdScoutingreportsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutingreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdScoutingreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutingreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutingreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutingreportsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutingreportsCount = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutingReportsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutingreports/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutingreportsCountQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/scoutingreports/count`] as const;
};

export const getGetApiV2ScoutsScoutIdScoutingreportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutingreportsCountQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutingreportsCount(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutingreportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>
>;
export type GetApiV2ScoutsScoutIdScoutingreportsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutingreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutingreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutingreportsCountQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutingreportsStats = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutingReportsStatsResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutingreports/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutingreportsStatsQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/scoutingreports/stats`] as const;
};

export const getGetApiV2ScoutsScoutIdScoutingreportsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutingreportsStatsQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutingreportsStats(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutingreportsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>
>;
export type GetApiV2ScoutsScoutIdScoutingreportsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutingreportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingreportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutingreportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingreportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutingreportsStatsQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutingnotes = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutingNotesListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutingnotes`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutingnotesQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/scoutingnotes`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdScoutingnotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutingnotesQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutingnotes(scoutId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutingnotesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>
>;
export type GetApiV2ScoutsScoutIdScoutingnotesQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutingnotes<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutingnotesQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutingnotesCount = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutingNotesCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutingnotes/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutingnotesCountQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/scoutingnotes/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdScoutingnotesCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutingnotesCountQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutingnotesCount(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutingnotesCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>
>;
export type GetApiV2ScoutsScoutIdScoutingnotesCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutingnotesCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdScoutingnotesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutingnotesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutingnotesCountQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdPlayerreportdraftsCount = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesPlayerReportDraftsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/playerreportdrafts/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/playerreportdrafts/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdPlayerreportdraftsCount(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>>
  >;
export type GetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdPlayerreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdPlayerreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdPlayerreportdraftsCountQueryOptions(
      scoutId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdPlayerreportdrafts = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesPlayerReportDraftsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/playerreportdrafts`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdPlayerreportdraftsQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/playerreportdrafts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdPlayerreportdraftsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdPlayerreportdraftsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdPlayerreportdrafts(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdPlayerreportdraftsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>
>;
export type GetApiV2ScoutsScoutIdPlayerreportdraftsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdPlayerreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdPlayerreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdPlayerreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdPlayerreportdraftsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdPlayerreportsCount = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesPlayerReportsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/playerreports/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdPlayerreportsCountQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/playerreports/count`] as const;
};

export const getGetApiV2ScoutsScoutIdPlayerreportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdPlayerreportsCountQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdPlayerreportsCount(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdPlayerreportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>
>;
export type GetApiV2ScoutsScoutIdPlayerreportsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdPlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdPlayerreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdPlayerreportsCountQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdPlayerreports = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesPlayerReportsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/playerreports`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdPlayerreportsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/playerreports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdPlayerreportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdPlayerreportsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdPlayerreports(scoutId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdPlayerreportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>
>;
export type GetApiV2ScoutsScoutIdPlayerreportsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdPlayerreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdPlayerreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdPlayerreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdPlayerreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdPlayerreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdPlayerreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdPlayerreportsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdSummaryreportsCount = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesSummaryReportsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/summaryreports/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdSummaryreportsCountQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/summaryreports/count`] as const;
};

export const getGetApiV2ScoutsScoutIdSummaryreportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdSummaryreportsCountQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdSummaryreportsCount(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdSummaryreportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>
>;
export type GetApiV2ScoutsScoutIdSummaryreportsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdSummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdSummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdSummaryreportsCountQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdSummaryreports = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesSummaryReportsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/summaryreports`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdSummaryreportsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/summaryreports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdSummaryreportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdSummaryreportsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdSummaryreports(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdSummaryreportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>
>;
export type GetApiV2ScoutsScoutIdSummaryreportsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdSummaryreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdSummaryreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdSummaryreports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdSummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdSummaryreportsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdSummaryreportdraftsCount = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesSummaryReportDraftsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/summaryreportdrafts/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/summaryreportdrafts/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdSummaryreportdraftsCount(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>>
  >;
export type GetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdSummaryreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdSummaryreportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdraftsCount>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdSummaryreportdraftsCountQueryOptions(
      scoutId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdSummaryreportdrafts = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesSummaryReportDraftsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/summaryreportdrafts`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdSummaryreportdraftsQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/summaryreportdrafts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdSummaryreportdraftsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdSummaryreportdraftsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdSummaryreportdrafts(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdSummaryreportdraftsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>
>;
export type GetApiV2ScoutsScoutIdSummaryreportdraftsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdSummaryreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdSummaryreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdSummaryreportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdSummaryreportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdSummaryreportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdSummaryreportdraftsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdGamereportsCount = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesGameReportsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/gamereports/count`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdGamereportsCountQueryKey = (
  scoutId: number
) => {
  return [`/api/v2/scouts/${scoutId}/gamereports/count`] as const;
};

export const getGetApiV2ScoutsScoutIdGamereportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdGamereportsCountQueryKey(scoutId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdGamereportsCount(scoutId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdGamereportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>
>;
export type GetApiV2ScoutsScoutIdGamereportsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdGamereportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdGamereportsCount<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdGamereportsCountQueryOptions(
    scoutId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdGamereports = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesGameReportsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/gamereports`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdGamereportsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/gamereports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdGamereportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdGamereportsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdGamereports(scoutId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdGamereportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>
>;
export type GetApiV2ScoutsScoutIdGamereportsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdGamereports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdGamereportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdGamereports<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdGamereportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdGamereportsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdGamereportdraftsCount = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesGameReportDraftsCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/gamereportdrafts/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdGamereportdraftsCountQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/gamereportdrafts/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdGamereportdraftsCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdGamereportdraftsCountQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdGamereportdraftsCount(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdGamereportdraftsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>
>;
export type GetApiV2ScoutsScoutIdGamereportdraftsCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdGamereportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdGamereportdraftsCount<
  TData = Awaited<
    ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdraftsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdGamereportdraftsCountQueryOptions(
      scoutId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdGamereportdrafts = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesGameReportDraftsListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/gamereportdrafts`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdGamereportdraftsQueryKey = (
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/gamereportdrafts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdGamereportdraftsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdGamereportdraftsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdGamereportdrafts(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdGamereportdraftsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>
>;
export type GetApiV2ScoutsScoutIdGamereportdraftsQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdGamereportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdGamereportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdGamereportdrafts<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: GetApiV2ScoutsScoutIdGamereportdraftsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdGamereportdrafts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdGamereportdraftsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdReportsStats = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesReportsStatsResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/reports/stats`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdReportsStatsQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/reports/stats`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdReportsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdReportsStatsQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdReportsStats(scoutId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdReportsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>
>;
export type GetApiV2ScoutsScoutIdReportsStatsQueryError = ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdReportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdReportsStatsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdReportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdReportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdReportsStats<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdReportsStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdReportsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdReportsStatsQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount = (
  scoutId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutAmateurFixturesUpcomingPendingCountResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutamateurfixtures/upcomingpendingcount`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryKey =
  (scoutId: number) => {
    return [
      `/api/v2/scouts/${scoutId}/scoutamateurfixtures/upcomingpendingcount`,
    ] as const;
  };

export const getGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
      >
    >,
    TError = ErrorType<unknown>
  >(
    scoutId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryKey(
        scoutId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
        >
      >
    > = ({ signal }) =>
      getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount(
        scoutId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!scoutId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
      >
    >
  >;
export type GetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
    >
  >,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2ScoutsScoutIdScoutamateurfixturesUpcomingpendingcountQueryOptions(
      scoutId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2ScoutsScoutIdScoutamateurfixtures = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2ScoutsQueriesScoutAmateurFixturesListResponse>(
    {
      url: `/api/v2/scouts/${scoutId}/scoutamateurfixtures`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2ScoutsScoutIdScoutamateurfixturesQueryKey = (
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams
) => {
  return [
    `/api/v2/scouts/${scoutId}/scoutamateurfixtures`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2ScoutsScoutIdScoutamateurfixturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2ScoutsScoutIdScoutamateurfixturesQueryKey(scoutId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>
  > = ({ signal }) =>
    getApiV2ScoutsScoutIdScoutamateurfixtures(
      scoutId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!scoutId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2ScoutsScoutIdScoutamateurfixturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>
>;
export type GetApiV2ScoutsScoutIdScoutamateurfixturesQueryError =
  ErrorType<unknown>;

export function useGetApiV2ScoutsScoutIdScoutamateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params: undefined | GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutamateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2ScoutsScoutIdScoutamateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2ScoutsScoutIdScoutamateurfixtures<
  TData = Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
  TError = ErrorType<unknown>
>(
  scoutId: number,
  params?: GetApiV2ScoutsScoutIdScoutamateurfixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2ScoutsScoutIdScoutamateurfixtures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2ScoutsScoutIdScoutamateurfixturesQueryOptions(
    scoutId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2SignuptiersFind = (
  params: GetApiV2SignuptiersFindParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SignUpTiersQueriesFindResponse>(
    { url: `/api/v2/signuptiers/find`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2SignuptiersFindQueryKey = (
  params: GetApiV2SignuptiersFindParams
) => {
  return [`/api/v2/signuptiers/find`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2SignuptiersFindQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2SignuptiersFindParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SignuptiersFindQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SignuptiersFind>>
  > = ({ signal }) => getApiV2SignuptiersFind(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SignuptiersFindQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SignuptiersFind>>
>;
export type GetApiV2SignuptiersFindQueryError = ErrorType<unknown>;

export function useGetApiV2SignuptiersFind<
  TData = Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2SignuptiersFindParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SignuptiersFind<
  TData = Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2SignuptiersFindParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SignuptiersFind<
  TData = Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2SignuptiersFindParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2SignuptiersFind<
  TData = Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2SignuptiersFindParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SignuptiersFind>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SignuptiersFindQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2Suggestedopportunityproclubs = (
  params?: GetApiV2SuggestedopportunityproclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SuggestedOpportunityProClubsQueriesListResponse>(
    {
      url: `/api/v2/suggestedopportunityproclubs`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2SuggestedopportunityproclubsQueryKey = (
  params?: GetApiV2SuggestedopportunityproclubsParams
) => {
  return [
    `/api/v2/suggestedopportunityproclubs`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2SuggestedopportunityproclubsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SuggestedopportunityproclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2SuggestedopportunityproclubsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>
  > = ({ signal }) =>
    getApiV2Suggestedopportunityproclubs(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SuggestedopportunityproclubsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>
>;
export type GetApiV2SuggestedopportunityproclubsQueryError = ErrorType<unknown>;

export function useGetApiV2Suggestedopportunityproclubs<
  TData = Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2SuggestedopportunityproclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Suggestedopportunityproclubs<
  TData = Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SuggestedopportunityproclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Suggestedopportunityproclubs<
  TData = Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SuggestedopportunityproclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2Suggestedopportunityproclubs<
  TData = Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SuggestedopportunityproclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Suggestedopportunityproclubs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SuggestedopportunityproclubsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2SummaryreportdraftsSummaryReportDraftId = (
  summaryReportDraftId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SummaryReportDraftsQueriesDetailsResponse>(
    {
      url: `/api/v2/summaryreportdrafts/${summaryReportDraftId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2SummaryreportdraftsSummaryReportDraftIdQueryKey = (
  summaryReportDraftId: number
) => {
  return [`/api/v2/summaryreportdrafts/${summaryReportDraftId}`] as const;
};

export const getGetApiV2SummaryreportdraftsSummaryReportDraftIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2SummaryreportdraftsSummaryReportDraftIdQueryKey(
      summaryReportDraftId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>>
  > = ({ signal }) =>
    getApiV2SummaryreportdraftsSummaryReportDraftId(
      summaryReportDraftId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!summaryReportDraftId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SummaryreportdraftsSummaryReportDraftIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>>
  >;
export type GetApiV2SummaryreportdraftsSummaryReportDraftIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2SummaryreportdraftsSummaryReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportdraftsSummaryReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportdraftsSummaryReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2SummaryreportdraftsSummaryReportDraftId<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2SummaryreportdraftsSummaryReportDraftIdQueryOptions(
      summaryReportDraftId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2SummaryreportdraftsSummaryReportDraftId = (
  summaryReportDraftId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/summaryreportdrafts/${summaryReportDraftId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteApiV2SummaryreportdraftsSummaryReportDraftIdMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
      >,
      TError,
      { summaryReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
    >,
    TError,
    { summaryReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
      >,
      { summaryReportDraftId: number }
    > = (props) => {
      const { summaryReportDraftId } = props ?? {};

      return deleteApiV2SummaryreportdraftsSummaryReportDraftId(
        summaryReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteApiV2SummaryreportdraftsSummaryReportDraftIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
    >
  >;

export type DeleteApiV2SummaryreportdraftsSummaryReportDraftIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2SummaryreportdraftsSummaryReportDraftId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
    >,
    TError,
    { summaryReportDraftId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteApiV2SummaryreportdraftsSummaryReportDraftId>
  >,
  TError,
  { summaryReportDraftId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2SummaryreportdraftsSummaryReportDraftIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted =
  (
    summaryReportDraftId: number,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/summaryreportdrafts/${summaryReportDraftId}/status/completed`,
        method: 'PATCH',
      },
      options
    );
  };

export const getPatchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompletedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
        >
      >,
      TError,
      { summaryReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
      >
    >,
    TError,
    { summaryReportDraftId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
        >
      >,
      { summaryReportDraftId: number }
    > = (props) => {
      const { summaryReportDraftId } = props ?? {};

      return patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted(
        summaryReportDraftId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompletedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
      >
    >
  >;

export type PatchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompletedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePatchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
        >
      >,
      TError,
      { summaryReportDraftId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompleted
      >
    >,
    TError,
    { summaryReportDraftId: number },
    TContext
  > => {
    const mutationOptions =
      getPatchApiV2SummaryreportdraftsSummaryReportDraftIdStatusCompletedMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Summaryreportdrafts = (
  v2SummaryReportDraftsCommandsCreateCommand: V2SummaryReportDraftsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/summaryreportdrafts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2SummaryReportDraftsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2SummaryreportdraftsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>,
    TError,
    { data: V2SummaryReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>,
  TError,
  { data: V2SummaryReportDraftsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>,
    { data: V2SummaryReportDraftsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Summaryreportdrafts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2SummaryreportdraftsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>
>;
export type PostApiV2SummaryreportdraftsMutationBody =
  V2SummaryReportDraftsCommandsCreateCommand;
export type PostApiV2SummaryreportdraftsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Summaryreportdrafts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>,
    TError,
    { data: V2SummaryReportDraftsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Summaryreportdrafts>>,
  TError,
  { data: V2SummaryReportDraftsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2SummaryreportdraftsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2SummaryreportdraftsSummaryReportDraftIdSummary = (
  summaryReportDraftId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/summaryreportdrafts/${summaryReportDraftId}/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryKey =
  (summaryReportDraftId: number) => {
    return [
      `/api/v2/summaryreportdrafts/${summaryReportDraftId}/summary`,
    ] as const;
  };

export const getGetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
    >,
    TError = ErrorType<unknown>
  >(
    summaryReportDraftId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryKey(
        summaryReportDraftId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
        >
      >
    > = ({ signal }) =>
      getApiV2SummaryreportdraftsSummaryReportDraftIdSummary(
        summaryReportDraftId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!summaryReportDraftId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
    >
  >;
export type GetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2SummaryreportdraftsSummaryReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportdraftsSummaryReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportdraftsSummaryReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2SummaryreportdraftsSummaryReportDraftIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportDraftId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2SummaryreportdraftsSummaryReportDraftIdSummary
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2SummaryreportdraftsSummaryReportDraftIdSummaryQueryOptions(
      summaryReportDraftId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const putApiV2SummaryreportdraftsSummaryReportDraftIdResponses = (
  summaryReportDraftId: number,
  v2SummaryReportDraftsCommandsResponsesUpdateCommand: V2SummaryReportDraftsCommandsResponsesUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/summaryreportdrafts/${summaryReportDraftId}/responses`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2SummaryReportDraftsCommandsResponsesUpdateCommand,
    },
    options
  );
};

export const getPutApiV2SummaryreportdraftsSummaryReportDraftIdResponsesMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses
        >
      >,
      TError,
      {
        summaryReportDraftId: number;
        data: V2SummaryReportDraftsCommandsResponsesUpdateCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses
      >
    >,
    TError,
    {
      summaryReportDraftId: number;
      data: V2SummaryReportDraftsCommandsResponsesUpdateCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses
        >
      >,
      {
        summaryReportDraftId: number;
        data: V2SummaryReportDraftsCommandsResponsesUpdateCommand;
      }
    > = (props) => {
      const { summaryReportDraftId, data } = props ?? {};

      return putApiV2SummaryreportdraftsSummaryReportDraftIdResponses(
        summaryReportDraftId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2SummaryreportdraftsSummaryReportDraftIdResponsesMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses
      >
    >
  >;
export type PutApiV2SummaryreportdraftsSummaryReportDraftIdResponsesMutationBody =
  V2SummaryReportDraftsCommandsResponsesUpdateCommand;
export type PutApiV2SummaryreportdraftsSummaryReportDraftIdResponsesMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePutApiV2SummaryreportdraftsSummaryReportDraftIdResponses = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses
      >
    >,
    TError,
    {
      summaryReportDraftId: number;
      data: V2SummaryReportDraftsCommandsResponsesUpdateCommand;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2SummaryreportdraftsSummaryReportDraftIdResponses>
  >,
  TError,
  {
    summaryReportDraftId: number;
    data: V2SummaryReportDraftsCommandsResponsesUpdateCommand;
  },
  TContext
> => {
  const mutationOptions =
    getPutApiV2SummaryreportdraftsSummaryReportDraftIdResponsesMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2Summaryreports = (
  v2SummaryReportsCommandsCreateCommand: V2SummaryReportsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/summaryreports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2SummaryReportsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2SummaryreportsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Summaryreports>>,
    TError,
    { data: V2SummaryReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Summaryreports>>,
  TError,
  { data: V2SummaryReportsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Summaryreports>>,
    { data: V2SummaryReportsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Summaryreports(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2SummaryreportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Summaryreports>>
>;
export type PostApiV2SummaryreportsMutationBody =
  V2SummaryReportsCommandsCreateCommand;
export type PostApiV2SummaryreportsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2Summaryreports = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Summaryreports>>,
    TError,
    { data: V2SummaryReportsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Summaryreports>>,
  TError,
  { data: V2SummaryReportsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2SummaryreportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2Summaryreports = (
  params?: GetApiV2SummaryreportsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SummaryReportsQueriesListResponse>(
    { url: `/api/v2/summaryreports`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2SummaryreportsQueryKey = (
  params?: GetApiV2SummaryreportsParams
) => {
  return [`/api/v2/summaryreports`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2SummaryreportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Summaryreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Summaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SummaryreportsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Summaryreports>>
  > = ({ signal }) => getApiV2Summaryreports(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Summaryreports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SummaryreportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Summaryreports>>
>;
export type GetApiV2SummaryreportsQueryError = ErrorType<unknown>;

export function useGetApiV2Summaryreports<
  TData = Awaited<ReturnType<typeof getApiV2Summaryreports>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2SummaryreportsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Summaryreports>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Summaryreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Summaryreports<
  TData = Awaited<ReturnType<typeof getApiV2Summaryreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Summaryreports>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Summaryreports>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Summaryreports<
  TData = Awaited<ReturnType<typeof getApiV2Summaryreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Summaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2Summaryreports<
  TData = Awaited<ReturnType<typeof getApiV2Summaryreports>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Summaryreports>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SummaryreportsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const deleteApiV2SummaryreportsSummaryReportId = (
  summaryReportId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/summaryreports/${summaryReportId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2SummaryreportsSummaryReportIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>,
    TError,
    { summaryReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>,
  TError,
  { summaryReportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>,
    { summaryReportId: number }
  > = (props) => {
    const { summaryReportId } = props ?? {};

    return deleteApiV2SummaryreportsSummaryReportId(
      summaryReportId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2SummaryreportsSummaryReportIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>
  >;

export type DeleteApiV2SummaryreportsSummaryReportIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const useDeleteApiV2SummaryreportsSummaryReportId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>,
    TError,
    { summaryReportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2SummaryreportsSummaryReportId>>,
  TError,
  { summaryReportId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2SummaryreportsSummaryReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2SummaryreportsSummaryReportIdSummary = (
  summaryReportId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SummaryReportsQueriesSummaryResponse>(
    {
      url: `/api/v2/summaryreports/${summaryReportId}/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2SummaryreportsSummaryReportIdSummaryQueryKey = (
  summaryReportId: number
) => {
  return [`/api/v2/summaryreports/${summaryReportId}/summary`] as const;
};

export const getGetApiV2SummaryreportsSummaryReportIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2SummaryreportsSummaryReportIdSummaryQueryKey(summaryReportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>>
  > = ({ signal }) =>
    getApiV2SummaryreportsSummaryReportIdSummary(
      summaryReportId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!summaryReportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SummaryreportsSummaryReportIdSummaryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>>
  >;
export type GetApiV2SummaryreportsSummaryReportIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2SummaryreportsSummaryReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportsSummaryReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportsSummaryReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2SummaryreportsSummaryReportIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  summaryReportId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2SummaryreportsSummaryReportIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2SummaryreportsSummaryReportIdSummaryQueryOptions(
      summaryReportId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2SummaryreportsCount = (
  params?: GetApiV2SummaryreportsCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2SummaryReportsQueriesCountResponse>(
    { url: `/api/v2/summaryreports/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2SummaryreportsCountQueryKey = (
  params?: GetApiV2SummaryreportsCountParams
) => {
  return [`/api/v2/summaryreports/count`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2SummaryreportsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2SummaryreportsCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>
  > = ({ signal }) =>
    getApiV2SummaryreportsCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2SummaryreportsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>
>;
export type GetApiV2SummaryreportsCountQueryError = ErrorType<unknown>;

export function useGetApiV2SummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2SummaryreportsCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2SummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2SummaryreportsCount<
  TData = Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2SummaryreportsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2SummaryreportsCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2SummaryreportsCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TeamsTeamtypes = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3TeamsQueriesMetadataTeamTypesListResponse>(
    { url: `/api/v2/teams/teamtypes`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2TeamsTeamtypesQueryKey = () => {
  return [`/api/v2/teams/teamtypes`] as const;
};

export const getGetApiV2TeamsTeamtypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TeamsTeamtypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>
  > = ({ signal }) => getApiV2TeamsTeamtypes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TeamsTeamtypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>
>;
export type GetApiV2TeamsTeamtypesQueryError = ErrorType<unknown>;

export function useGetApiV2TeamsTeamtypes<
  TData = Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsTeamtypes<
  TData = Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsTeamtypes<
  TData = Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TeamsTeamtypes<
  TData = Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TeamsTeamtypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TeamsTeamtypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2TeamsSearch = (
  params?: GetApiV2TeamsSearchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TeamsQueriesSearchListResponse>(
    { url: `/api/v2/teams/search`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TeamsSearchQueryKey = (
  params?: GetApiV2TeamsSearchParams
) => {
  return [`/api/v2/teams/search`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TeamsSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TeamsSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TeamsSearch>>
  > = ({ signal }) => getApiV2TeamsSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TeamsSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TeamsSearch>>
>;
export type GetApiV2TeamsSearchQueryError = ErrorType<unknown>;

export function useGetApiV2TeamsSearch<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TeamsSearchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsSearch<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsSearch<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2TeamsSearch<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TeamsSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TeamsSearchCount = (
  params?: GetApiV2TeamsSearchCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TeamsQueriesSearchCountResponse>(
    { url: `/api/v2/teams/search/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TeamsSearchCountQueryKey = (
  params?: GetApiV2TeamsSearchCountParams
) => {
  return [`/api/v2/teams/search/count`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TeamsSearchCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TeamsSearchCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>
  > = ({ signal }) => getApiV2TeamsSearchCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TeamsSearchCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>
>;
export type GetApiV2TeamsSearchCountQueryError = ErrorType<unknown>;

export function useGetApiV2TeamsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TeamsSearchCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TeamsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TeamsSearchCount<
  TData = Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TeamsSearchCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TeamsSearchCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TeamsSearchCountQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2Test = (
  params?: GetApiV2TestParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3TestsQueriesIndexResponse>(
    { url: `/api/v2/test`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TestQueryKey = (params?: GetApiV2TestParams) => {
  return [`/api/v2/test`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TestQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Test>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiV2Test>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2TestQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV2Test>>> = ({
    signal,
  }) => getApiV2Test(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Test>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Test>>
>;
export type GetApiV2TestQueryError = ErrorType<unknown>;

export function useGetApiV2Test<
  TData = Awaited<ReturnType<typeof getApiV2Test>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TestParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiV2Test>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Test>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Test<
  TData = Awaited<ReturnType<typeof getApiV2Test>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiV2Test>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Test>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Test<
  TData = Awaited<ReturnType<typeof getApiV2Test>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiV2Test>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2Test<
  TData = Awaited<ReturnType<typeof getApiV2Test>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiV2Test>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TestQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2TestError = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CommonResponsesBaseResponse>(
    { url: `/api/v2/test/error`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2TestErrorQueryKey = () => {
  return [`/api/v2/test/error`] as const;
};

export const getGetApiV2TestErrorQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TestError>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2TestErrorQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TestError>>
  > = ({ signal }) => getApiV2TestError(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TestError>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TestErrorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TestError>>
>;
export type GetApiV2TestErrorQueryError = ErrorType<unknown>;

export function useGetApiV2TestError<
  TData = Awaited<ReturnType<typeof getApiV2TestError>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestError>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestError>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestError<
  TData = Awaited<ReturnType<typeof getApiV2TestError>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestError>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestError>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestError<
  TData = Awaited<ReturnType<typeof getApiV2TestError>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2TestError<
  TData = Awaited<ReturnType<typeof getApiV2TestError>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TestErrorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2TestAuthenticated = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3TestsQueriesAuthenticatedResponse>(
    { url: `/api/v2/test/authenticated`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2TestAuthenticatedQueryKey = () => {
  return [`/api/v2/test/authenticated`] as const;
};

export const getGetApiV2TestAuthenticatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TestAuthenticatedQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TestAuthenticated>>
  > = ({ signal }) => getApiV2TestAuthenticated(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TestAuthenticatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TestAuthenticated>>
>;
export type GetApiV2TestAuthenticatedQueryError = ErrorType<unknown>;

export function useGetApiV2TestAuthenticated<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestAuthenticated<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestAuthenticated<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2TestAuthenticated<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticated>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TestAuthenticatedQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getApiV2TestAuthenticatedLambda = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V3TestsQueriesIndexResponse>(
    { url: `/api/v2/test/authenticated/lambda`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2TestAuthenticatedLambdaQueryKey = () => {
  return [`/api/v2/test/authenticated/lambda`] as const;
};

export const getGetApiV2TestAuthenticatedLambdaQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TestAuthenticatedLambdaQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>
  > = ({ signal }) => getApiV2TestAuthenticatedLambda(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TestAuthenticatedLambdaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>
>;
export type GetApiV2TestAuthenticatedLambdaQueryError = ErrorType<unknown>;

export function useGetApiV2TestAuthenticatedLambda<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
  TError = ErrorType<unknown>
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestAuthenticatedLambda<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TestAuthenticatedLambda<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2TestAuthenticatedLambda<
  TData = Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApiV2TestAuthenticatedLambda>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TestAuthenticatedLambdaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus =
  (
    trainingScheduleWeekId: number,
    dayPlanWorkoutId: number,
    v2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonResponsesSuccessResponse>(
      {
        url: `/api/v2/trainingscheduleweeks/${trainingScheduleWeekId}/dayplanworkouts/${dayPlanWorkoutId}/playerworkoutstatus`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: v2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand,
      },
      options
    );
  };

export const getPutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatusMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
        >
      >,
      TError,
      {
        trainingScheduleWeekId: number;
        dayPlanWorkoutId: number;
        data: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
      >
    >,
    TError,
    {
      trainingScheduleWeekId: number;
      dayPlanWorkoutId: number;
      data: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
        >
      >,
      {
        trainingScheduleWeekId: number;
        dayPlanWorkoutId: number;
        data: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
      }
    > = (props) => {
      const { trainingScheduleWeekId, dayPlanWorkoutId, data } = props ?? {};

      return putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus(
        trainingScheduleWeekId,
        dayPlanWorkoutId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatusMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
      >
    >
  >;
export type PutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatusMutationBody =
  V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
export type PutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatusMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Player)
 */
export const usePutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
        >
      >,
      TError,
      {
        trainingScheduleWeekId: number;
        dayPlanWorkoutId: number;
        data: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatus
      >
    >,
    TError,
    {
      trainingScheduleWeekId: number;
      dayPlanWorkoutId: number;
      data: V2TrainingScheduleWeeksCommandsPlayerWorkoutStatusesUpsertCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPutApiV2TrainingscheduleweeksTrainingScheduleWeekIdDayplanworkoutsDayPlanWorkoutIdPlayerworkoutstatusMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions =
  (
    trainingSessionGroupId: number,
    params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal
  ) => {
    return customInstance<V2TrainingSessionGroupsQueriesTrainingSessionsListResponse>(
      {
        url: `/api/v2/trainingsessiongroups/${trainingSessionGroupId}/trainingsessions`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };

export const getGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryKey =
  (
    trainingSessionGroupId: number,
    params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams
  ) => {
    return [
      `/api/v2/trainingsessiongroups/${trainingSessionGroupId}/trainingsessions`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
      >
    >,
    TError = ErrorType<unknown>
  >(
    trainingSessionGroupId: number,
    params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryKey(
        trainingSessionGroupId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
        >
      >
    > = ({ signal }) =>
      getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions(
        trainingSessionGroupId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!trainingSessionGroupId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
      >
    >
  >;
export type GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionGroupId: number,
  params:
    | undefined
    | GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionGroupId: number,
  params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionGroupId: number,
  params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionGroupId: number,
  params?: GetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessiongroupsTrainingSessionGroupIdTrainingsessionsQueryOptions(
      trainingSessionGroupId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const patchApiV2TrainingsessionsTrainingSessionIdStateOpened = (
  trainingSessionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/state/opened`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2TrainingsessionsTrainingSessionIdStateOpenedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened
        >
      >,
      TError,
      { trainingSessionId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened>
    >,
    TError,
    { trainingSessionId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened
        >
      >,
      { trainingSessionId: number }
    > = (props) => {
      const { trainingSessionId } = props ?? {};

      return patchApiV2TrainingsessionsTrainingSessionIdStateOpened(
        trainingSessionId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2TrainingsessionsTrainingSessionIdStateOpenedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened>
    >
  >;

export type PatchApiV2TrainingsessionsTrainingSessionIdStateOpenedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Coach)
 */
export const usePatchApiV2TrainingsessionsTrainingSessionIdStateOpened = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened>
    >,
    TError,
    { trainingSessionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateOpened>
  >,
  TError,
  { trainingSessionId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrainingsessionsTrainingSessionIdStateOpenedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Coach)
 */
export const patchApiV2TrainingsessionsTrainingSessionIdStateClosed = (
  trainingSessionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/state/closed`,
      method: 'PATCH',
    },
    options
  );
};

export const getPatchApiV2TrainingsessionsTrainingSessionIdStateClosedMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed
        >
      >,
      TError,
      { trainingSessionId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed>
    >,
    TError,
    { trainingSessionId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed
        >
      >,
      { trainingSessionId: number }
    > = (props) => {
      const { trainingSessionId } = props ?? {};

      return patchApiV2TrainingsessionsTrainingSessionIdStateClosed(
        trainingSessionId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchApiV2TrainingsessionsTrainingSessionIdStateClosedMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed>
    >
  >;

export type PatchApiV2TrainingsessionsTrainingSessionIdStateClosedMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Coach)
 */
export const usePatchApiV2TrainingsessionsTrainingSessionIdStateClosed = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed>
    >,
    TError,
    { trainingSessionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchApiV2TrainingsessionsTrainingSessionIdStateClosed>
  >,
  TError,
  { trainingSessionId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrainingsessionsTrainingSessionIdStateClosedMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Coach)
 */
export const putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch = (
  trainingSessionId: number,
  v2TrainingSessionsCommandsTrainingPlayersUpsertCommand: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers/batch`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrainingSessionsCommandsTrainingPlayersUpsertCommand,
    },
    options
  );
};

export const getPutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatchMutationOptions =
  <
    TError = ErrorType<V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponse>,
    TContext = unknown
  >(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
        >
      >,
      TError,
      {
        trainingSessionId: number;
        data: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
      >
    >,
    TError,
    {
      trainingSessionId: number;
      data: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
        >
      >,
      {
        trainingSessionId: number;
        data: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
      }
    > = (props) => {
      const { trainingSessionId, data } = props ?? {};

      return putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch(
        trainingSessionId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
      >
    >
  >;
export type PutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatchMutationBody =
  V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
export type PutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatchMutationError =
  ErrorType<V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponse>;

/**
 * @summary  (Auth roles: Coach)
 */
export const usePutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch =
  <
    TError = ErrorType<V2TrainingSessionsCommandsTrainingPlayersUpsertResponsesMisconfiguredPlayersErrorResponse>,
    TContext = unknown
  >(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
        >
      >,
      TError,
      {
        trainingSessionId: number;
        data: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
      },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof putApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatch
      >
    >,
    TError,
    {
      trainingSessionId: number;
      data: V2TrainingSessionsCommandsTrainingPlayersUpsertCommand;
    },
    TContext
  > => {
    const mutationOptions =
      getPutApiV2TrainingsessionsTrainingSessionIdTrainingplayersBatchMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdSummary = (
  trainingSessionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesSummaryResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdSummaryQueryKey = (
  trainingSessionId: number
) => {
  return [`/api/v2/trainingsessions/${trainingSessionId}/summary`] as const;
};

export const getGetApiV2TrainingsessionsTrainingSessionIdSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrainingsessionsTrainingSessionIdSummaryQueryKey(
      trainingSessionId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>>
  > = ({ signal }) =>
    getApiV2TrainingsessionsTrainingSessionIdSummary(
      trainingSessionId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trainingSessionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrainingsessionsTrainingSessionIdSummaryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>>
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdSummaryQueryOptions(
      trainingSessionId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdTrainingplayers = (
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesTrainingPlayersListResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryKey =
  (
    trainingSessionId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams
  ) => {
    return [
      `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
      >
    >,
    TError = ErrorType<unknown>
  >(
    trainingSessionId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryKey(
        trainingSessionId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
        >
      >
    > = ({ signal }) =>
      getApiV2TrainingsessionsTrainingSessionIdTrainingplayers(
        trainingSessionId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!trainingSessionId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
      >
    >
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayers<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params:
    | undefined
    | GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayers<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayers<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayers<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayers
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersQueryOptions(
      trainingSessionId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount = (
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesTrainingPlayersCountResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryKey =
  (
    trainingSessionId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams
  ) => {
    return [
      `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers/count`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
      >
    >,
    TError = ErrorType<unknown>
  >(
    trainingSessionId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryKey(
        trainingSessionId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
        >
      >
    > = ({ signal }) =>
      getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount(
        trainingSessionId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!trainingSessionId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
      >
    >
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params:
    | undefined
    | GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersCount
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersCountQueryOptions(
      trainingSessionId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats = (
  trainingSessionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesTrainingPlayersStatsResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryKey =
  (trainingSessionId: number) => {
    return [
      `/api/v2/trainingsessions/${trainingSessionId}/trainingplayers/stats`,
    ] as const;
  };

export const getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
      >
    >,
    TError = ErrorType<unknown>
  >(
    trainingSessionId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryKey(
        trainingSessionId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
        >
      >
    > = ({ signal }) =>
      getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats(
        trainingSessionId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!trainingSessionId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
      >
    >
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrainingplayersStats
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdTrainingplayersStatsQueryOptions(
      trainingSessionId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsBatch = (
  params: GetApiV2TrainingsessionsBatchParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesBatchResponse>(
    { url: `/api/v2/trainingsessions/batch`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrainingsessionsBatchQueryKey = (
  params: GetApiV2TrainingsessionsBatchParams
) => {
  return [
    `/api/v2/trainingsessions/batch`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrainingsessionsBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrainingsessionsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrainingsessionsBatchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>
  > = ({ signal }) =>
    getApiV2TrainingsessionsBatch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrainingsessionsBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>
>;
export type GetApiV2TrainingsessionsBatchQueryError = ErrorType<unknown>;

export function useGetApiV2TrainingsessionsBatch<
  TData = Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrainingsessionsBatchParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsBatch<
  TData = Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrainingsessionsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsBatch<
  TData = Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrainingsessionsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsBatch<
  TData = Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrainingsessionsBatchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrainingsessionsBatch>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrainingsessionsBatchQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers = (
  trainingSessionId: number,
  trialId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesTrialsPlayersListResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trials/${trialId}/players`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryKey =
  (
    trainingSessionId: number,
    trialId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams
  ) => {
    return [
      `/api/v2/trainingsessions/${trainingSessionId}/trials/${trialId}/players`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
      >
    >,
    TError = ErrorType<unknown>
  >(
    trainingSessionId: number,
    trialId: number,
    params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryKey(
        trainingSessionId,
        trialId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
        >
      >
    > = ({ signal }) =>
      getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers(
        trainingSessionId,
        trialId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(trainingSessionId && trialId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
      >
    >
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  trialId: number,
  params:
    | undefined
    | GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  trialId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  trialId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers<
  TData = Awaited<
    ReturnType<
      typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
    >
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  trialId: number,
  params?: GetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayers
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdTrialsTrialIdPlayersQueryOptions(
      trainingSessionId,
      trialId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Coach)
 */
export const getApiV2TrainingsessionsTrainingSessionIdTrials = (
  trainingSessionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrainingSessionsQueriesTrialsListResponse>(
    {
      url: `/api/v2/trainingsessions/${trainingSessionId}/trials`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrialsQueryKey = (
  trainingSessionId: number
) => {
  return [`/api/v2/trainingsessions/${trainingSessionId}/trials`] as const;
};

export const getGetApiV2TrainingsessionsTrainingSessionIdTrialsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrainingsessionsTrainingSessionIdTrialsQueryKey(
      trainingSessionId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>>
  > = ({ signal }) =>
    getApiV2TrainingsessionsTrainingSessionIdTrials(
      trainingSessionId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trainingSessionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrainingsessionsTrainingSessionIdTrialsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>>
  >;
export type GetApiV2TrainingsessionsTrainingSessionIdTrialsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrainingsessionsTrainingSessionIdTrials<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrials<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrainingsessionsTrainingSessionIdTrials<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Coach)
 */

export function useGetApiV2TrainingsessionsTrainingSessionIdTrials<
  TData = Awaited<
    ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
  >,
  TError = ErrorType<unknown>
>(
  trainingSessionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrainingsessionsTrainingSessionIdTrials>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrainingsessionsTrainingSessionIdTrialsQueryOptions(
      trainingSessionId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialcategoriesTrialCategoryIdProclubs = (
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialCategoriesQueriesProClubsListResponse>(
    {
      url: `/api/v2/trialcategories/${trialCategoryId}/proclubs`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialcategoriesTrialCategoryIdProclubsQueryKey = (
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams
) => {
  return [
    `/api/v2/trialcategories/${trialCategoryId}/proclubs`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialcategoriesTrialCategoryIdProclubsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialcategoriesTrialCategoryIdProclubsQueryKey(
      trialCategoryId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>>
  > = ({ signal }) =>
    getApiV2TrialcategoriesTrialCategoryIdProclubs(
      trialCategoryId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialCategoryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialcategoriesTrialCategoryIdProclubsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>>
  >;
export type GetApiV2TrialcategoriesTrialCategoryIdProclubsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialcategoriesTrialCategoryIdProclubs<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params: undefined | GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialcategoriesTrialCategoryIdProclubs<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialcategoriesTrialCategoryIdProclubs<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialcategoriesTrialCategoryIdProclubs<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdProclubsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdProclubs>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrialcategoriesTrialCategoryIdProclubsQueryOptions(
      trialCategoryId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2Trialcategories = (
  params?: GetApiV2TrialcategoriesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialCategoriesQueriesListResponse>(
    { url: `/api/v2/trialcategories`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialcategoriesQueryKey = (
  params?: GetApiV2TrialcategoriesParams
) => {
  return [`/api/v2/trialcategories`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TrialcategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Trialcategories>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialcategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialcategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialcategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Trialcategories>>
  > = ({ signal }) => getApiV2Trialcategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Trialcategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialcategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Trialcategories>>
>;
export type GetApiV2TrialcategoriesQueryError = ErrorType<unknown>;

export function useGetApiV2Trialcategories<
  TData = Awaited<ReturnType<typeof getApiV2Trialcategories>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TrialcategoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialcategories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Trialcategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Trialcategories<
  TData = Awaited<ReturnType<typeof getApiV2Trialcategories>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialcategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialcategories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Trialcategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Trialcategories<
  TData = Awaited<ReturnType<typeof getApiV2Trialcategories>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialcategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialcategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2Trialcategories<
  TData = Awaited<ReturnType<typeof getApiV2Trialcategories>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialcategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialcategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialcategoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialcategoriesTrialCategoryIdTrialgroups = (
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialCategoriesQueriesTrialGroupsListResponse>(
    {
      url: `/api/v2/trialcategories/${trialCategoryId}/trialgroups`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryKey = (
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams
) => {
  return [
    `/api/v2/trialcategories/${trialCategoryId}/trialgroups`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
    >,
    TError = ErrorType<unknown>
  >(
    trialCategoryId: number,
    params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryKey(
        trialCategoryId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
      >
    > = ({ signal }) =>
      getApiV2TrialcategoriesTrialCategoryIdTrialgroups(
        trialCategoryId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!trialCategoryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
    >
  >;
export type GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialcategoriesTrialCategoryIdTrialgroups<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params: undefined | GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialcategoriesTrialCategoryIdTrialgroups<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialcategoriesTrialCategoryIdTrialgroups<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialcategoriesTrialCategoryIdTrialgroups<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
  >,
  TError = ErrorType<unknown>
>(
  trialCategoryId: number,
  params?: GetApiV2TrialcategoriesTrialCategoryIdTrialgroupsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialcategoriesTrialCategoryIdTrialgroups>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrialcategoriesTrialCategoryIdTrialgroupsQueryOptions(
      trialCategoryId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialgroupsTrialGroupIdSummary = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesSummaryResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsTrialGroupIdSummaryQueryKey = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams
) => {
  return [
    `/api/v2/trialgroups/${trialGroupId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsTrialGroupIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsTrialGroupIdSummaryQueryKey(trialGroupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>
  > = ({ signal }) =>
    getApiV2TrialgroupsTrialGroupIdSummary(
      trialGroupId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsTrialGroupIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>
>;
export type GetApiV2TrialgroupsTrialGroupIdSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialgroupsTrialGroupIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params: undefined | GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialgroupsTrialGroupIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsTrialGroupIdSummaryQueryOptions(
    trialGroupId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialgroupsTrialGroupId = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesDetailsResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsTrialGroupIdQueryKey = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams
) => {
  return [
    `/api/v2/trialgroups/${trialGroupId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsTrialGroupIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsTrialGroupIdQueryKey(trialGroupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>
  > = ({ signal }) =>
    getApiV2TrialgroupsTrialGroupId(
      trialGroupId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsTrialGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>
>;
export type GetApiV2TrialgroupsTrialGroupIdQueryError = ErrorType<unknown>;

export function useGetApiV2TrialgroupsTrialGroupId<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params: undefined | GetApiV2TrialgroupsTrialGroupIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupId<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupId<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialgroupsTrialGroupId<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsTrialGroupIdQueryOptions(
    trialGroupId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2TrialgroupsTrialGroupId = (
  trialGroupId: number,
  v2TrialGroupsCommandsUpdateCommand: V2TrialGroupsCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialGroupsCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2TrialgroupsTrialGroupIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>,
    TError,
    { trialGroupId: number; data: V2TrialGroupsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>,
  TError,
  { trialGroupId: number; data: V2TrialGroupsCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>,
    { trialGroupId: number; data: V2TrialGroupsCommandsUpdateCommand }
  > = (props) => {
    const { trialGroupId, data } = props ?? {};

    return putApiV2TrialgroupsTrialGroupId(trialGroupId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2TrialgroupsTrialGroupIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>
>;
export type PutApiV2TrialgroupsTrialGroupIdMutationBody =
  V2TrialGroupsCommandsUpdateCommand;
export type PutApiV2TrialgroupsTrialGroupIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2TrialgroupsTrialGroupId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>,
    TError,
    { trialGroupId: number; data: V2TrialGroupsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupId>>,
  TError,
  { trialGroupId: number; data: V2TrialGroupsCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2TrialgroupsTrialGroupIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2TrialgroupsTrialGroupId = (
  trialGroupId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2TrialGroupsQueriesInfoItemsListResponse>(
    { url: `/api/v2/trialgroups/${trialGroupId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2TrialgroupsTrialGroupIdMutationOptions = <
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>,
    { trialGroupId: number }
  > = (props) => {
    const { trialGroupId } = props ?? {};

    return deleteApiV2TrialgroupsTrialGroupId(trialGroupId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2TrialgroupsTrialGroupIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>
>;

export type DeleteApiV2TrialgroupsTrialGroupIdMutationError =
  ErrorType<CommonResponsesForbiddenAccessResponse>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2TrialgroupsTrialGroupId = <
  TError = ErrorType<CommonResponsesForbiddenAccessResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupId>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2TrialgroupsTrialGroupIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialgroupsPopupquestions = (
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesPopUpQuestionsResponse>(
    {
      url: `/api/v2/trialgroups/popupquestions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsPopupquestionsQueryKey = (
  params: GetApiV2TrialgroupsPopupquestionsParams
) => {
  return [
    `/api/v2/trialgroups/popupquestions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsPopupquestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsPopupquestionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>
  > = ({ signal }) =>
    getApiV2TrialgroupsPopupquestions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsPopupquestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>
>;
export type GetApiV2TrialgroupsPopupquestionsQueryError = ErrorType<unknown>;

export function useGetApiV2TrialgroupsPopupquestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsPopupquestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsPopupquestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialgroupsPopupquestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsPopupquestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsPopupquestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsPopupquestionsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialgroupsQuestions = (
  params: GetApiV2TrialgroupsQuestionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesQuestionsResponse>(
    { url: `/api/v2/trialgroups/questions`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialgroupsQuestionsQueryKey = (
  params: GetApiV2TrialgroupsQuestionsParams
) => {
  return [
    `/api/v2/trialgroups/questions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsQuestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialgroupsQuestionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>
  > = ({ signal }) =>
    getApiV2TrialgroupsQuestions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsQuestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>
>;
export type GetApiV2TrialgroupsQuestionsQueryError = ErrorType<unknown>;

export function useGetApiV2TrialgroupsQuestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsQuestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsQuestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsQuestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialgroupsQuestions<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
  TError = ErrorType<unknown>
>(
  params: GetApiV2TrialgroupsQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsQuestions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsQuestionsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialgroupsTrialGroupIdTrialsSummary = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesTrialsSummaryResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}/trials/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryKey = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams
) => {
  return [
    `/api/v2/trialgroups/${trialGroupId}/trials/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
  >,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryKey(
      trialGroupId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>>
  > = ({ signal }) =>
    getApiV2TrialgroupsTrialGroupIdTrialsSummary(
      trialGroupId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>>
  >;
export type GetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialgroupsTrialGroupIdTrialsSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
  >,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params: undefined | GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdTrialsSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
  >,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdTrialsSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
  >,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialgroupsTrialGroupIdTrialsSummary<
  TData = Awaited<
    ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
  >,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdTrialsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getApiV2TrialgroupsTrialGroupIdTrialsSummary>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetApiV2TrialgroupsTrialGroupIdTrialsSummaryQueryOptions(
      trialGroupId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialgroupsSummary = (
  params?: GetApiV2TrialgroupsSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesSummaryListResponse>(
    { url: `/api/v2/trialgroups/summary`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialgroupsSummaryQueryKey = (
  params?: GetApiV2TrialgroupsSummaryParams
) => {
  return [`/api/v2/trialgroups/summary`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TrialgroupsSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialgroupsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialgroupsSummaryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>
  > = ({ signal }) =>
    getApiV2TrialgroupsSummary(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>
>;
export type GetApiV2TrialgroupsSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2TrialgroupsSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TrialgroupsSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialgroupsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialgroupsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialgroupsSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialgroupsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsSummaryQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player)
 */
export const getApiV2TrialgroupsTrialGroupIdInfoitems = (
  trialGroupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesInfoItemsListResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}/infoitems`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsTrialGroupIdInfoitemsQueryKey = (
  trialGroupId: number
) => {
  return [`/api/v2/trialgroups/${trialGroupId}/infoitems`] as const;
};

export const getGetApiV2TrialgroupsTrialGroupIdInfoitemsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsTrialGroupIdInfoitemsQueryKey(trialGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>
  > = ({ signal }) =>
    getApiV2TrialgroupsTrialGroupIdInfoitems(
      trialGroupId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsTrialGroupIdInfoitemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>
>;
export type GetApiV2TrialgroupsTrialGroupIdInfoitemsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialgroupsTrialGroupIdInfoitems<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdInfoitems<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdInfoitems<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player)
 */

export function useGetApiV2TrialgroupsTrialGroupIdInfoitems<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdInfoitems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsTrialGroupIdInfoitemsQueryOptions(
    trialGroupId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Trialgroups = (
  v2TrialGroupsCommandsCreateCommand: V2TrialGroupsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/trialgroups`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialGroupsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2TrialgroupsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trialgroups>>,
    TError,
    { data: V2TrialGroupsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Trialgroups>>,
  TError,
  { data: V2TrialGroupsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Trialgroups>>,
    { data: V2TrialGroupsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Trialgroups(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2TrialgroupsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Trialgroups>>
>;
export type PostApiV2TrialgroupsMutationBody =
  V2TrialGroupsCommandsCreateCommand;
export type PostApiV2TrialgroupsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Trialgroups = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trialgroups>>,
    TError,
    { data: V2TrialGroupsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Trialgroups>>,
  TError,
  { data: V2TrialGroupsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2TrialgroupsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2TrialgroupsTrialGroupIdCache = (
  trialGroupId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/trialgroups/${trialGroupId}/cache`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2TrialgroupsTrialGroupIdCacheMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>,
    { trialGroupId: number }
  > = (props) => {
    const { trialGroupId } = props ?? {};

    return deleteApiV2TrialgroupsTrialGroupIdCache(
      trialGroupId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2TrialgroupsTrialGroupIdCacheMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>
>;

export type DeleteApiV2TrialgroupsTrialGroupIdCacheMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2TrialgroupsTrialGroupIdCache = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2TrialgroupsTrialGroupIdCache>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2TrialgroupsTrialGroupIdCacheMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2TrialgroupsTrialGroupIdEnabled = (
  trialGroupId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesBaseResponse>(
    { url: `/api/v2/trialgroups/${trialGroupId}/enabled`, method: 'PATCH' },
    options
  );
};

export const getPatchApiV2TrialgroupsTrialGroupIdEnabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>,
    { trialGroupId: number }
  > = (props) => {
    const { trialGroupId } = props ?? {};

    return patchApiV2TrialgroupsTrialGroupIdEnabled(
      trialGroupId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2TrialgroupsTrialGroupIdEnabledMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>
  >;

export type PatchApiV2TrialgroupsTrialGroupIdEnabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2TrialgroupsTrialGroupIdEnabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdEnabled>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrialgroupsTrialGroupIdEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2TrialgroupsTrialGroupIdDisabled = (
  trialGroupId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesBaseResponse>(
    { url: `/api/v2/trialgroups/${trialGroupId}/disabled`, method: 'PATCH' },
    options
  );
};

export const getPatchApiV2TrialgroupsTrialGroupIdDisabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>,
    { trialGroupId: number }
  > = (props) => {
    const { trialGroupId } = props ?? {};

    return patchApiV2TrialgroupsTrialGroupIdDisabled(
      trialGroupId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2TrialgroupsTrialGroupIdDisabledMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>
  >;

export type PatchApiV2TrialgroupsTrialGroupIdDisabledMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2TrialgroupsTrialGroupIdDisabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>,
    TError,
    { trialGroupId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2TrialgroupsTrialGroupIdDisabled>>,
  TError,
  { trialGroupId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrialgroupsTrialGroupIdDisabledMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2TrialgroupsTrialGroupIdRules = (
  trialGroupId: number,
  v2TrialGroupsCommandsRulesUpdateCommand: V2TrialGroupsCommandsRulesUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}/rules`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialGroupsCommandsRulesUpdateCommand,
    },
    options
  );
};

export const getPutApiV2TrialgroupsTrialGroupIdRulesMutationOptions = <
  TError = ErrorType<V2TrialGroupsCommandsRulesUpdateInvalidRulesResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>,
    TError,
    { trialGroupId: number; data: V2TrialGroupsCommandsRulesUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>,
  TError,
  { trialGroupId: number; data: V2TrialGroupsCommandsRulesUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>,
    { trialGroupId: number; data: V2TrialGroupsCommandsRulesUpdateCommand }
  > = (props) => {
    const { trialGroupId, data } = props ?? {};

    return putApiV2TrialgroupsTrialGroupIdRules(
      trialGroupId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2TrialgroupsTrialGroupIdRulesMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>
>;
export type PutApiV2TrialgroupsTrialGroupIdRulesMutationBody =
  V2TrialGroupsCommandsRulesUpdateCommand;
export type PutApiV2TrialgroupsTrialGroupIdRulesMutationError =
  ErrorType<V2TrialGroupsCommandsRulesUpdateInvalidRulesResponse>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2TrialgroupsTrialGroupIdRules = <
  TError = ErrorType<V2TrialGroupsCommandsRulesUpdateInvalidRulesResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>,
    TError,
    { trialGroupId: number; data: V2TrialGroupsCommandsRulesUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2TrialgroupsTrialGroupIdRules>>,
  TError,
  { trialGroupId: number; data: V2TrialGroupsCommandsRulesUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2TrialgroupsTrialGroupIdRulesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2TrialgroupsTrialGroupIdRules = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialGroupsQueriesRulesResponse>(
    {
      url: `/api/v2/trialgroups/${trialGroupId}/rules`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialgroupsTrialGroupIdRulesQueryKey = (
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams
) => {
  return [
    `/api/v2/trialgroups/${trialGroupId}/rules`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialgroupsTrialGroupIdRulesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialgroupsTrialGroupIdRulesQueryKey(trialGroupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>
  > = ({ signal }) =>
    getApiV2TrialgroupsTrialGroupIdRules(
      trialGroupId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialgroupsTrialGroupIdRulesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>
>;
export type GetApiV2TrialgroupsTrialGroupIdRulesQueryError = ErrorType<unknown>;

export function useGetApiV2TrialgroupsTrialGroupIdRules<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params: undefined | GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdRules<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialgroupsTrialGroupIdRules<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2TrialgroupsTrialGroupIdRules<
  TData = Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
  TError = ErrorType<unknown>
>(
  trialGroupId: number,
  params?: GetApiV2TrialgroupsTrialGroupIdRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialgroupsTrialGroupIdRules>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialgroupsTrialGroupIdRulesQueryOptions(
    trialGroupId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator,Scout)
 */
export const getApiV2Trialidentities = (
  params?: GetApiV2TrialidentitiesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialIdentitiesQueriesListResponse>(
    { url: `/api/v2/trialidentities`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialidentitiesQueryKey = (
  params?: GetApiV2TrialidentitiesParams
) => {
  return [`/api/v2/trialidentities`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TrialidentitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2Trialidentities>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2TrialidentitiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialidentities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialidentitiesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2Trialidentities>>
  > = ({ signal }) => getApiV2Trialidentities(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2Trialidentities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialidentitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Trialidentities>>
>;
export type GetApiV2TrialidentitiesQueryError = ErrorType<void>;

export function useGetApiV2Trialidentities<
  TData = Awaited<ReturnType<typeof getApiV2Trialidentities>>,
  TError = ErrorType<void>
>(
  params: undefined | GetApiV2TrialidentitiesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialidentities>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Trialidentities>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Trialidentities<
  TData = Awaited<ReturnType<typeof getApiV2Trialidentities>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2TrialidentitiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialidentities>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2Trialidentities>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2Trialidentities<
  TData = Awaited<ReturnType<typeof getApiV2Trialidentities>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2TrialidentitiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialidentities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator,Scout)
 */

export function useGetApiV2Trialidentities<
  TData = Awaited<ReturnType<typeof getApiV2Trialidentities>>,
  TError = ErrorType<void>
>(
  params?: GetApiV2TrialidentitiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2Trialidentities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialidentitiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Trialidentities = (
  v2TrialIdentitiesCommandsCreateCommand: V2TrialIdentitiesCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/trialidentities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialIdentitiesCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2TrialidentitiesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trialidentities>>,
    TError,
    { data: V2TrialIdentitiesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Trialidentities>>,
  TError,
  { data: V2TrialIdentitiesCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Trialidentities>>,
    { data: V2TrialIdentitiesCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Trialidentities(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2TrialidentitiesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Trialidentities>>
>;
export type PostApiV2TrialidentitiesMutationBody =
  V2TrialIdentitiesCommandsCreateCommand;
export type PostApiV2TrialidentitiesMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Trialidentities = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trialidentities>>,
    TError,
    { data: V2TrialIdentitiesCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Trialidentities>>,
  TError,
  { data: V2TrialIdentitiesCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2TrialidentitiesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2TrialidentitiesTrialIdentityId = (
  trialIdentityId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialIdentitiesQueriesDetailsResponse>(
    {
      url: `/api/v2/trialidentities/${trialIdentityId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrialidentitiesTrialIdentityIdQueryKey = (
  trialIdentityId: number
) => {
  return [`/api/v2/trialidentities/${trialIdentityId}`] as const;
};

export const getGetApiV2TrialidentitiesTrialIdentityIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
  TError = ErrorType<unknown>
>(
  trialIdentityId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialidentitiesTrialIdentityIdQueryKey(trialIdentityId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>
  > = ({ signal }) =>
    getApiV2TrialidentitiesTrialIdentityId(
      trialIdentityId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialIdentityId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialidentitiesTrialIdentityIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>
>;
export type GetApiV2TrialidentitiesTrialIdentityIdQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialidentitiesTrialIdentityId<
  TData = Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
  TError = ErrorType<unknown>
>(
  trialIdentityId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialidentitiesTrialIdentityId<
  TData = Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
  TError = ErrorType<unknown>
>(
  trialIdentityId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialidentitiesTrialIdentityId<
  TData = Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
  TError = ErrorType<unknown>
>(
  trialIdentityId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2TrialidentitiesTrialIdentityId<
  TData = Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
  TError = ErrorType<unknown>
>(
  trialIdentityId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialidentitiesTrialIdentityId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialidentitiesTrialIdentityIdQueryOptions(
    trialIdentityId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2TrialidentitiesTrialIdentityId = (
  trialIdentityId: number,
  v2TrialIdentitiesCommandsUpdateCommand: V2TrialIdentitiesCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trialidentities/${trialIdentityId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialIdentitiesCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2TrialidentitiesTrialIdentityIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>,
    TError,
    { trialIdentityId: number; data: V2TrialIdentitiesCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>,
  TError,
  { trialIdentityId: number; data: V2TrialIdentitiesCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>,
    { trialIdentityId: number; data: V2TrialIdentitiesCommandsUpdateCommand }
  > = (props) => {
    const { trialIdentityId, data } = props ?? {};

    return putApiV2TrialidentitiesTrialIdentityId(
      trialIdentityId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2TrialidentitiesTrialIdentityIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>
>;
export type PutApiV2TrialidentitiesTrialIdentityIdMutationBody =
  V2TrialIdentitiesCommandsUpdateCommand;
export type PutApiV2TrialidentitiesTrialIdentityIdMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2TrialidentitiesTrialIdentityId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>,
    TError,
    { trialIdentityId: number; data: V2TrialIdentitiesCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2TrialidentitiesTrialIdentityId>>,
  TError,
  { trialIdentityId: number; data: V2TrialIdentitiesCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2TrialidentitiesTrialIdentityIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */
export const getApiV2TrialsTrialIdSummary = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesSummaryResponse>(
    { url: `/api/v2/trials/${trialId}/summary`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialsTrialIdSummaryQueryKey = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams
) => {
  return [
    `/api/v2/trials/${trialId}/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTrialIdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialsTrialIdSummaryQueryKey(trialId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>
  > = ({ signal }) =>
    getApiV2TrialsTrialIdSummary(trialId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!trialId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>
>;
export type GetApiV2TrialsTrialIdSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTrialIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params: undefined | GetApiV2TrialsTrialIdSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Scout,GeneralScout,SeniorScout,Coach)
 */

export function useGetApiV2TrialsTrialIdSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdSummaryQueryOptions(
    trialId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Player,Coach)
 */
export const getApiV2TrialsTrialIdTrialinstructions = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesTrialInstructionsListResponse>(
    {
      url: `/api/v2/trials/${trialId}/trialinstructions`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialsTrialIdTrialinstructionsQueryKey = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams
) => {
  return [
    `/api/v2/trials/${trialId}/trialinstructions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTrialIdTrialinstructionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialsTrialIdTrialinstructionsQueryKey(trialId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>
  > = ({ signal }) =>
    getApiV2TrialsTrialIdTrialinstructions(
      trialId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdTrialinstructionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>
>;
export type GetApiV2TrialsTrialIdTrialinstructionsQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialsTrialIdTrialinstructions<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params: undefined | GetApiV2TrialsTrialIdTrialinstructionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdTrialinstructions<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdTrialinstructions<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Player,Coach)
 */

export function useGetApiV2TrialsTrialIdTrialinstructions<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdTrialinstructionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdTrialinstructions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdTrialinstructionsQueryOptions(
    trialId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialsTrialIdEntriesSummary = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesEntriesSummaryListResponse>(
    {
      url: `/api/v2/trials/${trialId}/entries/summary`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialsTrialIdEntriesSummaryQueryKey = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams
) => {
  return [
    `/api/v2/trials/${trialId}/entries/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTrialIdEntriesSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialsTrialIdEntriesSummaryQueryKey(trialId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>
  > = ({ signal }) =>
    getApiV2TrialsTrialIdEntriesSummary(
      trialId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdEntriesSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>
>;
export type GetApiV2TrialsTrialIdEntriesSummaryQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTrialIdEntriesSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params: undefined | GetApiV2TrialsTrialIdEntriesSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialsTrialIdEntriesSummary<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdEntriesSummaryQueryOptions(
    trialId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialsTrialIdEntriesSummaryCount = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesEntriesSummaryCountResponse>(
    {
      url: `/api/v2/trials/${trialId}/entries/summary/count`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2TrialsTrialIdEntriesSummaryCountQueryKey = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams
) => {
  return [
    `/api/v2/trials/${trialId}/entries/summary/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTrialIdEntriesSummaryCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialsTrialIdEntriesSummaryCountQueryKey(trialId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>
  > = ({ signal }) =>
    getApiV2TrialsTrialIdEntriesSummaryCount(
      trialId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!trialId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdEntriesSummaryCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>
>;
export type GetApiV2TrialsTrialIdEntriesSummaryCountQueryError =
  ErrorType<unknown>;

export function useGetApiV2TrialsTrialIdEntriesSummaryCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params: undefined | GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesSummaryCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesSummaryCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialsTrialIdEntriesSummaryCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdEntriesSummaryCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesSummaryCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdEntriesSummaryCountQueryOptions(
    trialId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialsTrialIdEntriesEntryId = (
  trialId: number,
  entryId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesEntriesDetailsResponse>(
    {
      url: `/api/v2/trials/${trialId}/entries/${entryId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2TrialsTrialIdEntriesEntryIdQueryKey = (
  trialId: number,
  entryId: number
) => {
  return [`/api/v2/trials/${trialId}/entries/${entryId}`] as const;
};

export const getGetApiV2TrialsTrialIdEntriesEntryIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  entryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2TrialsTrialIdEntriesEntryIdQueryKey(trialId, entryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>
  > = ({ signal }) =>
    getApiV2TrialsTrialIdEntriesEntryId(
      trialId,
      entryId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(trialId && entryId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdEntriesEntryIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>
>;
export type GetApiV2TrialsTrialIdEntriesEntryIdQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTrialIdEntriesEntryId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  entryId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesEntryId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  entryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialIdEntriesEntryId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  entryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialsTrialIdEntriesEntryId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  entryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialIdEntriesEntryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdEntriesEntryIdQueryOptions(
    trialId,
    entryId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2TrialsTrialId = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesDetailsResponse>(
    { url: `/api/v2/trials/${trialId}`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialsTrialIdQueryKey = (
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams
) => {
  return [`/api/v2/trials/${trialId}`, ...(params ? [params] : [])] as const;
};

export const getGetApiV2TrialsTrialIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialsTrialIdQueryKey(trialId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTrialId>>
  > = ({ signal }) =>
    getApiV2TrialsTrialId(trialId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!trialId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTrialIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTrialId>>
>;
export type GetApiV2TrialsTrialIdQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTrialId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params: undefined | GetApiV2TrialsTrialIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTrialId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2TrialsTrialId<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
  TError = ErrorType<unknown>
>(
  trialId: number,
  params?: GetApiV2TrialsTrialIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTrialId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTrialIdQueryOptions(
    trialId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const putApiV2TrialsTrialId = (
  trialId: number,
  v2TrialsCommandsUpdateCommand: V2TrialsCommandsUpdateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/trials/${trialId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialsCommandsUpdateCommand,
    },
    options
  );
};

export const getPutApiV2TrialsTrialIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialsTrialId>>,
    TError,
    { trialId: number; data: V2TrialsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2TrialsTrialId>>,
  TError,
  { trialId: number; data: V2TrialsCommandsUpdateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2TrialsTrialId>>,
    { trialId: number; data: V2TrialsCommandsUpdateCommand }
  > = (props) => {
    const { trialId, data } = props ?? {};

    return putApiV2TrialsTrialId(trialId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2TrialsTrialIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2TrialsTrialId>>
>;
export type PutApiV2TrialsTrialIdMutationBody = V2TrialsCommandsUpdateCommand;
export type PutApiV2TrialsTrialIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePutApiV2TrialsTrialId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2TrialsTrialId>>,
    TError,
    { trialId: number; data: V2TrialsCommandsUpdateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2TrialsTrialId>>,
  TError,
  { trialId: number; data: V2TrialsCommandsUpdateCommand },
  TContext
> => {
  const mutationOptions = getPutApiV2TrialsTrialIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2TrialsTrialId = (
  trialId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/trials/${trialId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2TrialsTrialIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>,
  TError,
  { trialId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>,
    { trialId: number }
  > = (props) => {
    const { trialId } = props ?? {};

    return deleteApiV2TrialsTrialId(trialId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2TrialsTrialIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>
>;

export type DeleteApiV2TrialsTrialIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2TrialsTrialId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialId>>,
  TError,
  { trialId: number },
  TContext
> => {
  const mutationOptions = getDeleteApiV2TrialsTrialIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2TrialsTrialIdClone = (
  trialId: number,
  v2TrialsCommandsCloneCommand: V2TrialsCommandsCloneCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/trials/${trialId}/clone`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialsCommandsCloneCommand,
    },
    options
  );
};

export const getPostApiV2TrialsTrialIdCloneMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>,
    TError,
    { trialId: number; data: V2TrialsCommandsCloneCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>,
  TError,
  { trialId: number; data: V2TrialsCommandsCloneCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>,
    { trialId: number; data: V2TrialsCommandsCloneCommand }
  > = (props) => {
    const { trialId, data } = props ?? {};

    return postApiV2TrialsTrialIdClone(trialId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2TrialsTrialIdCloneMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>
>;
export type PostApiV2TrialsTrialIdCloneMutationBody =
  V2TrialsCommandsCloneCommand;
export type PostApiV2TrialsTrialIdCloneMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2TrialsTrialIdClone = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>,
    TError,
    { trialId: number; data: V2TrialsCommandsCloneCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2TrialsTrialIdClone>>,
  TError,
  { trialId: number; data: V2TrialsCommandsCloneCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2TrialsTrialIdCloneMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const postApiV2Trials = (
  v2TrialsCommandsCreateCommand: V2TrialsCommandsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesCreatedResponse1SystemInt64SystemPrivateCoreLibVersion8000CultureNeutralPublicKeyToken7cec85d7bea7798e>(
    {
      url: `/api/v2/trials`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialsCommandsCreateCommand,
    },
    options
  );
};

export const getPostApiV2TrialsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trials>>,
    TError,
    { data: V2TrialsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2Trials>>,
  TError,
  { data: V2TrialsCommandsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2Trials>>,
    { data: V2TrialsCommandsCreateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2Trials(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2TrialsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Trials>>
>;
export type PostApiV2TrialsMutationBody = V2TrialsCommandsCreateCommand;
export type PostApiV2TrialsMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePostApiV2Trials = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2Trials>>,
    TError,
    { data: V2TrialsCommandsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2Trials>>,
  TError,
  { data: V2TrialsCommandsCreateCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2TrialsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2TrialsTrialIdCache = (
  trialId: number,
  v2TrialsCommandsCacheDeleteCommand: V2TrialsCommandsCacheDeleteCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/trials/${trialId}/cache`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialsCommandsCacheDeleteCommand,
    },
    options
  );
};

export const getDeleteApiV2TrialsTrialIdCacheMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>,
    TError,
    { trialId: number; data: V2TrialsCommandsCacheDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>,
  TError,
  { trialId: number; data: V2TrialsCommandsCacheDeleteCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>,
    { trialId: number; data: V2TrialsCommandsCacheDeleteCommand }
  > = (props) => {
    const { trialId, data } = props ?? {};

    return deleteApiV2TrialsTrialIdCache(trialId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2TrialsTrialIdCacheMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>
>;
export type DeleteApiV2TrialsTrialIdCacheMutationBody =
  V2TrialsCommandsCacheDeleteCommand;
export type DeleteApiV2TrialsTrialIdCacheMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2TrialsTrialIdCache = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>,
    TError,
    { trialId: number; data: V2TrialsCommandsCacheDeleteCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2TrialsTrialIdCache>>,
  TError,
  { trialId: number; data: V2TrialsCommandsCacheDeleteCommand },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2TrialsTrialIdCacheMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2TrialsTrialIdEnabled = (
  trialId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/trials/${trialId}/enabled`, method: 'PATCH' },
    options
  );
};

export const getPatchApiV2TrialsTrialIdEnabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>,
  TError,
  { trialId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>,
    { trialId: number }
  > = (props) => {
    const { trialId } = props ?? {};

    return patchApiV2TrialsTrialIdEnabled(trialId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2TrialsTrialIdEnabledMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>
>;

export type PatchApiV2TrialsTrialIdEnabledMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2TrialsTrialIdEnabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdEnabled>>,
  TError,
  { trialId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrialsTrialIdEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const patchApiV2TrialsTrialIdDisabled = (
  trialId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    { url: `/api/v2/trials/${trialId}/disabled`, method: 'PATCH' },
    options
  );
};

export const getPatchApiV2TrialsTrialIdDisabledMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>,
  TError,
  { trialId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>,
    { trialId: number }
  > = (props) => {
    const { trialId } = props ?? {};

    return patchApiV2TrialsTrialIdDisabled(trialId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV2TrialsTrialIdDisabledMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>
>;

export type PatchApiV2TrialsTrialIdDisabledMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const usePatchApiV2TrialsTrialIdDisabled = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>,
    TError,
    { trialId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiV2TrialsTrialIdDisabled>>,
  TError,
  { trialId: number },
  TContext
> => {
  const mutationOptions =
    getPatchApiV2TrialsTrialIdDisabledMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const postApiV2TrialsTurkentriesEntryIdRatings = (
  entryId: number,
  v2TrialsCommandsTurkEntriesRatingsCreateCommand: V2TrialsCommandsTurkEntriesRatingsCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesBaseResponse>(
    {
      url: `/api/v2/trials/turkentries/${entryId}/ratings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2TrialsCommandsTurkEntriesRatingsCreateCommand,
    },
    options
  );
};

export const getPostApiV2TrialsTurkentriesEntryIdRatingsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>,
    TError,
    { entryId: number; data: V2TrialsCommandsTurkEntriesRatingsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>,
  TError,
  { entryId: number; data: V2TrialsCommandsTurkEntriesRatingsCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>,
    { entryId: number; data: V2TrialsCommandsTurkEntriesRatingsCreateCommand }
  > = (props) => {
    const { entryId, data } = props ?? {};

    return postApiV2TrialsTurkentriesEntryIdRatings(
      entryId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2TrialsTurkentriesEntryIdRatingsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>
  >;
export type PostApiV2TrialsTurkentriesEntryIdRatingsMutationBody =
  V2TrialsCommandsTurkEntriesRatingsCreateCommand;
export type PostApiV2TrialsTurkentriesEntryIdRatingsMutationError =
  ErrorType<unknown>;

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const usePostApiV2TrialsTurkentriesEntryIdRatings = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>,
    TError,
    { entryId: number; data: V2TrialsCommandsTurkEntriesRatingsCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2TrialsTurkentriesEntryIdRatings>>,
  TError,
  { entryId: number; data: V2TrialsCommandsTurkEntriesRatingsCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2TrialsTurkentriesEntryIdRatingsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialsTurkentriesNext = (
  params?: GetApiV2TrialsTurkentriesNextParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesTurkEntriesNextEntryResponse>(
    { url: `/api/v2/trials/turkentries/next`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialsTurkentriesNextQueryKey = (
  params?: GetApiV2TrialsTurkentriesNextParams
) => {
  return [
    `/api/v2/trials/turkentries/next`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTurkentriesNextQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesNextParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialsTurkentriesNextQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>
  > = ({ signal }) =>
    getApiV2TrialsTurkentriesNext(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTurkentriesNextQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>
>;
export type GetApiV2TrialsTurkentriesNextQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTurkentriesNext<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TrialsTurkentriesNextParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTurkentriesNext<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesNextParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTurkentriesNext<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesNextParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialsTurkentriesNext<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesNextParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesNext>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTurkentriesNextQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */
export const getApiV2TrialsTurkentriesCount = (
  params?: GetApiV2TrialsTurkentriesCountParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2TrialsQueriesTurkEntriesCountResponse>(
    { url: `/api/v2/trials/turkentries/count`, method: 'GET', params, signal },
    options
  );
};

export const getGetApiV2TrialsTurkentriesCountQueryKey = (
  params?: GetApiV2TrialsTurkentriesCountParams
) => {
  return [
    `/api/v2/trials/turkentries/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2TrialsTurkentriesCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2TrialsTurkentriesCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>
  > = ({ signal }) =>
    getApiV2TrialsTurkentriesCount(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2TrialsTurkentriesCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>
>;
export type GetApiV2TrialsTurkentriesCountQueryError = ErrorType<unknown>;

export function useGetApiV2TrialsTurkentriesCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
  TError = ErrorType<unknown>
>(
  params: undefined | GetApiV2TrialsTurkentriesCountParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTurkentriesCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2TrialsTurkentriesCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Scout,GeneralScout,SeniorScout)
 */

export function useGetApiV2TrialsTurkentriesCount<
  TData = Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetApiV2TrialsTurkentriesCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2TrialsTurkentriesCount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2TrialsTurkentriesCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postApiV2UsersLogin = (
  v3UsersCommandsLoginCommand: V3UsersCommandsLoginCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsLoginResponse>(
    {
      url: `/api/v2/users/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsLoginCommand,
    },
    options
  );
};

export const getPostApiV2UsersLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersLogin>>,
    TError,
    { data: V3UsersCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersLogin>>,
  TError,
  { data: V3UsersCommandsLoginCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersLogin>>,
    { data: V3UsersCommandsLoginCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2UsersLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersLogin>>
>;
export type PostApiV2UsersLoginMutationBody = V3UsersCommandsLoginCommand;
export type PostApiV2UsersLoginMutationError = ErrorType<unknown>;

export const usePostApiV2UsersLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersLogin>>,
    TError,
    { data: V3UsersCommandsLoginCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersLogin>>,
  TError,
  { data: V3UsersCommandsLoginCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2UsersLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdRefreshtokens = (
  userId: string,
  v3UsersCommandsUserRefreshTokensCreateCommand: V3UsersCommandsUserRefreshTokensCreateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsUserRefreshTokensCreateResponse>(
    {
      url: `/api/v2/users/${userId}/refreshtokens`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsUserRefreshTokensCreateCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdRefreshtokensMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>,
    TError,
    { userId: string; data: V3UsersCommandsUserRefreshTokensCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>,
  TError,
  { userId: string; data: V3UsersCommandsUserRefreshTokensCreateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>,
    { userId: string; data: V3UsersCommandsUserRefreshTokensCreateCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdRefreshtokens(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdRefreshtokensMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>
>;
export type PostApiV2UsersUserIdRefreshtokensMutationBody =
  V3UsersCommandsUserRefreshTokensCreateCommand;
export type PostApiV2UsersUserIdRefreshtokensMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdRefreshtokens = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>,
    TError,
    { userId: string; data: V3UsersCommandsUserRefreshTokensCreateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdRefreshtokens>>,
  TError,
  { userId: string; data: V3UsersCommandsUserRefreshTokensCreateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdRefreshtokensMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putApiV2UsersUserIdPassword = (
  userId: string,
  v3UsersCommandsChangePasswordCommand: V3UsersCommandsChangePasswordCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/${userId}/password`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsChangePasswordCommand,
    },
    options
  );
};

export const getPutApiV2UsersUserIdPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>,
    TError,
    { userId: string; data: V3UsersCommandsChangePasswordCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>,
  TError,
  { userId: string; data: V3UsersCommandsChangePasswordCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>,
    { userId: string; data: V3UsersCommandsChangePasswordCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return putApiV2UsersUserIdPassword(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2UsersUserIdPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>
>;
export type PutApiV2UsersUserIdPasswordMutationBody =
  V3UsersCommandsChangePasswordCommand;
export type PutApiV2UsersUserIdPasswordMutationError = ErrorType<unknown>;

export const usePutApiV2UsersUserIdPassword = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>,
    TError,
    { userId: string; data: V3UsersCommandsChangePasswordCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2UsersUserIdPassword>>,
  TError,
  { userId: string; data: V3UsersCommandsChangePasswordCommand },
  TContext
> => {
  const mutationOptions =
    getPutApiV2UsersUserIdPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersPasswordReset = (
  v3UsersCommandsResetPasswordSendEmailCommand: V3UsersCommandsResetPasswordSendEmailCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/password/reset`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsResetPasswordSendEmailCommand,
    },
    options
  );
};

export const getPostApiV2UsersPasswordResetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>,
    TError,
    { data: V3UsersCommandsResetPasswordSendEmailCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>,
  TError,
  { data: V3UsersCommandsResetPasswordSendEmailCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>,
    { data: V3UsersCommandsResetPasswordSendEmailCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2UsersPasswordReset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersPasswordResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>
>;
export type PostApiV2UsersPasswordResetMutationBody =
  V3UsersCommandsResetPasswordSendEmailCommand;
export type PostApiV2UsersPasswordResetMutationError = ErrorType<unknown>;

export const usePostApiV2UsersPasswordReset = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>,
    TError,
    { data: V3UsersCommandsResetPasswordSendEmailCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersPasswordReset>>,
  TError,
  { data: V3UsersCommandsResetPasswordSendEmailCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersPasswordResetMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putApiV2UsersPasswordReset = (
  v3UsersCommandsResetPasswordResetCommand: V3UsersCommandsResetPasswordResetCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/password/reset`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsResetPasswordResetCommand,
    },
    options
  );
};

export const getPutApiV2UsersPasswordResetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>,
    TError,
    { data: V3UsersCommandsResetPasswordResetCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>,
  TError,
  { data: V3UsersCommandsResetPasswordResetCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>,
    { data: V3UsersCommandsResetPasswordResetCommand }
  > = (props) => {
    const { data } = props ?? {};

    return putApiV2UsersPasswordReset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2UsersPasswordResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>
>;
export type PutApiV2UsersPasswordResetMutationBody =
  V3UsersCommandsResetPasswordResetCommand;
export type PutApiV2UsersPasswordResetMutationError = ErrorType<unknown>;

export const usePutApiV2UsersPasswordReset = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>,
    TError,
    { data: V3UsersCommandsResetPasswordResetCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2UsersPasswordReset>>,
  TError,
  { data: V3UsersCommandsResetPasswordResetCommand },
  TContext
> => {
  const mutationOptions = getPutApiV2UsersPasswordResetMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersPasswordResetCodevalidate = (
  v3UsersCommandsResetPasswordCodeValidateCommand: V3UsersCommandsResetPasswordCodeValidateCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/password/reset/codevalidate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsResetPasswordCodeValidateCommand,
    },
    options
  );
};

export const getPostApiV2UsersPasswordResetCodevalidateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>,
    TError,
    { data: V3UsersCommandsResetPasswordCodeValidateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>,
  TError,
  { data: V3UsersCommandsResetPasswordCodeValidateCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>,
    { data: V3UsersCommandsResetPasswordCodeValidateCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiV2UsersPasswordResetCodevalidate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersPasswordResetCodevalidateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>
>;
export type PostApiV2UsersPasswordResetCodevalidateMutationBody =
  V3UsersCommandsResetPasswordCodeValidateCommand;
export type PostApiV2UsersPasswordResetCodevalidateMutationError =
  ErrorType<unknown>;

export const usePostApiV2UsersPasswordResetCodevalidate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>,
    TError,
    { data: V3UsersCommandsResetPasswordCodeValidateCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersPasswordResetCodevalidate>>,
  TError,
  { data: V3UsersCommandsResetPasswordCodeValidateCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersPasswordResetCodevalidateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdLogout = (
  userId: string,
  v3UsersCommandsLogoutCommand: V3UsersCommandsLogoutCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/${userId}/logout`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsLogoutCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdLogoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>,
    TError,
    { userId: string; data: V3UsersCommandsLogoutCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>,
  TError,
  { userId: string; data: V3UsersCommandsLogoutCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>,
    { userId: string; data: V3UsersCommandsLogoutCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdLogout(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>
>;
export type PostApiV2UsersUserIdLogoutMutationBody =
  V3UsersCommandsLogoutCommand;
export type PostApiV2UsersUserIdLogoutMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdLogout = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>,
    TError,
    { userId: string; data: V3UsersCommandsLogoutCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdLogout>>,
  TError,
  { userId: string; data: V3UsersCommandsLogoutCommand },
  TContext
> => {
  const mutationOptions = getPostApiV2UsersUserIdLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdSwitchGuardians = (
  userId: string,
  v3UsersCommandsSwitchesGuardiansCommand: V3UsersCommandsSwitchesGuardiansCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsSwitchesGuardiansResponse>(
    {
      url: `/api/v2/users/${userId}/switch/guardians`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsSwitchesGuardiansCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdSwitchGuardiansMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesGuardiansCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesGuardiansCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>,
    { userId: string; data: V3UsersCommandsSwitchesGuardiansCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdSwitchGuardians(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdSwitchGuardiansMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>
>;
export type PostApiV2UsersUserIdSwitchGuardiansMutationBody =
  V3UsersCommandsSwitchesGuardiansCommand;
export type PostApiV2UsersUserIdSwitchGuardiansMutationError =
  ErrorType<unknown>;

export const usePostApiV2UsersUserIdSwitchGuardians = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesGuardiansCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchGuardians>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesGuardiansCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdSwitchGuardiansMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdSwitchCoaches = (
  userId: string,
  v3UsersCommandsSwitchesCoachesCommand: V3UsersCommandsSwitchesCoachesCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsSwitchesCoachesResponse>(
    {
      url: `/api/v2/users/${userId}/switch/coaches`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsSwitchesCoachesCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdSwitchCoachesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesCoachesCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesCoachesCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>,
    { userId: string; data: V3UsersCommandsSwitchesCoachesCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdSwitchCoaches(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdSwitchCoachesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>
>;
export type PostApiV2UsersUserIdSwitchCoachesMutationBody =
  V3UsersCommandsSwitchesCoachesCommand;
export type PostApiV2UsersUserIdSwitchCoachesMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdSwitchCoaches = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesCoachesCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchCoaches>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesCoachesCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdSwitchCoachesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdSwitchScouts = (
  userId: string,
  v3UsersCommandsSwitchesScoutsCommand: V3UsersCommandsSwitchesScoutsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsSwitchesScoutsResponse>(
    {
      url: `/api/v2/users/${userId}/switch/scouts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsSwitchesScoutsCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdSwitchScoutsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesScoutsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesScoutsCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>,
    { userId: string; data: V3UsersCommandsSwitchesScoutsCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdSwitchScouts(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdSwitchScoutsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>
>;
export type PostApiV2UsersUserIdSwitchScoutsMutationBody =
  V3UsersCommandsSwitchesScoutsCommand;
export type PostApiV2UsersUserIdSwitchScoutsMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdSwitchScouts = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesScoutsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchScouts>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesScoutsCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdSwitchScoutsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiV2UsersUserIdSwitchAdmins = (
  userId: string,
  v3UsersCommandsSwitchesAdminsCommand: V3UsersCommandsSwitchesAdminsCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V3UsersCommandsSwitchesAdminsResponse>(
    {
      url: `/api/v2/users/${userId}/switch/admins`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v3UsersCommandsSwitchesAdminsCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdSwitchAdminsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesAdminsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesAdminsCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>,
    { userId: string; data: V3UsersCommandsSwitchesAdminsCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdSwitchAdmins(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdSwitchAdminsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>
>;
export type PostApiV2UsersUserIdSwitchAdminsMutationBody =
  V3UsersCommandsSwitchesAdminsCommand;
export type PostApiV2UsersUserIdSwitchAdminsMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdSwitchAdmins = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>,
    TError,
    { userId: string; data: V3UsersCommandsSwitchesAdminsCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchAdmins>>,
  TError,
  { userId: string; data: V3UsersCommandsSwitchesAdminsCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdSwitchAdminsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2UsersUserIdUsersettings = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2UsersQueriesUserSettingsResponse>(
    { url: `/api/v2/users/${userId}/usersettings`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2UsersUserIdUsersettingsQueryKey = (userId: string) => {
  return [`/api/v2/users/${userId}/usersettings`] as const;
};

export const getGetApiV2UsersUserIdUsersettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2UsersUserIdUsersettingsQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>
  > = ({ signal }) =>
    getApiV2UsersUserIdUsersettings(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2UsersUserIdUsersettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>
>;
export type GetApiV2UsersUserIdUsersettingsQueryError = ErrorType<unknown>;

export function useGetApiV2UsersUserIdUsersettings<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdUsersettings<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdUsersettings<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2UsersUserIdUsersettings<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdUsersettings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2UsersUserIdUsersettingsQueryOptions(
    userId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postApiV2UsersUserIdUsersettings = (
  userId: string,
  v2UsersCommandsUserSettingsUpsertCommand: V2UsersCommandsUserSettingsUpsertCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    {
      url: `/api/v2/users/${userId}/usersettings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2UsersCommandsUserSettingsUpsertCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdUsersettingsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>,
    TError,
    { userId: string; data: V2UsersCommandsUserSettingsUpsertCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>,
  TError,
  { userId: string; data: V2UsersCommandsUserSettingsUpsertCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>,
    { userId: string; data: V2UsersCommandsUserSettingsUpsertCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdUsersettings(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdUsersettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>
>;
export type PostApiV2UsersUserIdUsersettingsMutationBody =
  V2UsersCommandsUserSettingsUpsertCommand;
export type PostApiV2UsersUserIdUsersettingsMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdUsersettings = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>,
    TError,
    { userId: string; data: V2UsersCommandsUserSettingsUpsertCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdUsersettings>>,
  TError,
  { userId: string; data: V2UsersCommandsUserSettingsUpsertCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdUsersettingsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary  (Auth roles: Administrator)
 */
export const getApiV2UsersUserIdRoles = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2UsersQueriesRolesResponse>(
    { url: `/api/v2/users/${userId}/roles`, method: 'GET', signal },
    options
  );
};

export const getGetApiV2UsersUserIdRolesQueryKey = (userId: string) => {
  return [`/api/v2/users/${userId}/roles`] as const;
};

export const getGetApiV2UsersUserIdRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV2UsersUserIdRolesQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>
  > = ({ signal }) => getApiV2UsersUserIdRoles(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2UsersUserIdRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>
>;
export type GetApiV2UsersUserIdRolesQueryError = ErrorType<unknown>;

export function useGetApiV2UsersUserIdRoles<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdRoles<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdRoles<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary  (Auth roles: Administrator)
 */

export function useGetApiV2UsersUserIdRoles<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdRoles>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2UsersUserIdRolesQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  (Auth roles: Administrator)
 */
export const deleteApiV2UsersUserIdRolesRoleId = (
  userId: string,
  roleId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesNoContentResponse>(
    { url: `/api/v2/users/${userId}/roles/${roleId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteApiV2UsersUserIdRolesRoleIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>,
    TError,
    { userId: string; roleId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>,
  TError,
  { userId: string; roleId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>,
    { userId: string; roleId: string }
  > = (props) => {
    const { userId, roleId } = props ?? {};

    return deleteApiV2UsersUserIdRolesRoleId(userId, roleId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiV2UsersUserIdRolesRoleIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>
>;

export type DeleteApiV2UsersUserIdRolesRoleIdMutationError = ErrorType<unknown>;

/**
 * @summary  (Auth roles: Administrator)
 */
export const useDeleteApiV2UsersUserIdRolesRoleId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>,
    TError,
    { userId: string; roleId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiV2UsersUserIdRolesRoleId>>,
  TError,
  { userId: string; roleId: string },
  TContext
> => {
  const mutationOptions =
    getDeleteApiV2UsersUserIdRolesRoleIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiV2UsersUserIdNotificationsStats = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2UsersQueriesNotificationsStatsResponse>(
    {
      url: `/api/v2/users/${userId}/notifications/stats`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetApiV2UsersUserIdNotificationsStatsQueryKey = (
  userId: string
) => {
  return [`/api/v2/users/${userId}/notifications/stats`] as const;
};

export const getGetApiV2UsersUserIdNotificationsStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2UsersUserIdNotificationsStatsQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>
  > = ({ signal }) =>
    getApiV2UsersUserIdNotificationsStats(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2UsersUserIdNotificationsStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>
>;
export type GetApiV2UsersUserIdNotificationsStatsQueryError =
  ErrorType<unknown>;

export function useGetApiV2UsersUserIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2UsersUserIdNotificationsStats<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotificationsStats>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2UsersUserIdNotificationsStatsQueryOptions(
    userId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const putApiV2UsersUserIdNotificationsSeen = (
  userId: string,
  params: PutApiV2UsersUserIdNotificationsSeenParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/${userId}/notifications/seen`,
      method: 'PUT',
      params,
    },
    options
  );
};

export const getPutApiV2UsersUserIdNotificationsSeenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>,
    TError,
    { userId: string; params: PutApiV2UsersUserIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>,
  TError,
  { userId: string; params: PutApiV2UsersUserIdNotificationsSeenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>,
    { userId: string; params: PutApiV2UsersUserIdNotificationsSeenParams }
  > = (props) => {
    const { userId, params } = props ?? {};

    return putApiV2UsersUserIdNotificationsSeen(userId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiV2UsersUserIdNotificationsSeenMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>
>;

export type PutApiV2UsersUserIdNotificationsSeenMutationError =
  ErrorType<unknown>;

export const usePutApiV2UsersUserIdNotificationsSeen = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>,
    TError,
    { userId: string; params: PutApiV2UsersUserIdNotificationsSeenParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiV2UsersUserIdNotificationsSeen>>,
  TError,
  { userId: string; params: PutApiV2UsersUserIdNotificationsSeenParams },
  TContext
> => {
  const mutationOptions =
    getPutApiV2UsersUserIdNotificationsSeenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putApiV2UsersUserIdNotificationsNotificationIdClick = (
  userId: string,
  notificationId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CommonResponsesSuccessResponse>(
    {
      url: `/api/v2/users/${userId}/notifications/${notificationId}/click`,
      method: 'PUT',
    },
    options
  );
};

export const getPutApiV2UsersUserIdNotificationsNotificationIdClickMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
      >,
      TError,
      { userId: string; notificationId: number },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
    >,
    TError,
    { userId: string; notificationId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
      >,
      { userId: string; notificationId: number }
    > = (props) => {
      const { userId, notificationId } = props ?? {};

      return putApiV2UsersUserIdNotificationsNotificationIdClick(
        userId,
        notificationId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutApiV2UsersUserIdNotificationsNotificationIdClickMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
    >
  >;

export type PutApiV2UsersUserIdNotificationsNotificationIdClickMutationError =
  ErrorType<unknown>;

export const usePutApiV2UsersUserIdNotificationsNotificationIdClick = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
    >,
    TError,
    { userId: string; notificationId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putApiV2UsersUserIdNotificationsNotificationIdClick>
  >,
  TError,
  { userId: string; notificationId: number },
  TContext
> => {
  const mutationOptions =
    getPutApiV2UsersUserIdNotificationsNotificationIdClickMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

export const getApiV2UsersUserIdNotifications = (
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<V2UsersQueriesNotificationsListResponse>(
    {
      url: `/api/v2/users/${userId}/notifications`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetApiV2UsersUserIdNotificationsQueryKey = (
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams
) => {
  return [
    `/api/v2/users/${userId}/notifications`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiV2UsersUserIdNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV2UsersUserIdNotificationsQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>
  > = ({ signal }) =>
    getApiV2UsersUserIdNotifications(userId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiV2UsersUserIdNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>
>;
export type GetApiV2UsersUserIdNotificationsQueryError = ErrorType<unknown>;

export function useGetApiV2UsersUserIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  params: undefined | GetApiV2UsersUserIdNotificationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiV2UsersUserIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetApiV2UsersUserIdNotifications<
  TData = Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  params?: GetApiV2UsersUserIdNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getApiV2UsersUserIdNotifications>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApiV2UsersUserIdNotificationsQueryOptions(
    userId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postApiV2UsersUserIdSwitchPlayers = (
  userId: string,
  v2UsersCommandsSwitchesPlayersCommand: V2UsersCommandsSwitchesPlayersCommand,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<V2UsersCommandsSwitchesPlayersResponse>(
    {
      url: `/api/v2/users/${userId}/switch/players`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: v2UsersCommandsSwitchesPlayersCommand,
    },
    options
  );
};

export const getPostApiV2UsersUserIdSwitchPlayersMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>,
    TError,
    { userId: string; data: V2UsersCommandsSwitchesPlayersCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>,
  TError,
  { userId: string; data: V2UsersCommandsSwitchesPlayersCommand },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>,
    { userId: string; data: V2UsersCommandsSwitchesPlayersCommand }
  > = (props) => {
    const { userId, data } = props ?? {};

    return postApiV2UsersUserIdSwitchPlayers(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV2UsersUserIdSwitchPlayersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>
>;
export type PostApiV2UsersUserIdSwitchPlayersMutationBody =
  V2UsersCommandsSwitchesPlayersCommand;
export type PostApiV2UsersUserIdSwitchPlayersMutationError = ErrorType<unknown>;

export const usePostApiV2UsersUserIdSwitchPlayers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>,
    TError,
    { userId: string; data: V2UsersCommandsSwitchesPlayersCommand },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiV2UsersUserIdSwitchPlayers>>,
  TError,
  { userId: string; data: V2UsersCommandsSwitchesPlayersCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiV2UsersUserIdSwitchPlayersMutationOptions(options);

  return useMutation(mutationOptions);
};

import Router from 'next/router';

import { fallbackLocale } from './getRegionalDate';

export const defaultFormat = 'dd/MM/yyyy';
const USFormat = 'MM/dd/yyyy';

const getDatePickerReginalFormat = () => {
  const locale = Router.locale ?? fallbackLocale;

  if (locale === 'en-US') {
    return USFormat;
  }

  return defaultFormat;
};

export default getDatePickerReginalFormat;

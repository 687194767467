import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { env } from 'env.mjs';

const isAiscoutDomain =
  typeof window !== 'undefined' &&
  window.location.origin === env.NEXT_PUBLIC_BASE_PATH_AISCOUT;
const isDevelopment = env.NEXT_PUBLIC_NODE_ENV === 'development';

const baseURL =
  isAiscoutDomain || isDevelopment
    ? env.NEXT_PUBLIC_CALCEY_API_BASE_URL_RAW
    : '/api/calceyProxy';

export const AXIOS_INSTANCE = Axios.create({
  baseURL,
});

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    baseURL,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

import { DateTime } from 'luxon';
import Router from 'next/router';
import { DateTimeFormatOptions } from 'next-intl';

import { defaultFormat } from './getDatePickerRegionalFormat';

export const fallbackLocale = 'en-GB';
const defaultDateString = '';
const commonOptions: DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
export const monthOptions: DateTimeFormatOptions = {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
};

// JS Date will not convert if the string is in dd/MM/yyyy format.
// Since the date may come in different formats, use Luxon to convert it to a valid date.
const getValidDateForOtherFormat = (
  date: string | undefined,
  format?: string | undefined
) => {
  if (!date) throw new Error('Invalid date');

  const luxonDate = DateTime.fromFormat(date, format ?? defaultFormat);

  if (luxonDate.isValid) {
    return new Date(luxonDate.toString());
  }

  throw new Error('Invalid date');
};

const getValidDate = (date: string | undefined) => {
  if (!date) throw new Error('Invalid date');

  const newDate = new Date(date);

  if (newDate.getDate()) {
    return newDate;
  }

  getValidDateForOtherFormat(date);
};

const getRegionalDate = (
  date: string | undefined,
  options?: DateTimeFormatOptions,
  format?: string
) => {
  const locale = Router.locale ?? fallbackLocale;

  try {
    const validatedDate = format
      ? getValidDateForOtherFormat(date, format)
      : getValidDate(date);

    return Intl.DateTimeFormat(locale, { ...commonOptions, ...options }).format(
      validatedDate
    );
  } catch {
    return defaultDateString;
  }
};

export default getRegionalDate;

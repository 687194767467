import React from 'react';
import { animated } from 'react-spring';

interface Props {
  children: React.ReactNode;
}

export const LoadingText = ({ children }: Props) => {
  return <animated.div>{children}</animated.div>;
};

export default LoadingText;
